import React, { useState, useEffect,useRef } from 'react'
import moment from 'moment'
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { Scrollbars } from 'react-custom-scrollbars';

import { Container, Row, Col, DropdownButton, Dropdown } from 'react-bootstrap';
import { CommonHeading } from '../../common/commonHeading';
import '../styles/siteManagement.scss'
import DashboardLanguage from '../../components/dashboardLanguage';
import DatePicker from "react-datepicker";


import selectedPinkArrowIcon from '../../assets/traceplusImages/pink_right_arrow_icon.svg'
import filterIcon from '../../assets/traceplusImages/filter.png'
import { getSiteLocations, locationChart } from '../actionMethods/actionMethods';

import spinnerLoader from '../../assets/images/Spinner Loader.gif'
import helpIcon from '../../assets/traceplusImages/help-icon.png'
import sortIcon from '../../assets/traceplusImages/sort.png'
import upIcon from '../../assets/traceplusImages/up-arrow.png'
import downIcon from '../../assets/traceplusImages/down-arrow.png'
import CommonDatePicker from '../../common/commonDatePicker';
import BarChart from './barChart'

import '../../dashboard/styles/dashboard.scss'
import { getTranslatedText } from '../../common/utilities';
import { getLanguageTranslation, setSelectedLanguage } from '../../dashboard/actionMethods/actionMethods';
import { atRiskLocation } from '../../manPowerManagement/actionMethods/actionMethods';
import {ReactComponent as Image404 } from  '../../assets/images/11132-ai.svg'
import {AgGridColumn, AgGridReact} from 'ag-grid-react';

import mqtt from "mqtt";

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import DeviceStatus from '../../components/DeviceStatus';
import {ReactComponent as Plus} from  '../../assets/images/caret-right.svg'
import {ReactComponent as Minus} from '../../assets/images/caret-down.svg'
import mqttService from '../../components/mqttService'


const riskLevelColor = {
    "low": '#04e06e',
    "medium": "#ffd700",
    "high": "#ff9696"
}

function SiteMangementList(props) {
    let date = localStorage.getItem('selectedDate') ? new Date(localStorage.getItem('selectedDate')) : new Date()

    const [siteLocationsList, updateSiteLocationsList] = useState([])
    const [preDefinedSiteLocationsList, updatePreDefinedSiteLocationList] = useState([])
    const [selectedLangValue, updateSelectedLangValue] = useState('en')
    const [searchValue, updateSearchValue] = useState('')
    const [globalSearch, setGlobalSearch] = useState('')
    const [globalCategory, setGlobalCategory] = useState('Location')
    const [suggestions, setSuggestions] = useState([])
    const [selectedDate, updateSelectedDate] = useState(date)
    const [isLoading, updateIsLoading] = useState(true)
    const [sortKey, setSortKey] = useState('area')
    const [sortType, setSortType] = useState('desc')
    const [activetab, setActiveTab] = useState('All')
    const [chartDates, setChartDates] = useState({
        start: moment().subtract(29, "days").toDate(),
        end: new Date(moment(date).format('YYYY-MM-DD 23:59:59')),
    });
    const [lessproductive,SetLessProductive]=useState([]);
    const[nonProductive,SetNonProductive] = useState([])
    const [productive,SetProductive] = useState([]);
    const [chartData, setChartData] = useState({
        attendance: { categories: [], series: [] },
        spreader: { categories: [], series: [] },
        bridger: { categories: [], series: [] },
      });
      const [activeAccord,SetActiveAccord] = useState([])
    const [chartLoader, setChartLoader] = useState(true);

    let userDetails = JSON.parse(localStorage.getItem('userLoginDetails'))
    let userSession = userDetails ? userDetails.session : '123456789'
    let org_id = userDetails ? userDetails.org_id : 6
    let reqId = null
    let totalEmployeeTag = localStorage.getItem('totalEmployeeTag') ? localStorage.getItem('totalEmployeeTag') : 0;
    let totalSiteLocation = localStorage.getItem('totalSiteLocation') ? localStorage.getItem('totalSiteLocation') : 0;
    const [widthContainer, setWidthContainer] = useState(0);
    const elementRef = useRef(null);   
    const [role,SetRole] = useState([]);
    const [permission,SetPermission] =useState([])
    const [permissionAPICall,SetPermissionAPICall] = useState(false);
    const [client,SetClient]=useState(null)
    const [mesg, setMesg] = useState(<><em>nothing heard</em></>);
    

  
    useEffect(() => {
      let perm= props.role.web_app_sitemanagement?JSON.parse(props.role.web_app_sitemanagement):null;
      if(perm){
  
         //console.log(perm);
          //perm.View = 'False'
          //perm.Create = 'False'
          //perm.remove = 'False'
          //perm.edit = 'False'

          SetPermission(perm)
          SetPermissionAPICall(true)

      }else{
          //console.log("undefined",perm);
      }
  },[props.role]);
  
    function errorHandler(message){
        //console.log(message)
    }

    useEffect(() => {
        if(permission && permission.View=='True'){
            //console.log(elementRef.current);
            setWidthContainer(elementRef.current.getBoundingClientRect().width);
            //console.log(elementRef.current.getBoundingClientRect().width)
        }
      }, [elementRef,permission]);

    function capitalizeFirstLetter(string) {
        return string.toLowerCase();
      }


    useEffect(() => {
        let requestBody = {}
        if(permission && permission.View=='True'){

        if (props.type && props.type === 'threat') {
            requestBody.start = getDateFormat(props.startDate)
            requestBody.end = getDateFormat(props.endDate)
            requestBody.rank = props.rank

            updateIsLoading(true)
            atRiskLocation(requestBody, userSession, org_id).then(res => {
                updatePreDefinedSiteLocationList(res.data.atrisk_locations)
                updateSiteLocationsList(res.data.atrisk_locations)
                updateIsLoading(false)
            })
        } else {
            requestBody.date = getDateFormat(selectedDate)
            getSiteLocationsValues(requestBody)
        }
    }
    }, [permission]);

    useEffect(() => {
        if (siteLocationsList.length) {
            reqId = setTimeout(() => {
                let arr = siteLocationsList.filter((e) => e.name.toLowerCase().search(globalSearch.toLowerCase()) !== -1)
                setSuggestions(arr)
            }, 300);

            return () => {
                clearTimeout(reqId)
            }
        }
    }, [globalSearch])

    useEffect(() => {
        if(permission && permission.View=='True'){

        getChartData();
        }
    }, [selectedDate, chartDates,permission]);

    function getSiteLocationsValues(requestBody) {
        updateIsLoading(true)
        if(permission && permission.View=='True'){

        getSiteLocations(requestBody, userSession, org_id).then(res => {
            if (res && res.data && res.data.location_details) {
                
                updatePreDefinedSiteLocationList(res.data.location_details)
                updateSiteLocationsList(res.data.location_details)
                updateIsLoading(false)

                //SetLessProductive()
                SetNonProductive(res.data.location_details.filter(location => location.location_type =='non_productive'));
                SetProductive(res.data.location_details.filter(location => location.location_type =='productive'));
                SetLessProductive(res.data.location_details.filter(location => location.location_type =='less_productive'));
            }
        })
        }
    }
    const getChartData = () => {
        setChartLoader(true);
        setChartData({
            attendance: { categories: [], series: [] },
            spreader: { categories: [], series: [] },
            bridger: { categories: [], series: [] },
        });

        let end = getDateFormat(chartDates.end);
        let start = getDateFormat(chartDates.start);

        let obj = {
            start,
            end,
        };
        if(permission && permission.View=='True'){

            locationChart(obj, userSession, org_id).then((res) => {
                if (res.data) {
                    let attendanceCategory = [];
                    let attendanceSeries = [];

                    let spreaderCategory = [];
                    let spreaderSeries = [];

                    let bridgerCategory = [];
                    let bridgerSeries = [];

                    let startDate = new Date(chartDates.start);
                    let endDate = new Date(chartDates.end);

                    for (let i = startDate; i < endDate; i.setDate(i.getDate() + 1)) {
                        let attendance = res.data.area_index || [];
                        let spreader = res.data.formit_index || [];
                        let bridger = res.data.risk_location || [];

                        let d = moment(startDate).format("YYYY-MM-DD");
                        let displayDate = moment(d).format("DD MMM");

                        let days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
                        let d2 = new Date(startDate);
                        let dayName = days[d2.getDay()];


                        let atIndex = attendance.findIndex(
                            (i) => moment(i.timestamp).format("YYYY-MM-DD") === d
                        );
                        let spIndex = spreader.findIndex(
                            (i) => moment(i.timestamp).format("YYYY-MM-DD") === d
                        );
                        let brIndex = bridger.findIndex(
                            (i) => moment(i.timestamp).format("YYYY-MM-DD") === d
                        );

                        if (atIndex > -1) {
                            attendanceCategory.push(displayDate);
                            attendanceSeries.push({
                                y: attendance[atIndex].ai,
                                name: displayDate,
                                color:  attendance[atIndex]['status'] ? riskLevelColor[`${attendance[atIndex]['status']}`] : "#ef5e8c",
                                tooltipDate :dayName
                            });
                        } else {
                            attendanceCategory.push(displayDate);
                            attendanceSeries.push({
                                y: 0,
                                name: displayDate,
                                color: "#ef5e8c",
                                tooltipDate :dayName
                            });
                        }

                        if (spIndex > -1) {
                            spreaderCategory.push(displayDate);
                            spreaderSeries.push({
                                y: spreader[spIndex].fi,
                                name: displayDate,
                                color:  spreader[spIndex]['status'] ? riskLevelColor[`${spreader[spIndex]['status']}`] : "#ef5e8c",
                                tooltipDate :dayName
                            });
                        } else {
                            spreaderCategory.push(displayDate);
                            spreaderSeries.push({ y: 0, name: displayDate, color: "#ef5e8c",tooltipDate :dayName });
                        }

                        if (brIndex > -1) {
                            bridgerCategory.push(displayDate);
                            bridgerSeries.push({
                                y: bridger[brIndex].risk_location,
                                name: displayDate,
                                color: "#ef5e8c",
                                tooltipDate :dayName
                            });
                        } else {
                            bridgerCategory.push(displayDate);
                            bridgerSeries.push({ y: 0, name: displayDate, color: "#ef5e8c",tooltipDate :dayName });
                        }
                    }

                    setChartData({
                        attendance: { categories: attendanceCategory, series: attendanceSeries },
                        spreader: { categories: spreaderCategory, series: spreaderSeries },
                        bridger: { categories: bridgerCategory, series: bridgerSeries },
                    });
                }
            });
        }
    };

    const handleDate = (date, type) => {
        setChartDates((prev) => ({ ...prev, [`${type}`]: date }));
    };

    function handleDateSelect(date) {
        if(permission && permission.View=='True'){

            updateSelectedDate(date)
            //updateThreatWatchColor('')

            let startDate = new Date().setDate(new Date().getDate() - 29);
            let endDate = new Date(moment(date).format('YYYY-MM-DD 23:59:59'));

            setChartDates({ start: startDate, end: endDate });

            let requestBody = {}
            requestBody.date = getDateFormat(date)
            getSiteLocationsValues(requestBody)
        }
    }

    function getDateFormat(date) {
        return moment(date).format('YYYY-MM-DD')
    }

    function handleClickCard(id) {
        localStorage.removeItem('ProductiveStart')
        localStorage.removeItem('ProductiveEnd')
        props.history.push(`/site-list/view/:${id}`)
        if(props.handleCloseDevice){
            props.handleCloseDevice();
        }        
        

    }

    function setTagStatus(status) {

        let statusValue = ''

        switch (status) {
            case "Overcrowded":
                statusValue = 'overCrowdedGradientColor'
                break;

            case "Normal":
                statusValue = 'normalGradientColor'
                break;

            case "Crowded":
                statusValue = 'crowdedGradientColor'
                break;

            default:
                break;
        }

        return statusValue
    }

    function getColor(element) {
        if (!element.area_index || !element.avg_footfall) {
            return '#f9e1e8'
        }

        return ''
    }

    const timeComparator = (valueA, valueB, nodeA, nodeB, isInverted) => {
        ////console.log('valueA, valueB, nodeA, nodeB, isInverted ',valueA, valueB, nodeA, nodeB, isInverted)


        let aMs = moment(valueA, 'HH:mm:ss').format('x')
        let bMs = moment(valueB, 'HH:mm:ss').format('x')
        //console.log("Time sort",aMs,bMs)
            if(valueA=='na' || valueB=='na')return 0;
            if (valueA == valueB) return 0;
            return (aMs > bMs) ? 1 : -1;       
     };


     

     const nameComparator = (valueA, valueB, nodeA, nodeB, isInverted) => {
        ////console.log('valueA, valueB, nodeA, nodeB, isInverted ',valueA, valueB, nodeA, nodeB, isInverted)


        let a = valueA.toUpperCase()
        let b = valueB.toUpperCase()

        
        
            //if(valueA=='na' || valueB=='na')return 0;

            if (valueA == valueB) return 0;
            return (a > b) ? 1 : -1;       

           // (a == b ? 0 : b > a ? 1 : -1) : (a == b ? 0 : a > b ? 1 : -1)


     };


     const checkboxSelection = function (params) {
        return params.columnApi.getRowGroupColumns().length === 0;
      };
      const headerCheckboxSelection = function (params) {
        return params.columnApi.getRowGroupColumns().length === 0;
      };
      let count=0;
    function teamDataRenderer(params){
        var eGui = document.createElement('div');
        eGui.innerHTML = '<h5 class="font-bold">'+params.value+'</h5><div class="bottom-val"><b>People:</b> '+params.data.team_members_count+'</div>';
         return eGui;
    }

    function locationDataRenderer(params){
        var eGui = document.createElement('div');
        eGui.innerHTML = '<h5 class="font-bold text-cap">'+capitalizeFirstLetter(params.value)+'</h5><div class="bottom-val"><b>Tag:</b> '+params.data.loc_tag+'</div>';
         return eGui;
    }
    function locationStatusRender(params){
        var eGui = document.createElement('div');

        let class1= params.data.crowded_status && (params.data.crowded_status == "Normal" || params.data.risk_status == "na") ? 'riskDivNegative' : 'riskDiv'
        let class2=params.data.risk_status && (params.data.risk_status == "No Risk" || params.data.risk_status == "na") ? 'riskDivNegative' : 'riskDiv'
            eGui.innerHTML =`<div class='${class1}'>${params.data.crowded_status}</div>
                    <div class='${class2}'>${params.data.risk_status}</div>`
         return eGui;
    }
    function RedirectIcon(params){
        var eGui = document.createElement('div');
        eGui.innerHTML = '<img src="'+selectedPinkArrowIcon+'" style="max-width:30px;"/>';
         return eGui;
    }

    function showCardList() {
        let arr = [];
        let  arr2 = [];
        let data ;
        arr2 = siteLocationsList.sort((a, b) => {
            if (a.loc_area_index !== 'na' && b.loc_area_index !== 'na') {
                return sortType === 'desc' ? b.loc_area_index - a.loc_area_index : a.loc_area_index - b.loc_area_index
            } else if (a.loc_area_index !== 'na' && b.loc_area_index === 'na') {
                return -1
            } else {
                return 1
            }
        })


        let widthCal=((widthContainer - 75) / 8);
        if(props.columnexist && props.isModalData ){
            widthCal=(((widthContainer - 20)) / props.columnexist);
            //console.log("If",widthCal)
        }
        else if(props.columnexist){
            widthCal=((widthContainer - 75) / props.columnexist);
            //console.log("Else",widthCal)
        }

        if(window.innerWidth<1280){
            widthCal="120"
        }

        //console.log("Width Cal Final",widthCal,widthContainer)

        
if(props.hideGlobalSearch){
    arr=<div><div className="ag-theme-alpine if cell-size-75" style={{height: ((arr2.length +2) *77),maxHeight:props.height?props.height: window.innerHeight - (props.type ? 370 : 280)  , width: "100%"}}>
    <AgGridReact
             rowHeight={75}
             autoGroupColumnDef={{
                 headerName: 'Location',minWidth: 45,field: 'name',headerCheckboxSelection: true,
                 cellRendererParams: { checkbox: true },       
             }}
            // defaultColDef={{sortable: true,resizable: true,minWidth: 45,width:135}}
            defaultColDef={{sortable: true,resizable: true,minWidth:45, width:widthCal , suppressColumnVirtualisation:true,skipHeaderOnAutoSize:true}}
             //pagination={true}
             rowData={arr2}
             key={props.AgkeyTable?props.AgkeyTable:"manpowerEmployee"}
             onCellClicked={ (event) => handleClickCard(event.data.loc_tag) }
             overlayNoRowsTemplate={
     
                 '<span class="ag-overlay-no-rows-center">No Site List Found !</span>'
               }

     >
    <AgGridColumn
        field="name"
        comparator={nameComparator}
        headerName={"Location"}
        checkboxSelection={checkboxSelection}
        headerCheckboxSelection={headerCheckboxSelection}
        cellRenderer= {(params)=>locationDataRenderer(params)}
       // valueFormatter={(params)=>capitalizeFirstLetter(getTranslatedText(params.value)) } 
        headerComponentParams =  {{
            template:`<div class="ag-cell-label-container" role="presentation"> 
                <div ref="eLabel" class="ag-header-cell-label" role="presentation"> 
              <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
              <span class="tooltipcustom"><img src='${helpIcon}'/><span class="descHelp tootltipHelp">Name of the area / Mac ID of the location tag.</span></span>
              <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span> 
              <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span> 
              <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span> 
            </div> 
          </div>`	
        }}
    />
        <AgGridColumn field="loc_area_index" headerName="Area Index" valueFormatter={(params) =>params.value!='na'?params.value+"%":'-'}    headerComponentParams =  {{
        template:`<div class="ag-cell-label-container" role="presentation"> 
            <div ref="eLabel" class="ag-header-cell-label" role="presentation"> 
        <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
        <span class="tooltipcustom"><img src='${helpIcon}'/><span class="descHelp tootltipHelp">Risk of transmission at this area.</span></span>
        <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span> 
        <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span> 
        <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span> 
        </div> 
    </div>`	
    }} ></AgGridColumn>
    <AgGridColumn field="loc_fomite_index" headerName="Fomite Index" valueFormatter={(params) =>params.value!='na'?params.value+"%":'-'}    headerComponentParams =  {{
        template:`<div class="ag-cell-label-container" role="presentation"> 
            <div ref="eLabel" class="ag-header-cell-label" role="presentation"> 
        <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
        <span class="tooltipcustom"><img src='${helpIcon}'/><span class="descHelp tootltipHelp">Fomite Index</span></span>
        <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span> 
        <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span> 
        <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span> 
        </div> 
    </div>`	
    }} ></AgGridColumn>
<AgGridColumn field="total_close_interactions" headerName="Cls. Contact" valueFormatter={(params) =>params.value!='na'?params.value:'-'}  headerComponentParams =  {{
        template:`<div class="ag-cell-label-container" role="presentation"> 
            <div ref="eLabel" class="ag-header-cell-label" role="presentation"> 
        <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
        <span class="tooltipcustom"><img src='${helpIcon}'/><span class="descHelp tootltipHelp">Close Contacts</span></span>
        <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span> 
        <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span> 
        <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span> 
        </div> 
    </div>`	
    }} ></AgGridColumn>
<AgGridColumn field="total_interactions" headerName="Visited By"   valueFormatter={(params) =>params.value!='na'?params.value:'-'} headerComponentParams =  {{
        template:`<div class="ag-cell-label-container" role="presentation"> 
            <div ref="eLabel" class="ag-header-cell-label" role="presentation"> 
        <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
        <span class="tooltipcustom"><img src='${helpIcon}'/><span class="descHelp tootltipHelp">Interactions.</span></span>
        <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span> 
        <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span> 
        <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span> 
        </div> 
    </div>`	
    }} ></AgGridColumn>
<AgGridColumn sort={"desc"} field="loc_cum_interaction_time" headerName="Avg. Time Spent" comparator={timeComparator} valueFormatter={(params) =>params.value!='na'?params.value:'-'} headerComponentParams =  {{
        template:`<div class="ag-cell-label-container" role="presentation"> 
            <div ref="eLabel" class="ag-header-cell-label" role="presentation"> 
        <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
        <span class="tooltipcustom"><img src='${helpIcon}'/><span class="descHelp tootltipHelp">Time Spent</span></span>
        <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span> 
        <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span> 
        <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span> 
        </div> 
    </div>`	
    }} ></AgGridColumn>
    <AgGridColumn field="crowded_freq" headerName="Crowded Freq"  valueFormatter={(params) =>params.value!='na'?params.value:'-'} headerComponentParams =  {{
        template:`<div class="ag-cell-label-container" role="presentation"> 
            <div ref="eLabel" class="ag-header-cell-label" role="presentation"> 
        <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
        <span class="tooltipcustom"><img src='${helpIcon}'/><span class="descHelp tootltipHelp">Crowded Freq</span></span>
        <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span> 
        <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span> 
        <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span> 
        </div> 
    </div>`	
    }} ></AgGridColumn>

    <AgGridColumn field="loc_tag" headerName="Status"
          cellRenderer= {(params)=>locationStatusRender(params)}    
        valueFormatter={(params) =>params.value!='na'?params.value:'-'} headerComponentParams =  {{
        template:`<div class="ag-cell-label-container" role="presentation"> 
            <div ref="eLabel" class="ag-header-cell-label" role="presentation"> 
        <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
        <span class="tooltipcustom"><img src='${helpIcon}'/><span class="descHelp tootltipHelp">Status</span></span>
        <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span> 
        <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span> 
        <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span> 
        </div> 
    </div>`	
    }} >
    </AgGridColumn>
</AgGridReact>
</div></div>
}else{

    arr=<div><div className="ag-theme-alpine if cell-size-75" style={{height: ((arr2.length +2) *77),maxHeight:props.height?props.height: window.innerHeight - (props.type ? 370 : 280)  , width: "100%"}}>
    <AgGridReact
        rowHeight={75}
        autoGroupColumnDef={{
            headerName: 'Location',minWidth: 45,field: 'name',headerCheckboxSelection: true,
            cellRendererParams: { checkbox: true },       
        }}
        defaultColDef={{sortable: true,resizable: true,minWidth:45, width:widthCal , suppressColumnVirtualisation:true,skipHeaderOnAutoSize:true}}
       // pagination={true}
        rowData={arr2}
        key={props.AgkeyTable?props.AgkeyTable:"manpowerEmployee"}
        onCellClicked={ (event) => handleClickCard(event.data.loc_tag) }
        overlayNoRowsTemplate={

            '<span class="ag-overlay-no-rows-center">No Site List Found !</span>'
          }
     >
    <AgGridColumn
        field="name"
        comparator={nameComparator}
        headerName={"Location"}
        checkboxSelection={checkboxSelection}
        headerCheckboxSelection={headerCheckboxSelection}
        cellRenderer= {(params)=>locationDataRenderer(params)}
        valueFormatter={(params)=>capitalizeFirstLetter(getTranslatedText(params.value)) } 
        rowClass= 'text-aln-left-first'
        className="testtststtststtstts"
        headerComponentParams =  {{
            template:`<div class="ag-cell-label-container" role="presentation"> 
                <div ref="eLabel" class="ag-header-cell-label" role="presentation"> 
              <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
              <span class="tooltipcustom"><img src='${helpIcon}'/><span class="descHelp tootltipHelp">Name of the area / Mac ID of the location tag.</span></span>
              <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span> 
              <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span> 
              <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span> 
            </div> 
          </div>`	
        }}
    />
        <AgGridColumn field="loc_area_index" headerName="Area Index" valueFormatter={(params) =>params.value!='na'?params.value+"%":'-'}    headerComponentParams =  {{
        template:`<div class="ag-cell-label-container" role="presentation"> 
            <div ref="eLabel" class="ag-header-cell-label" role="presentation"> 
        <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
        <span class="tooltipcustom"><img src='${helpIcon}'/><span class="descHelp tootltipHelp">Risk of transmission at this area.</span></span>
        <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span> 
        <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span> 
        <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span> 
        </div> 
    </div>`	
    }} ></AgGridColumn>
    <AgGridColumn field="loc_fomite_index" headerName="Fomite Index" valueFormatter={(params) =>params.value!='na'?params.value+"%":'-'}    headerComponentParams =  {{
        template:`<div class="ag-cell-label-container" role="presentation"> 
            <div ref="eLabel" class="ag-header-cell-label" role="presentation"> 
        <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
        <span class="tooltipcustom"><img src='${helpIcon}'/><span class="descHelp tootltipHelp">Fomite Index</span></span>
        <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span> 
        <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span> 
        <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span> 
        </div> 
    </div>`	
    }} ></AgGridColumn>
<AgGridColumn field="total_close_interactions" headerName="Cls. Contact" valueFormatter={(params) =>params.value!='na'?params.value:'-'}  headerComponentParams =  {{
        template:`<div class="ag-cell-label-container" role="presentation"> 
            <div ref="eLabel" class="ag-header-cell-label" role="presentation"> 
        <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
        <span class="tooltipcustom"><img src='${helpIcon}'/><span class="descHelp tootltipHelp">Close Contacts</span></span>
        <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span> 
        <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span> 
        <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span> 
        </div> 
    </div>`	
    }} ></AgGridColumn>
<AgGridColumn field="total_interactions" headerName="Visited By"   valueFormatter={(params) =>params.value!='na'?params.value:'-'} headerComponentParams =  {{
        template:`<div class="ag-cell-label-container" role="presentation"> 
            <div ref="eLabel" class="ag-header-cell-label" role="presentation"> 
        <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
        <span class="tooltipcustom"><img src='${helpIcon}'/><span class="descHelp tootltipHelp">Interactions.</span></span>
        <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span> 
        <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span> 
        <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span> 
        </div> 
    </div>`	
    }} ></AgGridColumn>
<AgGridColumn sort={"desc"} field="loc_cum_interaction_time" headerName="Avg. Time Spent" comparator={timeComparator} valueFormatter={(params) =>params.value!='na'?params.value:'-'} headerComponentParams =  {{
        template:`<div class="ag-cell-label-container" role="presentation"> 
            <div ref="eLabel" class="ag-header-cell-label" role="presentation"> 
        <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
        <span class="tooltipcustom"><img src='${helpIcon}'/><span class="descHelp tootltipHelp">Time Spent</span></span>
        <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span> 
        <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span> 
        <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span> 
        </div> 
    </div>`	
    }} ></AgGridColumn>
    <AgGridColumn field="crowded_freq" headerName="Crowded Freq"  valueFormatter={(params) =>params.value!='na'?params.value:'-'} headerComponentParams =  {{
        template:`<div class="ag-cell-label-container" role="presentation"> 
            <div ref="eLabel" class="ag-header-cell-label" role="presentation"> 
        <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
        <span class="tooltipcustom"><img src='${helpIcon}'/><span class="descHelp tootltipHelp">Crowded Freq</span></span>
        <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span> 
        <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span> 
        <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span> 
        </div> 
    </div>`	
    }} ></AgGridColumn>

    <AgGridColumn field="loc_tag" headerName="Status"
          cellRenderer= {(params)=>locationStatusRender(params)}    
        valueFormatter={(params) =>params.value!='na'?params.value:'-'} headerComponentParams =  {{
        template:`<div class="ag-cell-label-container" role="presentation"> 
            <div ref="eLabel" class="ag-header-cell-label" role="presentation"> 
        <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
        <span class="tooltipcustom"><img src='${helpIcon}'/><span class="descHelp tootltipHelp">Status</span></span>
        <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span> 
        <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span> 
        <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span> 
        </div> 
    </div>`	
    }} >
    </AgGridColumn>
</AgGridReact>
</div></div>
}
        


      


    //console.log(arr2);
        return arr
    }

    function changeLanguage(lang) {
        getLanguageTranslation(lang).then(res => {
            if (res && res.status >= 200 && res.status <= 200) {
                localStorage.setItem('languageData', JSON.stringify(res.data))
                localStorage.setItem('selectedLanguage', lang)
                props.setSelectedLanguage(lang)

            }
        })
    }

    const handleMouseEnter = (id) => {
        let doc = document.getElementById(id)

        if (doc) {
            doc.style.display = 'block'
        }
    }

    const handleMouseLeave = (id) => {
        let doc = document.getElementById(id)

        if (doc) {
            doc.style.display = 'none'
        }
    }

    useEffect(() => {
        if (props.language) {
            updateSelectedLangValue(props.language)
        }
    }, [props.language])

    useEffect(() => {
        if (preDefinedSiteLocationsList.length) {
            updateIsLoading(true)

            let arr = [...preDefinedSiteLocationsList]

            if (searchValue) {
                let invalid = /[°"§%()[\]{}=\\?´`'#<>|,;.:+_-]+/g;
                let value = searchValue.replace(invalid, "")
                arr = arr.filter(function (siteList) {
                    return (siteList.name.toLowerCase().search(value.toLowerCase()) !== -1 || siteList.loc_tag.toLowerCase().search(value.toLowerCase()) !== -1)
                })
            }

            if (sortKey === 'location') {
                arr = arr.sort((a, b) => {
                    a = a.name.toUpperCase()
                    b = b.name.toUpperCase()

                    return sortType === 'desc' ? (a == b ? 0 : b > a ? 1 : -1) : (a == b ? 0 : a > b ? 1 : -1)
                })
            } else if (sortKey === 'area') {
                arr = arr.sort((a, b) => {
                    if (a.loc_area_index !== 'na' && b.loc_area_index !== 'na') {
                        return sortType === 'desc' ? b.loc_area_index - a.loc_area_index : a.loc_area_index - b.loc_area_index
                    } else if (a.loc_area_index !== 'na' && b.loc_area_index === 'na') {
                        return -1
                    } else {
                        return 1
                    }
                })
            } else if (sortKey === 'formit') {
                arr = arr.sort((a, b) => {
                    if (a.loc_fomite_index !== 'na' && b.loc_fomite_index !== 'na') {
                        return sortType === 'desc' ? b.loc_fomite_index - a.loc_fomite_index : a.loc_fomite_index - b.loc_fomite_index
                    } else if (a.loc_fomite_index !== 'na' && b.loc_fomite_index === 'na') {
                        return -1
                    } else {
                        return 1
                    }
                })
            } else if (sortKey === 'contact') {
                arr = arr.sort((a, b) => {
                    if (a.total_close_interactions !== 'na' && b.total_close_interactions !== 'na') {
                        return sortType === 'desc' ? b.total_close_interactions - a.total_close_interactions : a.total_close_interactions - b.total_close_interactions
                    } else if (a.total_close_interactions !== 'na' && b.total_close_interactions === 'na') {
                        return -1
                    } else {
                        return 1
                    }
                })
            } else if (sortKey === 'visited') {
                arr = arr.sort((a, b) => {
                    if (a.total_interactions !== 'na' && b.total_interactions !== 'na') {
                        return sortType === 'desc' ? b.total_interactions - a.total_interactions : a.total_interactions - b.total_interactions
                    } else if (a.total_interactions !== 'na' && b.total_interactions === 'na') {
                        return -1
                    } else {
                        return 1
                    }
                })
            } else if (sortKey === 'timeSpent') {
                arr = arr.sort((a, b) => {
                    if (a.loc_cum_interaction_time !== 'na' && b.loc_cum_interaction_time !== 'na') {
                        return sortType === 'desc' ? b.loc_cum_interaction_time - a.loc_cum_interaction_time : a.loc_cum_interaction_time - b.loc_cum_interaction_time
                    } else if (a.loc_cum_interaction_time !== 'na' && b.loc_cum_interaction_time === 'na') {
                        return -1
                    } else {
                        return 1
                    }
                })
            } else if (sortKey === 'crowded') {
                arr = arr.sort((a, b) => {
                    if (a.crowded_freq !== 'na' && b.crowded_freq !== 'na') {
                        return sortType === 'desc' ? b.crowded_freq - a.crowded_freq : a.crowded_freq - b.crowded_freq
                    } else if (a.crowded_freq !== 'na' && b.crowded_freq === 'na') {
                        return -1
                    } else {
                        return 1
                    }
                })
            } else if (sortKey === 'statusHelp') {
                arr = arr.sort((a, b) => {
                    a = a.crowded_status.toUpperCase()
                    b = b.crowded_status.toUpperCase()

                    return sortType === 'desc' ? (a == b ? 0 : b > a ? 1 : -1) : (a == b ? 0 : a > b ? 1 : -1)
                })
            }

            updateSiteLocationsList(arr)
            updateIsLoading(false)
        }
    }, [sortKey, sortType, searchValue])

    function handleSiteManagement() {
        props.history.push('/site-list')
    }

    const handleSort = (key) => {
        setSortKey(key)
        setSortType(sortType === 'desc' ? 'asc' : 'desc')

    }

    return (<div className={permissionAPICall?"permissionTrue":"" }>
        {permissionAPICall?permission.View=="True"?
        <div className="dashboardComponentMainDiv siteManagementMainDiv"  style={props.hideHeading ? { padding: '0' } : {}}>
            <Container className='header-breadcrumb' >
                {
                    props.hideHeading ? '' :
                        <Row>
                            <Col lg={3} className="m-t-sm">
                                <CommonHeading title="Site Management"/>
                            </Col>
                            <Col lg={9} className="text-right">
                                <div className="dashboardLanguageMainDiv m-r-md">
                                    <DashboardLanguage
                                        selectedLangValue={selectedLangValue}
                                        changeLanguage={changeLanguage}
                                    />
                                </div>
                                <div className="commonHeadingDateMainDiv">
                                    <CommonDatePicker
                                        selectedDate={selectedDate}
                                        handleSelectDate={handleDateSelect}
                                    />
                                 </div>
                                <DeviceStatus permission={permission} role={props.role} />
                            </Col>
                        </Row>
                }

                {!props.hideGlobalSearch &&
                    <Row>
                        <Col className='m-t'>
                            <div className='globalSearch'>
                                <input
                                    type='text'
                                    value={globalSearch}
                                    onChange={(e) => setGlobalSearch(e.target.value)}
                                    placeholder={'Search'}
                                />

                                {globalSearch.trim().length ?
                                    <div className="suggestionBox">
                                        {suggestions.length ?
                                            suggestions.map((s, index) => (
                                                <div className='suggestion' key={index} onClick={() => handleClickCard(s.loc_tag)} >{s.name}</div>
                                            ))
                                            :
                                            <div className='suggestion'>No {globalCategory} Found</div>
                                        }
                                    </div>
                                    : null
                                }

                                <div className='optionBox'>
                                    <DropdownButton title={globalCategory}>
                                        <Dropdown.Item onClick={(e) => setGlobalCategory(e.target.text)}>Location</Dropdown.Item>
                                    </DropdownButton>
                                </div>
                            </div>
                        </Col>
                    </Row>
                }
    {!props.hideGlobalSearch && (
                    <Row>
                        <Col>
                            <div
                                className="siteManagementChartMainDiv m-t"
                                style={{ backgroundColor: "#202236", padding: "2rem 1.5rem" }}
                            >
                                <Row className="dateBox">
                                    <Col lg={2} className="p-r-0">
                                        <div className="contactRankText">
                                            {getTranslatedText("Start Date")}
                                        </div>
                                        <div
                                            className="startDateEndDateMainDiv"
                                            style={{ paddingLeft: "0px" }}
                                        >
                                            <DatePicker
                                                selected={chartDates.start}
                                                onChange={(date) => handleDate(date, "start")}
                                                dateFormat={"MMM dd"}
                                                isClearable={false}
                                                placeholderText={getTranslatedText("Start Date")}
                                                maxDate={moment(chartDates.end).toDate()}
                                                minDate={moment().subtract(120, "days").toDate()}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg={1} className="p-l-0"></Col>
                                    <Col lg={2} className="p-r-0">
                                        <div className="contactRankText">
                                            {getTranslatedText("End Date")}
                                        </div>
                                        <div
                                            className="startDateEndDateMainDiv"
                                            style={{ paddingLeft: "0px" }}
                                        >
                                            <DatePicker
                                                selected={moment(chartDates.end).toDate()}
                                                onChange={(date) => handleDate(date, "end")}
                                                dateFormat={"MMM dd"}
                                                isClearable={false}
                                                placeholderText={getTranslatedText("End Date")}
                                                maxDate={selectedDate}
                                                minDate={chartDates.start}
                                            />
                                            {/* <div className="dropdownIconDiv">
                        <img src={dropdownIcon} />
                      </div> */}
                                        </div>
                                    </Col>
                                </Row>
                                <div className="barChartMainDiv">
                                    <div className="chart">
                                        <div className="titleBox">
                                            <div className="title">Area Index</div>
                                            <div className="relative">
                                                <img
                                                    alt=""
                                                    className="helpicon"
                                                    src={helpIcon}
                                                    onMouseEnter={() =>
                                                        handleMouseEnter(`attendanceHelp`)
                                                    }
                                                    onMouseLeave={() =>
                                                        handleMouseLeave(`attendanceHelp`)
                                                    }
                                                />
                                                <div className="descHelp" id="attendanceHelp">
                                                Risk of transmission at this area.
                                                </div>
                                            </div>
                                        </div>
                                        <BarChart
                                            categories={chartData.attendance.categories}
                                            series={chartData.attendance.series}
                                            seriesName={"Area Index"}
                                        />
                                    </div>
                                    {/* <div className="chart">
                                        <div className="titleBox">
                                            <div className="title">Fomite index</div>
                                            <div className="relative">
                                                <img
                                                    alt=""
                                                    className="helpicon"
                                                    src={helpIcon}
                                                    onMouseEnter={() => handleMouseEnter(`spreaderHelp`)}
                                                    onMouseLeave={() => handleMouseLeave(`spreaderHelp`)}
                                                />
                                                <div className="descHelp" id="spreaderHelp">
                                                Evolution of the Fomite index of a location in time. 
                                                </div>
                                            </div>
                                        </div>
                                        <BarChart
                                            categories={chartData.spreader.categories}
                                            series={chartData.spreader.series}
                                            seriesName={"Fomite Index"}
                                        />
                                    </div> */}
                                    <div className="chart">
                                        <div className="titleBox">
                                            <div className="title">Risky locations </div>
                                            <div className="relative">
                                                <img
                                                    alt=""
                                                    className="helpicon"
                                                    src={helpIcon}
                                                    onMouseEnter={() => handleMouseEnter(`bridgerHelp`)}
                                                    onMouseLeave={() => handleMouseLeave(`bridgerHelp`)}
                                                />
                                                <div className="descHelp" id="bridgerHelp">
                                                Evolution of the number of risky locations in time.
                                                </div>
                                            </div>
                                        </div>
                                        <BarChart
                                            categories={chartData.bridger.categories}
                                            series={chartData.bridger.series}
                                            seriesName={"Risky Locations"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                )}

                {
                    <Row className={props.hideHeading ? '' : 'm-t'}>
                        <Col lg={12}>
                            <div className={'siteListMainDiv ' + (props.hideHeading ? 'p-l-0 p-r-0' : '')} ref={elementRef} style={props.hideHeading ? { paddingTop: 0, paddingBottom: 0 } : {}}>
                                <Row style={{ alignItems: 'center' }}>
                                    {
                                        props.isBubbleView ? '' :
                                            <Col lg={4} >
                                                <h3 className="locationsListing">
                                                    {props.title ? props.title : getTranslatedText('Locations')}
                                                    {props.hideGlobalSearch && (`(${siteLocationsList.length})`)}
                                                </h3>
                                            </Col>
                                    }
                                    <Col lg={4}></Col>
                                    {/* {!props.hideGlobalSearch ?
                                        <Col lg={7}>
                                            <div className="locationTabMainDiv" >
                                                <div className={'eachTab ' + (activetab == 'All' ? 'activeTabBG' : '')} onClick={() => setActiveTab('All')}>
                                                    {getTranslatedText('All')}
                                                    <span>{siteLocationsList.length}</span>
                                                </div>
                                                <div className={'eachTab ' + (activetab == 'Visit over 15\'' ? 'activeTabBG' : '')} onClick={() => setActiveTab('Visit over 15\'')}>
                                                    {getTranslatedText('Visit over 15\'')}
                                                    <span>20</span>
                                                </div>
                                                <div className={'eachTab ' + (activetab == 'Overcrowded' ? 'activeTabBG' : '')} onClick={() => setActiveTab('Overcrowded')}>
                                                    {getTranslatedText('Overcrowded')}
                                                    <span>20</span>
                                                </div>
                                                <div className={'eachTab ' + (activetab == 'Crowded' ? 'activeTabBG' : '')} onClick={() => setActiveTab('Crowded')}>
                                                    {getTranslatedText('Crowded')}
                                                    <span>20</span>
                                                </div>
                                            </div>  
                                        </Col>
                                        : null
                                    } */}
                                    {
                                        props.hideSearch ? '' :
                                            <Col lg={4}>
                                                <div className="listingSearchMainDiv">
                                                    <input type="text" value={searchValue} name="siteSearch" placeholder="Search" onChange={(event) => updateSearchValue(event.target.value)} />
                                                </div>
                                            </Col>
                                    }
                                </Row>

                                {
                                    props.isBubbleView ?
                                        <Row>
                                            <Col lg={12}>
                                                <div className="bubbleViewLocationsMainDiv">
                                                    <Row>
                                                        <Col lg={4}>
                                                            <h5 className="font-bold m-b-xs">Locations</h5>
                                                            <div className="dateText ">As of {moment(props.selectedDate).format('Do MMM YYYY')}</div>
                                                        </Col>
                                                        <Col lg={8}>
                                                            <div className="pinnedHighRiskMainDiv">
                                                                <div className="eachTabDiv activeTab">
                                                                    <span> Pinned</span>
                                                                    <div className="numberDiv">12</div>
                                                                </div>
                                                                <div className="eachTabDiv">
                                                                    <span>High Risk</span>
                                                                    <div className="numberDiv">9</div>
                                                                </div>
                                                                <div className="filterDiv">
                                                                    Filter
                                                                    <img src={filterIcon} />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                        </Row> : ''
                                }

                                {
                                    isLoading ?
                                        <div className="text-center m-t-lg">
                                            <img src={spinnerLoader} className="m-t-lg" />
                                        </div>
                                        :
                                        <React.Fragment>
                                            <Row>
                                                <Col lg={12}>
                                                    <div className="listingRecordMainDiv" style={props.isBubbleView ? { padding: '0rem 1rem' } : {}}>
                                                        
                                                                    {showCardList()}
                                                       
                                                    </div>
                                                </Col>
                                            </Row>
                                        </React.Fragment>
                                }

                            </div>
                        </Col>
                    </Row>
                }
            </Container>
        </div>:<Col><div className='center404'> <Image404 /><h4 style={{marginTop:"-30px",color:"#2e3552"}}>Access Restricted to this page, Please contact administrator</h4></div>
        </Col>: <div className="text-center m-t-lg">
        <img src={spinnerLoader} className="m-t-lg" />
      </div>}</div>
    )


}

const mapStateToProps = (state) => ({
    language: state.dashboard.selectedLangaugeValue
})

export default connect(mapStateToProps, { setSelectedLanguage })(withRouter(SiteMangementList))