import axios from "axios";


let prefixURL = process.env.REACT_APP_URL_LOGIN
let prefixURL2 = process.env.REACT_APP_URL

let token = localStorage.getItem('tokenAuthTrace')?localStorage.getItem('tokenAuthTrace'):'tituw3958589'

export function getLocationCordinate( sessionID, org_id) {
    return axios.get(prefixURL + `/location/get_location_cordinate?session=${sessionID}&org_id=${org_id}`)
        .then(res => res.data).catch(err => err)
}

export function getDeviceReport(sessionID, org_id){
    return axios.get(prefixURL + `/device/get_device_report?session=${sessionID}&org_id=${org_id}`)
    .then(res => res.data).catch(err => err)
}
export function getAllEmpProductivityInfo( sessionID, org_id,slug) {
    return axios.get(prefixURL2 + `/get_all_emp_productivity_info?session=${sessionID}&org_id=${org_id}&slug=${slug}`)
        .then(res => res.data).catch(err => err)
}
export function getCycleCountPlan( sessionID, org_id,date) {
    return axios.get(prefixURL2 + `/get_cycle_count_plan?session=${sessionID}&org_id=${org_id}&date=${date}`)
        .then(res => res.data).catch(err => err)
}
