import React, { useState, useEffect,useRef } from 'react'
import { useHistory } from 'react-router-dom';

import { Container, Row, Col } from 'react-bootstrap';
import moment from 'moment'
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import '../../../siteManagement/styles/siteManagement.scss'
import DashboardLanguage from '../../../components/dashboardLanguage';
import { getSiteOverview, getSiteFootFall, getSiteAreaIndex, footfallChart } from '../../actionMethods/actionMethods';

import spinnerLoader from '../../../assets/images/Spinner Loader.gif'
import CommonDatePicker from '../../../common/commonDatePicker';
import { getTranslatedText } from '../../../common/utilities';
import ClockIcon from '../../../dashboard/styles/images/clock.png'

import { getLanguageTranslation, setSelectedLanguage } from '../../../dashboard/actionMethods/actionMethods';
import { tsAnyKeyword } from '@babel/types';
import { Scrollbars } from "react-custom-scrollbars";
import ReactHighcharts from 'react-highcharts'
import Highcharts from "highcharts";
import DatePicker from "react-datepicker";
import helpIcon from "../../../assets/traceplusImages/help-icon.png";
import sortIcon from '../../../assets/traceplusImages/sort.png'
import upIcon from '../../../assets/traceplusImages/up-arrow.png'
import downIcon from '../../../assets/traceplusImages/down-arrow.png'
import calenderIcon from '../../../assets/traceplusImages/calendar_icon_white.svg'
import DeviceStatus from '../../../components/DeviceStatus'
import { getPproductivityWidget,
         getPproductivityDetailOverview,
         getDetailedProductivityChart,
         getProductivityIndividualInfo,
         getIndividualProductivityList } from '../../actionMethods/actionMethods'
import batteryIcon from "../../../assets/traceplusImages/battery.svg";    
import {AgGridColumn, AgGridReact} from 'ag-grid-react';     
import {ReactComponent as Image404 } from  '../../../assets/images/11132-ai.svg'

const riskLevelColor = {
    "low": '#04e06e',
    "medium": "#ffd700",
    "high": "#ff9696"
}



function ProductivityTruckDetails(props) {
    let history =useHistory();
    let date = localStorage.getItem('selectedDate') ? new Date(localStorage.getItem('selectedDate')) : new Date()
    let StartDate= localStorage.getItem('ProductiveStart')?new Date(localStorage.getItem('ProductiveStart')):localStorage.getItem('selectedDate') ? new Date(localStorage.getItem('selectedDate')) : new Date()  
    let EndDate= localStorage.getItem('ProductiveEnd')?new Date(localStorage.getItem('ProductiveEnd')):localStorage.getItem('selectedDate') ? new Date(localStorage.getItem('selectedDate')) : new Date()  
    const [dates, setDates] = useState({
        start: new Date(moment(StartDate).format('YYYY-MM-DD 23:59:59')),
        end: new Date(moment(EndDate).format('YYYY-MM-DD 23:59:59')),
      });


  
    const [employeeData, updateemployeeData] = useState([])

    const [slug,setSlug] = useState('asset')

    const [selectedLangValue, updateSelectedLangValue] = useState('en')

    const [locationID, updateLocationID] = useState('')

    const [selectedDate, updateSelectedDate] = useState(date)
    const [chartLoader, setChartLoader] = useState(true)
    const [donutLoader, setDonutLoader] = useState(true)

    const [sortKey, setSortKey] = useState('locationVisited')
    const [sortType, setSortType] = useState('desc')
    
    const [sortKeylocationVisited, setSortKeylocationVisited] = useState('locationVisited')
    const [sortTypelocationVisited, setSortTypelocationVisited] = useState('desc')
    
    const [sortKeyunproductive, setSortKeyunproductive] = useState('unproductive')
    const [sortTypeunproductive, setSortTypeunproductive] = useState('desc')
  
    const [sortKeyacess, setSortKeyacess] = useState('acess')
    const [sortTypeacess, setSortTypeacess] = useState('desc')
  
    const [locationVisitedData,SetLocationVisitedData]=useState([])

    const [unproductiveVisitedData,SetUnproductiveVisitedData]=useState([])
    const [acessData,SetAcessData]=useState([])
    const [donutData,SetDonutData] =useState([]);
    const [chartData,SetChartData]=useState({series: [] });

    const [selectedData,SetSelectedData]=useState([]);
    const [selectedSeries,SetSelectedSeries]=useState({name:'',data:[],time:''});
    const [selectedSeriesTime,SetSelectedSeriesTime]=useState('');


    const [individualInfo,SetIndividualInfo] = useState([])
    const [loaderWidget,SetLoaderWidget] = useState(true);  

    const [widthContainer, setWidthContainer] = useState(0);
    const elementRef = useRef(null);   

    const [activeTab,SetActiveTab] = useState('productivity');
    const [productivity,SetProductivity] = useState([])
    const [nonproductivity,SetNonProductivity] = useState([])
    const [lessproductivity,SetLessProductivity] = useState([])

    const [role,SetRole] = useState([]);
    const [permission,SetPermission] =useState([])
    const [permissionAPICall,SetPermissionAPICall] = useState(false);
    const [loaderWidgetBottom,SetLoaderWidgetBottom] =useState(true);
    let idVal = props.match.params.id.replace(":", "")
	
    useEffect(() => {
        let perm= props.role.productivity_management?JSON.parse(props.role.productivity_management):null;
        if(perm){
    
           //console.log(perm);
            // perm.View = 'False'
            // perm.Create = 'False'
            // perm.remove = 'False'
            // perm.edit = 'False'
            SetPermission(perm)
            SetPermissionAPICall(true)
        }else{
            //console.log("undefined",perm);
        }
    },[props.role]);
    

    useEffect(() => {
        if(permission && permission.View=='True'){
        setWidthContainer(elementRef.current.getBoundingClientRect().width);
        }
      }, [elementRef,permission,idVal]);

    const [configDonut,SetConfigDonut]=useState({
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie',
            height:200
        },
        title: {
            text: ''
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        exporting: {
            enabled: false
        },
        credits: {
            enabled: false
          },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                size:"90%",
                dataLabels: {
                    enabled: false
                },
                showInLegend: false
            }
        },
        series: [{
            name: 'Productivity',
            innerSize: '60%',
            colorByPoint: true,
            data:chartData.series
        }]
    });

    const categories= [
        '12 AM','01 AM','02 AM','03 AM','04 AM','05 AM','06 AM',
        '07 AM','08 AM','09 AM','10 AM','11 AM','12 PM','01 PM',
        '02 PM','03 PM','04 PM','05 PM','06 PM',
        '07 PM','08 PM','09 PM','10 PM','11 PM',
    ] 
    const cat2= [
        '0','1','2','3','4','5','6',
        '7','8','9','10','11','12','13',
        '14','15','16','17','18','19','20',
        '21','22','23'
    ]

    let config = {
        chart: {
            type: 'column',
            height: 330,
        },
        title: {
            text: ''
        },
        xAxis: {
            categories: categories,
            gridLineWidth: 1,
            gridLineDashStyle: 'longdash',
            tickPositioner: function () {
                let positions = []
                let tick = Math.floor(this.dataMin)
                let increment = Math.ceil((this.dataMax - this.dataMin) / (window.innerWidth > 1279)? 3: 4 );
                if(props.type=='month'){
                    increment = Math.ceil((this.dataMax - this.dataMin) / (window.innerWidth > 1279)? 8: 8 );
                }

                if (this.dataMax === this.dataMin) {
                    return [0]
                }

                if (this.dataMax !== null && this.dataMin !== null) {
                    for (tick; tick - increment <= this.dataMax; tick += increment) {
                        positions.push(tick);
                    }
                }

                return positions;
            }
        },
        exporting: {
            enabled: false
        },
        credits: {
            enabled: false
        },
        legend:{enabled:false},
        yAxis: {
            min: 0,
            gridLineWidth: 1,
            gridLineDashStyle: 'longdash',
            allowDecimals:false,
            title: {
                text: ''
            },
            stackLabels: {
                enabled: false,
                style: {
                    fontWeight: 'bold',
                    color: ( // theme
                        Highcharts.defaultOptions.title.style &&
                        Highcharts.defaultOptions.title.style.color
                    ) || 'gray'
                }
            }
        },

        tooltip: {
            headerFormat: '<b>{point.x}</b><br/>',
            pointFormat: '{series.name}: {point.y}'
        },
        plotOptions: {
            column: {
                stacking: 'normal',
                dataLabels: {
                    enabled: false
                },
                states: {
                    select: {
                        color: '#ef5e8c',
                        borderColor:'#ef5e8c',
                    },
                },    
                point: {
                    events: {
                        click: (e) => {
                            e.point.select(true);
                            formatSelectedData(e);
                        
                        }
                    }
                },
    
            },

        },
        series: chartData.series
    }

            function formatSelectedData(e){
                
                if(e.point.series){
                    SetSelectedSeries({name:e.point.series.name,data:e.point.tooltip,time:e.point.category})
                }
            }

    let userDetails = JSON.parse(localStorage.getItem('userLoginDetails'))

    let userSession = userDetails ? userDetails.session : '123456789'

    let org_id = userDetails ? userDetails.org_id : 6
    

    useEffect(()=>{
        if(permission && permission.View=='True'){
        let requestBody={}
        requestBody.startdate=getDateFormat(dates.start)
        requestBody.enddate=getDateFormat(dates.end)
        requestBody.slug='asset'
        requestBody.tag_serial=idVal
        setChartLoader(true)

        let maxVal = 0;
        let dataOFselected=[]
        getPproductivityDetailOverview(requestBody,userSession,org_id).then(res=>{
            
            let allData= [] ;
            Object.keys(res.data).forEach((key)=>{
                allData.push({id:key,value:res.data[key]})
            })

            let productive=[];
            let nonproductive=[];
            let lessproductive=[];
            let undefinedproductive=[];

            for(let i=0;i<cat2.length;i++){
                let item = allData.find(item=>item.id==cat2[i] )
                if(item){
                    productive.push({y:item.value.productive_count,tooltip:item.value.productive})
                    nonproductive.push({y:item.value.non_productive_count,tooltip:item.value.non_productive})
                    lessproductive.push({y:item.value.less_productive_count,tooltip:item.value.less_productive})
                    //undefinedproductive.push(item.value.undefined)
                    undefinedproductive.push({y:0,tooltip:[]})

                    if(maxVal< Object.keys(item.value.productive).length ){
                        maxVal=Object.keys(item.value.productive).length;
                        dataOFselected={name:'Productive',data:item.value.productive,time:categories[i]}
                    }
                    
                    if(maxVal<Object.keys(item.value.non_productive).length){
                        maxVal=Object.keys(item.value.non_productive).length;
                        dataOFselected={name:'Non Productive',data:item.value.non_productive,time:categories[i]}
                    }
                    
                    if(maxVal<Object.keys(item.value.less_productive).length){
                        maxVal=Object.keys(item.value.less_productive).length;
                        dataOFselected={name:'Less Productive',data:item.value.less_productive,time:categories[i]}
                    }


                }else{
                    productive.push({y:0,tooltip:[]})
                    nonproductive.push({y:0,tooltip:[]})
                    lessproductive.push({y:0,tooltip:[]})
                    undefinedproductive.push({y:0,tooltip:[]})
                }
 
            }
            //console.log("Selected Val",maxVal,dataOFselected);
            SetSelectedSeries({name:dataOFselected.name,data:dataOFselected.data,time:dataOFselected.time})

            SetChartData({
                series:[
                  {name:"Undefined",data:undefinedproductive,color:"#dedee3"},
                  {name:"Non Productive",data:nonproductive,color:"#fc0001"},
                  {name:"Less Productive",data:lessproductive,color:"#fdbf00"},
                  {name:"Productive",data:productive,color:"#90cf4e"},
                    ]  
                })
                setChartLoader(false)
            });     
            

            let letSeriesData=[]
            setDonutLoader(true)

            getDetailedProductivityChart(requestBody,userSession,org_id).then(res=>{
                
                if(res.status==200){
                    letSeriesData.push({
                        name: 'Productive',
                        y: Math.abs(res.data.productive),
                        color:'#90cf4e',
                    },
                    {
                        name: 'Les Productive',
                        y: Math.abs(res.data.less_productive),
                        color:'#fdbf00',
                    },{
                        name: 'Non Productive',
                        y: Math.abs(res.data.non_productive),
                        color:'#fc0001',
                    },{
                        name: 'Undefined',
                        y: Math.abs(res.data.undefined),
                        color:'#dedee3',
                    },
                    
                    ) 
                     
                }

                SetDonutData(letSeriesData);
                let  configDonutEG= {
                    chart: {
                        plotBackgroundColor: null,
                        plotBorderWidth: null,
                        plotShadow: false,
                        type: 'pie',
                        height:200
                    },
                    title: {
                        text: ''
                    },
                    tooltip: {
                        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                    },
                    accessibility: {
                        point: {
                            valueSuffix: '%'
                        }
                    },
                    exporting: {
                        enabled: false
                    },
                    credits: {
                        enabled: false
                      },
                    plotOptions: {
                        pie: {
                            allowPointSelect: true,
                            cursor: 'pointer',
                            size:"90%",
                            dataLabels: {
                                enabled: false
                            },
                            showInLegend: false
                        }
                    },
                    series: [{
                        name: 'Productivity',
                        innerSize: '60%',
                        colorByPoint: true,
                        data:letSeriesData,
                        cursor: 'pointer',

                    }]
                };
                SetConfigDonut(configDonutEG)
                setDonutLoader(false)

           
           
            })
        }
    },[dates,selectedDate,permission,idVal])

   useEffect(()=>{
    if(permission && permission.View=='True'){
       let requestBody={}
       requestBody.slug='asset'
       requestBody.tag_serial=idVal;
       requestBody.startdate= getDateFormat(dates.start);
       requestBody.enddate= getDateFormat(dates.end);
       getProductivityIndividualInfo(requestBody,userSession,org_id).then(res=>{
           //console.log(res);
           if(res.status===200){
               SetIndividualInfo(res.data);
           }
       })
       getIndividualProductivityList(requestBody,userSession,org_id).then(res=>{

        if(res.status==200){
            SetProductivity(res.data.productive);
            SetLessProductivity(res.data.less_productive)
            SetNonProductivity(res.data.non_productive)
        }
    }) 
    }
   },[dates,selectedDate,permission,idVal]) 
    const handleDate = (date, type) => {
        setDates((prev) => ({ ...prev, [`${type}`]: date }));
        if(type=='start'){
            localStorage.setItem('ProductiveStart',date);
        }else{
            localStorage.setItem('ProductiveEnd',date);
        }
        
               
       };   

    function handleSiteListClick() {
        props.history.push('/productivity')
    }


    const handleMouseEnter = (id) => {
        let doc = document.getElementById(id);
    
        if (doc) {
          doc.style.display = "block";
        }
      };

      const handleMouseLeave = (id) => {
        let doc = document.getElementById(id);
    
        if (doc) {
          doc.style.display = "none";
        }
      };

    useEffect(()=>{
        if(permission && permission.View=='True'){
        let requestBody={}
        requestBody.startdate= getDateFormat(dates.start) ;
        requestBody.enddate=getDateFormat(dates.end);
        requestBody.tag_serial = idVal;
        requestBody.slug='asset'
        SetLoaderWidget(true)
        getPproductivityWidget(requestBody,userSession,org_id).then(res=>{
            if(res.status==200){
                updateemployeeData(res.data);
            }
            SetLoaderWidget(false)
        })
    }   
    },[dates,permission,idVal])

    useEffect(()=>{
        if(permission && permission.View=='True'){
        ShowCardLocation();
        ShowCardAcess();
        ShowCardUnProductive();
        }
    },[employeeData,permission,idVal])

      const handleSortunproductive = (key) => {

        setSortKeyunproductive(key)
        setSortTypeunproductive(sortTypeunproductive === 'desc' ? 'asc' : 'desc')
        
        setSortKey(key)
        setSortType(sortTypeunproductive)

      }


      const handleSortlocationVisited = (key) => {
    
        setSortKeylocationVisited(key)
        setSortTypelocationVisited(sortTypelocationVisited === 'desc' ? 'asc' : 'desc')
        setSortKey(key)
        setSortType(sortTypelocationVisited)
    
    
      }
    const handleSortAcess = (key )=>{
    
        setSortKeyacess(key)
        setSortTypeacess(sortTypeacess === 'desc' ? 'asc' : 'desc')
        setSortKey(key)
        setSortType(sortTypeacess)
        
    }

    function capitalizeFirstLetter(string) {
        return string.toLowerCase();
    }


    function ShowCardLocation(){

        let arr = [];
        let data = employeeData.locations_visited?employeeData.locations_visited:[];
        let arr2 =[];
        arr2 = data.sort((a,b) => {
          let aMs = moment(a.time, 'HH:mm:ss').format('x')
          let bMs = moment(b.time, 'HH:mm:ss').format('x')
          return  sortTypelocationVisited=='desc'? bMs - aMs  : aMs - bMs
       })  
        for (let index = 0; index < arr2.length; index++) {
          let element = data[index];
          Object.keys(element).forEach((key) => {
            arr.push({name:element[key].name,time:element[key].time,tag_serial:key});
            // arr.push(
            //     <tr className="employee-detail-table-tr" key={'ShowCardLocation'+index} onClick={()=>RedirectSiteManagement(key)} style={{cursor:"pointer"}}>
            //       <td>{capitalizeFirstLetter(element[key].name)}</td>
            //       <td className="employee-detail-table-tr-td">{capitalizeFirstLetter(element[key].time)}</td>
            //     </tr>
            //   );
          });
        }
        SetLocationVisitedData(arr);
    }
    
    function handleClickCard(id) {
         history.push(`/site-list/view/:${id}`)
           
     }
     const timeComparator = (valueA, valueB, nodeA, nodeB, isInverted) => {
        //console.log('valueA, valueB, nodeA, nodeB, isInverted ',valueA, valueB, nodeA, nodeB, isInverted)


        let aMs = moment(valueA, 'HH:mm:ss').format('x')
        let bMs = moment(valueB, 'HH:mm:ss').format('x')
        //console.log("Time sort",aMs,bMs)
            if(valueA=='na' || valueB=='na')return 0;
            if (valueA == valueB) return 0;
            return (aMs > bMs) ? 1 : -1;       
     };

    function AGTableLocationVisited(arr2){
        //console.log("Test Data Reieved",arr2,widthContainer)
        let widthCal=widthContainer
       let arr=<div className='nochildAGTab'><div className="ag-theme-alpine if cell-size-35" style={{height: 275, maxHeight:275  , width: "100%"}}>
        <AgGridReact
                 rowHeight={35}
                // defaultColDef={{sortable: true,resizable: true,minWidth: 45,width:135}}
                defaultColDef={{sortable: true,resizable: true,minWidth:45,
                suppressColumnVirtualisation:true,skipHeaderOnAutoSize:true}}
                 rowData={arr2}
                 key={"AgLocationVistedData"}
                 onCellClicked={ (event) => handleClickCard(event.data.tag_serial) }
                 
                 overlayNoRowsTemplate={
         
                     '<span class="ag-overlay-no-rows-center">No data Found !</span>'
                   }
    
         >
        <AgGridColumn
            field="name"
            headerName={"Location Visited for selected period"}
           valueFormatter={(params)=>capitalizeFirstLetter(params.value) } 
           width={widthContainer - 150}
           
        />
            <AgGridColumn field="time" headerName="Time" comparator={timeComparator} width={"100"}
            valueFormatter={(params)=>capitalizeFirstLetter(params.value) } 
            >


            </AgGridColumn>
        </AgGridReact>
    </div></div>
    return arr;
    }
    
    function RedirectSiteManagement(tag_serial){

        props.history.push(`/site-list/view/:${tag_serial}`)
    }

    

    function ShowCardUnProductive(){
        let arr = [];
        let data = employeeData.Less_non_productive?employeeData.Less_non_productive:[];
        let arr2 =[];
        arr2 = data.sort((a,b) => {
          let aMs = moment(a.time, 'HH:mm:ss').format('x')
          let bMs = moment(b.time, 'HH:mm:ss').format('x')
          return  sortTypeunproductive=='desc'? bMs - aMs  : aMs - bMs
       })
    
        for (let index = 0; index < arr2.length; index++) {
          let element = data[index];
          let inc=0;
          Object.keys(element).forEach((key) => {
              if(key=='time'){
              }else{
                arr.push({name:Object.keys(element[key])[0],type:Object.values(element[key])[0],time:Object.values(element[key])[1],tag_serial:key});
                    // arr.push(
                    // <tr className="employee-detail-table-tr" key={'ShowCardUnProductive'+index} onClick={()=>RedirectSiteManagement(key)} style={{cursor:"pointer"}}>
                    //     <td>{capitalizeFirstLetter(Object.keys(element[key])[0])}</td>
                    //     <td className="employee-detail-table-tr-td">{Object.values(element[key])[0]=='non_productive'?<span className='danger productiveTable'>{Object.values(element[key])[1]!=null?Object.values(element[key])[1]:""}</span>:Object.values(element[key])[0]=='less_productive'?<span className='warning productiveTable'>{Object.values(element[key])[1]!=null?Object.values(element[key])[1]:""}</span>:<span className='green productiveTable'>{Object.values(element[key])[1]!=null?Object.values(element[key])[1]:""}</span>}</td>
                    // </tr>
                    // );
                }
                inc++;
          });
        }
        SetUnproductiveVisitedData(arr) ;

    
    }

    function StatusTypeRender(params){
        var eGui = document.createElement('div');

        if(params.data.type=='non_productive'){
            eGui.innerHTML = `<span class='danger productiveTable'>${params.value}</span>`
        }else if(params.data.type=='less_productive'){
            eGui.innerHTML = `<span class='warning productiveTable'>${params.value}</span>`
        }else{
            eGui.innerHTML = `<span class='green productiveTable'>${params.value}</span>`
        }

         return eGui;
    }


    function AGTableUnprotected(arr2){
       let arr=<div className='nochildAGTab'><div className="ag-theme-alpine if cell-size-35" style={{height: 275, maxHeight:275  , width: "100%"}}>
        <AgGridReact
                 rowHeight={35}
                // defaultColDef={{sortable: true,resizable: true,minWidth: 45,width:135}}
                defaultColDef={{sortable: true,resizable: true,minWidth:45,
                suppressColumnVirtualisation:true,skipHeaderOnAutoSize:true}}
                 rowData={arr2}
                 key={"AGTableUnprotected"}
                 onCellClicked={ (event) => handleClickCard(event.data.tag_serial) }
                 
                 overlayNoRowsTemplate={
         
                     '<span class="ag-overlay-no-rows-center">No data Found !</span>'
                   }
    
         >
        <AgGridColumn
            field="name"
            headerName={"Less and unproductive location"}
           valueFormatter={(params)=>capitalizeFirstLetter(params.value) } 
           width={widthContainer - 150}
           
        />
            <AgGridColumn field="time" headerName="Time" comparator={timeComparator} width={"100"} 
                cellRenderer={StatusTypeRender}
            ></AgGridColumn>
        </AgGridReact>
    </div></div>
    return arr;
    }
    

    function ShowCardAcess(){
        let arr = [];
        let arr2 =[];
        let data = employeeData.access_policy_breach?employeeData.access_policy_breach:[];
        arr2 = data.sort((a,b) => {
          let aMs = a.time
          let bMs = b.time
          return  sortTypeacess=='desc'? bMs - aMs  : aMs - bMs
       })






        for(let i=0;i<data.length;i++){
            let element= data[i]
            // arr.push(
            //     <tr className="employee-detail-table-tr" key={"locationVisitedData"+index} onClick={()=>RedirectSiteManagement(arr2[index].tag_serial)} style={{cursor:"pointer"}}>
            //       <td>{capitalizeFirstLetter(arr2[index].location)}</td>
            //       <td className="employee-detail-table-tr-td">{arr2[index].time}</td>
            //     </tr>
            //   );
            arr.push({name:element[Object.keys(element)[0]].name,time:element[Object.keys(element)[0]].count,tag_serial:Object.keys(element)[0]});
 
        }
        
        SetAcessData(arr)
    }

    function ShowDataTabActive(){
        let arr2=[];
        if(activeTab=='productivity'){
            arr2= productivity
        }else if(activeTab=='lessproductivity'){
            arr2= lessproductivity
        }else{
            arr2= nonproductivity
        }
    
        let arr=
            <div className="siteWidgetTable siteWidgetTable2  col-lg-12"><div className='nochildAGTab'><div className="ag-theme-alpine if cell-size-35" style={{height: 275, maxHeight:275  , width: "100%"}}>
        <AgGridReact
                 rowHeight={35}
                // defaultColDef={{sortable: true,resizable: true,minWidth: 45,width:135}}
                defaultColDef={{sortable: true,resizable: true,minWidth:45,flex:1,
                suppressColumnVirtualisation:true,skipHeaderOnAutoSize:true}}
                 rowData={arr2}
                 key={"AGTableUnprotected"}
                 onCellClicked={ (event) => handleClickCard(event.data.tag_serial) }
                 
                 overlayNoRowsTemplate={
         
                     '<span class="ag-overlay-no-rows-center">No data Found !</span>'
                   }
    
         >
        <AgGridColumn
            field="name"
            headerName={"Location"}
           valueFormatter={(params)=>capitalizeFirstLetter(params.value) } 
        />
         
        </AgGridReact>
    </div></div></div>
    return arr;
    }
    function AGTableAcessData(arr2){
        let arr=<div className='nochildAGTab'><div className="ag-theme-alpine if cell-size-35" style={{height: 275, maxHeight:275  , width: "100%"}}>
         <AgGridReact
                  rowHeight={35}
                 // defaultColDef={{sortable: true,resizable: true,minWidth: 45,width:135}}
                 defaultColDef={{sortable: true,resizable: true,minWidth:45,
                 suppressColumnVirtualisation:true,skipHeaderOnAutoSize:true}}
                  rowData={arr2}
                  key={"AGTableAcessData"}
                  onCellClicked={ (event) => handleClickCard(event.data.tag_serial) }
                  
                  overlayNoRowsTemplate={
          
                      '<span class="ag-overlay-no-rows-center">No data Found !</span>'
                    }
     
          >
         <AgGridColumn
             field="name"
             headerName={"Acess Policy Breach"}
            valueFormatter={(params)=>capitalizeFirstLetter(params.value) } 
            width={widthContainer - 150}
            
         />
             <AgGridColumn field="time" headerName="Time" width={"100"} 
              ></AgGridColumn>
         </AgGridReact>
     </div></div>
     return arr;
     }
 

    useEffect(() => {

        let idVal = props.match.params.id.replace(":", "")

        if (idVal) {
            let date = getDateFormat(selectedDate)

            
        }

    }, [selectedDate]);




    

    function getDateFormat(date) {
        return moment(date).format('YYYY-MM-DD')
    }

  
  
    function handleDateSelect(date) {
        updateSelectedDate(date)
        
        setDates({
            start: date,
            end: date,
        })
        localStorage.setItem('ProductiveStart',date);
        localStorage.setItem('ProductiveEnd',date);
    
  }

    function changeLanguage(lang) {
        getLanguageTranslation(lang).then(res => {
            if (res && res.status >= 200 && res.status <= 200) {
                localStorage.setItem('languageData', JSON.stringify(res.data))
                localStorage.setItem('selectedLanguage', lang)
                props.setSelectedLanguage(lang)

            }
        })
    }



    useEffect(() => {
        if (props.language) {
            updateSelectedLangValue(props.language)
        }
    }, [props.language])

    function RedirectUser(data){

        props.history.push(`/site-list/view/:${data.location_serial}`)

    }

    function DisplayToolTipData(){

        let data = selectedSeries;
        let arr=[];

        if(data.data!=null){


        Object.keys(data.data).forEach((key,i)=>{
       arr.push(
        <Row style={{ alignItems: 'center' }} key={"data"+i} onClick={()=>RedirectUser(data.data[key])}>
        <Col className="b-r" style={{ maxWidth: "70%",minWidth:"70%" }} >
            <b className="font-bold">{capitalizeFirstLetter(key)}</b>
        </Col>
        <Col className="" style={{ maxWidth: "30%",minWidth:"30%" }} >
            {/* <div className="priSriMriText">Spread Index</div> */}
            <h6 className="font-bold">{data.data[key].time ? data.data[key].time + '' : '-'}</h6>
        </Col>
    </Row>

    )
        })
    }

        return arr;
    }


        return (
            <>{permissionAPICall?permission.View=="True"?
            <div className="siteViewMainDiv manpowerManagementMainDiv productivityManagementDiv">
                <div className='employeeDetailsMainDiv'>
                <Container className='header-breadcrumb'>
                <Row>
                    <Col lg={5} className={'m-t-sm'}>
                        <div className="siteViewHeaderDiv">
                            <span className="smallHeader" onClick={handleSiteListClick}>{getTranslatedText('Productivity')}</span>
                            <span className="breadCrumbArrow"> &gt; </span>
                            <span className="mediumHeader">{getTranslatedText('Employee detail')}</span>
                        </div>
                    </Col>
                    <Col lg={7} className="text-right">
         
                <div className="dashboardLanguageMainDiv m-r-md">
                            <DashboardLanguage
                                selectedLangValue={selectedLangValue}
                                changeLanguage={changeLanguage}
                            />
                        </div>
                        <div className="commonHeadingDateMainDiv">
                            <CommonDatePicker
                                selectedDate={selectedDate}
                                handleSelectDate={handleDateSelect}
                            />
                        </div>

                        <DeviceStatus  permission={permission} role={props.role}/>
                    </Col>
                    </Row>
                <div className='manpowerManagementEmployeeListMainDiv manpowerManagementEmployeeListProductivity m-t-lg'>
                  
                    <Row className="">

                        <Col lg={3}>
                            <div className="siteViewDetailsLeftSideDiv" style={{ height: '100%' }}>
                                <div className="headerNameDiv">{individualInfo!=null?individualInfo.name:"-"}</div>
                                <div className="subHeaderDiv">{individualInfo!=null?individualInfo.department:"-"}</div>
         
                                <div className="separaterLine"></div>

                                <div className="batteryMainDiv batteryMainDiv2 m-t-lg" style={{overflow:"auto"}}>
                                <div style={{float:"left",marginTop:"5px"}}><img src={batteryIcon} /></div> 
                                    <div style={{float:"left"}}>&nbsp;{individualInfo.battery_status}{individualInfo.battery_status=='na'? '' : '%'}<br/>
                                    <span style={{fontSize:"80%"}}>&nbsp; Registered Device : </span>
                                    <span style={{fontSize:"80%"}}>{individualInfo.tag_serial}</span></div>
                                </div>
                                <div className="recommendMainDiv">
                                    {/* <h5 className="font-bold text-white">{getTranslatedText('Recommend')}</h5> */}
                                    <div class="tab-view tab-view-2">
                                            <div class={activeTab=='productivity'?"tab tab-activity cursor-pt active":"tab tab-activity cursor-pt"}  onClick={()=>SetActiveTab('productivity') }>Productivity</div>
                                            <div class={activeTab=='nonproductivity'?"tab tab-activity cursor-pt active":"tab tab-activity cursor-pt"} onClick={()=>SetActiveTab('nonproductivity') }>Non Productivity</div>
                                            <div class={activeTab=='lessproductivity'?"tab tab-activity cursor-pt active":"tab tab-activity cursor-pt"} onClick={()=>SetActiveTab('lessproductivity') }>Less Productivity</div>
                                        </div>
                                    {ShowDataTabActive()}

                                    {/* <div className="recommendListMainDiv m-t-md text-white">
                                       

                                        <div className="eachRecommendCardDiv">
                                            <Row>
                                                <Col lg={8}>
                                                    <span>Close contact with people count</span>
                                                </Col>

                                                <Col lg={4} className="text-right ">
                                                    <strong>
                                                        {individualInfo!=null?individualInfo.close_contact_people:"-"} 
                                                    </strong>
                                                </Col>
                                            </Row>
                                        </div>

                                        <div className="eachRecommendCardDiv">
                                            <Row>
                                                <Col lg={8}>
                                                    <span>Close contact with location count</span>
                                                </Col>

                                                <Col lg={4} className="text-right ">
                                                    <strong>
                                                    {individualInfo!=null?individualInfo.close_contact_locations:"-"} 
                                                    </strong>
                                                </Col>
                                            </Row>
                                        </div>

                                        <div className="eachRecommendCardDiv">
                                            <Row>
                                                <Col lg={8}>
                                                    <span>Interacted Employee Count</span>
                                                </Col>

                                                <Col lg={4} className="text-right ">
                                                    <strong>
                                                    {individualInfo!=null?individualInfo.interacted_employee_count:"-"} 
                                                    </strong>
                                                </Col>
                                            </Row>
                                        </div>


                                    </div> */}
                                </div>

                            </div>
                        </Col>
                        <Col lg={9} className='left-no-space-resp'>

                            <div className="siteViewRightSideDiv">
                                <Row>
                                    <Col lg={8}>
                                    <div
                                        className="startDateEndDateMainDiv date-start date-label-full"
                                        style={{ paddingLeft: "0px" }}
                                        >
                                        <label>Start Date</label>
                                        <DatePicker
                                            selected={dates.start}
                                            onChange={(date) => handleDate(date, "start")}
                                            dateFormat={'MMM dd'}
                                            isClearable={false}
                                            placeholderText={getTranslatedText(
                                            "Start Date"
                                            )}
                                            maxDate={moment(dates.end).toDate()}
                                            minDate={moment().subtract(120, "days").toDate()}
                                        />
                                        
                                        </div>

                                        <div
                                            className="startDateEndDateMainDiv date-end date-label-full"
                                            style={{ paddingLeft: "0px" }}
                                            >
                                            <label>End Date</label>
                                            <DatePicker
                                                selected={moment(dates.end).toDate()}
                                                onChange={(date) => handleDate(date, "end")}
                                                dateFormat={'MMM dd'}
                                                isClearable={false}
                                                placeholderText={getTranslatedText("End Date")}
                                                maxDate={selectedDate}
                                                minDate={dates.start}
                                            />
                                            </div>

                                    </Col>
                                    <Col lg={4} className='align-right ct-data ct-data-site pos-relative-data'>
                        
                        <div className="ct-graph-data ct-graph-data-false " key={"selectedData"} 
                        style={{height:selectedSeries.data!=null && selectedSeries.data.length> 0?((selectedSeries.data.length+ 2 )*25):""   }}
                        >
                            
                        <div>
                         <div className='smaller-header'>       
                         <div className='header-left' style={{width:"60%"}}>           
                         {selectedSeries.name}
                        </div>
                        <div className='header-right' style={{width:"40%"}}><strong style={{fontWeight:"500"}}>{selectedSeries.time}
                          </strong></div>                         
                        </div>
                            <Scrollbars style={{height:"90px"}}>
                                {DisplayToolTipData() }
                             </Scrollbars></div>

                        </div>
                    </Col>

                                </Row>
                                <Row>

            <Col lg={4} className='donutChart'>
                
                <div className='donut-inner'>
                {chartLoader?
                            <div className="text-center">
                                    <img src={spinnerLoader} className="m-t-lg" />
                                </div> :<>
                    <h6>Productivity</h6>
                    <ReactHighcharts config={configDonut} key={'donutchart'} />
                    <div className='legendarea'>
                        <div className='legendList'><span style={{background:"#90cf4e"}}></span > <span className='textTitle'>Productive</span></div>
                        <div className='legendList'><span style={{background:"#fdbf00"}}></span> <span className='textTitle'> Less Productive</span></div>
                        <div className='legendList'><span style={{background:"#fc0001"}}></span> <span className='textTitle'>Non Productive</span></div>
                        <div className='legendList'><span style={{background:"#dedee3"}}></span> <span className='textTitle'>Undefined</span></div>
                    </div>
                    </>}
                </div>
            </Col>                                        
            <Col lg={8}>
            {donutLoader?
                            <div className="text-center m-t-lg">
                                    <img src={spinnerLoader} className="m-t-lg" />
                                </div> :
                    <div className="stackChart"><ReactHighcharts config={config} key={'Stackedchart'}  /></div>}
            </Col>





                                </Row>

                            </div>
                        </Col>
                    </Row>
                    

                    <Row style={{ margin: 0 }} className="mostInteractedMainDiv">
                        <Col className="p-l-0" lg={4} ref={elementRef}>

                          {/* <table className="tableBox">
                            <tr className="employee-detail-table-tr">
                              <th style={{width:"75%"}}>
                                Location Visited for selected period
                                <img
                                  src={helpIcon}
                                  alt=""
                                  onMouseEnter={() =>
                                    handleMouseEnter(`interHelp`)
                                  }
                                  onMouseLeave={() =>
                                    handleMouseLeave(`interHelp`)
                                  }
                                />
                                <span className="descHelp" id="interHelp">
                                Location Visited for selected period
                                </span>

                              </th>
                              <th className="employee-detail-table-tr-td" style={{width:"25%"}}>
                                Time
                                <img
                                  alt=''
                                  className='sorticon'
                                  src={sortKey === 'locationVisited' ? sortTypelocationVisited === 'asc' ? upIcon : downIcon : sortIcon}
                                  onClick={() => handleSortlocationVisited('locationVisited')}
                                />
                              </th>
                            </tr>
                            {loaderWidget?<div className="text-center">
                                    <img src={spinnerLoader} className="m-t-lg" />
                                </div>:
                            <Scrollbars
                              autoHide
                              style={{ width: "100%", height: 150 }}
                            >
                              {ShowCardLocation()}
                            </Scrollbars>
                            }
                          </table> */}
                          {AGTableLocationVisited(locationVisitedData)}
                        </Col>
                        <Col lg={4}>
                          {/* <table className="tableBox">
                            <tr className="employee-detail-table-tr">
                              <th style={{width:"75%"}}>
                                Less and unproductive location
                                <img
                                  src={helpIcon}
                                  alt=""
                                  onMouseEnter={() =>
                                    handleMouseEnter(`locHelp`)
                                  }
                                  onMouseLeave={() =>
                                    handleMouseLeave(`locHelp`)
                                  }
                                />
                                <span className="descHelp" id="locHelp">
                                Less and unproductive location
                                </span>
                              </th>
                              <th className="employee-detail-table-tr-td" style={{width:"25%"}}>
                                Time
                                <img
                                  alt=''
                                  className='sorticon'
                                  src={sortKey === 'unproductive' ? sortTypeunproductive === 'asc' ? upIcon : downIcon : sortIcon}
                                  onClick={() => handleSortunproductive('unproductive')}
                                />
                              </th>
                            </tr>
                            {loaderWidget?<div className="text-center">
                                    <img src={spinnerLoader} className="m-t-lg" />
                                </div>:
                            <Scrollbars
                              autoHide
                              autoHeight
                              style={{ width: "100%" }}
                            >
                               {ShowCardUnProductive()} 
                            </Scrollbars> }
                          </table> */}
                          {AGTableUnprotected(unproductiveVisitedData)}
                        </Col>
                        <Col lg={4} className={'p-r-0'}>
                          {/* <table className="tableBox">
                            <tr className="employee-detail-table-tr">
                              <th style={{width:"65%"}}>
                                Acess Policy Breach
                                <img
                                  src={helpIcon}
                                  alt=""
                                  onMouseEnter={() =>
                                    handleMouseEnter(`breach`)
                                  }
                                  onMouseLeave={() =>
                                    handleMouseLeave(`breach`)
                                  }
                                />
                                <span className="descHelp" id="breach">
                                Acess Policy Breach. 
                                </span>
                              </th>
                              <th className="employee-detail-table-tr-td" style={{width:"35%"}}>
                                Occurence
                                <img
                                  alt=''
                                  className='sorticon'
                                  src={sortKey === 'acess' ? sortTypeacess === 'asc' ? upIcon : downIcon : sortIcon}
                                  onClick={() => handleSortAcess('acess')}
                                />
                              </th>
                            </tr>
                            {loaderWidget?<div className="text-center">
                                    <img src={spinnerLoader} className="m-t-lg" />
                                </div>:
                             <Scrollbars
                              autoHide
                              autoHeight
                              style={{ width: "100%" }}
                            >
                              {ShowCardAcess()} 
                            </Scrollbars>}
                          </table> */}
                          {AGTableAcessData(acessData)}
                        </Col>
                      </Row>
                  
                   
                    </div>
                </Container>
            </div>
            </div>:<Col><div className='center404'> <Image404 /><h4 style={{marginTop:"-30px",color:"#2e3552"}}>Access Restricted to this page, Please contact administrator</h4></div>
            </Col>:<Col lg={12} md={12} sm={12} xs={12} className="apiLoaderPermission">
                                    <img src={spinnerLoader} />
                                </Col>}</>
        )
    }




const mapStateToProps = (state) => ({
    language: state.dashboard.selectedLangaugeValue
})

export default connect(mapStateToProps, { setSelectedLanguage })(withRouter(ProductivityTruckDetails))


