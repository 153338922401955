import React from 'react'
import { Switch, Route } from 'react-router-dom';

import Dashboard from '../dashboard/components';
import ThreatWatch from '../dashboard/components/threatWatch';

import SiteMangementList from '../siteManagement/components/index'
import SiteMangementView from '../siteManagement/components/SiteMangementView'
import SiteViewDetails from '../siteManagement/components/details';
import LoginComponent from '../login/components/login';
import ManPowerMangementList from '../manPowerManagement/components';

import PrivateRoute from './privateRoute'
import EmployeeList from '../manPowerManagement/components/employeeList';
import EmployeeDetails from '../manPowerManagement/components/details';
// import CreatePolicy from '../policyManagement/components/list/createPolicy';
import PolicyManagement from '../policyManagement/components';
import PolicyCompliancePeople from "../policyManagement/components/policyCompliance/people";
import PolicyComplianceTeam from "../policyManagement/components/policyCompliance/team";
import PolicyComplianceLocation from '../policyManagement/components/policyCompliance/location'
import PolicyDetail from '../policyManagement/components/detail/policyDetail';
import VisitorManagement from '../visitorManagement/components/index';
import Productivity from '../productivity/componenets/index'
import VisitorDetails from '../visitorManagement/components/details/index';
import ProductivityDetails from '../productivity/componenets/detail/index'
import ProductivityLocationDetails from '../productivity/componenets/location/index'
import ProductivityTruckDetails from '../productivity/componenets/truck/index'
import Simplr from '../employeeTracker/components/Simplr'
import FacilitiesTracker from '../facilitiesTracker/components/index'
import RealTimeTracker from '../realtimeTracker/components/index'
import ReportExport from '../report/componenets/index'
import CycleCount from '../cycleCount/componenets/index'

const Routes = (props) => {

    return (<Switch>
        <Route exact path='/login' component={LoginComponent} role={props.role}/>
        <PrivateRoute exact path='/dashboard' component={Dashboard} role={props.role}/>
        <PrivateRoute exact path='/site-list/view/:id' component={SiteViewDetails} role={props.role}/>
        <PrivateRoute exact path='/site-list' component={SiteMangementList} role={props.role}/>
        <PrivateRoute exact path='/site-management' component={SiteMangementView} role={props.role}/>
        <PrivateRoute exact path='/manpower-management' component={ManPowerMangementList} role={props.role}/>
        <PrivateRoute exact path='/manpower-management/employee-list' component={EmployeeList} role={props.role}/>
        <PrivateRoute exact path='/manpower-management/employee-list/view/:id' component={EmployeeDetails} role={props.role}/>
        <PrivateRoute exact path='/policy-management' component={PolicyManagement} role={props.role}/>
        <PrivateRoute exact path='/policy-compliance-people/:id' component={PolicyCompliancePeople} role={props.role}/>
        <PrivateRoute exact path='/policy-compliance-location/:id' component={PolicyComplianceLocation} role={props.role}/>
        <PrivateRoute exact path='/policy-compliance-team/:id' component={PolicyComplianceTeam} role={props.role}/>
        <PrivateRoute exact path='/policy-detail/:id' component={PolicyDetail} role={props.role}/>
        <PrivateRoute exact path='/visitor-management' component={VisitorManagement} role={props.role}/>
        <PrivateRoute exact path='/visitor-management/view/:id' component={VisitorDetails} role={props.role}/>
        
        <PrivateRoute exact path='/productivity' component={Productivity} role={props.role}/>
        <PrivateRoute exact path='/productivity/:employee/:id' component={ProductivityDetails} role={props.role}/>
        <PrivateRoute exact path='/employee-tracker' component={Simplr} role={props.role} />
        <PrivateRoute exact path='/productivity/truck/:id' component={ProductivityTruckDetails} role={props.role}/>
        <PrivateRoute exact path='/productivity/location/:id' component={ProductivityLocationDetails} role={props.role}/>
        <PrivateRoute exact path='/facility-tracker' component={FacilitiesTracker} role={props.role}/>
        <PrivateRoute exact path='/geo-map-management' component={RealTimeTracker} role={props.role}/>
        <PrivateRoute exact path='/report' component={ReportExport} role={props.role}/>
        <PrivateRoute exact path='/cycle-count' component={CycleCount} role={props.role}/>
        
        {/* <PrivateRoute exact path='/policy-management/create-policy' component={CreatePolicy} /> */}
        <PrivateRoute exact path='/' component={Dashboard} role={props.role}/>
        
    </Switch>)
}

export default Routes
