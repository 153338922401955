import axios from "axios";


let prefixURL = process.env.REACT_APP_URL

export function getSiteLocations(requestBody , userSession, org_id) {
    
    return axios.get(prefixURL + `/get_site_locations_list?session=${userSession}&date=${requestBody.date}&org_id=${org_id}`)
        .then(res => res.data).catch(err => err)
}

export function getSiteOverview(requestBody , userSession, org_id) {
    return axios.get(prefixURL + `/get_site_overview?session=${userSession}&org_id=${org_id}&date=${requestBody.date}&tag_serial=${requestBody.locationID}`)
        .then(res => res.data).catch(err => err)
}

export function getSiteFootFall(requestBody, userSession, org_id) {
    return axios.get(prefixURL + `/get_site_footfall?session=${userSession}&org_id=${org_id}&date=${requestBody.date}&location_id=${requestBody.locationID}`)
        .then(res => res.data).catch(err => err)
}

export function getSiteAreaIndex(requestBody , userSession, org_id) {
    

    return axios.get(prefixURL + `/get_site_area_index?session=${userSession}&org_id=${org_id}&date=${requestBody.date}&location_id=${requestBody.locationID}`)
        .then(res => res.data).catch(err => err)
}

export function getLocationEmployee(requestBody , userSession, org_id) {
    

    return axios.get(prefixURL + `/get_location_employee_list?session=${userSession}&org_id=${org_id}&date=${requestBody.date}&tag_serial=${requestBody.tag_serial}`)
        .then(res => res.data).catch(err => err)
}



export const footfallChart = async (requestBody, userSession, org_id) => {
    try {
        const res = await axios.get(`${prefixURL}/get_location_footfall?session=${userSession}&start_date=${requestBody.start_date}&tag_serial=${requestBody.locationID}&org_id=${org_id}&end_date=${requestBody.end_date}`);
        return res.data;
    } catch (err) {
        return err;
    } 
}

export const locationChart = async (requestBody, userSession, org_id) => {
    try {
        // const res = await axios.get(`${prefixURL}/get_employee_org_charts?session=${userSession}&start_date=${requestBody.start}&emp_id=${requestBody.emp_id}&index_name=population&org_id=${org_id}&end_date=${requestBody.end}`)
        const res = await axios.get(`${prefixURL}/get_site_org_charts?session=${userSession}&start_date=${requestBody.start}&org_id=${org_id}&end_date=${requestBody.end}`)
        return res.data
    } catch (err) {
        return err;
    }
}