import React,{useEffect, useState} from 'react'
import ReactHighcharts from 'react-highcharts'
import moment from 'moment';

const riskLevelColor = {
  low: "#04e06e",
  medium: "#ffd700",
  high: "#ffa500",
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    height: "90%",
  },
};

function ChartReport(props) {
const [average,SetAverage]=useState(0);
    

    useEffect(()=>{
        let allVal=0
        //console.log("Data pass report",props.chartData,props.chartData.series);
        for (let i=0;i<props.chartData.series.length;i++){
            let result = props.chartData.series[i].data.reduce(function(sum, current) {
                     
                return sum + parseFloat(current);
              }, 0);
              allVal=allVal+result;
        }
        //console.log("Average Value for current chart",allVal,allVal/props.chartData.series.length,props.chartData.series.length);
        SetAverage(allVal/props.chartData.series.length);

    },[props.chartData])

    let config = {
        chart: {
            type: 'column',
            height: 400,
            //spacingLeft: 42,
                  },
        exporting: {
            enabled: false
        },
        credits: {
            enabled: false
        },
        title: {
            text: null
        },
        tooltip: {
            useHTML: true,
            formatter:  function () {
               ////console.log(this);
                  return `<span style="font-size: 10px;padding-right:15px;" class="tool-tip-x">${this.x}</span><span style="font-size: 10px" class="tool-tip-date">${this.series.options.acSeries?this.series.options.acSeries:this.series.options.department}</span><br/>
                         <span style="background:${this.color}" x="8" dy="15" class="tool-tip-status"></span>
                         <span dx="0" class="tool-tip-name"> ${this.series.name}: </span><span style="font-weight:bold" dx="0" class="tool-tip-result">${this.y.toFixed(2)}%</tspan>`;
                },
             borderColor: '#FFFFFF',
            backgroundColor: '#FFFFFF',
            borderRadius: 15
        },
        xAxis: {
            categories: props.chartData.categories || [],
            tickPositioner: function () {
                let positions = []
                let tick = Math.floor(this.dataMin)
                let increment = Math.ceil((this.dataMax - this.dataMin) / 10);
    
                if (this.dataMax === this.dataMin) {
                    return [0]
                }

                if (this.dataMax !== null && this.dataMin !== null) {
                    for (tick; tick - increment <= this.dataMax; tick += increment) {
                        positions.push(tick);
                    }
                }

                return positions;
            },
            
            events: {
                load: function (e) {
                    
                //this.series[0].data[index].setState('hover');
                }
            },
          //  gridLineDashStyle: 'ShortDash',
           // gridLineWidth: 1,
            // max: (start + 3 * move),
            // min: (start + 2 * move)
        },
        yAxis: {
            title: {
                text: " "
            },
            gridLineDashStyle: 'ShortDash',
            gridLineWidth: 0,
            min: 0,
            max: 100,
            labels: {
                enabled: true,
            },
            plotOptions: {
                series: {
                    cursor: 'pointer',
                    point: {
                        events: {
                            click: function () {
                                alert('Category: ' + this.category + ', value: ' + this.y);
                            }
                        }
                    }
                }
            },
            plotLines: [
                
                {
                    color:"#000",
                    width: 1,
                    value: average,
                    dashStyle: 'LongDash',
                    zIndex: 100,
                    //rotation: 90,
                    label: {
                        text: average.toFixed(2),
                        style: {
                            color: '#000',
                            fontWeight: 'bold',
                        },
                    }
                },
                
            ]





                    
        },
        series: props.chartData.series || []
    }

    return (
            <div>
                <ReactHighcharts  config={config}  key= {'reportv1Model'}/>
            </div>
        )
}


export default ChartReport;