import React, { useState } from 'react'
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import Modal from 'react-modal'
import Aside from './aside';


import traceplusLogo from '../assets/traceplusImages/trace_logo.png'
import { logoutUser } from '../login/actionMethods/actionMethods';

import SpinnerLoader from '../assets/images/Spinner Loader.gif'

const customStyle = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(255, 255, 255, 0.75)'
    },
    content: {
        position: 'absolute',
        width: '25rem',
        height: '13rem',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        border: '1px solid #ccc',
        background: '#fff',
        overflow: 'auto',
        WebkitOverflowScrolling: 'touch',
        borderRadius: '4px',
        outline: 'none',
        padding: '0'
    }
}

function LeftSideBar(props) {

    const [showLoader, updateShowLoader] = useState(false)
    const [showLogout, setShowLogout] = useState(false)
    const [windowWidth,SetWindowWidth] =useState(window.innerWidth)
    const [menuActive,SetMenuActive] = useState(false);
    
    let userDetails = JSON.parse(localStorage.getItem('userLoginDetails'))


    function handleLogout() {
        updateShowLoader(true)
        let requestBody = {}
        requestBody.token = localStorage.getItem('tokenAuthTrace')?localStorage.getItem('tokenAuthTrace'):'tituw3958589'
        requestBody.session = userDetails.session?userDetails.session:'123456789'
     
        logoutUser(requestBody).then(res => {
            if (res && res.status == 200) {

                    updateShowLoader(false);
                    localStorage.removeItem('isLoggedIn')
                    localStorage.removeItem('userLoginDetails')
                    localStorage.removeItem('selectedDate')
                    localStorage.removeItem('tokenAuthTrace')
                    props.history.push(`/login`)
            }
        })
    }

    const handleLogoutModal = () => {
        setShowLogout(!showLogout)
    }

    function handleResize() {
      SetWindowWidth(window.innerWidth)
    }
    function closeMenu(){
        SetMenuActive(false)
    }
  React.useEffect(() => {

    
    window.addEventListener('resize', handleResize);

  })
    return (
        <React.Fragment>
            <div className='header-resp' style={{display:parseInt(windowWidth)<1024?"block":"none"}}>
                <div className='container'>
                    <div className='logo-area'>
                    <img
                        src={userDetails && userDetails.org_logo ? userDetails.org_logo : traceplusLogo}
                        className="logo"
                        alt="TracePlus Logo"
                        onError={(e) => {
                            e.target.src = traceplusLogo
                        }}
                    />
                    </div>
                    <div className='menu-area' onClick={()=>SetMenuActive(!menuActive)}><span className='menu-anime'></span></div>
                </div>
            </div>

            <div className={menuActive?"leftSideBarDiv activeMenu":"leftSideBarDiv"}>
                <div className='logo-row'>
                    <img
                        src={userDetails && userDetails.org_logo ? userDetails.org_logo : traceplusLogo}
                        className="logo"
                        alt="TracePlus Logo"
                        onError={(e) => {
                            e.target.src = traceplusLogo
                        }}
                    />
                    <span className='menu-anime-close' onClick={()=>SetMenuActive(false)}></span>
                </div>
                <h3 className="adminName" style={{fontSize : userDetails && userDetails.empName && userDetails.empName.length > 14 ? "25px" : "1.75rem"}}>
                    {userDetails && userDetails.empName || 'Jean'}
                </h3>

                <h4 className="designation">
                {userDetails && userDetails.empDept || 'Admin'} <div className="LogoutDiv LogoutDiv2" onClick={handleLogoutModal}>
                    <span>Logout</span>
                </div> 
                    </h4>
                
                <Aside license = {userDetails && userDetails.license ? JSON.parse(userDetails.license) : "" } permission={props.role} closeMenu={closeMenu}/>
                
            </div>


            <Modal
                isOpen={showLogout}
                style={customStyle}
            >
                <div className='logoutModal'>
                    <div className="logoutTitle">Logout</div>
                    <div className='logoutDesc'>Are you sure you want to Logout?</div>

                    <div className='actionButtons'>
                        <span className='btnText borderRight' onClick={handleLogoutModal}>No</span>
                        <span className='btnText okBtn' onClick={handleLogout}>Yes, Logout</span>
                    </div>
                </div>
            </Modal>
            {
                showLoader ?
                    <div className="wholePageLoaderMainDiv">
                        <img src={SpinnerLoader} />
                    </div> : ''
            }
        </React.Fragment>
    )
}

const mapStateToProps = (state) => ({
    language: state.dashboard.selectedLangaugeValue
})

export default connect(mapStateToProps, {})(withRouter(LeftSideBar))

