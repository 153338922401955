import React,{useState} from 'react'
import { Select } from 'antd';
import { Container, Row, Col } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import { getTranslatedText } from '../../common/utilities';
import moment from 'moment';
import dropdownIcon from '../../assets/images/down-arrow.png'
// import Chart from './areaChart'
import SpinnerLoader from "../../assets/images/Spinner Loader.gif"
import Chart from './barChart'
import Scrollbars from 'react-custom-scrollbars';

const { Option } = Select;
const clickOccur= () =>{

}
class DashboardChart extends React.Component {



    render() {


        return (
            <React.Fragment>
                <div >
                    <div className="m-l-lg" >
                        <h5 className="font-bold m-t-md m-b">
                            {
                                this.props.yAxisTitle
                            }
                        </h5>
                        {/* <Select defaultValue="Day" >
                            <Option value="Day">Day View</Option>
                           <Option value="Week">Week View</Option>
                    <Option value="Hour">Hour View</Option>
                        </Select> */}
                        <Row className="m-t-xs" style={{marginBottom:"85px"}}>
                            <Col lg={3} className="p-r-0">
                                <div className="contactRankText">{getTranslatedText('Start Date')}</div>
                                <div className="startDateEndDateMainDiv" style={{ paddingLeft: "0px" }}>
                                    <DatePicker
                                        selected={this.props.startDate}
                                        onChange={date => this.props.handleSelectStartDate(date)}
                                        dateFormat={'MMM dd'}
                                        isClearable={false}
                                        maxDate={moment(this.props.endDate).subtract(0, 'days').toDate()}
                                        minDate={moment().subtract(60, 'days').toDate()}
                                    />
                                    {/* <div className="dropdownIconDiv">
                                        <img src={dropdownIcon} />
                                    </div> */}
                                </div>
                            </Col>
                            <Col lg={1} className="p-l-0">
                            </Col>
                            <Col lg={3} className="p-l-0">
                                <div className="contactRankText">{getTranslatedText('End Date')}</div>
                                <div className="startDateEndDateMainDiv" style={{ paddingLeft: "0px" }}>
                                    {/* Here is having some date issue */}
                                    
                                    <DatePicker
                                        selected={this.props.endDate}
                                        onChange={date => this.props.handleSelectEndDate(date)}
                                        dateFormat={'MMM dd'}
                                        isClearable={false}
                                        maxDate={this.props.selectedDate}
                                        minDate={this.props.startDate}
                                    />
                                    {/* <div className="dropdownIconDiv">
                                        <img src={dropdownIcon} />
                                    </div> */}
                                </div>
                            </Col>
                            <Col lg={2} className="">
                            </Col>

                        <Col lg={4} className='align-right ct-data overflow-visible'>
                        
                            <div className={this.props.initialLoader===true?'ct-graph-data loader':"ct-graph-data ct-graph-data-false"}
                            style={{height:this.props.ShowSelectedData.length> 0?((this.props.ShowSelectedData.length+ 2 )*25):""   }}
                            >
                                
                            {this.props.yAxisTitle=='Spread Index' && this.props.chartDate!=='' && this.props.ShowSelectedData.length>0?
                            // Spread index
                            <div>
                             <div className='smaller-header'>       
                             <div className='header-left' style={{width:"60%"}}>           
                            <div className='tab-small'>
                                    <div className={this.props.currentTab=='Employee'?'tab-1 tab-tiny active':"tab-1 tab-tiny"}
                                    onClick={()=>this.props.TabHandlerChart('Employee')}>
                                            Employee
                                        </div>
                                    <div className={this.props.currentTab=='Team'?'tab-2 tab-tiny active':"tab-2 tab-tiny"}
                                    onClick={()=>this.props.TabHandlerChart('Team')}>
                                        Team
                                    </div>   
                            </div>
                            </div>
                            <div className='header-right' style={{width:"40%"}}><strong style={{fontWeight:"500"}}>{moment(this.props.chartDate).format('MMM DD [:] ddd')}</strong></div>
                             
                            </div>
                                <Scrollbars style={{height:"140px"}}>
                                    {this.props.ShowSelectedData}
                                 </Scrollbars></div>:""
                                }

                             {/* //MOvement Index    */}

                             {(this.props.yAxisTitle=='Movement Index' || this.props.yAxisTitle=='Area Index' ) && this.props.chartDate!=='' && this.props.ShowSelectedData.length>0?
                            // Spread index
                                                          
                            <div>
                             <div className='smaller-header'>       
                                <div className='header-left' style={{width:"50%"}}>
                                <div class="tab-small"><div class="tab-1 tab-tiny active">{this.props.yAxisTitle=='Area Index'?"Location":"Locations"}</div></div>
                                    </div>
                                <div className='header-right' style={{width:"50%"}}><strong style={{fontWeight:"500"}}>{moment( this.props.chartDate).format('MMM DD [:] ddd')}</strong></div>
                            </div>
                                <Scrollbars style={{height:"140px"}}>
                                    {this.props.ShowSelectedData}
                                 </Scrollbars></div>:""
                                }



                                <div className='loader-inner'>
                                        <img src={SpinnerLoader} />
                                </div>

                                {this.props.ShowSelectedData}

                            </div>
                        </Col>
                        </Row>
                    </div>
                    <Row>
                        <Col lg={12}>
                            <Chart {...this.props} click={clickOccur}/>
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        )
    }

}



export default DashboardChart