import axios from "axios";


let prefixURL = process.env.REACT_APP_URL


export function getPolicyList(requestBody, sessionID, id) {

    return axios.get(prefixURL + `/get_policy_list?session=${sessionID}&org_id=${id}&start_date=${requestBody.start_date}&end_date=${requestBody.end_date}`)
        .then(res => res.data).catch(err => err)

}

export function getPolicyLocation(sessionID, id){
    return axios.get(prefixURL + `/get_policy_location?session=${sessionID}&org_id=${id}`)
        .then(res => res.data).catch(err => err)
}

export function getPolicyEmployee(sessionID, id){
    return axios.get(prefixURL + `/get_policy_employee?session=${sessionID}&org_id=${id}`)
        .then(res => res.data).catch(err => err)
}

export function getPolicyTeam(sessionID, id){
    return axios.get(prefixURL+`/get_policy_team?session=${sessionID}&org_id=${id}`).then(res=>res.data).catch(err=> err)
}
export function postCreatePolicy(requestBody, userSession, org_id) {
 
    return axios.post(prefixURL+ '/create_policy', {
        policy_name: requestBody.policy_name,
        loc_serial: requestBody.loc_serial,
        org_id: org_id,
        capacity_status: requestBody.capacity_status,
        accessibility_status: requestBody.accessibility_status,
        max_capacity: requestBody.max_capacity,
        check_allow: requestBody.check_allow,
        accessibility_person: requestBody.accessibility_person,
        accessibility_team: requestBody.accessibility_team        
    }).then(res => res.data).catch(err => err)


}

export function removePolicy(sessionID, org_id,id){
    return axios.get(prefixURL+`/remove_policy?session=${sessionID}&org_id=${org_id}&id=${id}`).then(res=>res.data).catch(err=> err)
}

export function getPolicyOverview(requestBody,sessionID, org_id,level){
    return axios.get(prefixURL+`/get_policy_overview?session=${sessionID}&org_id=${org_id}&end_date=${requestBody.end_date}&start_date=${requestBody.start_date}&level=${level}`).then(res=>res.data).catch(err=> err)
}

export function getPolicZoom(requestBody, userSession, org_id) {
    return axios.get(prefixURL+`/get_policy_details?session=${userSession}&org_id=${org_id}&end_date=${requestBody.end_date}&start_date=${requestBody.start_date}&policy_id=${requestBody.policy_id}`).then(res=>res.data).catch(err=> err)
}

export function getPolicyInfo(sessionID, org_id,id){
    return axios.get(prefixURL+`/get_policy_info?session=${sessionID}&org_id=${org_id}&policy_id=${id}`).then(res=>res.data).catch(err=> err)
}

export function postUpdatePolicy(requestBody, userSession, org_id) {
    return axios.post(prefixURL+ '/update_policy', {
        policy_name: requestBody.policy_name,
        loc_serial: requestBody.loc_serial,
        org_id: org_id,
        capacity_status: requestBody.capacity_status,
        accessibility_status: requestBody.accessibility_status,
        max_capacity: requestBody.max_capacity,
        check_allow: requestBody.check_allow,
        accessibility_person: requestBody.accessibility_person,
        accessibility_team: requestBody.accessibility_team,
        session:userSession,
        policy_id: requestBody.policy_id,
        status: requestBody.status
    }).then(res => res.data).catch(err => err)


}
