import axios from "axios";



let prefixURL = process.env.REACT_APP_URL
let TraceAdminPrefixURL = process.env.REACT_TRACEADMIN_URL 
let prefixURLTraceAdmin = process.env.REACT_APP_URL_LOGIN

export function getPproductivitylist(requestBody, sessionID, id) {

    return axios.get(prefixURL + `/get_productivity_list?session=${sessionID}&org_id=${id}&start_date=${requestBody.startdate}&end_date=${requestBody.enddate}&slug=${requestBody.slug}`)
        .then(res => res.data).catch(err => err)

}

export function getDepartment(sessionID,id){
    return axios.get(prefixURL+`/get_department_list?session=${sessionID}&org_id=${id}`).then(res=>res.data).catch(err=>err)
}
export function getSubDepartment(sessionID,department_id,org_id){
    return axios.get(prefixURL+`/get_sub_department_list?department_id=${department_id}&session=${sessionID}&org_id=${org_id}`,).then(res=>res.data).catch(err=>err)
}
export function getShift(sessionID,org_id,department_id,sub_department_id){
    //console.log('condition check',(sub_department_id && sub_department_id!='' && sub_department_id!='all'))
  
    if(sub_department_id!='' && sub_department_id!='all'){

        return axios.get(prefixURL+`/get_shift_list?org_id=${org_id}&session=${sessionID}&department_id=&sub_department_id=${sub_department_id}`).then(res=>res.data).catch(err=>err)
    }else{
        return axios.get(prefixURL+`/get_shift_list?org_id=${org_id}&session=${sessionID}&department_id=${department_id}&sub_department_id=`).then(res=>res.data).catch(err=>err)
    }
    
}

export function getPproductivityOverview(requestBody, sessionID, id) {

    /* old filter
    return axios.get(prefixURL + `/get_org_productivity_overview?session=${sessionID}&org_id=${id}&start_date=${requestBody.startdate}&end_date=${requestBody.enddate}&slug=${requestBody.slug}`)
        .then(res => res.data).catch(err => err)
    */
        return axios.get(prefixURL + `/get_org_productivity_overview_filter?session=${sessionID}&org_id=${id}&start_date=${requestBody.startdate}&end_date=${requestBody.enddate}&slug=${requestBody.slug}&department_id=${requestBody.department_id}&sub_department_id=${requestBody.sub_department_id}&shift_id=${requestBody.shift_id}`)
        .then(res => res.data).catch(err => err)

}

export function getPproductivityOverviewAll(requestBody, sessionID, id) {
    return axios.get(prefixURL + `/get_org_productivity_overview?session=${sessionID}&org_id=${id}&start_date=${requestBody.startdate}&end_date=${requestBody.enddate}&slug=${requestBody.slug}`)
        .then(res => res.data).catch(err => err)
        
}


export function getPproductivityWidget(requestBody, sessionID, id) {

    return axios.get(prefixURL + `/get_productivity_widget?session=${sessionID}&org_id=${id}&start_date=${requestBody.startdate}&end_date=${requestBody.enddate}&slug=${requestBody.slug}&tag_serial=${requestBody.tag_serial}`)
        .then(res => res.data).catch(err => err)

}
export function getPproductivityDetailOverview(requestBody, sessionID, id) {
    return axios.get(prefixURL + `/get_productivity_detailed_overview?session=${sessionID}&org_id=${id}&start_date=${requestBody.startdate}&end_date=${requestBody.enddate}&slug=${requestBody.slug}&tag_serial=${requestBody.tag_serial}`)
        .then(res => res.data).catch(err => err)
}
export function getDetailedProductivityChart(requestBody, sessionID, id) {

    return axios.get(prefixURL + `/get_detailed_productivity_chart?session=${sessionID}&org_id=${id}&start_date=${requestBody.startdate}&end_date=${requestBody.enddate}&slug=${requestBody.slug}&tag_serial=${requestBody.tag_serial}`)
        .then(res => res.data).catch(err => err)

}
export function getProductivityIndividualInfo(requestBody, sessionID, id) {

    return axios.get(prefixURL + `/get_productivity_individual_info?session=${sessionID}&org_id=${id}&start_date=${requestBody.startdate}&end_date=${requestBody.enddate}&slug=${requestBody.slug}&tag_serial=${requestBody.tag_serial}`)
        .then(res => res.data).catch(err => err)

}

export function getIndividualProductivityList(requestBody,sessionID, id){
    return axios.get(prefixURL + `/get_individual_productivity_list?session=${sessionID}&org_id=${id}&slug=${requestBody.slug}&tag_serial=${requestBody.tag_serial}`)
        .then(res => res.data).catch(err => err)
}

