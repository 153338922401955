import React, { useState, useEffect } from "react";
import ReactModal from 'react-modal';
import {getEmployeeList} from '../manPowerManagement/actionMethods/actionMethods'
import {getSiteLocations} from '../siteManagement/actionMethods/actionMethods'
import AgGridEmployeeList from '../manPowerManagement/components/agGridEmployeeList'
import SiteMangementList from '../siteManagement/components/index'
import {getDeviceStatus} from '../dashboard/actionMethods/actionMethods'
import moment from "moment";
import { Row,Col } from "react-bootstrap";
import {getPproductivitylist} from "../productivity/actionMethods/actionMethods";
import {AgGridColumn, AgGridReact} from 'ag-grid-react';
import helpIcon from  "../assets/traceplusImages/help-icon.png";
import dropdownIcon from "../assets/images/down-arrow.png";
import mediumRiskIcon from "../assets/traceplusImages/medium_risk_icon.svg";
import spinnerLoader from "../assets/images/Spinner Loader.gif";
import {ReactComponent as DoubleArrow} from '../assets/images/angles-right.svg'
import {ReactComponent as SingleArrow} from '../assets/images/angle-right.svg'
import {ReactComponent as Plus} from '../assets/images/caret-right.svg'
import {ReactComponent as Minus} from '../assets/images/caret-down.svg'
import { useHistory } from "react-router-dom";



const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        maxHeight: '90%'
    },
};

function DeviceStatus(props){
    let history = useHistory();
    let date = localStorage.getItem('selectedDate') ? new Date(localStorage.getItem('selectedDate')) : new Date((new Date().setDate(new Date().getDate() - 1)));
    const [employeePopupFlag, updateEmployeePopupFlag] = useState(false)
    const [locationPopupFlag, updateLocationPopupFlag] = useState(false)
    const [visitorPopupFlag, updateVisitorPopupFlag] = useState(false)
    const [tempempPopupFlag, updateTempEmpPopupFlag] = useState(false)
    const [assetPopupFlag, updateAssetPopupFlag] = useState(false)
    const [selectedDate, updateSelectedDate] = useState(date)
    const [startDateValue, updateStartDateValue] = useState(moment(date).subtract(7, 'days').toDate())
    const [endDateValue, updateEndDateValue] = useState(date)
    const [statusCount, updateStatusCount] = useState({ personal: 0, location: 0, asset:0,visitor:0 })
    const [modalType, setModalType] = useState('')
    const [threatEmployee, setThreatEmployee] = useState('')
    const [isLoading,SetisLoading] = useState(false);
    const [tabData,SetTabData] = useState([]);
    const [parentAccord,SetParentAccord] =useState([])
    const [assetEMp,SetAssetEmp]=useState([])
    let interval = 1440
    let userDetails = JSON.parse(localStorage.getItem('userLoginDetails'))
    let userSession = userDetails ? userDetails.session : '123456789'
    let org_id = userDetails ? userDetails.org_id : 6
  



    useEffect(()=>{
        if(props.permission && props.permission.View=='True'){
            getDeviceStatus(userSession,org_id).then(res=>{
                
                localStorage.setItem('totalEmployeeTag',res.data.employee)
                localStorage.setItem('totalSiteLocation',res.data.location)
                localStorage.setItem('visitorTag',res.data.visitor)
                localStorage.setItem('assetTag',res.data.asset)

                updateStatusCount({ personal: res.data.employee, location: res.data.location,asset:res.data.asset,visitor:res.data.visitor,temp:res.data.temporary_employee})       
            })
        }
    },[])
    
    function handleCloseModal() {
        updateEmployeePopupFlag(false)
        updateLocationPopupFlag(false)
        updateVisitorPopupFlag(false)
        updateAssetPopupFlag(false)
        updateTempEmpPopupFlag(false)
        setModalType('')
        setThreatEmployee('')
    }


    function getDateFormat(date) {
        return moment(date).format('YYYY-MM-DD')
    }

    const getStatusCount = (date) => {
        let requestBody = {}
        requestBody.date = getDateFormat(date)

        // getEmployeeList(requestBody, userSession, org_id).then(res => {
        //     if (res.status === 200) {
        //         updateStatusCount(prev => ({ ...prev, personal: res.count || 0 }))
        //         let totalEmployeeTagCount = res.count ? res.count:0;
        //         localStorage.setItem('totalEmployeeTag', totalEmployeeTagCount);
                
        //     }
        // })

        // getSiteLocations(requestBody, userSession, org_id).then((res) => {
        //     if (res.status === 200) {
        //         updateStatusCount(prev => ({ ...prev, location: res.data && res.data.location_count ? res.data.location_count : 0 }))
                
        //         let totalSiteLocationCount = res.data.location_count ? res.data.location_count:0;
        //         localStorage.setItem('totalSiteLocation', totalSiteLocationCount);
        //     }
        // })



    }

    function handleClickCard(id){
        handleCloseModal() ; 
        history.push(`/productivity/truck/:${id}`);  
      }
      
      function capitalizeFirstLetter(string) {
        if(string){
          return string.toLowerCase();
        }
      }
      
      function AGGridEMptyMEssage(key){
        let msg;
            msg='<span class="ag-overlay-no-rows-center">No Records Found !</span>'
        return msg
      }
      

    function AGTable(data,key){
        let widthCal= (window.innerWidth *0.8 - 75)  / 6;
       
        let arr;
          arr=<div className={key}><div className="ag-theme-alpine if cell-size-40" style={{height: 50 + ((data.length)*37) ,maxHeight:1000, width: "calc(100% - 1px)"}}>
            <AgGridReact
                rowHeight={35}
                autoGroupColumnDef={{
                    headerName: 'Name',minWidth: 200,field: 'name',headerCheckboxSelection: true,
        
                }}
                headerHeight={35}
        
             defaultColDef={{sortable: true,resizable: true,minWidth:45, width:widthCal , suppressColumnVirtualisation:true,skipHeaderOnAutoSize:true}}
                 
        
                rowData={data}
                key={key}
                onCellClicked={ (event) => handleClickCard(event.data.tag_serial) }
                overlayNoRowsTemplate={
                    AGGridEMptyMEssage('')
                  }        
             >
        
            <AgGridColumn
                field="emp_name"
                headerName={"Name"}
                headerComponentParams =  {{
                    template:`<div class="ag-cell-label-container" role="presentation"> 
                        <div ref="eLabel" class="ag-header-cell-label" role="presentation"> 
                      <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
                      <span class="tooltipcustom"><img src='${helpIcon}'/><span class="descHelp tootltipHelp">Name of the employee.</span></span>
                      <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span> 
                      <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span> 
                      <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span> 
                    </div> 
                  </div>`	
                }}
                valueFormatter={(params)=>capitalizeFirstLetter(params.value)}
            />
            <AgGridColumn field="productive" headerName="% Productive" valueFormatter={(params) =>params.value!='na'?params.value+"%":'-'}    headerComponentParams =  {{
                template:`<div class="ag-cell-label-container" role="presentation"> 
                    <div ref="eLabel" class="ag-header-cell-label" role="presentation"> 
                <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
                <span class="tooltipcustom"><img src='${helpIcon}'/><span class="descHelp tootltipHelp">High Productivity</span></span>
                <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span> 
                <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span> 
                <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span> 
                </div> 
            </div>`	
            }} ></AgGridColumn>
            <AgGridColumn field="less_productive" headerName="% Less Productive" valueFormatter={(params) =>params.value!='na'?params.value+"%":'-'}    headerComponentParams =  {{
                template:`<div class="ag-cell-label-container" role="presentation"> 
                    <div ref="eLabel" class="ag-header-cell-label" role="presentation"> 
                <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
                <span class="tooltipcustom"><img src='${helpIcon}'/><span class="descHelp tootltipHelp">Less Productivity </span></span>
                <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span> 
                <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span> 
                <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span> 
                </div> 
            </div>`	
            }} ></AgGridColumn>
                  <AgGridColumn field="non_productive" headerName="% Non-Productive" valueFormatter={(params) =>params.value!='na'?params.value+"%":'-'}    headerComponentParams =  {{
                template:`<div class="ag-cell-label-container" role="presentation"> 
                    <div ref="eLabel" class="ag-header-cell-label" role="presentation"> 
                <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
                <span class="tooltipcustom"><img src='${helpIcon}'/><span class="descHelp tootltipHelp">No Productivity </span></span>
                <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span> 
                <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span> 
                <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span> 
                </div> 
            </div>`	
            }} ></AgGridColumn>
            <AgGridColumn field="undefined" headerName="Undefined" valueFormatter={(params) =>params.value!='na'?params.value:'-'}  headerComponentParams =  {{
                template:`<div class="ag-cell-label-container" role="presentation"> 
                    <div ref="eLabel" class="ag-header-cell-label" role="presentation"> 
                <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
                <span class="tooltipcustom"><img src='${helpIcon}'/><span class="descHelp tootltipHelp">Access Breached</span></span>
                <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span> 
                <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span> 
                <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span> 
                </div> 
            </div>`	
            }} ></AgGridColumn>
        <AgGridColumn 
        width={widthCal+30}
        field="access_policy_breach" headerName="Access Policy Breached" valueFormatter={(params) =>params.value!='na'?params.value:'-'}  headerComponentParams =  {{
                template:`<div class="ag-cell-label-container" role="presentation"> 
                    <div ref="eLabel" class="ag-header-cell-label" role="presentation"> 
                <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
                <span class="tooltipcustom"><img src='${helpIcon}'/><span class="descHelp tootltipHelp">Access Breached</span></span>
                <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span> 
                <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span> 
                <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span> 
                </div> 
            </div>`	
            }} ></AgGridColumn>
              
        
        </AgGridReact>
        </div></div>
        
        return arr
      }
      function isInt(value) {
        return !isNaN(value) && (function(x) { return (x | 0) === x; })(parseFloat(value))
      }
    function AssetFlagModal(){
        updateAssetPopupFlag(true);
        let arr=[]
        let requestBody={}
        requestBody.startdate= getDateFormat(selectedDate);
        requestBody.enddate= getDateFormat(selectedDate);
        requestBody.slug= 'asset';
        SetisLoading(true);
        let empALL= [];
        getPproductivitylist(requestBody,userSession,org_id).then(res=>{
          if(res.data && res.status==200){
            
            Object.keys(res.data).forEach((key) => {
              let element=res.data[key];
              let child= [];
              let childName='';
              Object.keys(element).forEach((key2) => {
                if(isInt(key2)){

                    for(let i=0;i <element[key2].length;i++){
                        empALL.push(element[key2][i]);
                    }
                    
                }else{
                  childName=key2
                  child.push({name:key2,data:element[key2]})
                  for(let i=0;i <element[key2].length;i++){
                    empALL.push(element[key2][i]);
                }
                }
    
              });
    
              if(childName!==''&& Number.isInteger(childName) ===false ){
                arr.push({name:key,child:child})  
              }else{
                arr.push({name:key,data:element})
              }
               
             });

             SetAssetEmp(empALL);
             SetTabData(arr)
             SetisLoading(false);

            }
          });        

        //  return AGTable(arr.data,"WidgetAsset")


    }

    function HandlerAccordParent(val){
 
        let ActivList = [...parentAccord];
              let index = ActivList.indexOf(val)
              if(index !== -1){  
                  ActivList.splice(index, 1);
              }   
              else {  
                  ActivList.push(val);
      
              }
      
              SetParentAccord(ActivList);
      }

    function showCardList(data){
        let arr = AGTable(data,'AGAsset');
        return arr;
    }



    return(
        <div className="dashboardPeopleAndDateMainDiv">
        <div className="dashboardPeopleAndEmployeeMainDiv">
            <div className="deviceStatus">Device Status</div>
            <Row>
                <Col onClick={() => updateLocationPopupFlag(true)} lg={3} style={{ borderRight: '0.063rem solid #FFFFFF', cursor: 'pointer' }}>
                    {/* <div className="peopleOnPremisesInnerDiv">
                        <img src={peopleOnPremisesIcon} />
                        <span>{getTranslatedText('People on premises')}</span>
                    </div> */}
                    <div>Location</div>
                    <div>{statusCount.location}</div>
                </Col>

                <Col onClick={() => updateEmployeePopupFlag(true)} lg={3} style={{ borderRight: '0.063rem solid #FFFFFF',cursor: 'pointer' }}>
                    {/* <div className="employeeCountInnerDiv cursor-pointer" onClick={handleEmployeeClick}>
                        <div className="empCount">{employeeCount}</div>
                        <div>{getTranslatedText('Employees')}</div>
                    </div> */}
                    <div>Employee</div>
                    <div>{statusCount.personal}</div>
                </Col>
                <Col onClick={AssetFlagModal} lg={3} style={{ borderRight: '0.063rem solid #FFFFFF', cursor: 'pointer' }}>
                    {/* <div className="peopleOnPremisesInnerDiv">
                        <img src={peopleOnPremisesIcon} />
                        <span>{getTranslatedText('People on premises')}</span>
                    </div> */}
                    <div>Asset</div>
                    <div>{statusCount.asset}</div>
                </Col>

                <Col onClick={() => updateVisitorPopupFlag(true)} lg={3} style={{  borderRight: '0.063rem solid #FFFFFF',cursor: 'pointer' }}>
                    {/* <div className="peopleOnPremisesInnerDiv">
                        <img src={peopleOnPremisesIcon} />
                        <span>{getTranslatedText('People on premises')}</span>
                    </div> */}
                    <div>Visitor</div>
                    <div>{statusCount.visitor}</div>
                </Col>
                <Col onClick={() => updateTempEmpPopupFlag(true)} lg={3} style={{  cursor: 'pointer' }}>
                    {/* <div className="peopleOnPremisesInnerDiv">
                        <img src={peopleOnPremisesIcon} />
                        <span>{getTranslatedText('People on premises')}</span>
                    </div> */}
                    <div>TempEmp</div>
                    <div>{statusCount.temp}</div>
                </Col>
                

            </Row>

            <ReactModal
                isOpen={employeePopupFlag}
                style={customStyles}
                onRequestClose={handleCloseModal}
                shouldCloseOnOverlayClick={true}

            >
                <div className={"modal-mapowermanagement "+threatEmployee}>
                <AgGridEmployeeList
                
                    hideHeading={true}
                    title={modalType ? threatEmployee === 'risk' ? 'At Risk Employees' : 'Contaminated Employees' : 'Employee'}
                    hideGlobalSearch={true}
                    date={selectedDate}
                    startDate={startDateValue}
                    endDate={endDateValue}
                    handleCloseDevice = {handleCloseModal}
                    type={modalType}
                    threatEmployee={threatEmployee}
                    AgkeyTable={modalType ? threatEmployee === 'risk' ? 'At Risk Employees' : 'Contaminated Employees' : 'Employee'}
                    //height={(60*window.innerHeight)/100}
                    height={modalType ? threatEmployee === 'risk' ? (60*window.innerHeight)/100 : (60*window.innerHeight)/100 : (75*window.innerHeight)/100}
                    columnexist={modalType ? threatEmployee === 'risk' ? 9 : 9 : 8}
                    isModalData={true}
                    />
                </div>
            </ReactModal>

            

            <ReactModal
                isOpen={visitorPopupFlag}
                style={customStyles}
                onRequestClose={handleCloseModal}
                shouldCloseOnOverlayClick={true}
            >
                <div className={"modal-mapowermanagement "+threatEmployee}>
                
                <AgGridEmployeeList
                    hideHeading={true}
                    date={selectedDate}
                    isManpower={false}

                    type="Visitor"
                    title="Visitor"
                    //reloadInc={reloadInc}
                    key={props.AgkeyTable?props.AgkeyTable:"visitorManagement"}
                    columnexist={10}
                    handleCloseDevice = {handleCloseModal}
                />

                </div>

            </ReactModal>

            <ReactModal
                isOpen={tempempPopupFlag}
                style={customStyles}
                onRequestClose={handleCloseModal}
                shouldCloseOnOverlayClick={true}

            >
                <div className={"modal-mapowermanagement "+threatEmployee}>
                
                <AgGridEmployeeList
                    hideHeading={true}
                    date={selectedDate}
                    isManpower={false}
                    handleCloseDevice = {handleCloseModal}
                    //hideHeading={true}
                   // isManpower={false}
                    type={"Temporary Employee"}
                    title={"Temporary Employee"}
                    key={props.AgkeyTable?props.AgkeyTable:"tempemployee"}
                    columnexist={8}
                />

                </div>

            </ReactModal>

            <ReactModal
                isOpen={assetPopupFlag}
                style={customStyles}
                onRequestClose={handleCloseModal}
                shouldCloseOnOverlayClick={true}
            >
                {isLoading?
                          <div className="text-center m-t-lg m-b-lg">
                            <img src={spinnerLoader} className="" />
                         </div>:<><h3 class="locationsListing">Assets ({assetEMp.length})</h3>{showCardList(assetEMp)}</>}
            </ReactModal>


            <ReactModal
                isOpen={locationPopupFlag}
                style={customStyles}
                onRequestClose={handleCloseModal}
                shouldCloseOnOverlayClick={true}
 
            >
                <div className="modal-sitemanagement">
                <SiteMangementList
                    hideHeading={true}
                    hideGlobalSearch={true}
                    title={modalType ? 'At Risk Locations' : 'Location Tags'}
                    startDate={startDateValue}
                    endDate={endDateValue}
                    role={props.role}
                    type={modalType}
                    AgkeyTable={modalType ? 'At Risk Locations' : 'Location Tags'}
                    height={(75*window.innerHeight)/100} 
                    columnexist={8}
                    isModalData={true}
                    handleCloseDevice = {handleCloseModal}
                />
                </div>
            </ReactModal>


        </div>
    </div>

    )
}

export default DeviceStatus;