import axios from "axios";


let prefixURL = process.env.REACT_APP_URL_LOGIN

let token = localStorage.getItem('tokenAuthTrace')?localStorage.getItem('tokenAuthTrace'):'tituw3958589'

export function userLogin(requestBody) {
    
    return axios.post(prefixURL + `/user/login` , requestBody).then(res=>res).catch(err=>err)
}

export function forgotPassword(requestBody) {
    requestBody.token = token
    return axios.post(prefixURL + `/user/forgot_password` , requestBody).then(res=>res.data).catch(err=>err)
}

export function logoutUser(requestBody) {
    // let requestBody = {}
    // requestBody.token = token
    // requestBody.session = session?session:'123456789'
    return axios.post(prefixURL + `/user/logout` , requestBody).then(res=>res).catch(err=>err)
}

