import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { Checkbox, AutoComplete ,Select,Radio } from 'antd';
import { Container, Row, Col } from 'react-bootstrap';
import '../../siteManagement/styles/siteManagement.scss'
import '../styles/createPolicy.scss'
import spinnerLoader from "../../assets/images/Spinner Loader.gif"
import { getTranslatedText } from '../../common/utilities';
import { Scrollbars } from "react-custom-scrollbars";
import {getSiteLocations,getSiteOverview} from '../../siteManagement/actionMethods/actionMethods';
import {getDepartmentList,getEmployeeList} from '../../manPowerManagement/actionMethods/actionMethods';
import {getPolicyEmployee,getPolicyList,getPolicyLocation,getPolicyOverview,getPolicyTeam,postUpdatePolicy} from '../actionMethods/actionMethods';
import Modal from 'react-modal'
import SucessIcon from  "../../assets/images/success.png"



import moment from 'moment'
import { render } from 'react-dom';

const { Option } = Select;
const customStyle = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(255, 255, 255, 0.75)'
    },
    content: {
        position: 'absolute',
        height: '14rem',
        width:'540px',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        border: '1px solid #ccc',
        background: '#fff',
        overflow: 'auto',
        WebkitOverflowScrolling: 'touch',
        borderRadius: '4px',
        outline: 'none',
        padding: '0'
    }
  }
  
function UpdatePolicy(props) {
    let  acesemp = JSON.parse(props.policy_data.accessibility_list_allow_ind).length >0?
    JSON.parse(props.policy_data.accessibility_list_allow_ind):JSON.parse(props.policy_data.accessibility_list_disallow_ind)
    
    let acesteam=JSON.parse(props.policy_data.accessibility_list_allow_team).length >0?
    JSON.parse(props.policy_data.accessibility_list_allow_team):JSON.parse(props.policy_data.accessibility_list_disallow_team)
    
    const[CreatePolicy,UpdateCreatePolicy]=useState(false);
    const [options, setOptions] = useState([]);
    const [value, setValue] = useState('');
    const [selectedPolicy, updateSelectedPolicy] = useState('')
    const [selectedLocation,updateSelectedLocation]=useState([]);
    const [selectedLocationDetail,updateSelectedLocationDetail]=useState([]);
    const [currentSelected,updateCurrentSelected]=useState('');
    const [Loader,UpdateLoader]=useState(true)
    const [CapacityEnable,UpdateCapacityEnable]=useState(props.policy_data.capacity_status=='true'?"Yes":"No");
    const [AccessibilityEnable,UpdateAccessibilityEnable]=useState((props.policy_data.accessibility_status=='true'||props.policy_data.accessibility_status==="true")?"Yes":"No")
    const [policyName, updatePolicyName] = useState(props.policy_data.policy_name)
    const[ListLocation,updateListLocation] = useState([])
    const[ListEmployee,updateListEmployee] = useState([])
    const[ListTeam,updateListTeam] = useState([])
    const[seletedAccesibility,updateAccesibility]=useState([])
    const[SelectedCapacity,UpdateSelectedCapacity]=useState([])
    const [accessibility_person,Updateaccessibility_person]=useState(acesemp);
    const[accessibility_team,Updateaccessibility_team]=useState(acesteam)
    const[check_allow,Updatecheck_allow]=useState(
        (JSON.parse(props.policy_data.accessibility_list_allow_ind).length>0 || JSON.parse(props.policy_data.accessibility_list_allow_team).length>0)?"Yes":"No"
    );

    const [CurrentStatus,updateCurrentStatus]=useState(props.policy_data.status);
    const[polciyCapacity,UpdatePolicyCapacity]=useState(props.policy_data.max_capacity);
    const[TabActive,UpdateTabActive]=useState('people');
    const[errorName,setErrorName]=useState('');
    const[errorArea,setErrorArea]=useState('');
    const[sucessModal,setSucessModal]=useState(false);
    const[createLoader,setCreateLoader]=useState(false); 
    const[ErrMessage,SetErrMessage]=useState('');
    let date = localStorage.getItem('selectedDate') ? new Date(localStorage.getItem('selectedDate')) : new Date((new Date().setDate(new Date().getDate() - 1)));
    const[ErrorPolicy,SetErrrorPolicy]=useState(false);
    const[ErrorCapacity,SetErrorCapacity]=useState('');
    const[ErrorAcces,SetErrorAcess]=useState('');
    
    let interval = 1440
    let userDetails = JSON.parse(localStorage.getItem('userLoginDetails'))
    let userSession = userDetails ? userDetails.session : '123456789'
    let org_id = userDetails ? userDetails.org_id : 6;
    
////console.log(polciyCapacity);
    
//    const seletedAccesibility = [];


    const mockVal = (str, repeat = 1) => ({
        value: str.repeat(repeat),
    });
useEffect(()=>{
    if(sucessModal===true){
props.closeMarkModal();
    }
},[sucessModal])

useEffect(()=>{
    if(sucessModal===true){
    props.closeMarkModal();
    }
},[sucessModal])






    // const onSearch = (searchText) => {
    //     setOptions(
    //         !searchText ? [] : [mockVal(searchText), mockVal(searchText, 2), mockVal(searchText, 3)],
    //     );
    // };

    useEffect(()=>{
        let requestBody = {}
        requestBody.date = getDateFormat(date)

        getPolicyEmployee( userSession, org_id).then(res => {
            if (res.status === 200) {

                updateListEmployee(res.data);
            }
        });
        
        getPolicyTeam( userSession, org_id).then(res => {
            if (res.status === 200) {

                updateListTeam(res.data);
            }

        });

        getPolicyLocation( userSession, org_id).then(res => {
            if (res.status === 200) {

                updateListLocation(res.data);
            }
        });
        //console.log("Whether its true",(JSON.parse(props.policy_data.accessibility_list_allow_ind).length>0 || JSON.parse(props.policy_data.accessibility_list_allow_team).length>0));
        },[])

    useEffect(()=>{        	
        let requestBody = {}
        requestBody.date = getDateFormat(date)
        requestBody.locationID = props.policy_data.loc_serial;
        let arr=[];
        let arr3=[...seletedAccesibility];
        Object.keys(ListLocation).forEach((key) => {
              ////console.log("Serial "+props.policy_data.loc_serial)
                if(key==props.policy_data.loc_serial){
                    ////console.log("Yes Condition Eexecute")
                    arr.push({loc_tag:key,name:ListLocation[key]});
        
                    getSiteOverview(requestBody, userSession, org_id).then(res => {
                        if (res.status === 200) {
                            let ar2= [];
                            ar2.push(res.data.location_details[0])
                            updateSelectedLocationDetail(ar2);
                            
                        }
            
                    });
        
                }
                updateSelectedLocation(arr);

        })
    },[ListLocation])
    
    useEffect(()=>{
        let team=[...accessibility_team];
       // //console.log("accessibility_team")
       // //console.log(accessibility_team);
        let arr=[...seletedAccesibility];
        Object.keys(ListTeam).forEach((key) => {

            if(team.indexOf(ListTeam[key])> -1 ){
              // //console.log("IF Here "+key +" Name "+ListTeam[key]);
                arr.push({label:key,value:ListTeam[key]});
            }else{
                ////console.log("Else Here "+key +" Name "+ListTeam[key]);
            }
        })
        updateAccesibility(arr);
    },[ListTeam])
    useEffect(()=>{
        let team=[...accessibility_person];
        let arr=[...seletedAccesibility];
        Object.keys(ListEmployee).forEach((key) => {
            if(team.indexOf(key)> -1 ){
                ////console.log()
                arr.push({label:ListEmployee[key],value:key});
          ////console.log("IF Here "+key +" Name "+ListEmployee[key]);

            }else{
              //  //console.log("Else Here");
            }
        })
        updateAccesibility(arr);

    },[ListEmployee])


    function getDateFormat(date) {
        return moment(date).format('YYYY-MM-DD')
    }

    useEffect(()=>{
        ////console.log(ListLocation);
        UpdateLoader(true);
    },[ListEmployee,ListTeam,ListLocation])


    
    useEffect(() => {
        setTimeout(function(){
            showSelectedArea(selectedLocation);

        },10)
    }, [selectedLocation]);

    
    useEffect(() => {
        LocationEnterCapacity()
    }, [selectedLocationDetail]);

    
function CloseAlert(){
    UpdateCreatePolicy(false);
    props.reloadData();
}


    function onChange(e) {
       // //console.log(e);
        let arr=[];
       // //console.log(`checked = ${e.target.checked}`);
        arr=seletedAccesibility;
        if(e.target.checked){
            arr.push(e.target.value)
        
        }

    }

    function handleSelectedPolicy(key) {
        updateSelectedPolicy(key)
    }
    function onChangeLocation(value,label) {
        UpdateLoader(false);
       // //console.log(`selected ${value}`);
       // //console.log(label);
        let arr= [];
            arr.push({loc_tag:label.key,name:value});
            let requestBody = {}
            requestBody.date = getDateFormat(date)
            requestBody.locationID = label.key

            getSiteOverview(requestBody, userSession, org_id).then(res => {
                if (res.status === 200) {
                    let ar2= [];
                    ar2.push(res.data.location_details[0])
                    updateSelectedLocationDetail(ar2);
         //           //console.log(ar2)
                    
                }
    
            });

            setTimeout(function(){
                UpdateLoader(true);
            },100)
           // //console.log("Location Detail");
           // //console.log(selectedLocationDetail);  
            
        

            updateSelectedLocation(arr);
            updateCurrentSelected(value);
           // //console.log(selectedLocation);
           // //console.log(ListLocation)
    
      }
      
      function onBlurLocation() {
       // //console.log('blur');
      }
      
      function onFocusLocation() {
       // //console.log('focus');
      }
      
      function onSearchLocation(val) {
       // //console.log('search:', val);
      }
      function submitHandleCreatePolicy(e){
        e.preventDefault();
        let requestBody=[]

        if(policyName==''){
            setErrorName("Policy name is required");
        }else if(selectedLocation.length<0 ||selectedLocation.length <= 0){
            setErrorArea("Policy Location is required");
            setErrorName("");
        }
        else if(CapacityEnable=="Yes" && (polciyCapacity <= 0)){
                SetErrorCapacity("Please Enter Capacity");
                setErrorArea(""); 
        }
        else if(AccessibilityEnable=="Yes" &&seletedAccesibility.length <= 0 ){
            SetErrorCapacity('');
            SetErrorAcess("Please Select Employee or Team for the Policy");
        }
        else if(CapacityEnable=="No"&& AccessibilityEnable=="No"){
            props.OpenErrorPolicy();
          //  //console.log("IS IT")
            SetErrorAcess("") 
        }
        else if(policyName!=''&&selectedLocation.length>0){

            setCreateLoader(true);
        requestBody.policy_name = policyName
        requestBody.loc_serial =selectedLocation[0].loc_tag
        requestBody.org_id= org_id
        requestBody.capacity_status=CapacityEnable=='Yes'?true:false
        requestBody.accessibility_status=AccessibilityEnable=='Yes'?true:false
        requestBody.max_capacity=polciyCapacity
        requestBody.check_allow=check_allow=='Yes'?true:false
        requestBody.accessibility_person=accessibility_person
        requestBody.accessibility_team=accessibility_team
        requestBody.policy_id=props.policy_id
        requestBody.status=CurrentStatus
        postUpdatePolicy(requestBody, userSession, org_id).then(res => {
            if (res.message =='Policy Created' || res.message !='') {
                UpdateCreatePolicy(true);
                props.closeMarkModal();
                props.reloadData();      

            }else{
                
            }
        });
        
        }
    else{
            setErrorName("");
            setErrorArea('');

        }

        
    }

    
    const generateKey = (pre) => {
        return `${ pre }_${ new Date().getTime() }`;
    }
  function closeModal(){
     // //console.log("execution occur");
    props.closeMarkModal()
  } 
  function SucessModalCreatePolicy(){

  } 
  function HandlerAppendData(name,event){
       // //console.log(name);
        ////console.log(event);
  }

      function RemoveSelectedBox(e){
         let array =[...selectedLocation] ;
         ////console.log(selectedLocation);
 

        let myNewArray = array.filter(function(item){ 
            return item.loc_tag != e 
        })  
        
       // //console.log(e)
        updateSelectedLocation(myNewArray);
        updateCurrentSelected('');        
      }
      function showSelectedbox(data){
          
            let arr=
            <div className="eachSelectedDiv" key={generateKey(data.loc_tag)}><span>{data.name}</span><span className="closeDiv" onClick={()=>RemoveSelectedBox(data.loc_tag)}>X</span></div>
                
                
            
            return arr;
        }


    function UpdateLocation(event){
   
    }
    function showSelectedArea(name){    
        let arr=[];
      
    }

    function LocationSelector(){

    }
    function ShowLocationSelectBox(data){
        
       if( selectedLocation.indexOf(data) > -1){
            ////console.log("IF "+data);
       }else{
       // //console.log("Else "+data);
        let arr=<Option key={data.name}>{data.name}</Option>;
        return arr;
       }
    }



    function myFunction(name){
        return name;
    }
function RemoveSelectedBoxAcces(item,label,value){
    let array =[...seletedAccesibility] ;
    let person =  [...accessibility_person];
    let team =  [...accessibility_team]
   
   if( array.indexOf(item) > -1){
        const index = array.indexOf(item);
        array.splice(index,1);
        updateAccesibility(array);
   }
   if(person.indexOf(value)>-1){
    const index = person.indexOf(value);
    person.splice(index,1);
    Updateaccessibility_person(person);
   }
   if(team.indexOf(value)>-1){
    const index = team.indexOf(value);
    team.splice(index,1);
    Updateaccessibility_team(team);
   }      
}
function ShowSeletedAccesibilityCheck(item){

    
        return (
            <div className="eachSelectedDiv" key={generateKey(item.label+item.value)}>   
            <span>{item.label}</span><span className="closeDiv" onClick={()=>RemoveSelectedBoxAcces(item,item.label,item.value)}>X</span>
        </div>
        )
    }

 function onChangeCheckboxEmployee(e,value,label){
    ////console.log(e);
    ////console.log(value);
    ////console.log(label);
   let arr= [...seletedAccesibility]
    let team= [...accessibility_person]
    if(e.target.checked && arr.indexOf(label) =='-1'){
        arr.push({label:label,value:value});
        team.push(value);
        updateAccesibility(arr);
        Updateaccessibility_person(team)
    }else if(e.target.checked){

    }else{
        let index= arr.indexOf(label);
        let index2= team.indexOf(value);
        arr.splice(index,1);
        team.splice(index2,1);
        updateAccesibility(arr);
        Updateaccessibility_person(team)
    }
    ////console.log(accessibility_person);
}
function ShowListEmployee(){
    let arr=[];
    Object.keys(ListEmployee).forEach((key) => {
        // //console.log("Name key "+key)
        // //console.log(ListEmployee[key])
        arr.push( <tr className="employee-detail-table-tr" key={generateKey(ListEmployee[key])}>   
        <td> 
            <Checkbox onChange={(e) => onChangeCheckboxEmployee(e,key,ListEmployee[key])} checked={ accessibility_person.includes(key)} >{ListEmployee[key]}</Checkbox>
        </td></tr>
        )
    });   

    return arr;
}
function onChangeCheckbox(e,value,label){
    // //console.log(e);
    // //console.log(value);
   let arr= [...seletedAccesibility]
    let team= [...accessibility_team]
    if(e.target.checked && arr.indexOf(label) < 0){
        arr.push({label:label,value:value});
        team.push(value);
        updateAccesibility(arr);        
        Updateaccessibility_team(team)
    }else if(e.target.checked){

    }else{
        let index= arr.indexOf(label);
        let index2= team.indexOf(value);
        arr.splice(index,1);
        team.splice(index2,1);
        updateAccesibility(arr);
        Updateaccessibility_team(team)
    }

}
function ShowListTeam(){
    let arr=[];
    Object.keys(ListTeam).forEach((key) => {
        arr.push( <tr className="employee-detail-table-tr" key={generateKey(key)}>   
        <td>        <Checkbox onChange={(e) => onChangeCheckbox(e, ListTeam[key],key)} checked={ accessibility_team.includes(ListTeam[key])} >{key}</Checkbox></td></tr>
        )
    });   

    return arr;

}
function empCheckHandler(e){

}


const CapacityEnableChange = e => {
    ////console.log('radio checked', e.target.value);
    //setValue(e.target.value);
    UpdateCapacityEnable(e.target.value);
  };
 
const AccesibilityEnableChange = e =>{
    UpdateAccessibilityEnable(e.target.value);
}  



function LocationEnterCapacity(){
    let arr=[] ;
   // //console.log(arr);
    if(selectedLocationDetail.length > 0){
         for(let i=0;i<selectedLocationDetail.length;i++){   
            arr.push( <div className="eachPolicyAreaMainDiv">
                    
                    <div className="">{selectedLocationDetail[i].name}</div>
                    <div className="propsMainDiv m-t-sm">
                        <div className="eachPropDiv"><span className="countdiv">{selectedLocationDetail[i].area_size}</span><span className="fnt-small">Area Size Sq.m</span></div>
                        <div className="eachPropDiv"><span className="countdiv">{selectedLocationDetail[i].capacity}</span><span className="fnt-small">Area Capacity</span></div>
                        <div className="eachPropDiv"><span className="countdiv">{selectedLocationDetail[i].theroic_max_capacity}</span><span className="fnt-small">Theoric Max</span></div>
                        <div className="eachPropDiv"><span className="countdiv"><input type="text" onChange={(e)=>UpdatePolicyCapacity(e.target.value)} name="policyvalue" value={polciyCapacity}/></span><span className="fnt-small">Policy Capacity </span></div>
                    </div>
            </div>)
         }
    return arr;         
    }
}

function showLocationOption(){
    let arr= []

    Object.keys(ListLocation).forEach((key) => {
        arr.push( <Select.Option key={key} value={ListLocation[key]}>
            {ListLocation[key]}
        </Select.Option>);
        })
    return arr;
}
function AllowOnlyCheckbox(e){
    Updatecheck_allow(e.target.value);
}
function IncreaseCapacity(){
    UpdatePolicyCapacity(polciyCapacity+1);
}
function DecreaseCapacity(){
    if(polciyCapacity==0){

    }else{
    UpdatePolicyCapacity(polciyCapacity-1);
    }
}


    return (
        
        <div className={createLoader?"siteViewMainDiv siteManagementMainDiv createPolicyContainer loading":"siteViewMainDiv siteManagementMainDiv createPolicyContainer "}>
              <div className="loading-div">
                  <img src={spinnerLoader}/>
             </div>
             <div className="createPolicyMainDiv">
                                     
            <form onSubmit={submitHandleCreatePolicy}>
            <Scrollbars autoHide style={{height:"480px"}}
                    
            >
             
                <Row className="m-t margin-zero" style={{marginBottom:"10px"}}>
                    <Col lg={6}>
                          
                                    <h5 className="font-bold">Update Policy</h5>
                                    <Row className="m-t">
                                        <Col lg={6}  className="">
                                            <label>Name your policy </label>
                                            <input type="text" value={policyName} placeholder="Please enter policy name" onChange={(e)=>updatePolicyName(e.target.value)} />
                                            {policyName==''?errorName?<div class="error-message">{errorName}</div>:"":""}
                                        </Col>
                                        <Col lg={6}>
                                            <div className="activeSelect activeSelectStaus">
                                            <label>Status</label>                                            
                                            <Select
                                                id="antd-location"
                                                style={{ width: 200 }}
                                                placeholder="Search a location"
                                                optionFilterProp="children"
                                                onChange={(value)=>updateCurrentStatus(value)}
                                                // onSearch={onSearchLocation}
                                                value={CurrentStatus}
                                            >
                                  <Select.Option key='active' value="active">Active</Select.Option>  
                                  <Select.Option key='inactive' value="inactive">Inactive</Select.Option>  
                                            </Select>

                                            </div>    
                                        </Col>

                                        <Col lg={12}  className="space-less">
                                            <Row>
                                                <Col lg={6}>
                                            <div className="activeSelect">
                                            <label>Select Area or Category</label>
                                            
                                            <Select
                                                id="antd-location"
                                                style={{ width: 200 }}
                                                placeholder="Search a location"
                                                optionFilterProp="children"
                                                onChange={onChangeLocation}
                                                onFocus={onFocusLocation}
                                                onBlur={onBlurLocation}
                                                // onSearch={onSearchLocation}
                                                value={currentSelected}
                                                filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                
                                                }
                                            >
                                  {/* {(ListLocation)} */}
                                  {/* {ListLocation.map(ShowLocationSelectBox)} */}
                                                  {showLocationOption()}  
                                            </Select>
                                            {selectedLocation.length<=0?errorArea?<div class="error-message">{errorArea}</div>:"":""}


                                            </div>    
                                        </Col>

                                        <Col lg={6} >
                                            {selectedLocation.length > 0?
                                                                                   <div>   <label>Selected Areas </label>
  
                                            <div className="selectedPersonDiv m-t">                                                
                                                {selectedLocation.map(showSelectedbox)}
                                            </div></div>:"" 
                                            }
                                        </Col>
                                        </Row>
                                        </Col>
                                        

                                       </Row>


                                    <Row className="m-t">
                                        <Col lg={12}>
                                            <div className="policySpecificationsMainDiv">
                                                <h5 className="font-bold m-b">Policy Specifications</h5>
                                                <div  onClick={() =>handleSelectedPolicy('Capacity')} className={'eachPolicySpecificationMainDiv ' + (selectedPolicy=== 'Capacity' ? 'activePolicy' : '')}>
                                                    <Row>
                                                        <Col lg={12}>
                                                            <div className="labelDiv">Capacity</div>
                                                            <div className="roundDiv-checkbox">
                                                            <Radio.Group onChange={CapacityEnableChange} value={CapacityEnable}>
                                                                <Radio value={'Yes'}>Yes</Radio>
                                                                <Radio value={'No'}>No</Radio>
                                                            </Radio.Group>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    {
                                                        
                                                        selectedPolicy == 'Capacity' && CapacityEnable=='Yes' && selectedLocation.length >0?
                                                            <Row>
                                                                <Col lg={7}>
                                                                    <div className="capacityViewDiv">
                                                                        <input type="text" placeholder="Enter Capacity" value={polciyCapacity} />
                                                                        <div className="plusMinusDiv">
                                                                            <div onClick={IncreaseCapacity} >+</div>
                                                                            <div onClick={DecreaseCapacity}>-</div>
                                                                        </div>
                                                                    </div>
                                                                </Col>

                                                            </Row> : ''
                                                    }
                                                </div>
                                                <Row>
                                                    <Col lg={12}  >
                                                    {polciyCapacity<=0?ErrorCapacity && CapacityEnable=="Yes" ?<div class="error-message">{ErrorCapacity}</div>:"":""}
                                                     
                                                    </Col>
                                                </Row>


                                                <div onClick={() => handleSelectedPolicy('Accessibility')} className={'eachPolicySpecificationMainDiv ' + (selectedPolicy === 'Accessibility' ? 'activePolicy' : '')}>
                                                    <Row>
                                                        <Col lg={12}>
                                                            <div className="labelDiv">Accessibility</div>
                                                            <div className="roundDiv-checkbox">                                            
                                                    
                                                            <Radio.Group onChange={AccesibilityEnableChange} value={AccessibilityEnable}>
                                                                <Radio value={'Yes'}>Yes</Radio>
                                                                <Radio value={'No'}>No</Radio>
                                                            </Radio.Group>

                                                            </div>                                                        </Col>
                                                    </Row>
                                                    
                                                    
                                                </div>
                                                <Row>
                                                    <Col lg={12}  >
                                                    {seletedAccesibility.length<=0?ErrorAcces && AccessibilityEnable=="Yes" ?<div class="error-message">{ErrorAcces}</div>:"":""}
                                                     
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col lg={12}>
                                                        <div className="check_allow"> 
                                                            <div className="roundDiv-checkbox">
                                                            <Radio.Group onChange={AllowOnlyCheckbox} value={check_allow}>
                                                                <Radio value={'Yes'}>Allowed</Radio>
                                                                <Radio value={'No'}>Restricted</Radio>
                                                            </Radio.Group>
                                                            </div>
                                                        </div>
                                                 </Col>
                                            </Row>
                                            </div>
                                                           <Row>

                                                                <Col lg={12}>
                                                                    <div className="selectedPersonDiv m-t">
                                                                                    
                                                        
{/* {ShowSeletedAccesibilityCheck()}                      */}

{seletedAccesibility.map(ShowSeletedAccesibilityCheck)}
                                                                    </div>
                                                                </Col>
                                                            </Row> 
  </Col>
                                    </Row>
                                    <div className="submit-area"></div>


                    </Col>



                    <Col lg={2}></Col>
                    <Col lg={4}>
                    <div className="addAreaPolicyMainDiv">
                                    {
                                        selectedPolicy == 'Capacity' && CapacityEnable=='Yes' ?
                                           <div>
                                                <h5 className="font-bold text-white">Add area for you policy</h5>
                                                <Scrollbars
                                                        autoHide
                                                        style={{ width: "100%", height: 360 }}
                                                        ><React.Fragment>
                                              {
                                                  
                                                
                                                 LocationEnterCapacity()
                                                }
                                            { Loader===false?<div className="loaderdov"><img src={spinnerLoader} /></div>:""}
                                            </React.Fragment>
                                                </Scrollbars>

                                                </div>
                                           
                                             :"" 

                                    }
                                                                        {
                                        selectedPolicy == 'Accessibility' && AccessibilityEnable=='Yes' ?
                                              <div className="tab-view smaller-size space-less">
                                                <div onClick={()=>UpdateTabActive('people')} className={TabActive=="people"?"tab tab-activity cursor-pt active":"tab tab-activity cursor-pt"}>
                                                    People
                                                </div>
                                                <div onClick={()=>UpdateTabActive('team')} className={TabActive=="team"?"tab tab-activity cursor-pt active":"tab tab-activity cursor-pt"}>
                                                    Team
                                                </div>
                                                {TabActive=="people"?
                                                <div className="bg-grey pad-20">
                                                   <Scrollbars
                                                        autoHide
                                                        style={{ width: "100%", height: 320 }}
                                                        >
                                                    <React.Fragment>        
                                                                                                           
                                                    <table className="tableBox">
                                                        <tbody>
                                                        <tr className="employee-detail-table-tr">
                                                                <th>
                     {/* <input type="checkbox" name="checked" className="space-right" />  */}
                     <span className="head-align">Name</span> 
                                                                </th>
                                                        </tr>
                                                        
                                                      
 {ShowListEmployee()}
                                                        </tbody>
                                                        </table>
                                                        </React.Fragment> 
                                                        </Scrollbars>


                                                </div>:""

                                                }                            


                                            {TabActive=="team"?
                                                <div className="bg-grey pad-20">
                                                        <Scrollbars
                                                        autoHide
                                                        style={{ width: "100%", height: 320 }}
                                                        >
                                                            <React.Fragment>
                                                    
                                                    <table className="tableBox">
                                                        <tbody>
                                                        <tr className="employee-detail-table-tr">
                                                                <th>
                                                {/* <input type="checkbox" name="checked" className="space-right" />  */}
                                                <span className="head-align">Team</span> 
                                                                </th>
                                                        </tr>
                                                        
                                                      
                                         {ShowListTeam()}


                                                        </tbody>
                                                        </table>
                                                        


                                                        </React.Fragment>
                                                        </Scrollbars>


                                                </div>:""

                                                }                            



                                            

                                                </div>
                                            :"" 

                                    }
                                    </div>
                                </Col>

                </Row>
                </Scrollbars>
                <div className="actiondiv">
                    <button  className="btn btn-reset" onClick={()=>props.closeMarkModal()} >Cancel</button>
                    <button type="submit"  className="btn btn-submit" >Update</button>
                </div>  
            </form>
            <Modal
                isOpen={CreatePolicy}
                style={customStyle}
                className="alert-sucess"
            >
                <div className='logoutModal MarkforPositive'>
                    <div className="sucess-inner">
                        <img src={SucessIcon}/>
                    </div>
                    <div className='logoutTitle'>{ErrMessage}</div>
                    <div className='actionButton'>
                        <span className='btnText okBtn bnt-close-bigger' onClick={()=>CloseAlert}>Close </span>
                    </div>
                </div>
            </Modal>
            </div>
        </div>
    )
}

export default UpdatePolicy