import React, { useState, useCallback,useReducer,useEffect } from 'react'
import { Container, Row, Col, Dropdown, DropdownButton } from "react-bootstrap";
import { Button } from "antd";
import { CommonHeading } from "../../common/commonHeading";
import Viewer from './Viewer'
import {
  append,
  compose,
  isEmpty,
  map,
  reject,
  intersperse,
  evolve,
  max,
  mapObjIndexed,values
} from 'ramda'
import moment from "moment";
import { getTranslatedText } from  "../../common/utilities";
import {
  getLanguageTranslation,
  setSelectedLanguage,
} from "../../dashboard/actionMethods/actionMethods";
import DashboardLanguage from "../../components/dashboardLanguage";
import CommonDatePicker from "../../common/commonDatePicker";

import { Slider } from 'antd';
import {ReactComponent as Play} from '../../assets/images/circle-play.svg'
import {ReactComponent as Pause} from '../../assets/images/circle-pause.svg'
import LocationIcon from '../../assets/images/location.png'
import ManIcon from '../../assets/images/man.png'
import mqttService from '../../components/mqttService';

import {ReactComponent as Image404 } from '../../assets/images/11132-ai.svg'
import spinnerLoader from "../../assets/images/Spinner Loader.gif";

import ProductiveIcon from  "../../assets/images/locationProductive.png"
import DefaultIcon from  "../../assets/images/locationDefaultIcon.png"
import nonProductiveIcon from "../../assets/images/locationNonProductive.png";
import lessProductiveIcon from "../../assets/images/locationLessProductive.png";

import {getLocationCordinate} from '../actionMethods/actionMethods';
//import {getEmployeeList} from '../../manPowerManagement/actionMethods/actionMethods'
import { getDepartment,getSubDepartment } from '../../productivity/actionMethods/actionMethods';

import {getPproductivitylist} from '../../productivity/actionMethods/actionMethods'
import {getAllEmpProductivityInfo,getCycleCountPlan} from '../actionMethods/actionMethods';

import {ReactComponent as Eye} from '../../assets/images/eye.svg';
import ReactModal from 'react-modal';

import Scrollbars from 'react-custom-scrollbars';

import '../style/style.scss'
import { Select } from 'antd';
import {AgGridColumn, AgGridReact} from 'ag-grid-react';

let dataSocket=[];   
let dataSocketAll=[];   
let batch30=[];

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: 1100,
    maxWidth: "80%",
    height: "650",
  },
};

const FacilitiesTracker = (props) => {

  //console.log("Width calculator",((window.innerWidth * 80)/100) - 5)
  const { Option } = Select;
  const INITIAL_MODE = '3d'  
  const [mode, setMode] = useState(INITIAL_MODE)
  const [activeTab, SetActiveTab]= useState('');
  const noElevationIn2D = useCallback(value => (mode === '3d' ? value : 0), [
    mode
  ])

  let date = localStorage.getItem("selectedDate")
    ? new Date(localStorage.getItem("selectedDate"))
    : new Date();
    const [role,SetRole] = useState([]);
    const [permission,SetPermission] =useState([])
    const [permissionAPICall,SetPermissionAPICall] = useState(false);
  
  const [selectedDate, updateSelectedDate] = useState(date);
  const [selectedLangValue, updateSelectedLangValue] = useState("en");


  const autoElevation = map(
    evolve({ position: { elevation: noElevationIn2D } })
  )
  
  const [space,setSpace] = useState()


  let userDetails = JSON.parse(localStorage.getItem("userLoginDetails"));
  let userSession = userDetails ? userDetails.session : "123456789";
  let org_id = userDetails ? userDetails.org_id : 6;

  
  const [client,SetClient] =useState(null)
  const [pointData,SetPointData]=useState([]);
  const [spaceID,SetSpaceID]=useState('');
  const [locationCordinate,SetLocationCordinate]= useState([]);
  const [cameraPlacement,SetCameraPlacement] =useState([]);
  
  const [employeeALL,SetEmployeeAll] =useState([]);
  const [employeeDefault,SetEmployeeDefault] =useState([]);

  const [department,SetDeparment] = useState([]);
  const [sub_department,SetSubDeparment] = useState([]);

  const [activeDepartment,SetActiveDepartment] = useState('');
  const [activeSubDepartment,SetActiveSubDepartment] = useState('');
  const [activeType,SetActiveType] = useState('');
  const [apiCall,SetAPICall]=useState(false)

  const [loaderEmp,SetLoaderEmp] =useState(false);
  const [slug,SetSlug]=useState('');

  const [modalView,SetModalView]=useState(false);
  const [modalData,SetModalData ]= useState([]);
  const [mapData,SetMapData]=[
                      {name:"Geo Map 1",id:'59a74b5c-374a-4ef2-a5c3-63f3aa2b220f'},
                      {name:"Geo Map 2",id:'59a74b5c-374a-4ef2-a5c3-63f3aa2b220f'},
                      {name:"Geo Map 3",id:'59a74b5c-374a-4ef2-a5c3-63f3aa2b220f'},
                      {name:"Geo Map 4",id:'59a74b5c-374a-4ef2-a5c3-63f3aa2b220f'}
                    ]

  const [cycleCount,SetCycleCount]=useState([]);
  const [time,SetTime] =useState('');                    
  
  const timerLive = ()=>{
    SetTime(moment().format('HH:mm'));
  }
  
  let lastReqId = null

    function errorHandler(message){

    }

    function SubscribeHandler(message){

    }
    useEffect(() => {
      let perm= props.role.geo_map_permissions?JSON.parse(props.role.geo_map_permissions):null;
      
      if(perm){
        

          SetPermission(perm)
          SetPermissionAPICall(true)
      }else{
      }
  },[props.role]);

  useEffect(()=>{
    if(permission.facilities=="True"){
      
      getLocationCordinate(userSession,org_id).then(res=>{
        if(res.status==200){
          SetSpaceID(res.data.space_id)
          getCycleCountPlan(userSession,org_id,getDateFormat(moment())).then(res2=>{
              if(res2.status==200){
                  for(let i=0;i<res.data.location.length;i++){
                    let ctPlan = moment().format('HH');
                    console.log("ctPlan",ctPlan)
                      let data = res2.data[res.data.location[i].tag_serial]
                      data =  data?data.find(item=>item.hour == ctPlan+":00"):undefined; 
                      let result =0;
                      if(data){
                          res.data.location[i].planned = Math.ceil(data.cycle_count);
                      }else{
                        res.data.location[i].planned = '-';
                      }
                      //res.data.location[i].planned = GetRandomDigit(7,15);
                  }
                  SetLocationCordinate(res.data.location);
              }else{
                //console.log("Current date executed");
                for(let i=0;i<res.data.location.length;i++){
                    res.data.location[i].planned = '-';
                  }
                  SetLocationCordinate(res.data.location);
              }
            })
        }
      })

      getDepartment(userSession,org_id).then(res=>{
        if(res.status==200){
          SetDeparment(res.data);  
        }
      });
      
      SetLoaderEmp(true);
      
      getAllEmpProductivityInfo(userSession,org_id,'employee').then(res=>{
        SetLoaderEmp(false);
        if(res.status==200){
          SetEmployeeAll(res.data);
          SetEmployeeDefault(res.data)
        }
      })


    }

  },[permission])  

  useEffect(()=>{
  SetActiveDepartment('');
  let type= slug==''?'':slug=='employee'?'regular':'daily_worker';
  let emp = employeeDefault.filter(item=>item.emp_type.toString().includes(type)); 

    SetEmployeeAll(emp);   
  },[slug])

useEffect(()=>{
  SetActiveSubDepartment('');
  let type= slug==''?'':slug=='employee'?'regular':'daily_worker';
if(activeDepartment=='all'){
  
  let emp = employeeDefault.filter(item=>item.emp_type.toString().includes(type)); 

  SetEmployeeAll(emp);
  
  SetSubDeparment([]);     
}else{
  let filterEmp = employeeDefault.filter(item => item.dept_id == activeDepartment && item.emp_type.toString().includes(type)) 
  
  getSubDepartment(userSession,activeDepartment,org_id).then(res=>{
    if(res.status==200){
      SetSubDeparment(res.data);
    }
  })
  
 

SetEmployeeAll(filterEmp);

}
 
},[activeDepartment])



useEffect(()=>{
  let type= slug==''?'':slug=='employee'?'regular':'daily_worker';

  if(activeSubDepartment=='all'){
    let filterEmp = employeeDefault.filter(item => 
        item.dept_id.toString().toLowerCase().includes(activeDepartment=='all'?'':activeDepartment)  
        && item.dept_id.toString().toLowerCase().includes(activeDepartment=='all'?'':activeDepartment)  
        && item.emp_type.toString().includes(type)
      )  


    SetEmployeeAll(filterEmp);
  }else if(activeSubDepartment==''  && activeDepartment=='all' ){

  }else{
    let filterEmp = employeeDefault.filter(item => 
      item.dept_id == activeDepartment &&
      item.sub_dept_id == activeSubDepartment 
      && item.emp_type.toString().includes(type)
      )  


    SetEmployeeAll(filterEmp);
  }
  removeFrame()
 
  

},[activeSubDepartment])

function GetRandomDigit(min=0,max=10){
  return  Math.floor(Math.random() * (max - min + 1)) + min;
}

useEffect(()=>{

  removeFrame()
  if(client){
    client.end();
    SetClient(mqttService.getClient(errorHandler));
  }

},[activeType])
useEffect(()=>{

  SetAPICall(false)
  removeFrame()

  if(client){
    client.end();
    SetClient(mqttService.getClient(errorHandler));
    
  }
},[employeeALL])



  function isInt(value) {
    return !isNaN(value) && (function(x) { return (x | 0) === x; })(parseFloat(value))
  }


  useEffect(()=>{
      if(permission.facilities=='True'){

        SetTime(moment().format('HH:mm'));
      if (!space) {
        return
      }
      SetClient(mqttService.getClient(errorHandler));
    }
    //SetClient(mqttService.getClient(errorHandler));
    },[space,permission])

    useEffect(()=>{
      if(permission.facilities=='False'){
        return
      }
      if (!space) {
        return
      }
      if(client && space){

      let allLoc = [...locationCordinate];

      for(let i=0 ;i<allLoc.length;i++){
        client.subscribe(org_id+'v1/'+allLoc[i].tag_serial,0, (error) => {
          if (error) {

            return
          }
        });
        
    }
      client.on('message', (topic, message) => {
        


        let data = JSON.parse(message.toString().replace(/'/g, '"'));
       
       // //console.log("Data recived ",data)
        let item = dataSocket.findIndex((item) => item.tag_serial == data.tag_id);
        let item2 = dataSocketAll.findIndex((item) => item.tag_serial == data.tag_id);
        
        if(item  > -1){
          //same timeframe
          if( ( dataSocket[item].contact_tag_id != data.contact_tag_id )){
            let currentData = dataSocket[item];

            let productive=[];
            
            let pos = locationCordinate.find(item=>item.tag_serial == data.contact_tag_id)
            if(activeType!=''){
              let mm = employeeALL.find(item=>item.tag_serial == data.tag_id); 
              productive = mm.loc_type?mm.loc_type[0][activeType]:[];  
              
              let check= productive.find(item=>item.tag_serial == data.contact_tag_id)
              //check if current user in active type location(productive/ non prod /less)
              if(check){
                
                if(dataSocket[item].time == data.contact_time && dataSocket[item].rssi > data.rssi){
                  ////console.log("user has changed location active productiveType in same timestamp",data)
 
                  //here conditon will execute for first one rsi newer value is higher
                  dataSocket[item].location_id =pos.location_id;      
                  dataSocket[item].position = {
                    "levelIndex": 0,
                    "x": pos?pos.position.x + ((0.5*(Math.random() - 0.5) * 20)):(11.205211986727418 + ((0.111*(Math.random() - 0.5) * 20))),
                    "z": pos?pos.position.z +((0.5*(Math.random() - 0.5) * 10)):(-9.236610745591577+ ((0.1*(Math.random() - 0.5) * 10))),
                    "elevation": 1.4
                  }
                  dataSocket[item].rssi= data.rssi; 
                  dataSocket[item].tag_serial= data.tag_id;
                  dataSocket[item].contact_tag_id= data.contact_tag_id;
                  
                  dataSocketAll[item2]=dataSocket[item]
                  let indexBatch = batch30.findIndex((item) => item.tag_serial == data.tag_id);
                  if(indexBatch > -1){
                    batch30[indexBatch] = dataSocket[item];
                  }else{
                    batch30.push(dataSocket[item])
                  }

                 // dispatchPoint({type: 'update',id: dataSocket[item].id,updates: { position:dataSocket[item].position }})

              //   dispatchPoint({ type: 'remove', id: dataSocket[item].id });     
                
                  

                } else if(dataSocket[item].time != data.contact_time){
                  //here condition will execute for not equal time
                 // //console.log("user has changed location with any filter active with different time",data)
                  dataSocket[item].location_id =pos.location_id;      
                  dataSocket[item].position = {
                    "levelIndex": 0,
                    "x": pos?pos.position.x + ((0.5*(Math.random() - 0.5) * 20)):(11.205211986727418 + ((0.111*(Math.random() - 0.5) * 20))),
                    "z": pos?pos.position.z +((0.5*(Math.random() - 0.5) * 10)):(-9.236610745591577+ ((0.1*(Math.random() - 0.5) * 10))),
                    "elevation": 1.4
                  }
                  dataSocket[item].rssi= data.rssi; 
                  dataSocket[item].tag_serial= data.tag_id;
                  dataSocket[item].contact_tag_id= data.contact_tag_id;
                  dataSocketAll[item2]=dataSocket[item]
                  //dispatchPoint({type: 'update',id: dataSocket[item].id,updates: { position:dataSocket[item].position }})
                  //dispatchPoint({ type: 'remove', id: dataSocket[item].id });     
                  let indexBatch = batch30.findIndex((item) => item.tag_serial == data.tag_id);
                  if(indexBatch > -1){
                    batch30[indexBatch] = dataSocket[item];
                  }else{
                    batch30.push(dataSocket[item])
                  }
          
                }
          
                }else{
                 // dispatchPoint({ type: 'remove', id: dataSocket[item].id });              
                }
                }    
            else{
              //if user is not in selected any type
              if(dataSocket[item].time == data.contact_time && dataSocket[item].rssi > data.rssi){
                //here conditon will execute for first one rsi newer value is higher
               // //console.log("user has changed location without  any filter active in same timestamp",data,dataSocket[item],pos)

                dataSocket[item].location_id =pos.location_id;      
                dataSocket[item].position = {
                  "levelIndex": 0,
                  "x": pos?pos.position.x + ((0.5*(Math.random() - 0.5) * 20)):(11.205211986727418 + ((0.111*(Math.random() - 0.5) * 20))),
                  "z": pos?pos.position.z +((0.5*(Math.random() - 0.5) * 10)):(-9.236610745591577+ ((0.1*(Math.random() - 0.5) * 10))),
                  "elevation": 1.4
                }
                dataSocket[item].rssi= data.rssi; 
                dataSocket[item].tag_serial= data.tag_id;
                dataSocket[item].contact_tag_id= data.contact_tag_id;
                dataSocketAll[item2]=dataSocket[item]
              //  dispatchPoint({type: 'update',id: dataSocket[item].id,updates: { position:dataSocket[item].position }})
                  //dispatchPoint({ type: 'remove', id: dataSocket[item].id });     
                  // dispatchPoint({
                  //       type: 'add',
                  //       point: {
                  //         id: dataSocket[item].id,
                  //         name: dataSocket[item].name,
                  //         position: dataSocket[item].position
                  //       }
                  // }) 
                  let indexBatch = batch30.findIndex((item) => item.tag_serial == data.tag_id);
                  if(indexBatch> -1){
                    batch30[indexBatch] = dataSocket[item];
                  }else{
                    batch30.push(dataSocket[item])
                  }

              }else if(dataSocket[item].time != data.contact_time){
               // //console.log("user has changed location without  any filter active in different timestamp",data,dataSocket[item],pos)
                //here condition will execute for not equal time
                dataSocket[item].location_id =pos.location_id;      
                dataSocket[item].position = {
                  "levelIndex": 0,
                  "x": pos?pos.position.x + ((0.5*(Math.random() - 0.5) * 20)):(11.205211986727418 + ((0.111*(Math.random() - 0.5) * 20))),
                  "z": pos?pos.position.z +((0.5*(Math.random() - 0.5) * 10)):(-9.236610745591577+ ((0.1*(Math.random() - 0.5) * 10))),
                  "elevation": 1.4
                }
                dataSocket[item].rssi= data.rssi; 
                dataSocket[item].tag_serial= data.tag_id;
                dataSocket[item].contact_tag_id= data.contact_tag_id;
                dataSocketAll[item2]=dataSocket[item]
                
                let indexBatch = batch30.findIndex((item) => item.tag_serial == data.tag_id);
                if(indexBatch > -1){
                  batch30[indexBatch] = dataSocket[item];
                }else{
                  batch30.push(dataSocket[item])
                }

            }
                      
          }
        }else{
         
        }
          
        }else{
          
          

          let formatAr={}


          
          let mm = employeeALL.find(item=>item.tag_serial == data.tag_id); 
          if(mm){
            let productive=[];
              if(activeType==''){
                   
              }else{
                productive = mm.loc_type?mm.loc_type[0][activeType]:[];


               }
          
          formatAr.id= randomString(32);
          formatAr.type= 'add';
          formatAr.name = mm?mm.emp_name: data.tag_id
           

          if(activeType==''){
            let pos = locationCordinate.find(item=>item.tag_serial == data.contact_tag_id)
            if(pos){
            let prod = mm.loc_type?mm.loc_type[0]['productive']:[];
            let nonprod = mm.loc_type?mm.loc_type[0]['non_productive']:[];
            let lessprod = mm.loc_type?mm.loc_type[0]['less_productive']:[];
            formatAr.location_id =pos.location_id;
            
           
            formatAr.position = {
              "levelIndex": 0,
              "x": pos?pos.position.x + ((0.5*(Math.random() - 0.5) * 20)):(11.205211986727418 + ((0.111*(Math.random() - 0.5) * 20))),
              "z": pos?pos.position.z +((0.5*(Math.random() - 0.5) * 10)):(-9.236610745591577+ ((0.1*(Math.random() - 0.5) * 10))),
              "elevation": 1.4
            }
            if(prod.find(item=>item.tag_serial == data.contact_tag_id)){
              formatAr.type='productive';
              formatAr.icon= ProductiveIcon
            }

           else if(nonprod.find(item=>item.tag_serial == data.contact_tag_id)){
              formatAr.type='non_productive';
              formatAr.icon= nonProductiveIcon
            }
            
           else if(lessprod.find(item=>item.tag_serial == data.contact_tag_id)){
              formatAr.type='less_productive';
              formatAr.icon= lessProductiveIcon
            }
            else{
              formatAr.type='undefined';
              formatAr.icon= ManIcon
            }
            
            
            formatAr.time= data.contact_time; 
            formatAr.rssi= data.rssi; 
            formatAr.tag_serial= data.tag_id;
            formatAr.contact_tag_id= data.contact_tag_id;
            
            dataSocket.push(formatAr);
            dataSocketAll.push(formatAr);


            if(pos.planned > dataSocket.filter(item=>item.location_id == pos.location_id ).length){
              updateDataLayer(pos,nonProductiveIcon);
            }else{
              updateDataLayer(pos,ProductiveIcon);
            }

            //updateDataLayer


              dispatchPoint({
                type: 'add',
                point: {
                  id: formatAr.id,
                  name: formatAr.name,
                  position: formatAr.position
                }
          })

          
        }else{

        }


          }else{

            let check= productive.find(item=>item.tag_serial == data.contact_tag_id)
            if(check){
              let pos = locationCordinate.find(item=>item.tag_serial == data.contact_tag_id)
         
              if(pos){
              formatAr.location_id =pos.location_id;
    
             
              formatAr.position = {
                "levelIndex": 0,
                "x": pos?pos.position.x + ((0.5*(Math.random() - 0.5) * 20)):(11.205211986727418 + ((0.111*(Math.random() - 0.5) * 20))),
                "z": pos?pos.position.z +((0.5*(Math.random() - 0.5) * 10)):(-9.236610745591577+ ((0.1*(Math.random() - 0.5) * 10))),
                "elevation": 1.4
              }
              

              formatAr.time= data.contact_time; 
              formatAr.rssi= data.rssi; 
              formatAr.tag_serial= data.tag_id; 
              dataSocket.push(formatAr);
              dataSocketAll.push(formatAr);
              
              formatAr.type=activeType
              formatAr.icon= activeType=='productive'?ProductiveIcon:activeType=='less_productive'?lessProductiveIcon:nonProductiveIcon

                dispatchPoint({
                  type: 'add',
                  point: {
                    id: formatAr.id,
                    name: formatAr.name,
                    position: formatAr.position
                  }
            })
          }else{

          }

            }else{

            }



            
          }
          


      }else{
        
      }
          
        }


      });



      return () => {

        client.end();
      }
    }



    },[client,permission,locationCordinate])

    
    function randomString(length) {
        let chars='0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
        var result = '';
        for (var i = length; i > 0; --i) {
            if(i==24){
              result += chars[Math.floor(Math.random() * chars.length)]+"-";
            }
            if(i==16){
              result += chars[Math.floor(Math.random() * chars.length)]+"-";
            }
            if(i==12){
              result += chars[Math.floor(Math.random() * chars.length)]+"-";
            }
            if(i==8){
              result += chars[Math.floor(Math.random() * chars.length)]+"-";
            }else{
              result += chars[Math.floor(Math.random() * chars.length)];
            }
          
        }
        return result;
      }  
    function handleDateSelect(date) {
    updateSelectedDate(date);

    let startDate = new Date().setDate(new Date().getDate() - 29);
    let endDate = new Date(moment(date).format('YYYY-MM-DD 23:59:59'));
    //setChartDates({ start: startDate, end: endDate });
  }

  function getDateFormat(date) {
    return moment(date).format("YYYY-MM-DD");
  }

  const onReady = useCallback(space => setSpace(space), [])

  const [icons, dispatchIcon] = useReducer((icons, action) => {
    switch (action.type) {
      case 'add':
        return [...icons, action.icon]
      case 'update':
        return icons.map(pt =>
          pt.id === action.id ? { ...pt, ...action.updates } : pt
        )
      case 'remove':
        return reject(r => r.id === action.id)(icons)
      default:

    }
  },[]/*[
    {
      "id": "45f8d5d7-485d-5fe3-87d4-ec83ad282178",
      "name": "12 PM",
       "name":"Loc 1",
      "tooltip": '12 PM',
      "position": {
        "levelIndex": 0,
        "x": 5.345798104140962,
        "z": -7.660746541830819,
        "elevation": 0.0020000000950055608
      },
      "color":'#000'
    }]
    */)

  const [points, dispatchPoint] = useReducer((points, action) => {
      
    switch (action.type) {
      case 'add':
        return [...points, action.point]
      case 'update':
        return points.map(pt =>
          pt.id === action.id ? { ...pt, ...action.updates } : pt
        )
      case 'remove':
        return reject(r => r.id === action.id)(points)
      default:

    }
    
  },[])
  

  useEffect(()=>{
    if (!space) {
      return
    }
    let data = [...locationCordinate]
    if(data.length>0){
      for (let i = 0;i<data.length;i++){
       
        

        //dispatchIcon(arr);

        let arr={};
        arr.type='add'
        arr.icon={}
        arr.id=data[i].unique_loc_id;
        arr.tooltip=data[i].name 
        arr.position= data[i].position
        arr.position.elevation=5
        arr.name = data[i].name
        arr.width = 96

        arr.url = DefaultIcon
        arr.widthSize=3

        AddDataLayer(arr)






      }
    }    
  },[locationCordinate,spaceID,space])


  useEffect(() => {

    if(permission.facilities=='False'){
      return
    }

    if (!space) {
      return
    }

    space.addDataLayer({
      id: 'points',
      type: 'icon',
      data: autoElevation(points),
      icon:  {
        url:ManIcon,
        width: 683,
        height: 800,
      },
      width: 4,
      anchor: 'bottom',
      tooltip: d => d.name,

      // onDrop: ({ data, position }) =>{
      //   //console.log("Change location update",data,position);
      //   dispatchPoint({
      //     type: 'update',
      //     id: data.id,
      //     updates: { position }
      //   })

      // }

         


    })


////console.log("let check",points)


  }, [space, points, autoElevation,permission])


  const [currentCount, setCount] = useState(0);

  useEffect (() => {
    if(permission.facilities=='False'){
      return
    }

    if (!space) {
      return
    }
 
    space.addDataLayer({
      id: 'icons',
      type: 'icon',
      data: autoElevation(icons),
      icon: {
        url: LocationIcon,
        width: 96,
        height: 96
      },
      width: 4,
      anchor: 'top',
      tooltip: d => d.name,
      onDrop: ({ data, position }) =>
        dispatchIcon({
              type: 'update',
              id: data.id,
              updates: { position }
            })
    })
    
  }, [space, icons, autoElevation,permission])

  

  const displayFrameFrame = () => {

    let data=dataSocket;
    //  dataSocket=[]

      if(data.length >0){
          for(let i =0; i < data.length; i++){
              let dt = data[i];

              dispatchPoint({ type: 'remove', id: dt.id });
              
              dataSocket.splice(i,1);
              
              let locationFind = locationCordinate.find(item=>item.location_id==data[i].location_id);
              if(locationFind  ){
                if(dataSocket && dataSocket.filter(item=>item.location_id ==data[i].id).length < locationFind.planned){
                  updateDataLayer(locationFind,nonProductiveIcon);
                }else if(dataSocket && dataSocket.filter(item=>item.location_id == data[i].id).length >= locationFind.planned){
                  updateDataLayer(locationFind,ProductiveIcon);
                }else{
                  updateDataLayer(locationFind,DefaultIcon);
                }
                
              }
              
          }
          
         }
  }

  const batchUpdateFrame30 = () => {

    let data=batch30;
    batch30=[];

      if(data.length >0){
          for(let i =0; i < data.length; i++){
              let dt = data[i];
              let locationFind = locationCordinate.find(item=>item.location_id==data[i].location_id);
              if(locationFind  ){
                if(dataSocket && dataSocket.filter(item=>item.location_id ==data[i].id).length < locationFind.planned){
                  updateDataLayer(locationFind,nonProductiveIcon);
                }else if(dataSocket && dataSocket.filter(item=>item.location_id ==data[i].id).length >= locationFind.planned){
                  updateDataLayer(locationFind,ProductiveIcon);
                }else{
                  updateDataLayer(locationFind,DefaultIcon);
                }
              }
             dispatchPoint({type: 'update',id: data[i].id,updates: {position:data[i].position}  }) 
          }
          
         }
  }

  const removeFrame = () => {
    if(!space){
      return
    }
    let data=dataSocketAll;
     dataSocket=[]

      if(data.length >0){
          for(let i =0; i < data.length; i++){
              let dt = data[i];
              dispatchPoint({ type: 'remove', id: dt.id });
          }
          
         }
       for(let i=0 ;i<locationCordinate.length;i++){
        updateDataLayer(locationCordinate[i],DefaultIcon);
       }
         

  }
  
  function updateDataLayer(data,url){

    ////console.log("update layer executed",data)

    if(space){

    if(url){
      space.updateDataLayer({
        id: data.unique_loc_id,
        data: [{
          position: data.position, 
        }],
        icon: {
          url: url,
          width: 96,
          height:96
        },
        width: 4,
        anchor: 'top',
        //tooltip: data => data.name,
      })    
    }
  }

  }


  function AddDataLayer(data){

    if(space){
    space.addDataLayer({
      id: data.id,
      type: 'icon',
      data: [{
        position: data.position, 
      }],
      icon: {
        url: data.url,
        width: 96,
        height: 96
      },
      width: 4,
      anchor: 'top',
      tooltip: d => data.name,
    })
  }
}

useEffect(() => {
const id3 = setInterval(timerLive, 1000);
return () =>{
  clearInterval(id3);
  }   
},[]);

  useEffect(() => {
    if(permission.facilities=='False'){
      return
    }
    if (!space) {
          return
    }           
      const id = setInterval(displayFrameFrame, 120000);
      const id2 = setInterval(batchUpdateFrame30, 30000);
      
      
      return () =>{
      clearInterval(id);
      clearInterval(id2);
      } 
      
    },[currentCount,space]);

      useEffect(() => {

        if (!space) {

          return
        }   

      },[currentCount,space,permission]);


      function getDateFormat(date) {
        return moment(date).format("YYYY-MM-DD");
      }
    
    
    useEffect(() => {
      if (props.language) {
        updateSelectedLangValue(props.language);
      }
    }, [props.language]);
    
  
  
    const handleDate = (date, type) => {
      // setChartDates((prev) => ({ ...prev, [`${type}`]: date }));
    };

    function changeLanguage(lang) {
      getLanguageTranslation(lang).then((res) => {
        if (res && res.status >= 200 && res.status <= 200) {
          localStorage.setItem("languageData", JSON.stringify(res.data));
          localStorage.setItem("selectedLanguage", lang);
          props.setSelectedLanguage(lang);
        }
      });
    }

    function changeTab(name){
      SetActiveTab(name);
    }

    function ModalFormatData(hourData,location_id){
    
      let data = [];

      let arr =[];

      arr.push(
        <div className='titleArea'> 
         <h4>{location_id.toLowerCase()}  <button className='alignRightFixed' onClick={()=>SetModalView(false)}>x</button></h4>
        </div>
      )

      let inner =[];      
      for(let i=0;i<hourData.length;i++){
        if(i==1){
          //console.log("compare",hourData[i],employeeDefault)
        }
        
        let emp= employeeDefault.find(item=>item.tag_serial == hourData[i].tag_serial );


      inner.push(emp)   
      }
      inner = inner.map((item,index)=> {item.sno = index +1; return item })
      arr.push(AGTable(inner))
      return arr;
      //return 'hello';

    }

    function AGGridEMptyMEssage(key){
      let msg;
          msg='<span class="ag-overlay-no-rows-center">No Records Found !</span>'
      return msg
    }
    
    function viewIconRenderer(params){
      var eGui = document.createElement('div');
      eGui.innerHTML = '<span class="icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!--! Font Awesome Pro 6.0.0-alpha3 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path d="M288 128C217.3 128 160 185.3 160 256s57.33 128 128 128c70.64 0 128-57.32 128-127.9C416 185.4 358.7 128 288 128zM288 352c-52.93 0-96-43.06-96-96s43.07-96 96-96c52.94 0 96 43.02 96 96.01C384 308.9 340.1 352 288 352zM572.5 238.1C518.3 115.5 410.9 32 288 32S57.69 115.6 3.469 238.1C1.563 243.4 0 251 0 256c0 4.977 1.562 12.6 3.469 17.03C57.72 396.5 165.1 480 288 480s230.3-83.58 284.5-206.1C574.4 268.6 576 260.1 576 256C576 251 574.4 243.4 572.5 238.1zM543.2 260.2C492.3 376 394.5 448 288 448c-106.5 0-204.3-71.98-255-187.3C32.58 259.6 32.05 256.9 31.1 256.2c.0547-1.146 .5859-3.783 .7695-4.363C83.68 135.1 181.5 64 288 64c106.5 0 204.3 71.98 255 187.3c.3945 1.08 .9238 3.713 .9785 4.443C543.9 256.9 543.4 259.6 543.2 260.2z"/></svg></span>';
       return eGui;
    }
    
    function AGTable(data){
    

      if(data){
        //console.log("Ag Data",data)
      
        //let widthCal= ((((window.innerWidth * 80>1100?1100:(window.innerWidth * 80)/100))- 60 )/5 )  ;

        let widthCal= 0;
    
        if( ((window.innerWidth * 80)/100) > 1100){
          widthCal=((1100 - 205)/3) ;
        }else{
          widthCal=(( (window.innerWidth * 80)/100) - 205)/3 ;
        }
    
        let arr;

        arr=<div className={"keyAGTable"}><div className="ag-theme-alpine if cell-size-40" style={{height: 60 + ((data.length > 0? data.length:1)*36),maxHeight:500 , width: "calc(100% - 1px)"}}>
          <AgGridReact
              rowHeight={35}
              autoGroupColumnDef={{
                  headerName: 'Name',minWidth: 200,field: 'name',
      
              }}
              headerHeight={35}
    
           defaultColDef={{sortable: true,resizable: true,minWidth:45, width:widthCal , suppressColumnVirtualisation:true,skipHeaderOnAutoSize:true}}           
              //pagination={true}
              rowData={data}
              key={'keyAGTable'}
              onCellClicked={ (event) => window.open(`/productivity/employee/:${event.data.tag_serial}`, "_blank")  }
              overlayNoRowsTemplate={
                  AGGridEMptyMEssage('')
                }        
           >
            
          <AgGridColumn
              field="sno"
              headerName={"S.No"}
              valueFormatter={(params)=>params.value}
              cellClass={'textCapitalize textCap'}
  
              width={75}
          />
            <AgGridColumn
              field="emp_name"
              headerName={"Name"}
              valueFormatter={(params)=>params.value.toLowerCase()}
              cellClass={'textCapitalize textCap'}
          />
          <AgGridColumn 
              field="dept_name" 
              headerName="Department" 
              valueFormatter={(params) =>params.value.toLowerCase()}    
              cellClass={'textCapitalize textCap'}
             >
          </AgGridColumn> 
           <AgGridColumn
             field="sub_dept_name"
             headerName="Sub Department"
             cellClass={'textCapitalize textCap'}
             valueFormatter={(params) =>params.value!=''?params.value.toLowerCase():'-'}    headerComponentParams =  {{
          }} ></AgGridColumn> 
          <AgGridColumn
             field="sub_dept_name"
             headerName="View"
             width={80}
             cellClass={'textCapitalize textCap'}
             cellRenderer= {(params)=>viewIconRenderer(params)} >
            </AgGridColumn> 
      </AgGridReact>
      </div></div>
      
      return arr
    }else{
      return ;
    }
    
    }

    function modalViewShow(dataItem){
      // //console.log("location id",location_id)
      let data = dataSocket.filter(item=>item.location_id==dataItem.location_id);
      //console.log("clicked",data);
      let arr =[];
      arr = ModalFormatData(data,dataItem.name);      
     SetModalData(arr);
     SetModalView(true)
     
    }
     function changeGeoMapSpace(val){

      space.remove();
      SetSpaceID(val);



     }

      
  return (<>
      {permissionAPICall?permission.facilities=="True"? 
    <div className="manpowerManagementMainDiv">
    <Container className="header-breadcrumb">
      <Row>
        <Col lg={6}>

        {/* <CommonHeading title="Facility Tracker" /> */}
                <div className='tabHeader'>
              
                <div className='tabHeaderList' onClick={()=>props.history.push('/employee-tracker')}>
                <CommonHeading title="Employee Tracker"/>
                </div>   
                <div className='tabHeaderList active'>
                <CommonHeading title="Facility Tracker" />
                </div>
           </div>   
    </Col>
        <Col lg={6} className="text-right">
          <div className="commonLangaugeStyleDiv">
            <DashboardLanguage
              selectedLangValue={selectedLangValue}
              changeLanguage={changeLanguage}
            />
          </div>

          <div className="siteHeadingDatePickerDiv" style={{ width: "20%" }}>
            <CommonDatePicker
              selectedDate={selectedDate}
              handleSelectDate={handleDateSelect}
            />
          </div>
        </Col>
      </Row>



      <Row className="m-t" >
        <Col lg={12} >
          <div className='manStyle' style={{backgroundColor:"#fff",width:"100%",float:"left"}}>
            <div className='inlineBlockFilter'>
              <h5 style={{marginBottom:0,lineHeight:"35px"}}> Filter</h5>
            </div>
        
            <div className='inlineBlockFilter'>
            <Select
                placeholder="Select Department"
                optionFilterProp="children"
                onChange={(val)=>{SetActiveDepartment(val)}}
                //defaultValue={'Select Department'}
                value={activeDepartment==''?'Select Department':activeDepartment}
                key="departmentFilter"
                style={{width:250}}
                disabled={!space}
            >
              <Option value='all' key={"Department All"}>All</Option>
                {
                department && department.length > 0? department.map((item)=>{
                    return <Option value={item.department_id} key={"Department"+item.department_id}>{item.name}</Option>
                }):""
                }
            </Select>
            </div>
            <div className='inlineBlockFilter'>
                <Select
                    placeholder="Select Sub Department"
                    optionFilterProp="children"
                    style={{width:250}}
                    onChange={(val)=>{SetActiveSubDepartment(val)}}
                    //defaultValue={'Select Sub Department'}
                    disabled={activeDepartment==''?true:false}
                    value={activeSubDepartment==''?'Select Sub Department':activeSubDepartment}
                    key="SubdepartmentFilter"
                >
                  <Option value='all' key={"Sub Department All"}>All</Option>
                    {
                    sub_department && sub_department.length > 0? sub_department.map((item)=>{
                        return <Option value={item.sub_department_id} key={"Sub Department"+item}>{item.sub_dept_name}</Option>
                    }):""
                    }
                </Select>
            </div>

            <div className='inlineBlockFilter'>
                <button className={activeType==''?'button active btn-productive-filter':'button btn-productive-filter'} onClick={()=>SetActiveType('')} disabled={!space}>All</button>
                <button className={activeType=='productive'?'button active btn-productive-filter':'button btn-productive-filter'} onClick={()=>SetActiveType('productive')} disabled={!space}>Productive</button>
                <button className={activeType=='non_productive'?'button active btn-productive-filter':'button btn-productive-filter'} onClick={()=>SetActiveType('non_productive')} disabled={!space}>Non Productive</button>
                <button className={activeType=='less_productive'?'button active btn-productive-filter':'button btn-productive-filter'} onClick={()=>SetActiveType('less_productive')} disabled={!space}>Less Productive</button>
            </div>
            {space?<><div className='greenLive'>Live</div><div className='timerLive'><span className='toptime'>{time}</span><span className='currentDate'>{moment().format('DD-MM-YYYY')}</span></div></>:""}
            </div>
        </Col>
      </Row>

      <Row className="m-t">
        <Col lg={9} >
          <div className='manStyle' style={{maxWidth:"100%",margin:"0 auto"}}>

          {loaderEmp && !apiCall? <div className='loaderStyle' style={{background:"#fff"}}><div className="loadingInfo">
                    <h4>
                        <span>Fetching Employee detail please wait</span>
                         <span className="loading-dots">
                                <div className="loading-dots--dot"></div>
                                <div className="loading-dots--dot"></div>
                                <div className="loading-dots--dot"></div>
                          </span>
                      </h4>
                    </div>
                  </div>:""} 

             {apiCall? <div className="text-center m-t-lg">
                                    <img src={spinnerLoader} className="m-t-lg" />
                                </div>:spaceID!=''? <Viewer onReady={onReady} spaceID={spaceID} changeTab={changeTab} /*cameraPlacement={cameraPlacements}*//>:<h2>Please Setup Geo Map or contact to administrator</h2>}
          </div>
        </Col>
        <Col lg={3} className="p-l-0 ">
          <div className="legendArea manStyle" style={{height:'100%',background:"#fff"}}>
            <div className='legendStart'>
              <div className={!space?'legendList legendHeader disable':'legendList legendHeader'}>
                  <div className='legendLeftAreaProd'><strong>Location</strong></div>
                  <div className='legendLeftAreaProd legendLeftAreaProdSmall'><strong>Plan Count</strong></div>
                  <div className='legendLeftAreaProd legendLeftAreaProdSmall'><strong>Actual Count</strong></div>
                  <div className='legendRightAreaProd'><strong>Worker List</strong></div>
              </div>
                    {locationCordinate.map(item=>{

                      return(
                                    <div key={"legendList"+item.name} className={!space?'legendList disable':'legendList'}>
                                              <div className='legendLeftAreaProd'>{item.name}</div>
                                              <div className='legendLeftAreaProd legendLeftAreaProdSmall'>{item.planned}</div>
                                              <div className={dataSocket.filter(el=>el.location_id == item.location_id).length > parseInt(item.planned)?'legendLeftAreaProd legendLeftAreaProdSmall legendLeftAreaProdSmallGreen':
                                                dataSocket.filter(el=>el.location_id == item.location_id).length == parseInt(item.planned)?'legendLeftAreaProd legendLeftAreaProdSmall legendLeftAreaProdSmallGreen':item.planned == '-'?"legendLeftAreaProd legendLeftAreaProdSmall":"legendLeftAreaProd legendLeftAreaProdSmall legendLeftAreaProdSmallRed"}>{!space?'-':dataSocket.filter(el=>el.location_id == item.location_id).length}</div>
                                              {dataSocket.filter(el=>el.location_id == item.location_id).length > 0?<div className='legendRightAreaProd' onClick={()=>modalViewShow(item)} ><Eye/></div>:<div className='legendRightAreaProd disabled nonCLickable'><Eye/></div>}
                                        </div>
                                )
                    })}

      <div className={!space?'legendList legendHeader legendFooter disable':'legendList legendHeader'}>
                  <div className='legendLeftAreaProd'><strong>Total</strong></div>
                  <div className='legendLeftAreaProd legendLeftAreaProdSmall'>{locationCordinate.reduce((partialSum, a) => (partialSum==undefined?0:partialSum) + (a.planned!=='-'?a.planned:0), 0)}</div>
                  <div className='legendLeftAreaProd legendLeftAreaProdSmall'><strong>{dataSocket.length}</strong></div>
                  <div className='legendRightAreaProd'></div>
              </div>

                </div>

          </div>
        </Col>
      </Row>
    </Container>

    <ReactModal
                isOpen={modalView}
                style={customStyles}
                onRequestClose={()=>SetModalView(false)}
                shouldCloseOnOverlayClick={false}
                
            >
                <div className={"modal-cyclecount"}>
                  {modalData}
                </div>
            </ReactModal>

  </div>:<Col lg={12}>
            {/* <div className='errorPermission'></div> */}
        <div className='center404'> <Image404 /><h4 style={{marginTop:"-30px",color:"#2e3552"}}>Access Restricted to this page, Please contact administrator</h4></div>
        </Col>:<Col lg={12} md={12} sm={12} xs={12} className="apiLoaderPermission">
                                <img src={spinnerLoader} />
                            </Col>}
                            </>
  )
}

export default FacilitiesTracker