import React,{useState,useEffect,useRef} from "react";
import { Container, Row, Col } from 'react-bootstrap';
import moment from "moment";
import { getTranslatedText } from "../../../common/utilities";
import DatePicker from 'react-datepicker';
import selectedPinkArrowIcon from '../../../assets/traceplusImages/pink_right_arrow_icon.svg'
import filterIcon from '../../../assets/traceplusImages/filter.png'
import spinnerLoader from '../../../assets/images/Spinner Loader.gif'
import helpIcon from '../../../assets/traceplusImages/help-icon.png'
import sortIcon from '../../../assets/traceplusImages/sort.png'
import upIcon from '../../../assets/traceplusImages/up-arrow.png'
import downIcon from '../../../assets/traceplusImages/down-arrow.png'
import { Checkbox, Button, Select } from 'antd';
import Scrollbars from "react-custom-scrollbars";
import { useHistory, withRouter } from "react-router-dom";
import {getPolicyOverview} from '../../actionMethods/actionMethods'
import {AgGridColumn, AgGridReact} from 'ag-grid-react';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

const { Option } = Select;



function PolicyList(props){
    let history = useHistory();
    let date = localStorage.getItem("selectedDate") ? new Date(localStorage.getItem("selectedDate")) : new Date();
    let interval = 5;
    const [selectedLangValue, updateSelectedLangValue] = useState("en");
    const [sortKey, setSortKey] = useState('name')
    const [sortType, setSortType] = useState('desc')
    const[typeTable,SetTypeTable]=useState(props.selectedTab?props.selectedTab:'people');
    const [isLoading, updateIsLoading] = useState(true)
    const [searchValue, updateSearchValue] = useState('')
    const [messageError, setMessageError] = useState("")
    const [messageValue, updateMessageValue] = useState('')
    const [tabData,SetTabData]=useState([]);
    const[tabPeopleData,SetTabPeopleData]=useState([])
    const[tabTeamData,SetTabTeamData]=useState([])
    const[tabLocationData,SetTabLocationData]=useState([])
    const[tabPrevPeopleData,SetTabPrevPeopleData]=useState([])
    const[tabPrevTeamData,SetTabPrevTeamData]=useState([])
    const[tabPrevLocationData,SetTabPrevLocationData]=useState([])
    const [loaderTable,SetLoaderTable]=useState(true);
    const [apiLoad,SetAPILoad]=useState(0);
    const[oldStartDate,SetOlStartdDate]=useState('');
    const[oldEndDate,SetOldEndDate]=useState('');
    const[levelAll,SetLevelAll]=useState('');
    // const[unFormatedPeople,updateunFormatedPeople]=useState([]);
    // const[unFormatedTeam,updateunFormatedTeam]=useState([]);
    // const[unFormatedLocation,updateunFormatedLocation]=useState([]);
    const [widthContainer, setWidthContainer] = useState(0);
    const elementRef = useRef(null);   
   

    useEffect(() => {
        //console.log(elementRef.current);
        setWidthContainer(elementRef.current.getBoundingClientRect().width);
        //console.log(elementRef.current.getBoundingClientRect().width)
      }, [elementRef]);

    
    let userDetails = JSON.parse(localStorage.getItem("userLoginDetails"));
    let userSession = userDetails ? userDetails.session : "123456789";
    let org_id = userDetails ? userDetails.org_id : 6;
    if(props.start_date){
         localStorage.setItem('policy_start_date',props.start_date);
    }
    if(props.end_date){
        localStorage.setItem('policy_end_date',props.end_date);
   }

    useEffect(()=>{
        SetLoaderTable(true);
       
        let requestBody=[]
        requestBody.start_date=getDateFormat(props.start_date);
        requestBody.end_date=getDateFormat(props.end_date);

        ////console.log("Condition Outside",())
        //console.log("props.date ",props.start_date)
        //console.log("props.date ",props.end_date)
        //console.log("condition props",(props.start_date!=oldStartDate ))
        //console.log("condition props",( props.end_date!=oldEndDate ))
        //console.log("condition props",( props.level!=levelAll))
        if((props.start_date!=oldStartDate || props.end_date!=oldEndDate || props.level!='') ){
            SetOlStartdDate(props.start_date)
            SetOldEndDate(props.end_date)
            SetLevelAll(props.level)
        if(props.level=='all'){

            //console.log("condition 1",props.start_date!=null,props.start_date)
            //console.log("condition 2",props.end_date!=null,props.end_date)
            //console.log("condition 3",props.level!=null,props.level)
           
            //console.log("condition 4",oldStartDate)
            //console.log("condition 5",oldEndDate)
            //console.log("condition 6",levelAll)
                      
            getPolicyOverview(requestBody,userSession, org_id,'employee').then(res => {
                if (res.status === 200) {
                    
                    
                    SetTabPeopleData(FormatPeopleData(res.data));
                    SetTabPrevPeopleData(FormatPeopleData(res.data))

                }
            });
            getPolicyOverview(requestBody,userSession, org_id,'team').then(res => {
                if (res.status === 200) {
                    SetTabTeamData(FormatTeamData(res.data));
                    SetTabPrevTeamData(FormatTeamData(res.data))
                }
            });
            getPolicyOverview(requestBody,userSession, org_id,'loc_team').then(res => {
                if (res.status === 200) {
                    SetTabLocationData(FormatLocationData(res.data));
                    SetTabPrevLocationData(FormatLocationData(res.data))
                    SetLoaderTable(false);
                }
            });

            setTimeout(function(){
                SetLoaderTable(false);
            },5000)
            

        }


////console.log('Now api calll Test');
    }else{
        //console.log()
    }

    },[props.level,props.start_date,props.end_date]);

    useEffect(()=>{
     

     },[]);

     function FormatPeopleData(data){
         let arr=[]
         //	{"name": "V. Krishna Sumanth","access": "23","policy_breached": "na","capacity": "na","status": "negative","tag_serial": "01CD5E981C0655","team": "Glucometer"},
         Object.keys(data).forEach((key) => {
         let element=data[key]

         arr.push({name:key,access:element.accesability.count,policy_breached:element.total_breach,capacity:element.capacity.count,tag_serial:element.tag_id})
        });   
    

        
        return arr;
     }

     function FormatTeamData(data){
        let arr=[]
        let arr2=[];
        let index=0;
      //  //console.log("Team Data");
        Object.keys(data).forEach((key) => {
            ////console.log(key);
         let element= data[key]
         ////console.log(element);
         
        arr.push({name:key,
                access:element.team_accesability_breach,
                policy_breached:element.total_team_breach,
                capacity:element.team_capacity_breach
        })

        });
       // //console.log(arr);   
        return arr;
    }
    
    function FormatLocationData(data){
        let arr=[];
        let arr2=[];
        Object.keys(data).forEach((key) => {
           ////console.log(data[key]);
           // //console.log(key);
         let element=data[key]
         arr.push({
                name:key,
                access:element.location_accesability_breach,
                policy_breached:element.location_total_breach,
                capacity:element.location_capacity_breach,
                tag_serial:element.loc_serial
        })
           
        });


        return arr;
    }
    
function getDateFormat(date) {
    return moment(date).format("YYYY-MM-DD");
}


   const handleSort = (key) => {
    setSortKey(key)
    setSortType(sortType === 'desc' ? 'asc' : 'desc')
}

    const tabPeople='';
    const tabTeam= '';
    const tabLocation=''    ;
useEffect(() =>{
   if(typeTable=='people'){
    SetTabData(tabPeopleData);
   }else if(typeTable=='location'){
    SetTabData(tabLocation);
   } else if(typeTable=='team'){
    SetTabData(tabTeamData);
   }
},[typeTable])


useEffect(()=>{
    showCardListPeople();
},[tabPeopleData])
useEffect(()=>{
    showCardListTeam();
},[tabTeamData])


useEffect(() => {

    let arr = [];
    let initialArray=[];

	if(typeTable=='people'){
		arr = tabPeopleData;
        initialArray= tabPrevPeopleData

	}else if(typeTable=='location'){
		arr =tabLocationData;
        initialArray= tabPrevLocationData
        
	} else if(typeTable=='team'){
		arr =tabTeamData;
        initialArray= tabPrevTeamData
	}
    if (searchValue=='') {
        let invalid = /[°"§%()[\]{}=\\?´`'#<>|,;.:+_-]+/g;
        let value = searchValue.replace(invalid, "")
        arr = initialArray
    }
    if (searchValue!='' && typeTable=='people') {
        let invalid = /[°"§%()[\]{}=\\?´`'#<>|,;.:+_-]+/g;
        let value = searchValue.replace(invalid, "")
        arr = arr.filter(function (employeeList) {
            return (employeeList.name.toLowerCase().search(value.toLowerCase()) !== -1 )
        })
      //  //console.log("If Condition");
        ////console.log(arr)
    }else{
        let invalid = /[°"§%()[\]{}=\\?´`'#<>|,;.:+_-]+/g;
        let value = searchValue.replace(invalid, "")
        arr = arr.filter(function (employeeList) {
            return (employeeList.name.toLowerCase().search(value.toLowerCase()) !== -1)
        })         
      //  //console.log("Else Condition");
       // //console.log(arr)
    }
    


    if (sortKey === 'name') {
        arr = arr.sort((a, b) => {
            a = a.name.toUpperCase()
            b = b.name.toUpperCase()

            return sortType === 'desc' ? (a == b ? 0 : b > a ? 1 : -1) : (a == b ? 0 : a > b ? 1 : -1)
        })
    } else if (sortKey === 'tagID') {
        arr = arr.sort((a, b) => {
            a = a.tag_serial.toUpperCase()
            b = b.tag_serial.toUpperCase()

            return sortType === 'desc' ? (a == b ? 0 : b > a ? 1 : -1) : (a == b ? 0 : a > b ? 1 : -1)
        })
    } else if (sortKey === 'breached') {
        arr = arr.sort((a, b) => {
            if (a.policy_breached !== 'na' && b.policy_breached !== 'na') {
                return sortType === 'desc' ? b.policy_breached - a.policy_breached : a.policy_breached - b.policy_breached
            } else if (a.policy_breached !== 'na' && b.policy_breached === 'na') {
                return -1
            } else {
                return 1
            }
        })
    } else if (sortKey === 'access') {
        arr = arr.sort((a, b) => {
            if (a.access !== 'na' && b.access !== 'na') {
                return sortType === 'desc' ? b.access - a.access : a.access - b.access
            } else if (a.access !== 'na' && b.access === 'na') {
                return -1
            } else {
                return 1
            }
        })
    } else if (sortKey === 'capacity') {
        arr = arr.sort((a, b) => {
            if (a.capacity !== 'na' && b.capacity !== 'na') {
                return sortType === 'desc' ? b.capacity - a.capacity : a.capacity - b.capacity
            } else if (a.capacity !== 'na' && b.capacity === 'na') {
                return -1
            } else {
                return 1
            }
        })
      //  //console.log(arr);
    } 

    if(typeTable=='people'){
        SetTabPeopleData(arr);
        SetTabData(arr)
       }else if(typeTable=='location'){
       SetTabLocationData(arr) 
       SetTabData(arr)
       } else if(typeTable=='team'){
        SetTabTeamData(arr)
        SetTabData(arr)
       }
    updateIsLoading(false)

},[sortKey, sortType, searchValue]);




function handleClickCard(id) {
 
    if(typeTable=='people'){

        history.push(`/policy-compliance-people/:${id}`);
       }else if(typeTable=='location'){
        history.push(`/policy-compliance-location/:${id}`);
        } else if(typeTable=='team'){
        history.push(`/policy-compliance-team/:${id}`);
       }
}

const generateKey = (pre) => {
    return `${ pre }_${ new Date().getTime() }`;
}
function capitalizeFirstLetter(string) {
    return string.toLowerCase();
  }


  function AGGridEMptyMEssage(key){
    let msg;
    if(key=='showCardListPeople' &&tabPrevPeopleData.length>0){
        msg='<span class="ag-overlay-no-rows-center">No Records Found !</span>'
    }else if(key=='showCardListPeople'){
        msg='<span class="ag-overlay-no-rows-center">No Policy has been breached !</span>'
    }
    
    else if(key=='showCardListLocation' && tabPrevLocationData.length>0){
        msg='<span class="ag-overlay-no-rows-center">No Records Found !</span>'
    }else if(key=='showCardListLocation'){
        msg='<span class="ag-overlay-no-rows-center">No Policy has been breached !</span>'
    }
    else if(key=='showCardListTeam' && tabPrevTeamData.length>0){
        msg='<span class="ag-overlay-no-rows-center">No Records Found !</span>'
    }else if(key=='showCardListTeam'){
        msg='<span class="ag-overlay-no-rows-center">No Policy has been breached !</span>'
    }


return msg
  }

 function AGGridTableFormat(data,key){
  //  {name:capitalizeFirstLetter(key),tag_serial:element.tag_serial,policy_breached:element.policy_breached,access:element.access,capacity:element.capacity}

  let widthCal= (widthContainer - 10)/ 5;
 
  
let arr;
  arr=<div><div className="ag-theme-alpine if cell-size-40" style={{height: window.innerHeight - 280 , width: "calc(100% - 1px)"}}>
    <AgGridReact
        rowHeight={40}
        autoGroupColumnDef={{
            headerName: 'Name',minWidth: 200,field: 'name',headerCheckboxSelection: true,
//            cellRendererParams: { checkbox: true },       
        }}
     //   defaultColDef={{sortable: true,resizable: true,minWidth: 45,width:140 }}
     defaultColDef={{sortable: true,resizable: true,minWidth:45, width:widthCal , suppressColumnVirtualisation:true,skipHeaderOnAutoSize:true}}
         
        //pagination={true}
        rowData={data}
        key={props.AgkeyTable?props.AgkeyTable:"manpowerEmployee"}
        onCellClicked={ (event) => handleClickCard(event.data.name) }

        overlayNoRowsTemplate={
            AGGridEMptyMEssage(key)
          }
          
     >

{key=='showCardListPeople'?  
    <AgGridColumn
        field="name"
        headerName={"Name"}
        headerComponentParams =  {{
            template:`<div class="ag-cell-label-container" role="presentation"> 
                <div ref="eLabel" class="ag-header-cell-label" role="presentation"> 
              <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
              <span class="tooltipcustom"><img src='${helpIcon}'/><span class="descHelp tootltipHelp">Name of the employee.</span></span>
              <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span> 
              <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span> 
              <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span> 
            </div> 
          </div>`	
        }}
        valueFormatter={(params)=>capitalizeFirstLetter(params.value)}
    />:key=='showCardListTeam'?<AgGridColumn
    field="name"
    minWidth={200}
    headerName={"Name"}
    headerComponentParams =  {{
        template:`<div class="ag-cell-label-container" role="presentation"> 
            <div ref="eLabel" class="ag-header-cell-label" role="presentation"> 
          <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
          <span class="tooltipcustom"><img src='${helpIcon}'/><span class="descHelp tootltipHelp">Name of the team.</span></span>
          <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span> 
          <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span> 
          <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span> 
        </div> 
      </div>`	
    }}
/>:<AgGridColumn
        field="name"
        minWidth={180}
        headerName={"Name"}
        headerComponentParams =  {{
            template:`<div class="ag-cell-label-container" role="presentation"> 
                <div ref="eLabel" class="ag-header-cell-label" role="presentation"> 
              <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
              <span class="tooltipcustom"><img src='${helpIcon}'/><span class="descHelp tootltipHelp">Name of the location.</span></span>
              <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span> 
              <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span> 
              <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span> 
            </div> 
          </div>`	
        }}
    />} 
        <AgGridColumn field="tag_serial" headerName="Tag ID" valueFormatter={(params) =>params.value!='na'?params.value:'-'}    headerComponentParams =  {{
        template:`<div class="ag-cell-label-container" role="presentation"> 
            <div ref="eLabel" class="ag-header-cell-label" role="presentation"> 
        <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
        <span class="tooltipcustom"><img src='${helpIcon}'/><span class="descHelp tootltipHelp">Tag ID</span></span>
        <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span> 
        <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span> 
        <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span> 
        </div> 
    </div>`	
    }} ></AgGridColumn>
    <AgGridColumn field="policy_breached" headerName="Policies Breached" valueFormatter={(params) =>params.value!='na'?params.value:'-'}    headerComponentParams =  {{
        template:`<div class="ag-cell-label-container" role="presentation"> 
            <div ref="eLabel" class="ag-header-cell-label" role="presentation"> 
        <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
        <span class="tooltipcustom"><img src='${helpIcon}'/><span class="descHelp tootltipHelp">Policies Breached</span></span>
        <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span> 
        <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span> 
        <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span> 
        </div> 
    </div>`	
    }} ></AgGridColumn>
<AgGridColumn field="access" headerName="Access" valueFormatter={(params) =>params.value!='na'?params.value:'-'}  headerComponentParams =  {{
        template:`<div class="ag-cell-label-container" role="presentation"> 
            <div ref="eLabel" class="ag-header-cell-label" role="presentation"> 
        <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
        <span class="tooltipcustom"><img src='${helpIcon}'/><span class="descHelp tootltipHelp">Access Breached</span></span>
        <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span> 
        <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span> 
        <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span> 
        </div> 
    </div>`	
    }} ></AgGridColumn>
<AgGridColumn minWidth={150} field="capacity" headerName="Capacity"   valueFormatter={(params) =>params.value!='na'?params.value:'-'} headerComponentParams =  {{
        template:`<div class="ag-cell-label-container" role="presentation"> 
            <div ref="eLabel" class="ag-header-cell-label" role="presentation"> 
        <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
        <span class="tooltipcustom"><img src='${helpIcon}'/><span class="descHelp tootltipHelp">Capacity Breached.</span></span>
        <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span> 
        <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span> 
        <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span> 
        </div> 
    </div>`	
    }} ></AgGridColumn>
</AgGridReact>
</div></div>

return arr


 } 


function showCardListPeople(){
 
    let arr=[];
    for (let i=0;i<tabPeopleData.length;i++){
        let element=tabPeopleData[i];
        let key =element.name;
        if(element.capacity > 0 || element.access > 0 || element.policy_breached >0 ){
            arr.push({name:key,tag_serial:element.tag_serial,policy_breached:element.policy_breached,access:element.access,capacity:element.capacity})

    }else{
        
    }
       }  
      //console.log(arr.length);
      if(arr.length==0 || arr.length < 0){
       // arr.push(<h3 class="text-center m-t-lg">No Policy has been breached !</h3>);
    }    
    
    return AGGridTableFormat(arr,'showCardListPeople');

}

function showCardListTeam(){
    let arr=[];

    for(let i=0;i  < tabTeamData.length;i++){
        let element= tabTeamData[i];
        let key=element.name
        
        if(element.capacity > 0 || element.access > 0 || element.policy_breached >0 ){
            arr.push({name:key,tag_serial:element.tag_serial,policy_breached:element.policy_breached,access:element.access,capacity:element.capacity})
        }
}      
if(arr.length==0 || arr.length < 0){
//    arr.push(<h3 class="text-center m-t-lg">No Policy has been breached !</h3>);
}    
return AGGridTableFormat(arr,'showCardListTeam');   
}


function showCardListLocation(){
    let arr=[];
    for (let i=0;i<tabLocationData.length;i++){
     let element=tabLocationData[i]
     let key=element.name 
     if(element.capacity > 0 || element.access > 0 || element.policy_breached >0 ){       
        arr.push({name:key,tag_serial:element.tag_serial,policy_breached:element.policy_breached,access:element.access,capacity:element.capacity})
       }
    }
      
if(arr.length==0 || arr.length < 0){
    //arr.push(<h3 class="text-center m-t-lg">No Policy has been breached !</h3>);
}       return AGGridTableFormat(arr,'showCardListLocation');

}


    function handleChangeTab(type) {
        let arr=[];
        updateSearchValue('')
        SetTypeTable(type);
        if(typeTable=='people'){
            arr = tabPeople.data;
           }else if(typeTable=='location'){
            arr =tabLocation.data;
           } else if(typeTable=='team'){
            arr =tabTeam.data;
           }
           SetTabData(arr);
 

    }


    function handleChange(value) {
       // //console.log(`selected ${value}`);
    }
    function sendMessage() {
        if (messageValue == '' || messageValue == null || messageValue == undefined) {
            setMessageError("Message is required.")
        } else if (messageValue.length > 100) {
            setMessageError("Message size can not be more than 100 character.")
        } else {
            setMessageError("")
        }
    }

    const handleMouseLeave = (id) => {
        let doc = document.getElementById(id)

        if (doc) {
            doc.style.display = 'none'
        }
    }
    const handleMouseEnter = (id) => {
        let doc = document.getElementById(id)

        if (doc) {
            doc.style.display = 'block'
        }
    }

return(

    <React.Fragment>
        <div 
        
        className={loaderTable?"siteManagementMainDiv siteManagementMainDivAuto loading":"siteManagementMainDiv siteManagementMainDivAuto"} 
        
        style={{paddingBottom:"0px"}}>
            <div className="siteListMainDiv" ref={elementRef} style={{paddingBottom:"0px"}}>

                 <Row >
                    <Col lg={12}>
                            <Row style={{ alignItems: 'center' }}>
                               
                                    <Col lg={8}>
                                        <div className="empTeamTabMainDiv" style={{ float: 'left' }}>
                                            <div className={'eachTab ' + (typeTable  == 'people'  ? 'activeTabBG' : '')} onClick={() => handleChangeTab('people')}>{getTranslatedText('People')}</div>
                                            <div className={'eachTab ' + (typeTable == 'team' ? 'activeTabBG' : '')} onClick={() => handleChangeTab('team')}>{getTranslatedText('Team')}</div>
                                            <div className={'eachTab ' + (typeTable == 'location' ? 'activeTabBG' : '')} onClick={() => handleChangeTab('location')}>{getTranslatedText('Location')}</div>
                                        </div>
                                    </Col>
                               
                                {props.hideSearch ? '' :
                                    <Col lg={4} className={props.hideHeading ? 'p-r-0 float-right' : ''}>
                                        <div className="listingSearchMainDiv">
                                            <input type="text" value={searchValue} name="siteSearch" placeholder={"Search "+typeTable} onChange={(event) => updateSearchValue(event.target.value)} />
                                        </div>
                                    </Col>
                                }
                            </Row>
                            

{/* Type People */}
                            {typeTable=="people" ?
            <div className="listingRecordMainDiv" style={props.isBubbleView ? { padding: '0rem 1rem' } : {}}>
                
              
                 <div class="loaderDiv loaderDiv-2" ><img src={spinnerLoader} /></div>
                 <div className="canvas2 hide-loading">   
                                                   
                                                    {showCardListPeople()}
                                                    {searchValue && tabData.length == 0 ?
                                                        <h3 className="text-center m-t-lg">No Records Found !</h3> : ''
                                                    }
                                                </div></div>:""} 
                                                

{/* Type Team */}
{typeTable=="team" ?
            <div className="listingRecordMainDiv" style={props.isBubbleView ? { padding: '0rem 1rem' } : {}}> 
                 <div class="loaderDiv loaderDiv-2" ><img src={spinnerLoader} /></div>
                 <div className="canvas2 hide-loading">
                                                    {showCardListTeam()}
                                                    {searchValue && tabData.length == 0 ?
                                                        <h3 className="text-center m-t-lg">No Records Found !</h3> : ''
                                                    }

                                                </div></div>:""} 


                                                {typeTable=="location" ?
            <div className="listingRecordMainDiv" style={props.isBubbleView ? { padding: '0rem 1rem' } : {}}>
                
                 <div class="loaderDiv loaderDiv-2" ><img src={spinnerLoader} /></div>
              
                 <div className="canvas2 hide-loading">
                  {/* <div className="tetts"> {window.innerHeight - 320  }</div> */}
                                                    {showCardListLocation()}
                                                    {searchValue && tabData.length == 0 ?
                                                        <h3 className="text-center m-t-lg">No Records Found !</h3> : ''
                                                    }
                                                </div></div>:""} 

                                                
                                                                                                       

            </Col>
        </Row>

        </div>
        </div>
        </React.Fragment>                   


)

}

export default PolicyList;