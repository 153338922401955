
import React,{useState } from 'react';
import { Link, withRouter } from 'react-router-dom'

import { sidebarMenuItems } from '../common/menuItems';
import { getTranslatedText } from '../common/utilities';
import DraggableList from "react-draggable-lists";
import Sortable from "./Sortable";
import { useEffect } from 'react';


const listItems = [
    "Entertainment",
    "Private Time",
    "Rest",
    "Meal",
    "Exercise",
    "Work",
    "Home Projects",
    "Family"
  ];
  
function Aside(props) {

    let menu =  localStorage.getItem('menuseq');
    let  initialMenu = localStorage.getItem('menuseq')?JSON.parse(localStorage.getItem('menuseq')):sidebarMenuItems.map(item=>{return item.title})
    const defSeq = sidebarMenuItems.map(item=>{return item.title});

    const [ids, setIds] = useState([]) ;
    const license = props.license


    useEffect(()=>{
          
          let dashboard = props.permission.webapp_health_management_page?props.permission.webapp_health_management_page:"False"
          let site = props.permission.webapp_site_management_page?props.permission.webapp_site_management_page:"False"
          let user = props.permission.webapp_manpower_management_page?props.permission.webapp_manpower_management_page:"False"
          let cycle = props.permission.webapp_manpower_management_page?props.permission.webapp_manpower_management_page:"False"
          let policy = props.permission.webapp_policy_management_page?props.permission.webapp_policy_management_page:"False"
          let visitor = props.permission.webapp_visitor_management_page?props.permission.webapp_visitor_management_page:"False"                                  
          let productive = props.permission.web_app_productivity_management_page?props.permission.web_app_productivity_management_page:"False"                                   
          let geomap = props.permission.geo_map?props.permission.geo_map:"False"
          let report = props.permission.report?props.permission.report:"False"
          for (let i = 0; i < defSeq.length; i++) {
            if( (defSeq[i] =='Health Management' && dashboard =="True") ||
            (defSeq[i] =='Manpower Management' && user =="True") ||
            (defSeq[i] =='Site Management' && site =="True") ||
            (defSeq[i] =='Policy Management' && policy =="True") ||
            (defSeq[i] =='Visitor Management' && visitor =="True") ||
            (defSeq[i] =='Productivity Management' && productive =="True") ||
            (defSeq[i] =='Geo Map Management' && geomap =="True") ||
            (defSeq[i] =='Reports' && report =="True") ||
            (defSeq[i] =='Cycle Count' && cycle=='True' ) ){
              let reqMenu =   initialMenu.indexOf(defSeq[i])
                if(reqMenu == -1){
                    initialMenu.push(defSeq[i]);
                    console.log("push occur");
                }
            }  else{
                let nonreqMenu =   initialMenu.indexOf(defSeq[i])
                if(nonreqMenu > -1){
                    initialMenu.splice(nonreqMenu, 1)
                }
            }             
          }
          //localStorage.setItem('menuseq',JSON.stringify(initialMenu));
          setIds(initialMenu)
  

    },[props.permission])


    const handleSort = (ids) => {
      
        localStorage.setItem('menuseq',JSON.stringify(ids));

        console.log('menuseq ',JSON.parse(localStorage.getItem('menuseq')));
        
        setIds(ids);
    };
  
    const items = {
      8: "Hello 8",
      3: "Hello 3",
      4: "Hello 4",
      5: "Hello 5",
      6: "Hello 6"
    };
    const  getActiveTab = (eachMenuItem) => {



        let className = ''

        if (props.location.pathname.includes(eachMenuItem.paths)) {
            className = 'activeTab'
        }

        return className
    }


    

        return (
            <aside>

                <ul style={{ width: 200, margin: "0 auto" }}>
                {/* <DraggableList width={200} height={50} rowSize={1} className="ov-hidden">
                            {this.showMenuItems()}
          </DraggableList>
                     */}

                            <Sortable
                                wrapperElement="tr"
                                ids={ids}
                                items={sidebarMenuItems}
                                onSort={handleSort}
                                renderActive={(item, id, index) => <div>{item.title}</div>}
                                >
                                {ids?.map((id,index) => {
                                    let element = sidebarMenuItems.find(item=> item.title=== id);
                                    let arr;
                                    if( element  ) {
                                        let content = null
                        
                                        if(element.status=='active'){
                                        content = <Link to={element.paths} onClick={()=>props.closeMenu()} >
                                            <img src={getActiveTab(element) !== '' ? element.activeIcon : element.defaultIcon} alt="" title={element.title} />
                                            <span className={getActiveTab(element)}>
                                                {getTranslatedText(element.title)}
                                            </span>
                                        </Link>
                                        }else{
                                            content =<a href="#" className="disabled-anchor">
                                                            <img src={element.defaultIcon} alt="" title={element.title} />
                                                            <span class="">{element.title}</span></a>
                                        }
                        
                                        arr= <div className={element.status=='inactive'?"disabled-li":getActiveTab(element)} key={"menu"+index}>{content}</div>
                                        
                                    }
                    

                                    const row = items[id];


                                    return (
                                    <Sortable.Item as="li" key={"sortableItem"+id} id={id} >
                                        {arr}
                                    </Sortable.Item>
                                    );
                                })}
                                </Sortable>
{/* 
                             <Sortable
                                wrapperElement="tr"
                                ids={ids}
                                items={items}
                                onSort={handleSort}
                                renderActive={(item, id, index) => <td>{item}</td>}
                                >
                                {ids?.map((id) => {
                                    const row = items[id];
                                    return (
                                    <Sortable.Item as="tr" key={id} id={id}>
                                        <td>{row}</td>
                                    </Sortable.Item>
                                    );
                                })}
                                </Sortable> */}
                </ul>
            
            </aside>
        )
    
}

export default withRouter(Aside)