import React, { useState, useEffect,useRef,useLayoutEffect } from "react";

import { Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import DatePicker from "react-datepicker";
import { Switch } from "antd";
import moment from "moment";
import { Scrollbars } from "react-custom-scrollbars";
import "../../../../siteManagement/styles/siteManagement.scss";
import "../../../../manPowerManagement/style/manpowerManagement.scss"
import spinnerLoader from "../../../../assets/images/Spinner Loader.gif";
import CommonDatePicker from "../../../../common/commonDatePicker";
import { getTranslatedText } from "../../../../common/utilities";
import {
  getLanguageTranslation,
  setSelectedLanguage,
} from "../../../../dashboard/actionMethods/actionMethods";
import { prepareDateObj } from "../../../../dashboard/components/helper";

import downArrowFill from "../../../../assets/images/down-arrowFill.png";
import DashboardLanguage from "../../../../components/dashboardLanguage";
import batteryIcon from "../../../../assets/traceplusImages/battery.svg";
import emailIcon from "../../../../assets/traceplusImages/employee_email_icon.svg";
import dropdownIcon from "../../../../assets/images/down-arrow.png";
import selectedPinkArrowIcon from "../../../../assets/traceplusImages/pink_right_arrow_icon.svg";
import empIDIcon from "../../../../assets/traceplusImages/employee_id_icon.svg";
import helpIcon from "../../../../assets/traceplusImages/help-icon.png";
import sortIcon from '../../../../assets/traceplusImages/sort.png'
import upIcon from '../../../../assets/traceplusImages/up-arrow.png'
import downIcon from '../../../../assets/traceplusImages/down-arrow.png'
import '../../../styles/policyCompliance.scss'
import { Checkbox, Button, Select } from 'antd';
import {getPolicyOverview} from '../../../actionMethods/actionMethods';

import Modal from 'react-modal'
import DeviceStatus from "../../../../components/DeviceStatus";
import {ReactComponent as Image404 } from  '../../../../assets/images/11132-ai.svg'

function PolicyComplianceLocation(props) {
  let date = localStorage.getItem("selectedDate") ? new Date(localStorage.getItem("selectedDate")) : new Date();
  let interval = 5;
  let idVal = props.match.params.id.replace(":", "");
  const queryString = window.location.href;
  let idVal2 =decodeURI(queryString.split('/:')[1]);
  
  const [employeeDetails, updateEmployeeDetails] = useState("");
  const [prevEmployeeDetails, updatePrevEmployeeDetails] = useState("");

  const [selectedLangValue, updateSelectedLangValue] = useState("en");
  const [infectedFlag, updateInfectedFlag] = useState(false);

  const [employeeIndexData, updateEmployeeIndexData] = useState("");
  const [employeeData, setEmployeeData] = useState("");
  const [prevemployeeData, setprevEmployeeData] = useState("");
  const [employeeHistoryData, setEmployeeHistoryData] = useState('');

  const [selectedDate, updateSelectedDate] = useState(date);
  const [dates, setDates] = useState({
    start: new Date(moment(date).format('YYYY-MM-DD 23:59:59')),
    end: new Date(moment(date).format('YYYY-MM-DD 23:59:59')),
  });
  const[tagID,SetTagID]=useState('');
  const [chartData, setChartData] = useState({ series: [], categories: [] });
 // const [testedPositiveDate, updateTestedPositiveDate] = useState(null);

    const [sortKey, setSortKey] = useState('access')
    const [sortType, setSortType] = useState('desc')

    const [testedPositiveDate, updateTestedPositiveDate] = useState(date);
    const [chartLoader, setChartLoader] = useState(true);

    const[dataCapacity,SetDataCapacity]=useState([]);
    const[dataAccess,SetAcessCapacity]=useState([]);
  
    const [sortKeyaccess, setSortKeyaccess] = useState('access')
    const [sortTypeaccess, setSortTypeaccess] = useState('desc')
  
    const [sortKeycapacity, setSortcapacity] = useState('capacity')
    const [sortTypecapacity, setSortTypecapacity] = useState('desc')

  
  
  const[SelectedEmployee,SetSelectedEmployee]=useState([])
  const[IsAllEmployee,SetIsAllEmployee]=useState(false);
  const[SelectedTeam,SetSelectedTeam]=useState([]);
  const[IsAllTeam,SetIsAllTeam]=useState(true);
  const [LocationData,SetLocationData]=useState([]);
  //const[selected,SetSelected]=useState([]);
  const [selected, setSelected] = useState([])
  const [loader,SetLoader]=useState(true)
  const[LocationName,SetLocationName]=useState([]);
  const [capacityBreach,setCapacityBreach]=useState([]);
  const [accessBreach,setAccesBreach]=useState([]);
  const [teamData,SetTeamData]=useState([]);
  let userDetails = JSON.parse(localStorage.getItem("userLoginDetails"));
  let userSession = userDetails ? userDetails.session : "123456789";
  let org_id = userDetails ? userDetails.org_id : 6;
  const [PrevCapacityBreach,SetPrevCapacityBreach]=useState([]);
  const [PrevAcessBreach,SetPrevAcessBreach]=useState([]);
  const [TempAcess,SetTempAcess]=useState([])
  const [TempCapacity,SetTempCapacity]=useState([])

  const [heightDiv, setHeight] = useState(0);
  const elementRef = useRef(null);

  const [role,SetRole] = useState([]);
  const [permission,SetPermission] =useState([])
  const [permissionAPICall,SetPermissionAPICall] = useState(false);


  




    let Height= window.innerHeight - 100;
    const initialHeight = Height >810?Height:810; 
    const leftheight = initialHeight - 310;

    
	 useEffect(() => {
    let perm= props.role.policy_management?JSON.parse(props.role.policy_management):null;
    if(perm){

       //console.log(perm);
        // perm.View = 'False'
        // perm.Create = 'False'
        // perm.remove = 'False'
        // perm.edit = 'False'
        SetPermission(perm)
        SetPermissionAPICall(true)
    }else{
        //console.log("undefined",perm);
    }
},[props.role]);

    //const teamData=[];
  const EMPDATA =[
      {
          name:"Bruce Banner",
          team:"Gamma Team Resarch",
          startdate:"13-11-2021",
          enddate:"15-11-2021",
      }
  ]








  


  useEffect(() => {
 
  }, [dates]);

  useEffect(() => {
    if (props.language) {
      updateSelectedLangValue(props.language);
    }
  }, [props.language]);

  function changeLanguage(lang) {
    getLanguageTranslation(lang).then((res) => {
      if (res && res.status >= 200 && res.status <= 200) {
        localStorage.setItem("languageData", JSON.stringify(res.data));
        localStorage.setItem("selectedLanguage", lang);
        props.setSelectedLanguage(lang);
      }
    });
  }
  
useEffect(()=>{
  if(permission && permission.View=='True'){
    DateChangeHandler()
  }
},[dates,permission])

useEffect(()=>{

  let arr=[];
  let arrTemp=[];
  let sel = [...SelectedTeam];
  let LocationCheck=[];
  //console.log("sel");
  //console.log(sel);
 //console.log(dataCapacity);
  for (let i=0;i<dataCapacity.length;i++){
    if((IsAllTeam || sel.includes(dataCapacity[i].team))  && parseInt(dataCapacity[i].occurence) > 0){
        arr.push({
          team:dataCapacity[i].team,
          name:dataCapacity[i].name,
          occurence:dataCapacity[i].occurence 
        })


    } 

  }
  ////console.log(arr);
  SetTempCapacity(arr);
////console.log(TempCapacity)



},[dataCapacity,SelectedTeam])

useEffect(()=>{

  let arr=[];
  let arrTemp=[];
  let sel = [...SelectedTeam];
  let LocationCheck=[];
  ////console.log("sel");
  ////console.log(sel);
  ////console.log(dataAccess);
  for (let i=0;i<dataAccess.length;i++){
    if((IsAllTeam || sel.includes(dataAccess[i].team))  && parseInt(dataAccess[i].occurence) > 0){
        arr.push({
          team:dataAccess[i].team,
          name:dataAccess[i].name,
          occurence:dataAccess[i].occurence 
        })
    } 

  }
  ////console.log(arr);
  SetTempAcess(arr);
  
},[dataAccess,SelectedTeam])

  function capitalizeFirstLetter(string) {
    if(string){
    return string.toLowerCase();
    }
  }

  const handleDate = (date, type) => {
    setDates((prev) => ({ ...prev, [`${type}`]: date }));
   
  };

  function DateChangeHandler(){
    if(permission && permission.View=='True'){
    SetLoader(true);
    let requestBody=[]
    requestBody.start_date=getDateFormat(dates.start);
    requestBody.end_date=getDateFormat(dates.end);
        getPolicyOverview(requestBody,userSession, org_id,'loc_team').then(res => {

 
            if (res.status === 200) {
                let data= res.data;
                let dataAcPush=[];
                let dataTeamName=[];
                let dataCaPush=[]
              Object.keys(data).forEach((key) => {
                //console.log("Brazing shopfloor #2",idVal,key,idVal2);
                let breach=0;
                if(key==idVal || idVal2 == key){
                  SetLocationName(key)
                  SetTagID(data[key].loc_serial);
                  ////console.log(data[key])
                //accesability
                
                  let accesability=data[key].accesability; 
                  let teamEMP=[];
                  Object.keys(accesability).forEach((key) => {
                  // //console.log(key);
                  // //console.log(accesability);
                  //key contain Team Name
 
                    for ( let i=0;i< accesability[key].employee.length;i++){
                      
                      let element=accesability[key].employee[i] 
                      dataAcPush.push({team:key,name:element.name,occurence:element.count})
                      breach = breach + element.count;
                    }
                    dataTeamName.push({name:key,occurence:breach}); 
                });

                let capacity=data[key].capacity;
                Object.keys(capacity).forEach((key) => {
  
                  for ( let i=0;i< capacity[key].employee.length;i++){
                    let element2=capacity[key].employee[i] 
                    dataCaPush.push({team:key,name:element2.name,occurence:element2.count})
                    breach = breach + element2.count;
  
                  }
                  dataTeamName.push({name:key,occurence:breach});
                });
                SetTeamData(dataTeamName);
                setAccesBreach(dataAcPush);
                setCapacityBreach(dataCaPush);
                SetDataCapacity(dataCaPush);
                SetAcessCapacity(dataAcPush);       
            
  
               // //console.log(data[key].capacity)
              
  
                ////console.log(employeeData) ;            
                SetLoader(false)  
                ////console.log(dataTeamName)
              }else{
  
              }
              })
              SetLoader(false);
            }          
        });
      }
  }

  function getDateFormat(date) {
    return moment(date).format("YYYY-MM-DD");
  }

  function handleManpowerManagementList() {
    props.history.push("/policy-management");
  }

  function onChangeCheckbox(e, index) {
    let arr = [...SelectedEmployee]
    ////console.log("index", index);
    SetIsAllTeam(false)
    if (SelectedEmployee.includes(index)) {
        let i = arr.indexOf(index)
        arr.splice(i, 1)
        SetSelectedEmployee(arr)
    } else {
        arr.push(index)
        SetSelectedEmployee(arr)
    }
  }




  function onChangeCheckboxTeam(e, index) {

    let arr = [...SelectedTeam]
    ////console.log("index", index);
    SetIsAllTeam(false);
    if(e.target.checked &&SelectedTeam.includes(index)){
      ////console.log("Condition 1");
      SetSelectedTeam(arr)
      //console.log("cond team 1")
    }else if(e.target.checked){
      arr.push(index)
     // //console.log("Condition 2");
      SetSelectedTeam(arr)
      //console.log("cond team 2")
 
    }else if(SelectedTeam.includes(index) && e.target.checked===false){
      let i = arr.indexOf(index)
      arr.splice(i, 1)
      //console.log("cond team 3")
      ////console.log("Condition 3");
      SetSelectedTeam(arr)
    }
    else {
     // //console.log("Condition 4");
        arr.push(index)
        //console.log("cond team 4")
        SetSelectedTeam(arr)
    }
  }


  

  function onChangeAllCheckbox(e, type) {
    // alert(e.target.checked)
    if (e.target.checked && type=="Employee") {
        let arr = Array.from({ length: type === 'Employee' ? dataCapacity.length : dataCapacity.length }, (v, i) => i);
        SetIsAllEmployee((prev) => !prev)
        SetSelectedEmployee(arr)
        //console.log("1 Condition")
       
    }else if(e.target.checked && type=="Team"){
      let arr = []
      for(let i=0 ;i<teamData.length;i++){
        arr.push(teamData[i].name)
      }
      SetIsAllTeam((prev) => !prev)
      SetSelectedTeam(arr)
      //console.log("2 Condition")

    } else if(type=="Employee") {
        SetIsAllEmployee(false)
        SetSelectedEmployee([])
        //console.log("3 Condition")
    }else if(type =='Team'){
      SetIsAllTeam(false)
      SetSelectedTeam([])
      //console.log("4 Condition")
      
    }

}

  

  function handleDateSelect(date) {
    updateSelectedDate(date);

    setDates({
      start: new Date(moment(date).format('YYYY-MM-DD 23:59:59')),
      end: new Date(moment(date).format('YYYY-MM-DD 23:59:59')),
    });

  }








  const handleMouseEnter = (id) => {
    let doc = document.getElementById(id);

    if (doc) {
      doc.style.display = "block";
    }
  };

  const handleMouseLeave = (id) => {
    let doc = document.getElementById(id);

    if (doc) {
      doc.style.display = "none";
    }
  };






  const handleSort = (key) => {
    
    setSortKey(key)
    setSortType(sortType === 'desc' ? 'asc' : 'desc')
  }

  

  function showMostCapacityBreach(data) {
    let arr = [];
    let arr2=[];
    for (let index = 0; index < data.length; index++) {
      const element = data[index];
   if(element.occurence > 0){
      arr.push(
            <tr className="employee-detail-table-tr" key={generateKey(index)}>
              {/* <td style={{width:"30px"}}><Checkbox onChange={(e) => onChangeCheckbox(e, index)} checked={IsAllEmployee || SelectedEmployee.includes(index)} /></td> */}
                <td style={{width:"75%"}}>{capitalizeFirstLetter(element.name)}</td>               
                <td className="employee-detail-table-tr-td occurence-center" style={{width:"25%"}}>{element.occurence}</td>

            </tr>
      );
    }
  }
    return arr;
  }



function showTeamMemberData(data){
    let arr = [];
    let arr2=[];

    for (let i=0;i<data.length;i++){
      let item  = arr2.find(item => item.name === data[i].name); 
      if(item){
        
      } else{
        arr2.push(data[i]);
      }  
    
    }

    for (let index = 0; index < arr2.length; index++) {
      const element = arr2[index];
   if(element.occurence > 0){
    arr.push(
      <tr className="employee-detail-table-tr" key={generateKey(index)}>   
          <td className="employee-detail-table-tr-td" style={{maxWidth:"30px",width:"30px"}}>
          <Checkbox onChange={(e) => onChangeCheckboxTeam(e, element.name)} checked={IsAllTeam || SelectedTeam.includes(element.name)} />
          </td>
          <td>{capitalizeFirstLetter(element.name)}</td>
          <td className="breached-data"><span className="breached-member violated">Breached</span></td>
      </tr>
);
   }else{
      arr.push(
            <tr className="employee-detail-table-tr" key={generateKey(index)}>   
                <td className="employee-detail-table-tr-td" style={{maxWidth:"30px",width:"30px"}}>
                <Checkbox onChange={(e) => onChangeCheckboxTeam(e, element.name)} checked={IsAllTeam || SelectedTeam.includes(element.name)} />
                </td>
                <td>{capitalizeFirstLetter(element.name)}</td>
                <td className="breached-data"><span className="breached-member non-violated">Not Breached</span></td>
            </tr>
      );
   }




    }
    return arr;
}


  const generateKey = (pre) => {
    return `${ pre }_${ new Date().getTime() }`;
}

useEffect(()=>{
  showMostCapacityBreach(TempCapacity)
},[TempCapacity])
  
useEffect(()=>{
  showMostAccessBreach(TempAcess)
},[TempAcess])

  function showMostAccessBreach(data) {
    let arr = [];
    let arr2=[];
    let arr3 = [...SelectedTeam]
    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      if(element.occurence > 0){
        arr.push(
          <tr className="employee-detail-table-tr" key={generateKey(index)}>   
              <td>{capitalizeFirstLetter(element.name) } </td>
              <td className="employee-detail-table-tr-td occurence-center" style={{width:"25%"}}>{element.occurence}</td>
          </tr>
          );
        }
      
    }

    return arr;
  }

function handleSortcapacity(){
   
    setSortTypecapacity(sortTypecapacity=='desc'?'asc':'desc');
    
}
function handleSortAcess(){
    setSortTypeaccess(sortTypeaccess=='desc'?'asc':'desc')
}

useEffect(() => {
    let arr=[];
    arr = TempCapacity.sort((a,b) => {
        return  sortTypecapacity=='desc'? b.occurence - a.occurence  : a.occurence - b.occurence
     })
     SetTempCapacity(arr); 
    // //console.log(arr);    
  }, [sortTypecapacity]);


  useEffect(() => {
    let arr=[];
    arr = TempAcess.sort((a,b) => {
        return  sortTypeaccess=='desc'? b.occurence - a.occurence  : a.occurence - b.occurence
     })
     SetTempAcess(arr)
  }, [sortTypeaccess]);


  
  if(permissionAPICall && permission.View=="False"){
    return(<Col lg={12}>
      
  <div className='center404'> <Image404 /><h4 style={{marginTop:"-30px",color:"#2e3552"}}>Access Restricted to this page, Please contact administrator</h4></div>
  </Col>)

  }

  if (capacityBreach || accessBreach) {
 
    return (
      
      <div className={loader===true?"manpowerManagementMainDiv siteViewMainDiv  policy-compliance-location loading":"manpowerManagementMainDiv siteViewMainDiv  policy-compliance-location"}
      >
           <div className="loading-div">
                  <img src={spinnerLoader}/>
             </div>
        <div className="employeeDetailsMainDiv">
          <Container className="header-breadcrumb">
              
            <Row>
              <Col lg={5} className="m-t-sm">
                <div className="siteViewHeaderDiv">
                  <span
                    className="smallHeader"
                    onClick={handleManpowerManagementList}
                  >
                    {getTranslatedText("Policy Compliance")}
                  </span>
                  <span className="breadCrumbArrow"> &gt; </span>
                  <span className="mediumHeader">
                    {getTranslatedText("Group")}
                  </span>
                </div>
              </Col>

              <Col lg={7} className="text-right">
              <div className="dashboardLanguageMainDiv m-r-md">
                            <DashboardLanguage
                                selectedLangValue={selectedLangValue}
                                changeLanguage={changeLanguage}
                            />
                        </div>
                        <div className="commonHeadingDateMainDiv">
                            <CommonDatePicker
                                selectedDate={selectedDate}
                                handleSelectDate={handleDateSelect}
                            />
                        </div>

                        <DeviceStatus permission={permission} role={props.role} />
              </Col>
            </Row>
            <div className="mostInteractedMainDiv mostInteractedMainDiv2 m-t-lg" style={{height:810,minHeight:initialHeight}}>
            <div className="m-b">

          <Row>
              <Col lg={12}>
                  <div className="compliance-header" style={{ marginBottom: 20 }}>
                  <Row>
                    <Col lg={7}>
                    <h5 className="font-bold">{LocationName}</h5>
                    <p>Tag ID {tagID}</p>
                    </Col>
                    <Col lg={1}>
                    <div className="alertDiv" style={{float:"right"}}>
                              <div className="viewAllEmployeesButton disabledBtn">
                                Alert
                              </div>
                            </div>
                   
                      </Col>
                    <Col lg={2} className="p-r-0 p-l-0">
                            <div
                              className="startDateEndDateMainDiv date-start"
                              style={{ paddingLeft: "0px" }}
                            >
                              <label>Start Date</label>
                              <DatePicker
                                selected={dates.start}
                                onChange={(date) => handleDate(date, "start")}
                                dateFormat={'MMM dd'}
                                isClearable={false}
                                placeholderText={getTranslatedText(
                                  "Start Date"
                                )}
                                maxDate={moment(dates.end).toDate()}
                                minDate={moment().subtract(120, "days").toDate()}
                              />
                              {/* <div className="dropdownIconDiv">
                                <img src={dropdownIcon} />
                              </div> */}
                            </div>
                          </Col>
                          <Col lg={2} className="p-l-0">
                            <div
                              className="startDateEndDateMainDiv date-end"
                              style={{ paddingLeft: "0px" }}
                            >
                              <label>End Date</label>
                              <DatePicker
                                selected={moment(dates.end).toDate()}
                                onChange={(date) => handleDate(date, "end")}
                                dateFormat={'MMM dd'}
                                isClearable={false}
                                placeholderText={getTranslatedText("End Date")}
                                maxDate={selectedDate}
                                minDate={dates.start}
                              />
                             
                            </div>
                          </Col>
                         </Row> 
                  </div>
              </Col>
          </Row>

          <div class="border-outer pad-10">
          <div class="top-title">   <h6>Team</h6></div>
                      <Row style={{ margin: 0 }}>
                       <Col className="p-l-0" lg={6}>
                       <Col className="p-l-0 p-r-0 ov-hidden" lg={12}>
                        
                          <table className="tableBox" ref={elementRef}>
                            <tr className="employee-detail-table-tr">
                              <th style={{width:"30px"}}>
                                <Checkbox onChange={(e) => onChangeAllCheckbox(e, 'Team')} checked={IsAllTeam} />
                              </th> 
                              <th>
                                
                                Name
                                <img
                                  src={helpIcon}
                                  alt=""
                                  onMouseEnter={() =>
                                    handleMouseEnter(`interHelp`)
                                  }
                                  onMouseLeave={() =>
                                    handleMouseLeave(`interHelp`)
                                  }
                                />
                                <span className="descHelp" id="interHelp">
                                List of Employee 
                                </span>

                              </th>
                            
                            </tr>
  
                            <Scrollbars
                              autoHide
                              style={{ width: "100%", height: 510, minHeight:leftheight }}
                                
                            >
                              {showTeamMemberData(teamData)}
                            </Scrollbars>
                        
                            
                          </table>
                        </Col>

                       
                        </Col>

                        <Col className="p-l-0 p-r-0" lg={6}>
                        <Row style={{ margin: 0 }}>
                         <Col className="p-l-0 p-r-0 ov-hidden" lg={12} style={{marginBottom:20}}>   

                           <div className="top-title"><h6>Capacity breach events</h6></div>
                          <table className="tableBox">
                            <tr className="employee-detail-table-tr">
                              <th style={{width:"65%"}} >
                              Employee
                                <img
                                  src={helpIcon}
                                  alt=""
                                  onMouseEnter={() =>
                                    handleMouseEnter(`CapacityinterHelp`)
                                  }
                                  onMouseLeave={() =>
                                    handleMouseLeave(`CapacityinterHelp`)
                                  }
                                />
                                <span className="descHelp" id="CapacityinterHelp">
                                List of Employee 
                                </span>

                              </th>
                              <th className="employee-detail-table-tr-td occurence-center" style={{width:"35%"}}>
                                Occurence
                                <img
                                  alt=''
                                  className='sorticon'
                                  src={sortTypecapacity === 'asc' ? upIcon : downIcon }
                                  onClick={() => handleSortcapacity('capacity')}
                                />
                              </th>
                            </tr>
                            <Scrollbars
                              autoHide
                              style={{ width: "100%", height: 165, minHeight:(leftheight/2)- 94   }}
                            >
                            
                              {/* {showMostCapacityBreach(capacityBreach)} */}
                              {showMostCapacityBreach(TempCapacity)}
                            </Scrollbars>

                
                          </table>
                       
                       
                       
                        </Col>

                        <Col className="p-l-0 p-r-0 ov-hidden" lg={12}>
                        <div className="top-title">   <h6>Access breach events</h6></div>
                          <table className="tableBox">
                            <tr className="employee-detail-table-tr">
                              <th style={{width:"65%"}}>
                              Employee
                                <img
                                  src={helpIcon}
                                  alt=""
                                  onMouseEnter={() =>
                                    handleMouseEnter(`AccessinterHelp`)
                                  }
                                  onMouseLeave={() =>
                                    handleMouseLeave(`AccessinterHelp`)
                                  }
                                />
                                <span className="descHelp" id="AccessinterHelp">
                                List of Employee 
                                </span>

                              </th>
                              <th className="employee-detail-table-tr-td occurence-center" style={{width:"35%"}}>
                                Occurence
                                <img
                                  alt=''
                                  className='sorticon'
                                  src={sortTypeaccess === 'asc' ? upIcon : downIcon }
                                  onClick={() => handleSortAcess('access')}
                                />
                              </th>
                            </tr>
                            <Scrollbars
                              autoHide
                              style={{ width: "100%", height: 165,minHeight:(leftheight/2)- 80    }}
                            >
                              {/* {showMostAccessBreach(accessBreach)} */}
                              {showMostAccessBreach(TempAcess)}
                            </Scrollbars>

                            
                          </table>
                        </Col>
                        </Row>              
                        </Col>
                        </Row>
                   </div>     
                        <div style={{marginTop:"10px"}}>
                        
                    
                     </div>
                      
                    </div>
             </div>
           
          </Container>
        </div>
      </div>
    );
  } else {
    return (
      <div className="text-center m-t-lg">
        <img src={spinnerLoader} className="m-t-lg" />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  language: state.dashboard.selectedLangaugeValue,
});

export default connect(mapStateToProps, { setSelectedLanguage })(
  withRouter(PolicyComplianceLocation)
);
