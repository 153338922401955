
import React, { useRef, useState,useEffect } from 'react';
import { Container, Row, Col, Dropdown, DropdownButton } from "react-bootstrap";
import { Button } from "antd";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import { withRouter } from "react-router-dom";

import { CommonHeading } from "../../common/commonHeading";
import DashboardLanguage from "../../components/dashboardLanguage";
import '../../manPowerManagement/style/manpowerManagement.scss';

import CommonDatePicker from "../../common/commonDatePicker";

import helpIcon from "../../assets/traceplusImages/help-icon.png";
import dropdownIcon from "../../assets/images/down-arrow.png";
import mediumRiskIcon from "../../assets/traceplusImages/medium_risk_icon.svg";
import spinnerLoader from "../../assets/images/Spinner Loader.gif";

import moment from "moment";
import { getTranslatedText } from "../../common/utilities";
import {
  getLanguageTranslation,
  setSelectedLanguage,
} from "../../dashboard/actionMethods/actionMethods";
import ReactModal from "react-modal";
import '../../cycleCount/styles/style.scss'
import {ReactComponent as Plus} from  '../../assets/images/caret-right.svg'
import {ReactComponent as Minus} from '../../assets/images/caret-down.svg'
import {ReactComponent as Eye} from '../../assets/images/eye.svg'
import Scrollbars from 'react-custom-scrollbars';
//import { getSiteLocations } from '../../siteManagement/actionMethods/actionMethods';
import { getPproductivitylist } from '../../productivity/actionMethods/actionMethods';

import {ReactComponent as Image404 } from  '../../assets/images/11132-ai.svg'

import {AgGridColumn, AgGridReact} from 'ag-grid-react';

import {getCycleCount} from '../../cycleCount/actionMethods/actionMethods'

const riskLevelColor = {
  low: "#04e06e",
  medium: "#ffd700",
  high: "#ffa500",
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: 1100,
    maxWidth: "80%",
    height: "650",
  },
};


function CycleCount(props) {
  let date = localStorage.getItem("selectedDate")
    ? new Date(localStorage.getItem("selectedDate"))
    : new Date();

  const [selectedDate, updateSelectedDate] = useState(date);
  const [selectedLangValue, updateSelectedLangValue] = useState("en");

  const [PriData, updatePRIData] = useState("");
  const [prevPriData, updatePrevPriData] = useState("");

  const [selectedTab, updatedSelectedTab] = useState("employees");
  const [dataCountCycle,SetDataCountCycle]= useState([])

 const [location,SetLocation] = useState([]);
 
 const [loader,SetLoader]=useState(true);

const [employee,SetEmployee] =useState([]);
const [permission,SetPermission]=useState({View:"False",Create:"False",edit:"False",remove:"False"});
const [permissionAPICall,SetPermissionAPICall]=useState(false);
  
 const [parentAccord,SetParentAccord]=useState([]);
 const [modalData,SetModalData]=useState([]);
 const [showModal,SetShowModal]=useState(false);

 //const [dataAll,SetDataAll] =useState([]);
 const [agDataHour,SetAgDataHour]=useState([]);

  let userDetails = JSON.parse(localStorage.getItem("userLoginDetails"));
  let userSession = userDetails ? userDetails.session : "123456789";
  let org_id = userDetails ? userDetails.org_id : 6;
  let lastReqId = null
  
  function getRandomItem(arr,no=5) {
    let data=[];
    for(let i=0;i<no;i++){
      const randomIndex = Math.floor(Math.random() * arr.length);
      data.push(arr[randomIndex]) ;

    }
    return data;
}
function GetRandomDigit(min=0,max=10){
  return  Math.floor(Math.random() * (max - min + 1)) + min;
}

const [columnDefs, setColumnDefs] = useState([]);

  function handleDateSelect(date) {
    updateSelectedDate(date);

    let startDate = new Date().setDate(new Date().getDate() - 29);
    let endDate = new Date(moment(date).format('YYYY-MM-DD 23:59:59'));

    //setChartDates({ start: startDate, end: endDate });
  }

  function getDateFormat(date) {
    return moment(date).format("YYYY-MM-DD");
  }


  useEffect(() => {
    let perm= props.role.manpower_management?JSON.parse(props.role.manpower_management):null;
    if(perm){



        SetPermission(perm)
        SetPermissionAPICall(true)
    }else{

    }
},[props.role]);

useEffect(()=>{
  SetLocation(props.locationList);
  let loc = props.locationList;
  
  getCycleCount(userDetails.session,org_id,getDateFormat(selectedDate),'five_min').then(res=>{
  
    let dataformat=[];
    let hourpush=[];
    let data=res.data;
    Object.keys(data).forEach((key)=>{
      let allData= data[key]
      let singleCount={};
      let emp=[];

      let dataNew = loc.find(item => item.loc_tag == key);
    
      singleCount.name= dataNew.name;
      singleCount.data=[];
      
      for(let y=0;y<24;y++){
        for(let z = 5; z <=60; z = z + 5){
          let singleRecord={}
          singleRecord.hour = `${y <10?"0"+y:y}:${z <10?"0"+z+":00":z+":00"}`;
           let matchingHour = `${y <10?"0"+y:y}:${z <10?"0"+z:z}`;
  
          if(hourpush.indexOf(singleRecord.hour)== -1){
            hourpush.push(singleRecord.hour);
          }
  
  
          singleRecord.plan=GetRandomDigit();
          let cycleCoun =getRandomItem(employee,GetRandomDigit(0,2));
          singleRecord.actualWorkerList= cycleCoun;
          singleRecord.actualWorker= cycleCoun.length;
                          

                  let hour = y<10?"0"+y+":00":y+":00"
                  let plan = allData.plan.find(item =>item.hour == hour);
                  console.log("plan push",dataNew.name,plan,y<10?"0"+y:y);
                  
                  if(plan){
                    
                    singleRecord.plan= plan.cycle_count
                  }else{
                    singleRecord.plan= 0
                  }
                  let actual = allData.actual.find(item =>item.contact_time == matchingHour);



                 if(actual){

                  singleRecord.actualWorker=actual.cycle_count;
                  singleRecord.actualWorkerList= actual.nodes;


                 }else{
                  singleRecord.actualWorker=0;
                  singleRecord.actualWorkerList=[];
                 }
                 singleCount.data.push(singleRecord);
        } 
     
    }

    dataformat.push(singleCount);

    console.log("check data",dataformat);

let agData=[];
for(let j=0;j<hourpush.length;j++){
  let singleData = {};
  for(let i= 0 ;i<loc.length;i++){
    singleData.hour = hourpush[j];
    let paticularlocData= dataformat.find(item=>item.name == loc[i].name)          
    if(paticularlocData){
      
      singleData[`${slugify(loc[i].name)}_actual`] = paticularlocData.data[j].actualWorker
      singleData[`${slugify(loc[i].name)}_planned`] = paticularlocData.data[j].plan
    }

    
  }
  agData.push(singleData);

}

SetAgDataHour(agData);
SetDataCountCycle(dataformat);
  
  
    });     
  
  
    
  })
},[props.locationList])
useEffect(() => {
  let requestBody={}
    requestBody.date= getDateFormat(date);



    let arr=[];

    requestBody.startdate=getDateFormat(selectedDate)
    requestBody.enddate=getDateFormat(selectedDate)
    requestBody.slug='employee'
    let dept=[]
    let catIndex=0;
    let empList= [];
    getPproductivitylist(requestBody,userDetails.session,org_id).then(res=>{
     
      if (res.status==200){
    
        Object.keys(res.data).forEach((key) => {
          
          let element=res.data[key];
          let child= [];
          let childName='';      
          let parentLength=0;
          let subdept=[]
          Object.keys(element).forEach((key2) => {
        
            if(isInt(key2)){
          
              
            }else{
              
              childName=key2  
              subdept.push(key2);
              for (let c = 0;c<element[key2].length;c++){
                parentLength++;
                element[key2][c].department=key;
                element[key2][c].sub_department=key2;

                //delete thisIsObject[key]; 
                empList.push(element[key2][c]);
              }
              child.push({name:key2,data:element[key2] })
            }

          });

          if(childName!==''&& Number.isInteger(childName) ===false ){
            arr.push({name:key,child:child})   
            dept.push({name:key,sub_department:subdept}) 
          }else{

            dept.push({name:key,sub_department:subdept}) 
            for (let i=0;i<element.length;i++){
              element[i].department=key;
              element[i].sub_department='';
              delete element[i]['access_policy_breach']; 
              delete element[i]['less_productive']; 
              delete element[i]['non_productive']; 
              delete element[i]['productive']; 
              delete element[i]['undefined']; 
              delete element[i]['wasted_time']; 
              empList.push(element[i]);
            }
            arr.push({name:key,data:element})            
          }
         });


      }

      SetEmployee(empList)      
    })



},[props.role]);

function isInt(value) {
  return !isNaN(value) && (function(x) { return (x | 0) === x; })(parseFloat(value))
}


function slugify(string) {  
  return string.toString().toLowerCase().replace(/[^a-zA-Z0-9]/g,'_');
}



// useEffect(()=>{
//     let dataformat=[];
//     let hourpush=[];
//     if(permission.View=="True" && location.length > 0 && employee.length> 0){
     
//     for(let i=0;i<location.length;i++){
//       let singleCount={};
//       let emp=[];
//           singleCount.name= location[i].name;
//           //singleCount.avg_cycle_count = GetRandomDigit(10,20);
//           singleCount.data=[];
//           for(let y=0;y<24;y++){
//             for(let z = 5; z <=60; z = z + 5){
//               let singleRecord={}
//               singleRecord.hour = `${y <10?"0"+y:y}:${z <10?"0"+z+":00":z+":00"}`;
              

//               if(hourpush.indexOf(singleRecord.hour)== -1){
//                 hourpush.push(singleRecord.hour);
//               }
//               singleRecord.planned=GetRandomDigit();
//               let cycleCoun =getRandomItem(employee,GetRandomDigit(0,2));
//               singleRecord.actualWorkerList= cycleCoun;
//               singleRecord.actualWorker= cycleCoun.length;
//               singleCount.data.push(singleRecord);
//             } 
//         }
//         dataformat.push(singleCount); 
//     }


    

//       let agData=[];
//       for(let j=0;j<hourpush.length;j++){
//         let singleData = {};
//         for(let i= 0 ;i<location.length;i++){
//           singleData.hour = hourpush[j];
//           let paticularlocData= dataformat.find(item=>item.name == location[i].name)          
          
//           singleData[`${slugify(location[i].name)}_actual`] = paticularlocData.data[j].actualWorker
//           singleData[`${slugify(location[i].name)}_planned`] = paticularlocData.data[j].planned
          
//         }
//         agData.push(singleData);

//     }
 
//     SetAgDataHour(agData);
//     SetDataCountCycle(dataformat);
//     }
//   },[permission,location,employee])

 
  



function AGGridEMptyMEssage(key){
  let msg;
      msg='<span class="ag-overlay-no-rows-center">No Records Found !</span>'
  return msg
}

function colorDataRenderer(params,locationName){
  var eGui = document.createElement('div');
  let plan =  params.data[`${slugify(locationName)}_planned`]   
  let act =  params.data[`${slugify(locationName)}_actual`]   
    
      
            
      if(plan > act){
            eGui.innerHTML = '<div class="redFont">'+params.value+'</div>';
          }else{
            eGui.innerHTML = eGui.innerHTML = '<div class="greenFont">'+params.value+'</div>';
          }
          return eGui;          
    }
    
  //params.data,params.value,location

  function ViewBoxModalHour(params,data,value,location){
    console.log(params,data,value,location,dataCountCycle);
    let hour = data.hour;
    let arr;    
    let value1 = dataCountCycle.find(item=>item.name==location);
    let value2 = value1?value1.data.find(item=>item.hour== hour):[];
    let hourData= [];
    
    if(value2){
      let allnode=value2.actualWorkerList.length===0?[]:JSON.parse(value2.actualWorkerList)
      for(let i =0 ;i<allnode.length;i++ ){
        let emp = employee.find(item=>item.tag_serial == allnode[i]);
        emp.sno =i+1
        hourData.push(emp);
      }   

      arr = ModalFormatData(hourData,location,hour);
    }
    
    SetModalData(arr);
    SetShowModal(true)
  } 
  
  function ModalFormatData(hourData,location_id ,hour){

    let arr =[];
    arr.push(
      <div className='titleArea'> 
        <h4>{location_id.toLowerCase()} ({hour}) <button className='alignRightFixed' onClick={()=>SetShowModal(false)}>x</button></h4>
      </div>
    )
    
    arr.push(AGTable(hourData))
    return arr;
    } 

    function AGTable(data){

      if(data){
    
        let widthCal= 0;
        
        if( ((window.innerWidth * 80)/100) > 1100){
          widthCal=((1100- 200)/3) ;
        }else{
          widthCal=((( (window.innerWidth * 80)/100) - 200)/3) - 10;
        }
      
      
      
      let arr;
        arr=<div className={"keyAGTable"}><div className="ag-theme-alpine if cell-size-40" style={{height: 60 + ((data.length > 0? data.length:1)*36),maxHeight:500 , width: "calc(100% - 1px)"}}>
          <AgGridReact
              rowHeight={35}
              autoGroupColumnDef={{
                  headerName: 'Name',minWidth: 50,field: 'name',
              }}
              headerHeight={35}
    
           defaultColDef={{sortable: true,resizable: true,minWidth:45, width:widthCal , suppressColumnVirtualisation:true,skipHeaderOnAutoSize:true}}           
              //pagination={true}
              rowData={data}
              key={'keyAGTable'}
              onCellClicked={ (event) => window.open(`/productivity/employee/:${event.data.tag_serial}`, "_blank")  }
              overlayNoRowsTemplate={
                  AGGridEMptyMEssage('')
                }        
           >
            
          <AgGridColumn
              field="sno"
              headerName={"S.No"}
              valueFormatter={(params)=>params.value}
              cellClass={'textCapitalize textCap'}
              width={75}
          />
            <AgGridColumn
              field="emp_name"
              headerName={"Name"}
              valueFormatter={(params)=>params.value.toLowerCase()}
              cellClass={'textCapitalize textCap'}
          />
          <AgGridColumn 
              field="department" 
              headerName="Department" 
              valueFormatter={(params) =>params.value.toLowerCase()}    
              cellClass={'textCapitalize textCap'}
             >
          </AgGridColumn> 
           <AgGridColumn
             field="sub_department"
             headerName="Sub Department"
             cellClass={'textCapitalize textCap'}
             valueFormatter={(params) =>params.value!=''?params.value.toLowerCase():'-'}    headerComponentParams =  {{
          }} ></AgGridColumn> 
          <AgGridColumn
             field="sub_department"
             headerName="View"
             cellClass={'textCapitalize textCap'}
             cellRenderer= {(params)=>viewIconRenderer(params)} 
             width={80}
             >
            </AgGridColumn> 
      </AgGridReact>
      </div></div>
      
      return arr
    }else{
      return ;
    }
    
    }
    
    function AGGridEMptyMEssage(key){
      let msg;
          msg='<span class="ag-overlay-no-rows-center">No Records Found !</span>'
      return msg
    }
    
    function viewIconRenderer(params){
      var eGui = document.createElement('div');
      eGui.innerHTML = '<span class="icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!--! Font Awesome Pro 6.0.0-alpha3 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path d="M288 128C217.3 128 160 185.3 160 256s57.33 128 128 128c70.64 0 128-57.32 128-127.9C416 185.4 358.7 128 288 128zM288 352c-52.93 0-96-43.06-96-96s43.07-96 96-96c52.94 0 96 43.02 96 96.01C384 308.9 340.1 352 288 352zM572.5 238.1C518.3 115.5 410.9 32 288 32S57.69 115.6 3.469 238.1C1.563 243.4 0 251 0 256c0 4.977 1.562 12.6 3.469 17.03C57.72 396.5 165.1 480 288 480s230.3-83.58 284.5-206.1C574.4 268.6 576 260.1 576 256C576 251 574.4 243.4 572.5 238.1zM543.2 260.2C492.3 376 394.5 448 288 448c-106.5 0-204.3-71.98-255-187.3C32.58 259.6 32.05 256.9 31.1 256.2c.0547-1.146 .5859-3.783 .7695-4.363C83.68 135.1 181.5 64 288 64c106.5 0 204.3 71.98 255 187.3c.3945 1.08 .9238 3.713 .9785 4.443C543.9 256.9 543.4 259.6 543.2 260.2z"/></svg></span>';
       return eGui;
    }
function AGTableHour(data){
  
  let arr=[];
  if(data){
    let cols=[];
    cols.push({headerName: 'TimeStamp',field:'hour',pinned:'left',width:"80"});
    for(let i=0;i<location.length;i++){
      let paticularlocData= dataCountCycle.find(item=>item.name == location[i].name);          
      if(paticularlocData){

      
      cols.push({
        headerName: location[i].name,
        cellStyle: { 'border-right': '1px solid #ddd'},
        headerClass:"borderRightCell",
        //autoHeight: true,
        headerHeight:30,
        rowHeight:30,
        
        children: [
          {
            field: `${slugify(location[i].name)}_planned`,
            headerName: 'Planned',
            width: 75,
            cellStyle: {'background-color': '#f3f4f3'},
            headerClass:"plannedTitle",
            //autoHeight: true,
            rowHeight:30,
            headerHeight:30

          },
          {
            field: `${slugify(location[i].name)}_actual`,
            headerName: 'Actual',
            width: 75,
            onCellClicked:(params)=>ViewBoxModalHour(params,params.data,params.value,location[i].name),
            
            cellStyle: { 'border-right': '1px solid #ddd'},
            cellRenderer:(params)=>colorDataRenderer(params,location[i].name),
            headerClass:"borderRightCell",
            //autoHeight: true,
            rowHeight:30,
            headerHeight:30
            
          },
        ],
      });
    }
    }

   return( <div style={{width:"100%",height:"900px"}} className="headerHightDouble">
    <div style={{ height: '100%', width: '100%' }} className="ag-theme-alpine">
      <AgGridReact
        rowData={data}
        columnDefs={cols}
        //defaultColDef={{sortable: true,resizable: true,filter: false}}
        defaultColDef={{sortable: true,resizable: true,rowHeight:30}}     
        //groupDisplayType={'groupRows'}      
        groupDisplayType={'groupCols'}  
        animateRows={false}    
        rowGroupPanelShow={'always'}
        columnGroupPanelShow={'always'}
        paginationAutoPageSize={true}
        pagination={true}
        headerHeight={30}
        rowHeight={30}

        //onGridReady={onGridReady}
      ></AgGridReact>
    </div>
  </div>)



  }

}
  function  GridExample() {

 
  
    //return arr;
  };

  function getDateFormat(date) {
    return moment(date).format("YYYY-MM-DD");
  }


  useEffect(() => {
    if (props.language) {
      updateSelectedLangValue(props.language);
    }
        
  }, [props.language]);


  const handleDate = (date, type) => {
   // setChartDates((prev) => ({ ...prev, [`${type}`]: date }));
  };
  function changeLanguage(lang) {
    getLanguageTranslation(lang).then((res) => {
      if (res && res.status >= 200 && res.status <= 200) {
        localStorage.setItem("languageData", JSON.stringify(res.data));
        localStorage.setItem("selectedLanguage", lang);
        props.setSelectedLanguage(lang);
      }
    });
  }
  function handleCloseModal(){
    SetShowModal(false)
  }

  useEffect(()=>{

    // exportEnableDisable={exportEnableDisable}
    // filterEnableDisable={filterEnableDisable}
    // min={filterSlide[0]}
    // max={filterSlide[1]}
    // date={dates}
    // department_id= {department_id}
    // sub_department_id= {sub_department_id}
    // filterEnableCycle ={filterEnableCycle}
    // exportEnableCycle = {exportEnableCycle}

    SetLoader(false);
    if(props.filterEnableCycle){
  

    }

  },[props])

  return (<>
    {permissionAPICall?permission.View=="True"?<div className="manpowerManagementMainDiv">
      <Container className="header-breadcrumb">
       <Row className="m-t">
          <Col lg={12}>
            <div className=''>
              {loader?<Col lg={12} md={12} sm={12} xs={12} className="apiLoaderPermission">
                        <img src={spinnerLoader} />
                    </Col>:<>{AGTableHour(agDataHour) }
                    
                  </>
                    }
            </div>
          </Col>
        </Row>
      </Container>
      <ReactModal
                isOpen={showModal}
                style={customStyles}
                onRequestClose={handleCloseModal}
                shouldCloseOnOverlayClick={false}
                
            >
                <div className={"modal-cyclecount"}>
                  {modalData}
                </div>
            </ReactModal>


    </div>:<Col lg={12}>
    {/* <div className='errorPermission'></div> */}
<div className='center404'> <Image404 /><h4 style={{marginTop:"-30px",color:"#2e3552"}}>Access Restricted to this page, Please contact administrator</h4></div>
</Col>:<Col lg={12} md={12} sm={12} xs={12} className="apiLoaderPermission">
                        <img src={spinnerLoader} />
                    </Col>}
  </>);
}

const mapStateToProps = (state) => ({
  language: state.dashboard.selectedLangaugeValue,
});

export default connect(mapStateToProps, { setSelectedLanguage })(
  withRouter(CycleCount)
);
