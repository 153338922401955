
import React,{useEffect, useState} from 'react'
import ReactHighcharts from 'react-highcharts'
import moment from 'moment';

import rightIcon from '../styles/images/right-arrow.png'
import LeftIcon from '../styles/images/left-arrow.png'

import {
    getEmployeeList,
  } from "../../manPowerManagement/actionMethods/actionMethods";

import { getSiteLocations } from '../../siteManagement/actionMethods/actionMethods';  
import ReactModal from 'react-modal';
import { Row,Col } from 'react-bootstrap';
import { useHistory, withRouter } from "react-router-dom";
/*
const riskLevelColor = {
    "low": '#04e06e',
    "medium": "#ffd700",
    "high": "#ffa500"
}*/

const riskLevelColor = {
    "low": '#04e06e',
    "medium": "#ffd700",
    "high": "#ff9696" 
}

const BarChart = (props) => {
    
   
    const [typeChart,SetTypeChart] = useState(props.yAxisTitle);
    const [currentDate,setCurrentDate]=useState();
    const [barData,SetBarData]=useState(false);
    const [BarAPIDataSpread,SetBarApiDataSpread]=useState([]);
    const[topFiveData,SetTopfiveData]=useState([]);
    const [seriesActive,SetSetSeriesActive]=useState('');
    const [target,SetTarget]=useState('');
    let history = useHistory();
    
    const [BarAPIDataMovement,SetBarApiDataMovement]=useState([]);
    const [BarAPIDataArea,SetBarApiDataArea]=useState([]);
    let userDetails = JSON.parse(localStorage.getItem('userLoginDetails'))
    let userSession = userDetails ? userDetails.session : '123456789'
    let org_id = userDetails ? userDetails.org_id : 6
    
    
    
    let config = {
        chart: {
            type: 'column',
            height: 300,
            spacingLeft: 42,
                  },
        exporting: {
            enabled: false
        },
        credits: {
            enabled: false
        },
        title: {
            text: null
        },
        tooltip: {
            useHTML: true,
            formatter:  function () {
                  return `<span style="font-size: 10px;padding-right:15px;" class="tool-tip-x">${this.x}</span><span style="font-size: 10px" class="tool-tip-date">${this.point.options.tooltipDate}</span><br/>
                         <span style="background:${this.color}" x="8" dy="15" class="tool-tip-status"></span>
                         <span dx="0" class="tool-tip-name"> ${this.series.name}: </span><span style="font-weight:bold" dx="0" class="tool-tip-result">${this.y}</tspan>`;
                },
             borderColor: '#FFFFFF',
            backgroundColor: '#FFFFFF',
            borderRadius: 15
        },
        xAxis: {
            categories: props.chartData.categories || [],
            tickPositioner: function () {
                let positions = []
                let tick = Math.floor(this.dataMin)
                let increment = Math.ceil((this.dataMax - this.dataMin) / 10);
    
                if (this.dataMax === this.dataMin) {
                    return [0]
                }

                if (this.dataMax !== null && this.dataMin !== null) {
                    for (tick; tick - increment <= this.dataMax; tick += increment) {
                        positions.push(tick);
                    }
                }

                return positions;
            },
            
            events: {
                load: function (e) {
                    
                //this.series[0].data[index].setState('hover');
                }
            },
          //  gridLineDashStyle: 'ShortDash',
           // gridLineWidth: 1,
            // max: (start + 3 * move),
            // min: (start + 2 * move)
        },
        yAxis: {
            title: {
                text: " "
            },
            gridLineDashStyle: 'ShortDash',
            gridLineWidth: 0,
            min: 0,
            max: 100,
            labels: {
                enabled: false,
            },
            plotOptions: {
                series: {
                    cursor: 'pointer',
                    point: {
                        events: {
                            click: function () {
                                alert('Category: ' + this.category + ', value: ' + this.y);
                            }
                        }
                    }
                }
            },        
            plotLines: [
                
                {
                    color: riskLevelColor.high,
                    states: {
                        select: {
                            color: 'blue'
                        }
                    },
                    hover:{
                        color:"#000",
                    },
                    allowPointSelect: true,
                    width: 1,
                    value: 100,
                    dashStyle: 'LongDash',
                    zIndex: 100,
                    label: {
                        text: 'High',
                        style: {
                            color: riskLevelColor.high,
                            fontWeight: 'bold',
                        },
                        rotation:270,
                        className:'Demo-high labelfixchart',
                        y: 60,
                        x:-20
                    }
                },
                {
                    color: riskLevelColor.medium,
                    states: {
                        select: {
                            color: 'blue'
                        }
                    },
                    hover:{
                        color:"#000",
                    },
                    allowPointSelect: true,
                    width: 1,
                    value: 66,
                    dashStyle: 'LongDash',
                    zIndex: 100,
                    rotation: 90,
                    label: {
                        text: 'Medium',
                        style: {
                            color: riskLevelColor.medium,
                            fontWeight: 'bold'
                        },
                        rotation:270,
                        className:'Demo-Medium labelfixchart',
                        y: 65,
                        x:-20
                    }
                },
                {
                    color: riskLevelColor.low,
                    states: {
                        select: {
                            color: 'blue'
                        }
                    },
                    allowPointSelect: true,
                    width: 1,
                    value: 33,
                    dashStyle: 'LongDash',
                    zIndex: 100,
                    label: {
                        text: 'Low',
                        style: {
                            color: riskLevelColor.low,
                            fontWeight: 'bold'
                        },
                        className:'Demo-Low labelfixchart',
                        rotation:270,
                        y: 60,
                        x:-20 
                    }
                }
            ]
        },
        series: [{
            name: 'Risk',
            showInLegend: false,
            cursor: 'pointer',
            states: {
                select: {
                    
                    color: '#ef5e8c',
                    borderColor:'#ef5e8c',
                },
            },
                    
            column:{
                hover:{
                    color:"#000",
                }
            },
            allowPointSelect: true,
            point: {
                events: {
                    click: (e) => {
                        e.point.select(true);
                   props.getDataHandlerChartCLick(typeChart,e.point.options.acdate);
                    //props.getDataHandlerChartDashbaord(date);
                    
                    }
                }
            },

            data: props.chartData?.series || [],
            zones: [
                {
                    value: 33,
                    color: riskLevelColor.low
                },
                {
                    value: 66,
                    color: riskLevelColor.medium
                },
                {
                    // value: 100,
                    color: riskLevelColor.high
                }
            ]
        }]
    }


    function ShowSpreadData(data){
        let arr=[];
        if(data){

        for (let i=0;i<data.length;i++){
            let element=data[i];    
            arr.push(

                <div className="card-body">
                    <Row style={{ alignItems: 'center' }}>
                        <Col className="b-r" style={{ maxWidth: "70%",minWidth:"70%" }} onClick={() => handleClickCard(element.tag_serial)}>
                            <h5 className="font-bold">{element.name}</h5>
                            <div><b>Team:</b> {element.team}</div>
                            <div><b>Tag:</b> {element.tag_serial}</div>
                        </Col>

                        <Col className="" style={{ maxWidth: "30%",minWidth:"30%" }} onClick={() => handleClickCard(element.tag_serial)}>
                            {/* <div className="priSriMriText">Spread Index</div> */}
                            <h6 className="font-bold">{element.spread_index !== 'na' ? element.spread_index + '%' : '-'}</h6>
                        </Col>

        
                    </Row>
                    </div>

        )
        }
    }
    
        return arr;
    }
    function handleClickCard(id) {
        history.push(`/manpower-management/employee-list/view/:${id}`)
    } 
    
    
  function handleCloseModal(){
      SetBarData(false);
      SetTopfiveData([])
  }
  const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '40%',
        height: '40%'
    },
};
function getDataHandler(date){
    SetBarData(true);
    let requestBody = {}
    requestBody.date = date;
    if(typeChart=='Spread Index'){
        getEmployeeList(requestBody, userSession, org_id).then(res => {
        
        if (res) {

               SetBarApiDataSpread(res.data)
            }
        }).catch(() => {
        })
    }
    if(typeChart=='Area Index'){
        
    }
    if(typeChart=='Movement Index'){
        
    }

}

function getHandlerAreaIndex(){

}
function getHandlerMovementIndex(){
    
}
function getHandlerSpreadIndex(){
    
}
const styles = {
    rect: {
        fill: "#000 !important",
        color: "#000 !important",
    },
    
  }

    return (
        <div    >
            <ReactHighcharts  config={config} height={100} style={styles} containerProps={{ className: props.className }} />
            {/* {seriesActive?`<style>.highcharts-column-series rect:nth-child(4){fill: #000 !important;
color: #000;}</style>`:""} */}
            <ReactModal
                isOpen={barData}
                style={customStyles}
                onRequestClose={handleCloseModal}
                shouldCloseOnOverlayClick={true}
                
            >
            <div className='close-modal-new' onClick={handleCloseModal}>x</div>
                {ShowSpreadData(topFiveData)}
            </ReactModal>

        </div>
    )
}

export default BarChart