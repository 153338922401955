import React, { useState, useCallback,useReducer,useEffect } from 'react'
import { Container, Row, Col, Dropdown, DropdownButton } from "react-bootstrap";
import { Button } from "antd";
import { CommonHeading } from "../../common/commonHeading";
import Viewer from '../../employeeTracker/hooks/Viewer'
import {
  append,
  compose,
  isEmpty,
  map,
  reject,
  intersperse,
  evolve,
  max,
  mapObjIndexed,values
} from 'ramda'
import moment from "moment";
import { getTranslatedText } from  "../../common/utilities";
import {
  getLanguageTranslation,
  setSelectedLanguage,
} from "../../dashboard/actionMethods/actionMethods";
import DashboardLanguage from "../../components/dashboardLanguage";
import CommonDatePicker from "../../common/commonDatePicker";

import { Slider } from 'antd';
import {ReactComponent as Play} from '../../assets/images/circle-play.svg'
import {ReactComponent as Pause} from '../../assets/images/circle-pause.svg'
import LocationIcon from '../../assets/images/location.png'
import mqttService from '../../components/mqttService';

import { useLocation } from 'react-router-dom';



const FacilitiesTracker = (props) => {
  const INITIAL_MODE = '3d'  
  const [mode, setMode] = useState(INITIAL_MODE)
  const [activeTab, SetActiveTab]= useState('');
  const noElevationIn2D = useCallback(value => (mode === '3d' ? value : 0), [
    mode
  ])
  let date = localStorage.getItem("selectedDate")
    ? new Date(localStorage.getItem("selectedDate"))
    : new Date();

  const [selectedDate, updateSelectedDate] = useState(date);
  const [selectedLangValue, updateSelectedLangValue] = useState("en");

  const autoElevation = map(
    evolve({ position: { elevation: noElevationIn2D } })
  )
  
  const [space,setSpace] = useState()
  const [autoPlay,SetAutoPlay] =useState(true);
  const locationCurrent = useLocation()

  let userDetails = JSON.parse(localStorage.getItem("userLoginDetails"));
  let userSession = userDetails ? userDetails.session : "123456789";
  let org_id = userDetails ? userDetails.org_id : 6;
  const [client,SetClient] =useState(null)
  let lastReqId = null

    const marks = {
      0: '12 PM',
      1: '01 PM',
      2: '02 PM',
      3: '03 PM',
    };

    const marks2 = {
      3: '03 PM',
      4: '04 PM',
      5: '05 PM',
      6: '06 PM',
    };
    const marksAll= {
      0: '12 PM',
      1: '01 PM',
      2: '02 PM',
      3: '03 PM',
      3: '03 PM',
      4: '04 PM',
      5: '05 PM',
      6: '06 PM',
    }


    
    useEffect(()=>{
      if(client){
        client.end() 
      }
      
      },[locationCurrent])



    function errorHandler(message){


    }

    function SubscribeHandler(message){

    }
    

    useEffect(()=>{
      SetClient(mqttService.getClient(errorHandler));

    },[])

    useEffect(()=>{
      if(client){

      client.subscribe('28/021097BD147760',0, (error) => {
        if (error) {
          return
        }
      });


      client.on('message', (topic, message) => {
        


      });
      return () => {

        client.end();
      }
    }

    },[client])



    function handleDateSelect(date) {
    updateSelectedDate(date);

    let startDate = new Date().setDate(new Date().getDate() - 29);
    let endDate = new Date(moment(date).format('YYYY-MM-DD 23:59:59'));
    //setChartDates({ start: startDate, end: endDate });
  }

  function getDateFormat(date) {
    return moment(date).format("YYYY-MM-DD");
  }
  // memoize so Viewer render once only (wrapped in memo)
  const onReady = useCallback(space => setSpace(space), [])

  const [icons, dispatchIcon] = useReducer((icons, action) => {
    switch (action.type) {
      case 'add':
        return [...icons, action.icon]
      case 'update':
        return icons.map(pt =>
          pt.id === action.id ? { ...pt, ...action.updates } : pt
        )
      case 'remove':
        return reject(r => r.id === action.id)(icons)
      default:
        console.error(`Unknown action type ${action.type}`)
    }
  },[
    {
      "id": "45f8d5d7-485d-5fe3-87d4-ec83ad282178",
      "name": "12 PM",
       "name":"Loc 1",
      "tooltip": '12 PM',
      "position": {
        "levelIndex": 0,
        "x": 5.345798104140962,
        "z": -7.660746541830819,
        "elevation": 0.0020000000950055608
      },
      "color":'#000'
    },
    {
      "id": "7bc373c4-8192-554e-8240-94bcbc4f72c9",
      "name":"Loc 2",
      "tooltip": '01 PM',
      "position": {
        "levelIndex": 0,
        "x": 5.704142276986953,
        "z": -3.8479600800725433,
        "elevation": 0.00200000009499135
      },
      "color":'#ff0000'
    },
    {
      "id": "fc000dc1-4070-5102-b200-5bf5028bdc20",
      "name":"Loc 3",
      "tooltip": '02 PM',
      "position": {
        "levelIndex": 0,
        "x": 7.541267753695121,
        "z": -9.40195400311693,
        "elevation": 0.0020000000949949026
      },
      color:'#000'
    },
    {
      "id": "74f036bd-ef77-5a69-a65e-2bd74ba10d6b",
      "name":"Loc 4",
      "tooltip": '03 PM',
      "position": {
        "levelIndex": 0,
        "x": 9.99282184476353,
        "z": -3.786867824967688,
        "elevation": 0.0020000000949984553
      },
      color:'#000'
    },
    {
      "id": "8ed4fc34-bf9d-5d47-910b-c7ec70ab1269",
      "name":"Loc 5",
      "tooltip": '04 PM',
      "position": {
        "levelIndex": 0,
        "x": 11.264784870067071,
        "z": -9.736350154670562,
        "elevation": 0.0020000000949984553
      },
      color:'#000'
    },
    {
      "id": "04ea34b8-7871-5a22-a3b3-2c2fb475609d",
      "name":"Loc 6",
      "tooltip": '05 PM',
      "position": {
        "levelIndex": 0,
        "x": 11.837465121789982,
        "z": -6.2393547219341,
        "elevation": 0.0020000000949949026
      },
      color:'#000'
    },
    {
      "id": "332ac4e4-4b01-556b-b0db-2046931bc045",
      "name":"Loc 7",
      "tooltip": '06 PM',
      "position": {
        "levelIndex": 0,
        "x": 8.116261065088876,
        "z": -5.033945814324044,
        "elevation": 0.0020000000949949026
      },
      color:'#000'
    }  
  ])

  const [points, dispatchPoint] = useReducer((points, action) => {
      
    switch (action.type) {
      case 'add':
        return [...points, action.point]
      case 'update':
        return ;
      case 'remove':
        return reject(r => r.id === action.id)(points)
      default:
        console.error(`Unknown action type ${action.type}`)
    }
    
  },[])
  const [defaultPoint,SetDefaultPoint]=useState([
    {
      "id": "4742e1e1-a746-5693-b34e-629d212a2c25",
      "name": "J6 (12 PM)",
      "position": {
        "levelIndex": 0,
        "x": 5.704735007350029,
        "z": -3.545882725963879,
        "elevation": 0.0020000000949984553
      },time:'12 PM'      
    },
    {
      "id": "587daa2a-56e1-5d10-acf2-66d962f77129",
      "name": "X4 (12 PM)",
      "position": {
        "levelIndex": 0,
        "x": 5.272311336410749,
        "z": -4.520396215703474,
        "elevation": 0.0020000000949984553
      },time:'12 PM'
    },

    {
      "id": "6919fce8-1a24-5fcd-81fb-7342d61d7fb4",
      "name": "Y4 (01 PM)",
      "position": {
        "levelIndex": 0,
        "x": 5.8987262196070045,
        "z": -4.321862883908747,
        "elevation": 0.0020000000949949026
      },time:'01 PM'
    },
    {
      "id": "90ff8fa2-6727-5c0f-99f5-c91fc73b2216",
      "name": "G5 (01 PM)",
      "position": {
        "levelIndex": 0,
        "x": 4.37701150317306,
        "z": -3.692615245306862,
        "elevation": 0.0020000000949984553
      },time:'01 PM'
    },
    {
      "id": "3d2eb61d-4af0-58f3-8a30-6780d9f79ca6",
      "name": "S5 (02 PM)",
      "position": {
        "levelIndex": 0,
        "x": 5.067948404501414,
        "z": -3.7984313468465682,
        "elevation": 0.0020000000949949026
      },time:'02 PM'
    },
    {
      "id": "0bc2dbec-eadc-500f-bb2a-f9979a447af9",
      "name": "A7 (02 PM)",
      "position": {
        "levelIndex": 0,
        "x": 10.583150115195702,
        "z": -3.6343043918031768,
        "elevation": 0.0020000000949949026
      },time:'02 PM'
    },
    {
      "id": "134550d8-3046-536e-b7dc-c7bf1a491701",
      "name": "R2 (02 PM)",
      "position": {
        "levelIndex": 0,
        "x": 11.719616586929796,
        "z": -4.717431016924272,
        "elevation": 0.0020000000949949026
      },time:'02 PM'
    },
    {
      "id": "aec271ef-803a-56a8-b326-140c949e58ff",
      "name": "Z9",
      "position": {
        "levelIndex": 0,
        "x": 11.516617243194897,
        "z": -4.284952157229026,
        "elevation": 0.0020000000949984553
      },time:'03 PM'
    },
    {
      "id": "95b6089e-e62a-5ef2-879b-eb6f30487f01",
      "name": "U7",
      "position": {
        "levelIndex": 0,
        "x": 10.508552031795954,
        "z": -4.331787311288027,
        "elevation": 0.0020000000949949026
      },time:'03 PM'
    },
    {
      "id": "d1aedc66-7594-5514-9a52-42b1ea67b351",
      "name": "P1",
      "position": {
        "levelIndex": 0,
        "x": 11.788769052579333,
        "z": -3.3562688794235473,
        "elevation": 0.0020000000949949026
      },time:'04 PM'
    },
    {
      "id": "c8339f53-7713-5999-945d-99fae61f3cb8",
      "name": "N8",
      "position": {
        "levelIndex": 0,
        "x": 7.9450143020552515,
        "z": -5.076098826103553,
        "elevation": 0.0020000000949984553
      },time:'04 PM'
    },
    {
      "id": "1ff745bb-f1c7-58a3-9323-76449b77458c",
      "name": "S4",
      "position": {
        "levelIndex": 0,
        "x": 9.106878967203173,
        "z": -5.399854590319769,
        "elevation": 0.0020000000949949026
      },time:'05 PM'
    },
    {
      "id": "adc6d1c8-4d59-5a1e-8e4d-fa047b35b623",
      "name": "Q3",
      "position": {
        "levelIndex": 0,
        "x": 8.532543784918314,
        "z": -5.505447911029062,
        "elevation": 0.0020000000949984553
      },time:'05 PM'
    },
    {
      "id": "df5997b6-9e9f-507e-9a63-f9dc19be8231",
      "name": "T6",
      "position": {
        "levelIndex": 0,
        "x": 8.677449277905499,
        "z": -4.549835302408321,
        "elevation": 0.0020000000949984553
      },time:'05 PM'
    },
    {
      "id": "2a3dfb45-4ac6-5de4-80a8-58489fa6affb",
      "name": "L9",
      "position": {
        "levelIndex": 0,
        "x": 7.165802275454137,
        "z": -8.35238535713736,
        "elevation": 0.0020000000949984553
      },time:'06 PM'
    },
    {
      "id": "1242ea47-a18e-5e44-ac05-d49e477b870f",
      "name": "B3",
      "position": {
        "levelIndex": 0,
        "x": 7.205640903460445,
        "z": -9.018835800712118,
        "elevation": 0.0020000000949949026
      },time:'12 PM'
    },
    {
      "id": "9a7e0b6e-0e8a-5b86-a152-df130eca8cb9",
      "name": "J4",
      "position": {
        "levelIndex": 0,
        "x": 7.85560040649206,
        "z": -8.50242879138393,
        "elevation": 0.0020000000949949026
      },time:'01 PM'
    },
    {
      "id": "2b3d4e60-8588-50f2-b2a0-49aede71b20b",
      "name": "T3",
      "position": {
        "levelIndex": 0,
        "x": 8.040263583060652,
        "z": -8.75190294990833,
        "elevation": 0.0020000000949949026
      },time:'02 PM'
    },
    {
      "id": "7e73c500-bd04-56ae-8311-6f1f34d3cd69",
      "name": "V7",
      "position": {
        "levelIndex": 0,
        "x": 10.625354286628687,
        "z": -9.235904896209489,
        "elevation": 0.0020000000949949026
      },time:'03 PM'
    },
    {
      "id": "608416e3-afdb-5397-87fa-d2ebf5e0466e",
      "name": "H2",
      "position": {
        "levelIndex": 0,
        "x": 11.205211986727418,
        "z": -9.236610745591577,
        "elevation": 0.0020000000949949026
      },time:'04 PM'
    },
    {
      "id": "0e815359-79ae-554f-b7f9-5fda3f21061b",
      "name": "N8",
      "position": {
        "levelIndex": 0,
        "x": 10.778541504503849,
        "z": -9.826795881092458,
        "elevation": 0.002000000095002008
      },time:'05 PM'
    },
    {
      "id": "9ac99c27-bf1f-5bcb-8534-076830e8559e",
      "name": "D6",
      "position": {
        "levelIndex": 0,
        "x": 11.155694086730305,
        "z": -9.710917791126043,
        "elevation": 0.0020000000949984553
      },time:'06 PM'
    },
    {
      "id": "2e44f863-98e1-5ade-a9d6-941df15b1e05",
      "name": "C3",
      "position": {
        "levelIndex": 0,
        "x": 11.28899375633437,
        "z": -8.356443755940496,
        "elevation": 0.0020000000949949026
      },time:'06 PM'
    },
    {
      "id": "89ffd019-3dc1-5814-891a-c7474437f729",
      "name": "U6",
      "position": {
        "levelIndex": 0,
        "x": 10.350495103967505,
        "z": -6.024414869543491,
        "elevation": 0.0020000000949984553
      },time:'12 PM'
    }
  ])

  useEffect(() => {
    if (!space) {
      return
    }
    space.addDataLayer({
      id: 'points',
      type: 'point',
      data: autoElevation(points),
      diameter: 0.25,
      anchor: 'bottom',
      tooltip: d => d.name,
      onDrop: ({ data, position }) =>
        dispatchPoint({
          type: 'update',
          id: data.id,
          updates: { position }
        })
    })
  }, [space, points, autoElevation])


  const [currentCount, setCount] = useState(0);

  useEffect (() => {
    if (!space) {
      return
    }
    space.addDataLayer({
      id: 'icons',
      type: 'icon',
      data: map(evolve({ position: { elevation: max(0.25) } }))(
        autoElevation(icons)
      ),
      icon: {
        url: LocationIcon,
        width: 512,
        height: 512
      },
      width: 0.5,
      tooltip: d => d.name,
      onDrop: ({ data, position }) =>
        dispatchIcon({
          type: 'update',
          id: data.id,
          updates: { position }
        })
    })
    
  }, [space, icons, autoElevation])

  

  const timer = () => {
    setCount(currentCount + 1);    
  }

  useEffect(() => {

        if (!space) {

          return
        }   
        if (currentCount > (icons.length) || autoPlay===false) {
            return;
        }
        const id = setInterval(timer, 4000);

        

        let itemAdd = defaultPoint.filter(item=> item.time==marksAll[currentCount]);
        let itemRemove = defaultPoint.filter(item=> item.time==marksAll[currentCount - 1]);
        if(itemAdd.length >0){
          for(let i =0; i<defaultPoint.length; i++){
            //dispatchPoint({ type: 'remove', id: defaultPoint[i].id });
          }
        }

        setTimeout(function(){
          if(itemAdd.length >0){
            for(let i =0; i<itemAdd.length; i++){
              //dispatchPoint({ type: 'remove', id:itemAdd[i].id });
              dispatchPoint({
                type: 'add',
                point: {
                  id: itemAdd[i].id,
                  name: itemAdd[i].name,
                  position: itemAdd[i].position
                }
              })
            }
          }  
        },100)

        

        

       return () => clearInterval(id);
      },[currentCount,space,autoPlay]);

      useEffect(() => {

        if (!space) {

          return
        }   

        if(autoPlay){

        }else{

          let itemAdd = defaultPoint.filter(item=> item.time==marksAll[currentCount]);
          let itemRemove = defaultPoint.filter(item=> item.time==marksAll[currentCount - 1]);
  
          if(itemAdd.length >0){
            for(let i =0; i<defaultPoint.length; i++){
              dispatchPoint({ type: 'remove', id: defaultPoint[i].id });
            }
          }
  
          setTimeout(function(){
            if(itemAdd.length >0){
              for(let i =0; i<itemAdd.length; i++){
                //dispatchPoint({ type: 'remove', id:itemAdd[i].id });
                dispatchPoint({
                  type: 'add',
                  point: {
                    id: itemAdd[i].id,
                    name: itemAdd[i].name,
                    position: itemAdd[i].position
                  }
                })
              }
            }  
          },100)
        }



      },[currentCount,space,autoPlay]);


      function getDateFormat(date) {
        return moment(date).format("YYYY-MM-DD");
      }
    
    
    useEffect(() => {
      if (props.language) {
        updateSelectedLangValue(props.language);
      }
    }, [props.language]);

    
  
  
    const handleDate = (date, type) => {
      // setChartDates((prev) => ({ ...prev, [`${type}`]: date }));
    };

    function changeLanguage(lang) {
      getLanguageTranslation(lang).then((res) => {
        if (res && res.status >= 200 && res.status <= 200) {
          localStorage.setItem("languageData", JSON.stringify(res.data));
          localStorage.setItem("selectedLanguage", lang);
          props.setSelectedLanguage(lang);
        }
      });
    }

    function changeTab(name){
      SetActiveTab(name);
    }
    function HandleRange(e,marks){
      setCount(e);
      SetAutoPlay(false);      

    }
   function autoPlayhandler(){
    SetAutoPlay(!autoPlay)
    if(currentCount > icons.length){
      setCount(0);
    }else{
    }
   }
      
  return (
    <div className="manpowerManagementMainDiv">
    <Container className="header-breadcrumb">
      <Row>
        <Col lg={6}>
          <CommonHeading title="Facility Tracker" />
        </Col>
        <Col lg={6} className="text-right">
          <div className="commonLangaugeStyleDiv">
            <DashboardLanguage
              selectedLangValue={selectedLangValue}
              changeLanguage={changeLanguage}
            />
          </div>

          <div className="siteHeadingDatePickerDiv" style={{ width: "20%" }}>
            <CommonDatePicker
              selectedDate={selectedDate}
              handleSelectDate={handleDateSelect}
            />
          </div>
        </Col>
      </Row>




      <Row className="m-t">
        <Col lg={12}>
          <div style={{maxWidth:900,margin:"0 auto"}}>
        <Viewer onReady={onReady} changeTab={changeTab}/>
      <div position='center' my='md'>
      
        <Row className='nicerFormatRange'>
          <Col lg={5}>
            <Slider
              marks={marks}
              defaultValue={currentCount} max={3} min={0} 
              value={currentCount}
              tooltipVisible={false} 
              onChange={(e)=>HandleRange(e,marks[e])}
              disabled={!space}
              key={'range1'}
            />
          </Col>
          <Col lg={2}>
          <Button disabled={!space} className='autoplayStop btn-smaller' onClick={autoPlayhandler}>{autoPlay&&currentCount<=(icons.length-1)?<Play/>:<Pause/>}</Button>  
          </Col>
          <Col lg={5}>
            <Slider
              marks={marks2}
              defaultValue={currentCount} max={6} min={3} 
              value={currentCount}
              tooltipVisible={false} 
              onChange={(e)=>HandleRange(e,marks[e])}
              disabled={!space}
              key={'range2'}
            />
          </Col>
        </Row>
          
      </div>
      </div>
        </Col>
      </Row>
    </Container>
  </div>
  )
}

export default FacilitiesTracker