import React,{useState} from 'react'
import { Row,Col } from 'react-bootstrap'
import { NoDataToDisplay } from 'react-highcharts-no-data-to-display'

import rightIcon from '../../../dashboard/styles/images/right-arrow.png'
import LeftIcon from '../../../dashboard/styles/images/left-arrow.png'
import { useHistory, withRouter } from "react-router-dom";
import moment from 'moment'
const ReactHighcharts = require('react-highcharts');
NoDataToDisplay(ReactHighcharts.Highcharts);

const riskLevelColor = {
    "low": '#04e06e',
    "medium": "#ffd700",
    "high": "#ff9696"
}



const BarChart = (props) => {
    let history = useHistory();
    
    let totalEmployeeTag = localStorage.getItem('totalEmployeeTag') ? localStorage.getItem('totalEmployeeTag') : 0;
    const [ShowSelectedData,SetSHowSelectedData]=useState([])
    const [SeriesName,SetSeriesName]=useState('');

    function capitalizeFirstLetter(string) {
        return string.toLowerCase();
      }

      function RedirectUser(tag_serial,type){
          //console.log("RedirectUser",tag_serial,type)
          if(type=="employee"){
              history.push(`/productivity/employee/:${tag_serial}`)
          }else{
            history.push(`/productivity/truck/:${tag_serial}`)
          }
      }

    function formatSelectedData(arr2,name){
        SetSeriesName(name);
       let arr=[]

       for(let y=0; y< arr2.length;y++){
        //console.log("Here tooltip formattion",arr2[y])
        
          //person.push({name:Object.keys(cData[key].tag[y])[0],tag_serial:Object.values(cData[key].tag[y])[0],type:cData[key].tag[y].tag_type})
           
        arr.push( 
      <Row style={{ alignItems: 'center' }} key={name+"data"+y} onClick={()=>RedirectUser(Object.values(arr2[y])[0] ,arr2[y].tag_type)}>
        <Col className="b-r" style={{ maxWidth: "100%",minWidth:"100%" }} >
            <b className="font-bold">{capitalizeFirstLetter(Object.keys(arr2[y])[0]) }</b>
        </Col>
    </Row>
)
  
  
      }
     
        SetSHowSelectedData(arr);
        props.SetDataDisplay(name,arr);
    }

    let config = {
        chart: {
            type: 'column',
            height: 370,
            zoomType: 'x',
            events: {
                // load: function () {
                //     const chart = this

                //     const moveLeft = () => {
                //         let { min, max, dataMin } = chart.xAxis[0].getExtremes()
                //         let move = 8
                //         if (min - move >= dataMin) {
                //             min -= move
                //             max -= move
                //         }
                //         chart.xAxis[0].setExtremes(min, max)
                //     }
                //     const moveRight = () => {                        
                //         let { min, max, dataMax } = chart.xAxis[0].getExtremes()
                //         let move = 8
                //         if (max + move <= dataMax) {
                //             min += move
                //             max += (move)
                //         }
                //         chart.xAxis[0].setExtremes(min, max)
                //     }

                //     const leftArrowUrl = LeftIcon
                //     const rightArrowUrl = rightIcon
                //     const leftArrow = chart.renderer.image(leftArrowUrl, 30, 150, 30, 30).attr({ zIndex: 10 })
                //     const rightArrow = chart.renderer.image(rightArrowUrl, chart.chartWidth - 50, 150, 30, 30).attr({ zIndex: 10 })
                //     leftArrow.on('click', moveLeft).add()
                //     rightArrow.on('click', moveRight).add()
                // }
            }
        },
        exporting: {
            enabled: false
        },
        credits: {
            enabled: false
        },
        title: {
            text: null
        },
        tooltip: {
            enabled: true,
            useHTML: true,
            formatter: function() {
              var strTooltip = "";
              let item2 = this.point.options.tooltip
            
            //   if(item2){
                
                
            //     for(let i=0; i<item2.length; i++){
            //         strTooltip+='<span dx="0" class="tool-tip-name"><b>'+item2[i].name+'</b> <span class="al-right" style="float:right"> '+item2[i].time+'</span></span><br/>';

            //     }

            //   }

                  return `<span style="font-size: 10px;padding-right:15px;" class="tool-tip-x">${this.x} <br/></span>
                                     Footfall <strong>${item2.length}</strong>                              
                         `;
                },
            borderRadius: 15
        
          },

          
        xAxis: {
            categories: props.chartData.categories,
            gridLineWidth: 1,
            gridLineDashStyle: 'longdash',
            // tickPositioner: function () {
            //     let positions = []
            //     let tick = Math.floor(this.dataMin)
            //     let increment = Math.ceil((this.dataMax - this.dataMin) / (window.innerWidth > 1279)? 3: 4 );
            //     if(props.type=='month'){
            //         increment = Math.ceil((this.dataMax - this.dataMin) / (window.innerWidth > 1279)? 8: 8 );
            //     }

            //     if (this.dataMax === this.dataMin) {
            //         return [0]
            //     }

            //     if (this.dataMax !== null && this.dataMin !== null) {
            //         for (tick; tick - increment <= this.dataMax; tick += increment) {
            //             positions.push(tick);
            //         }
            //     }

            //     return positions;
            // }
            tickPositioner: function () {
                let positions = []
                let tick = Math.floor(this.dataMin)
                let increment = Math.ceil((this.dataMax - this.dataMin) / (window.innerWidth > 1279)? 7: 6 );
               positions.push(0);
               positions.push(3);
               positions.push(6);
               positions.push(9);
               positions.push(13);
               positions.push(17);
               positions.push(20);
               positions.push(23);
                return positions;
            },
            labels: {
                useHTML:true,
                formatter: function () {
                    if(this.value=='12 AM'){
                        return `<div class="xaxis-legend xaxis-legend-12" style="font-size: 11px;">
                                                <div class="legendtime">${this.value}</div>
                                                <div class="legendDate" date="${moment(moment(props.date).add(1, 'days')).format('DD-MM-YY')}"></div>
                                                </div>`;

                          
                    }else if(this.value=='07 AM'){
                        return `<div class="xaxis-legend xaxis-legend-12" style="font-size: 11px;">
                                                <div class="legendtime">${this.value}</div>
                                                <div class="legendDate"  date="${moment(props.date).format('DD-MM-YY')}"></div>
                                                </div>`;
                    }else{
                        return `<div class="xaxis-legend" style="font-size: 11px;">
                                                <div class="legendtime">${this.value}</div>
                                                <div class="legendDate"></div>
                                                </div>`;
                    }
                    

     
                }
            },
            },
        yAxis: {
            minTickInterval:1,
            offset:1,
            min:0,
            allowDecimals:false,
            title: {
                text: 'Footfall'
            },
            
            gridLineDashStyle: 'ShortDash',
            gridLineWidth: 1,
            min: 0,
            labels: {
                enabled: true
            },
            
        },
        series: [{
            allowPointSelect: true,
            name: 'Risk',
            showInLegend: false,
            cursor: 'pointer',
            states: {
                select: {
                    color: '#ef5e8c',
                    borderColor:'#ef5e8c',
                },
            },    
            point: {
                events: {
                    click: (e) => {
                        e.point.select(true);
                        formatSelectedData(e.point.options.tooltip,e.point.name);
                        ////console.log(e.point.options.tooltip,e.point.name)
                    
                    }
                }
            },

            data:props.chartData.series
        }],
        // colors: ['#ef5e8c', '#f7c6d4']
    }

    return (
        <ReactHighcharts config={config} key={'FootfallChart'} />
    )
}

export default BarChart