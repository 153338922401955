import React, { useState, useEffect } from "react";
import { Container, Row, Col, Dropdown, DropdownButton } from "react-bootstrap";
import { Button } from "antd";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import { withRouter } from "react-router-dom";

import { CommonHeading } from "../../common/commonHeading";
import DashboardLanguage from "../../components/dashboardLanguage";
import "../style/style.scss";

import CommonDatePicker from "../../common/commonDatePicker";
import {
  getOrgPri,
  employeeChart,
} from "../../manPowerManagement/actionMethods/actionMethods";
import {getVisitorTagList,getTempTagList, getproductiveCat} from '../actionMethods/actionMethods'

import helpIcon from "../../assets/traceplusImages/help-icon.png";
import dropdownIcon from "../../assets/images/down-arrow.png";
import mediumRiskIcon from "../../assets/traceplusImages/medium_risk_icon.svg";
import spinnerLoader from "../../assets/images/Spinner Loader.gif";

import moment from "moment";
import { getTranslatedText } from "../../common/utilities";
import EmployeeList from "../../manPowerManagement/components/employeeList";
import CheckInIcon from "../../assets/traceplusImages/check-in.png"
import CheckOutIcon from "../../assets/traceplusImages/check-out.png"
import {
  getLanguageTranslation,
  setSelectedLanguage,
} from "../../dashboard/actionMethods/actionMethods";

import CheckIn from "./checkIn";
import ReactModal from "react-modal";
import CheckOut from "./checkOut";
import AgGridEmployeeList from "../../manPowerManagement/components/agGridEmployeeList";
//import GridExample from './agGridTest'
import DeviceStatus from "../../components/DeviceStatus";
import {ReactComponent as Image404 } from  '../../assets/images/11132-ai.svg'



const riskLevelColor = {
  low: "#04e06e",
  medium: "#ffd700",
  high: "#ffa500",
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "600px",
    height: "auto",
  },
};

function VisitorManagement(props) {
  let date = localStorage.getItem("selectedDate")
    ? new Date(localStorage.getItem("selectedDate"))
    : new Date();

  const [selectedDate, updateSelectedDate] = useState(date);
  const [startDate,SetStartDate] = useState(date);
  const [endDate,SetEndDate] = useState(date);
  const [selectedLangValue, updateSelectedLangValue] = useState("en");
  const [numAttended, updateNumberAttended] = useState(0);
 
  const [searchValue, updateSearchValue] = useState("");
  const [globalSearchData, setGlobalSearchData] = useState([]);
  const [suggestion, setSuggestions] = useState([])
  const [globalCategory, setGlobalCategory] = useState("Employees");
  const [globalSearch, setGlobalSearch] = useState("");
  const [teamList, updateTeamList] = useState([]);
  const [preDefinedTeamList, updatedPredefinedTeamList] = useState([]);
  const [employeePopupFlag, updateEmployeePopupFlag] = useState(false);
  const [checkInFlag, updatecheckInFlag] = useState(false);
  const [checkOutFlag, updatecheckOutFlag] = useState(false);
  const [activeTab,SetActiveTab]=useState('visitor') 
  // const [modalType, setModalType] =useState("");
 
  const [PriData, updatePRIData] = useState("");
  const [prevPriData, updatePrevPriData] = useState("");

  const [selectedTab, updatedSelectedTab] = useState("employees");
  const [totalTag,updateTotalTag] = useState(0);
  const [activeTag,updateActiveTag] = useState(0);
  const [inactiveTag,updateInActiveTag] = useState(0);
  const [availableTag,updateAvaialableTag] = useState(0);
  const [tagList,updateTagList]=useState([]);

  
  const [totalTagTemp,updateTotalTagTemp] = useState(0);
  const [activeTagTemp,updateActiveTagTemp] = useState(0);
  const [inactiveTagTemp,updateInActiveTagTemp] = useState(0);
  const [availableTagTemp,updateAvaialableTagTemp] = useState(0);
  const [tagListTemp,updateTagListTemp]=useState([]);

  const [reloadInc,setReloadInc]=useState(0);

  const [role,SetRole] = useState([]);
  const [permission,SetPermission] =useState([])
  const [permissionAPICall,SetPermissionAPICall] = useState(false);

  const [productiveCategory,SetProductivitCategory]= useState([]);
  const [productiveID,SetProductiveID] =useState([]);
  const [productiveIDData,SetProductiveIDData] =useState([])
  

  let userDetails = JSON.parse(localStorage.getItem("userLoginDetails"));
  let userSession = userDetails ? userDetails.session : "123456789";
  let org_id = userDetails ? userDetails.org_id : 6;
  let lastReqId = null

  useEffect(() => {
    let perm= props.role.visitor_management?JSON.parse(props.role.visitor_management):null;
    if(perm){

       //console.log(perm);
        // perm.View = 'False'
        // perm.Create = 'False'
        // perm.remove = 'False'
        // perm.edit = 'False'
        SetPermission(perm)
        SetPermissionAPICall(true)
    }else{
        //console.log("undefined",perm);
    }
},[props.role]);

  useEffect(() => {
    if(permission && permission.View=='True'){
    getVisitorTagList(userSession, org_id).then(res => {
        if (res.status === 200) {
            getTagCout(res.data);
            updateTagList(res.data);
        }
        else if(res.status === 302){
        
        }
    });
    getTempTagList(userSession,org_id).then(res=>{
      if (res.status === 200) {
        getTagCoutTemp(res.data);
        updateTagListTemp(res.data)

    }
  
    })
  }
  function getTagCoutTemp(data){
    let activeTagCount=[];
    let inactiveTagCount=[];
    let avaialbleTagCount=[];
    let totalTagCount=[];
    let arr=[]
    activeTagCount=data.active?data.active:[]
    avaialbleTagCount=data.available_for_today?data.available_for_today:[]
    inactiveTagCount=data.inactive?data.inactive:[]
    //console.log("Inactive",inactiveTagCount.length)

    updateActiveTagTemp(activeTagCount.length)
    updateInActiveTagTemp(inactiveTagCount.length)
    updateAvaialableTagTemp(avaialbleTagCount.length)
    
    totalTagCount=[...activeTagCount,...inactiveTagCount,...avaialbleTagCount]; 
    //console.log("totalTagCount",totalTagCount)
    let unique = totalTagCount.filter(onlyUnique);
    //console.log("unique",unique);
    updateTotalTagTemp(unique.length)
  }
    
    }, [selectedDate,permission]);



    function reloadData(){
      if(permission && permission.View=='True'){
      getVisitorTagList(userSession, org_id).then(res => {
        if (res.status === 200) {
            getTagCout(res.data);

        }
        else if(res.status === 302){
        
        }
    });
    let cou =reloadInc +1;
    setReloadInc(cou);     
  }
    }

    function onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
      }
  function getTagCout(data){
    let activeTagCount=[];
    let inactiveTagCount=[];
    let avaialbleTagCount=[];
    let totalTagCount=[];
    let arr=[]
    activeTagCount=data.active?data.active:[]
    avaialbleTagCount=data.available_for_today?data.available_for_today:[]
    inactiveTagCount=data.inactive?data.inactive:[]
    //console.log("Inactive",inactiveTagCount.length)

    updateActiveTag(activeTagCount.length)
    updateInActiveTag(inactiveTagCount.length)
    updateAvaialableTag(avaialbleTagCount.length)
    
    totalTagCount=[...activeTagCount,...inactiveTagCount,...avaialbleTagCount]; 
    //console.log("totalTagCount",totalTagCount)
    let unique = totalTagCount.filter(onlyUnique);
    //console.log("unique",unique);
    updateTotalTag(unique.length)
  }

  function handleDateSelect(date) {
    updateSelectedDate(date);

    let startDate = new Date().setDate(new Date().getDate() - 29);
    let endDate = new Date(moment(date).format('YYYY-MM-DD 23:59:59'));

  }

  function getDateFormat(date) {
    return moment(date).format("YYYY-MM-DD");
  }

 
 
  const handleMouseEnter = (id) => {
    let doc = document.getElementById(id);

    if (doc) {
      doc.style.display = "block";
    }
  };

  const handleMouseLeave = (id) => {
    let doc = document.getElementById(id);

    if (doc) {
      doc.style.display = "none";
    }
  };



  useEffect(() => {
    if (globalSearch.length) {
      lastReqId = setTimeout(() => {
        setSuggestions(globalSearchData.filter((e) => e.name.toLowerCase().search(globalSearch.trim().toLowerCase()) !== -1))
      }, 300);
      return () => {
        clearTimeout(lastReqId)
      }
    } else {
      setSuggestions([])
    }
  }, [globalSearch])




  function getDateFormat(date) {
    return moment(date).format("YYYY-MM-DD");
  }

  function changeLanguage(lang) {
    getLanguageTranslation(lang).then((res) => {
      if (res && res.status >= 200 && res.status <= 200) {
        localStorage.setItem("languageData", JSON.stringify(res.data));
        localStorage.setItem("selectedLanguage", lang);
        props.setSelectedLanguage(lang);
      }
    });
  }

  useEffect(() => {
    if (props.language) {
      updateSelectedLangValue(props.language);
    }
  }, [props.language]);

  function handleTabViewChange(key) {
    updatedSelectedTab(key);
    updateSearchValue("");
  }

  function allEmployeePopUp() {
    updateEmployeePopupFlag(true);
    // setModalType('threat')
  }

  function OpenCheckIn(){
    if(activeTab=='visitor'){

    }else{

      getproductiveCat(userDetails.session,org_id).then(res=>{

        if(res.status==200){
          SetProductivitCategory(res.data);
        }
        
      })
    }
    updatecheckInFlag(true);  
  }
  function OpenCheckOut(){
    updatecheckOutFlag(true);  
  }
  function handleCloseModal() {
    updateEmployeePopupFlag(false);
    updatecheckInFlag(false);
    updatecheckOutFlag(false);
    // setModalType("")
  }

  function handleClickSearch(id) {
    props.history.push(`/manpower-management/employee-list/view/${id}`);
  }

  const handleDate = (date, type) => {
    if(type="start"){
      SetStartDate(date)
    }else{
      SetEndDate(date)
    }
    
  };


  return (<>
    {permissionAPICall?permission.View=="True"?
    <div className="manpowerManagementMainDiv">
      <Container className="header-breadcrumb">
        <Row>
          <Col lg={4} className="m-t-sm" >
            {/* <CommonHeading title="Visitor Management" /> */}
             <div className="VisitorHeaderTab">
              <h5 className={activeTab=='visitor'?"tabVisitor commonHeading tabVisitorActive":"commonHeading tabVisitor"} onClick={()=>SetActiveTab('visitor')}>Visitor</h5>
              <h5 className={activeTab=='tempemployee'?"tabVisitor commonHeading tabVisitorActive":"commonHeading tabVisitor"} onClick={()=>SetActiveTab('tempemployee')}>Temporary Employee</h5>
             </div>
                {/* <h5 class="commonHeading">Visitor Management</h5> */}

          </Col>
          <Col lg={8} className="text-right">
          <div className="dashboardLanguageMainDiv m-r-md">
                            <DashboardLanguage
                                selectedLangValue={selectedLangValue}
                                changeLanguage={changeLanguage}
                            />
                        </div>
                        <div className="commonHeadingDateMainDiv">
                            <CommonDatePicker
                                selectedDate={selectedDate}
                                handleSelectDate={handleDateSelect}
                            />
                        </div>

                        <DeviceStatus permission={permission} role={props.role} />
          </Col>
        </Row>
        </Container>
        <Container>



        {!props.hideGlobalSearch && (
          <Row>
            <Col>
              <div
                className="manpowerManagementEmployeeListMainDiv topHeaderVisitor m-t"
                style={{ backgroundColor: "#202236", padding: "2rem 1.5rem" }}
              ><Row>
                  {activeTab=='visitor'?
                  <Col lg={4} className="visitor-col visitor-stats">
                      <div class="minHeight-200">
                         
                    <div class="visitor">
                        <div class="title">
                            Total tags available <span className="count-visitor">{totalTag}</span>
                        </div>
                    </div> 
                      <div className="sub-stats-visitor">
                          <div className="stats-cols">
                              <div className="countBigger">{activeTag}</div>
                              <div className="countText">Active</div>
                          </div>
                          <div className="stats-cols">
                              <div className="countBigger">{availableTag}</div>
                              <div className="countText">Available for today</div>
                          </div>
                          <div className="stats-cols">
                              <div className="countBigger">{inactiveTag}</div>
                              <div className="countText">Inactive</div>
                          </div>
                      </div> 
                      </div>  
                  </Col>:<Col lg={4} className="visitor-col visitor-stats">
                      <div class="minHeight-200">
                         
                    <div class="visitor">
                        <div class="title">
                            Total tags available <span className="count-visitor">{totalTagTemp}</span>
                        </div>
                    </div> 
                      <div className="sub-stats-visitor">
                          <div className="stats-cols">
                              <div className="countBigger">{activeTagTemp}</div>
                              <div className="countText">Active</div>
                          </div>
                          <div className="stats-cols">
                              <div className="countBigger">{availableTagTemp}</div>
                              <div className="countText">Available for today</div>
                          </div>
                          <div className="stats-cols">
                              <div className="countBigger">{inactiveTagTemp}</div>
                              <div className="countText">Inactive</div>
                          </div>
                      </div> 
                      </div>  
                  </Col>}
                  {permission.edit=="True" && permission.Create=="True"?<Col lg={4} className="visitor-col" onClick={OpenCheckIn}>
                  <div class="minHeight-200 check-in">
                  <div class="visitor"><div class="title"><span className="bg-icon"><img src={CheckInIcon} /></span> Check In</div></div>
                  </div>
                  </Col>:<Col lg={4} className="visitor-col ">
                  <div class="minHeight-200 check-in">
                  
                  <div class="visitor "><div class="title disable hover-message"><span className="bg-icon"><span class="descHelp tootltipHelp" style={{width:"150px"}}>No permission</span><img src={CheckInIcon} /></span> Check In</div></div>
                  </div>
                  </Col>}

                  
                  {permission.edit=="True" && permission.remove=="True"?<Col lg={4} className="visitor-col" onClick={OpenCheckOut}>
                  <div class="minHeight-200 check-in">
                  <div class="visitor"><div class="title"><span className="bg-icon"><img src={CheckOutIcon} /></span> Check Out</div></div>
                  </div>
                  </Col>:<Col lg={4} className="visitor-col">
                  <div class="minHeight-200 check-in">
                  <div class="visitor"><div class="title disable hover-message"><span className="bg-icon"><span class="descHelp tootltipHelp" style={{width:"150px"}}>No permission</span><img src={CheckOutIcon} /></span> Check Out</div></div>
                  </div>

                  </Col>}
              </Row>


              </div>
            </Col>
          </Row>
        )}

        <Row className="m-t">
          <Col lg={12}>
              <div className="manpowerManagementEmployeeListMainDiv">
              
{activeTab=='visitor'?
                <AgGridEmployeeList
                    hideHeading={true}
                    date={selectedDate}
                    isManpower={false}
                    handleTabViewChange={handleTabViewChange}
                    type={"Visitor"}
                    title={"Visitor"}
                    setGlobalSearchData={(data) => setGlobalSearchData(data)}
                    reloadInc={reloadInc}
                    key={props.AgkeyTable?props.AgkeyTable:"visitorManagement"}
                    columnexist={10}
                    datepicker={true}
                    startDate={startDate}
                    endDate={endDate}
                    handleDate={handleDate}
                />:""
        }

       {activeTab=='tempemployee'? <AgGridEmployeeList
                      hideHeading={true}
                      date={selectedDate}
                      isManpower={false}
                      handleTabViewChange={handleTabViewChange}
                      type={"Temporary Employee"}
                      title={"Temporary Employee"}
                      setGlobalSearchData={(data) => setGlobalSearchData(data)}
                      reloadInc={reloadInc}
                      key={props.AgkeyTable?props.AgkeyTable:"tempemployee"}
                      columnexist={10}
                      datepicker={true}
                      startDate={startDate}
                      endDate={endDate}
                      handleDate={handleDate}
                  />:""}
                  
              </div>
          </Col>
        </Row>
      </Container>
      <ReactModal
                isOpen={checkInFlag}
                style={customStyles}
                onRequestClose={handleCloseModal}
                shouldCloseOnOverlayClick={true}
            >
                <CheckIn activeTab={activeTab} tagList={tagList} tagListTemp={tagListTemp} handleCloseModal={handleCloseModal} reloadData={reloadData} />
               
            </ReactModal>
            <ReactModal
                isOpen={checkOutFlag}
                style={customStyles}
                onRequestClose={handleCloseModal}
                shouldCloseOnOverlayClick={true}
                
            >
            <CheckOut handleCloseModal={handleCloseModal} reloadData={reloadData} activeTab={activeTab} tagList={tagList} tagListTemp={tagListTemp} />
            </ReactModal>


    </div>:<Col lg={12}>
            {/* <div className='errorPermission'></div> */}
        <div className='center404'> <Image404 /><h4 style={{marginTop:"-30px",color:"#2e3552"}}>Access Restricted to this page, Please contact administrator</h4></div>
        </Col>:<Col lg={12} md={12} sm={12} xs={12} className="apiLoaderPermission">
                                <img src={spinnerLoader} />
                            </Col>}</>
  );
}

const mapStateToProps = (state) => ({
  language: state.dashboard.selectedLangaugeValue,
});

export default connect(mapStateToProps, { setSelectedLanguage })(
  withRouter(VisitorManagement)
);
