import React, { useState, useEffect,useRef } from 'react'
import { useHistory } from 'react-router-dom';

import { Container, Row, Col } from 'react-bootstrap';
import moment from 'moment'
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import '../../../siteManagement/styles/siteManagement.scss'
import DashboardLanguage from '../../../components/dashboardLanguage';
import { getSiteOverview, getSiteFootFall, getSiteAreaIndex, footfallChart } from '../../actionMethods/actionMethods';

import spinnerLoader from '../../../assets/images/Spinner Loader.gif'
import CommonDatePicker from '../../../common/commonDatePicker';
import { getTranslatedText } from '../../../common/utilities';
import ClockIcon from '../../../dashboard/styles/images/clock.png'

import { getLanguageTranslation, setSelectedLanguage } from '../../../dashboard/actionMethods/actionMethods';
import { tsAnyKeyword } from '@babel/types';
import { Scrollbars } from "react-custom-scrollbars";
import ReactHighcharts from 'react-highcharts'
import Highcharts from "highcharts";
import DatePicker from "react-datepicker";
import helpIcon from "../../../assets/traceplusImages/help-icon.png";
import sortIcon from '../../../assets/traceplusImages/sort.png'
import upIcon from '../../../assets/traceplusImages/up-arrow.png'
import downIcon from '../../../assets/traceplusImages/down-arrow.png'
import calenderIcon from '../../../assets/traceplusImages/calendar_icon_white.svg'
import DeviceStatus from '../../../components/DeviceStatus'
import { getPproductivityWidget,
         getPproductivityDetailOverview,
         getDetailedProductivityChart,
         getProductivityIndividualInfo,
         getIndividualProductivityList } from '../../actionMethods/actionMethods'
import batteryIcon from "../../../assets/traceplusImages/battery.svg";    
import {AgGridColumn, AgGridReact} from 'ag-grid-react';     
import {ReactComponent as Image404 } from  '../../../assets/images/11132-ai.svg'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Switch } from 'antd';

const riskLevelColor = {
    "low": '#04e06e',
    "medium": "#ffd700",
    "high": "#ff9696"
}



function ProductivityDetails(props) {
    let history =useHistory();
    let date = localStorage.getItem('selectedDate') ? new Date(localStorage.getItem('selectedDate')) : new Date()
    let StartDate= localStorage.getItem('ProductiveStart')?new Date(localStorage.getItem('ProductiveStart')):localStorage.getItem('selectedDate') ? new Date(localStorage.getItem('selectedDate')) : new Date()  
    let EndDate= localStorage.getItem('ProductiveEnd')?new Date(localStorage.getItem('ProductiveEnd')):localStorage.getItem('selectedDate') ? new Date(localStorage.getItem('selectedDate')) : new Date()  
    // const [dates, setDates] = useState({
    //     start: new Date(moment(StartDate).format('YYYY-MM-DD 23:59:59')),
    //     end: new Date(moment(EndDate).format('YYYY-MM-DD 23:59:59')),
    //   });


  
    const [employeeData, updateemployeeData] = useState([])



    const [selectedLangValue, updateSelectedLangValue] = useState('en')

    const [locationID, updateLocationID] = useState('')

    const [selectedDate, updateSelectedDate] = useState(date)
    const [chartLoader, setChartLoader] = useState(true)
    const [donutLoader, setDonutLoader] = useState(true)

    const [sortKey, setSortKey] = useState('locationVisited')
    const [sortType, setSortType] = useState('desc')
    
    const [sortKeylocationVisited, setSortKeylocationVisited] = useState('locationVisited')
    const [sortTypelocationVisited, setSortTypelocationVisited] = useState('desc')
    
    const [sortKeyunproductive, setSortKeyunproductive] = useState('unproductive')
    const [sortTypeunproductive, setSortTypeunproductive] = useState('desc')
  
    const [sortKeyacess, setSortKeyacess] = useState('acess')
    const [sortTypeacess, setSortTypeacess] = useState('desc')
  
    const [locationVisitedData,SetLocationVisitedData]=useState([])

    const [unproductiveVisitedData,SetUnproductiveVisitedData]=useState([])
    const [acessData,SetAcessData]=useState([])
    const [donutData,SetDonutData] =useState([]);
    const [chartData,SetChartData]=useState({series: [] });
    const [chartDataWorking,SetChartDataWorking]=useState({series: [] });

    const [selectedData,SetSelectedData]=useState([]);
    const [selectedSeries,SetSelectedSeries]=useState({name:'',data:[],time:''});
    const [selectedSeriesTime,SetSelectedSeriesTime]=useState('');


    const [individualInfo,SetIndividualInfo] = useState([])
    const [loaderWidget,SetLoaderWidget] = useState(true);  

    const [widthContainer, setWidthContainer] = useState(0);
    const elementRef = useRef(null);   
   
    const [activeTab,SetActiveTab] = useState('productivity');
    const [productivity,SetProductivity] = useState([])
    const [nonproductivity,SetNonProductivity] = useState([])
    const [lessproductivity,SetLessProductivity] = useState([])

    const [role,SetRole] = useState([]);
    const [permission,SetPermission] =useState([])
    const [permissionAPICall,SetPermissionAPICall] = useState(false);

    const [loaderWidgetBottom,SetLoaderWidgetBottom] =useState(true);
    const [maxValue,SetmaxValue] = useState(60); 
    const [shiftStartTime,SetShiftStartTime]= useState('');
    const [shiftEndTime,SetShiftEndTime]= useState('');
    const [cumulativeHour,SetCumulativeHour]=useState('');
    const [shiftHourWorking,SetShiftHourworking]=useState();
    const [selectBarTime,SetSelectBarTime] = useState(date);
    const [offShiftTime,SetOffShiftTime]=useState([]);
    const [cmlWorkingHour,SetCMLWorkingHour]=useState('');
    const [cmlNonWorkingHour,SetCMLNonWorkingHour]=useState('');
    const [productiveLocation,SetProductiveLocation]=useState('');
    const [workingPercent,SetWorkingPercent] =useState('');
    const [nonworkingPercent,SetNonWorkingPercent] =useState('');
    const [activeTabShift,SetActiveTabShift]=useState(true); 
    const [slug,SetSlug]=useState(props.match.params.employee?props.match.params.employee:'employee');
    let idVal = props.match.params.id.replace(":", "")



    useEffect(() => {
        //console.log("props history",props);
        let perm= props.role.productivity_management?JSON.parse(props.role.productivity_management):null;
        if(perm){
            SetPermission(perm)
            SetPermissionAPICall(true)
        }else{
 
        }
        
    },[props.role]);

    useEffect(()=>{
//console.log("Slug value",slug)
    },[slug])
    useEffect(() => {
        if(permission && permission.View=='True'){
         setWidthContainer(elementRef.current.getBoundingClientRect().width);
        }
      }, [elementRef,permission]);

    const [configDonut,SetConfigDonut]=useState({
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie',
            height:200
        },
        title: {
            text: ''
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        exporting: {
            enabled: false
        },
        credits: {
            enabled: false
          },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                size:"90%",
                dataLabels: {
                    enabled: false
                },
                showInLegend: false
            }
        },
        series: [{
            name: 'Productivity',
            innerSize: '60%',
            colorByPoint: true,
            data:chartData.series
        }]
    });


    // const categories= [
    //     '00:00','01:00','02:00','03:00','04:00','05:00','06:00',
    //     '07:00','08:00','09:00','10:00','11:00','12:00','13:00',
    //     '14:00','15:00','16:00','17:00','18:00','19:00','20:00',
    //     '21:00','22:00','23:00','24:00',
    // ]
    const categories= [
        '12 AM','01 AM','02 AM','03 AM','04 AM','05 AM','06 AM',
        '07 AM','08 AM','09 AM','10 AM','11 AM','12 PM','01 PM',
        '02 PM','03 PM','04 PM','05 PM','06 PM',
        '07 PM','08 PM','09 PM','10 PM','11 PM',
    ] 

    // const categories2= [
    //     '07 <span>AM</span>','08 <span>AM</span>','09 <span>AM</span>','10 <span>AM</span>','11 <span>AM</span>','12 <span>PM</span>','01 <span>PM</span>',
    //     '02 <span>PM</span>','03 <span>PM</span>','04 <span>PM</span>','05 <span>PM</span>','06 <span>PM</span>',
    //     '07 <span>PM</span>','08 <span>PM</span>','09 <span>PM</span>','10 <span>PM</span>','11 <span>PM</span>','12 <span>AM</span>','01 <span>AM</span>','02 <span>AM</span>','03 <span>AM</span>','04 <span>AM</span>','05 <span>AM</span>','06 <span>AM</span>',
    // ] 
    const categories2= [
        '07 AM','08 AM','09 AM','10 AM','11 AM','12 PM','01 PM',
        '02 PM','03 PM','04 PM','05 PM','06 PM',
        '07 PM','08 PM','09 PM','10 PM','11 PM','12 AM','01 AM','02 AM','03 AM','04 AM','05 AM','06 AM',
    ]

    const cat2= [
        '0','1','2','3','4','5','6',
        '7','8','9','10','11','12','13',
        '14','15','16','17','18','19','20',
        '21','22','23'
    ]

    const cat3= [
        7,8,9,10,11,12,13,
        14,15,16,17,18,19,20,
        21,22,23,0,1,2,3,4,5,6
    ]

    let config = {
        chart: {
            type: 'column',
            height: 330,
        },
        title: {
            text: ''
        },
        xAxis: {
            categories: categories2,
            gridLineWidth: 1,
            gridLineDashStyle: 'longdash',
            //plotBands:activeTabShift=="working"?[]:offShiftTime,
            plotBands:offShiftTime,
            tickPositioner: function () {
                let positions = []
                let tick = Math.floor(this.dataMin)
                let increment = Math.ceil((this.dataMax - this.dataMin) / (window.innerWidth > 1279)? 7: 6 );
                // if(props.type=='month'){
                //     increment = Math.ceil((this.dataMax - this.dataMin) / (window.innerWidth > 1279)? 8: 8 );
                // }

                // if (this.dataMax === this.dataMin) {
                //     return [0]
                // }

                // if (this.dataMax !== null && this.dataMin !== null) {
                //     for (tick; tick - increment <= this.dataMax; tick += increment) {
                //         positions.push(tick);
                //     }
                // }
               positions.push(0);
               positions.push(3);
               positions.push(6);
               positions.push(9);
               positions.push(13);
               positions.push(17);
               positions.push(20);
               positions.push(23);
                return positions;
            },
            labels: {
                useHTML:true,
                formatter: function () {
                    if(this.value=='12 AM'){
                        return `<div class="xaxis-legend xaxis-legend-12" style="font-size: 11px;">
                                                <div class="legendtime">${this.value}</div>
                                                <div class="legendDate" date="${moment(moment(date).add(1, 'days')).format('DD-MM-YY')}"></div>
                                                </div>`;

                          
                    }else if(this.value=='07 AM'){
                        return `<div class="xaxis-legend xaxis-legend-12" style="font-size: 11px;">
                                                <div class="legendtime">${this.value}</div>
                                                <div class="legendDate"  date="${moment(date).format('DD-MM-YY')}"></div>
                                                </div>`;
                    }else{
                        return `<div class="xaxis-legend" style="font-size: 11px;">
                                                <div class="legendtime">${this.value}</div>
                                                <div class="legendDate"></div>
                                                </div>`;
                    }
                    

     
                }
            },
        },
        exporting: {
            enabled: false
        },
        credits: {
            enabled: false
        },
        legend:{enabled:false},
        yAxis: {
            min:0,
            //max:maxValue>60?60:maxValue,
            max:3600000,
            tickAmount:7,
           
            labels: {
                formatter: function () {
           
                    
                let seconds = this.value / 1000;
                // 2- Extract hours:
                const hours = parseInt( seconds / 3600 ); // 3,600 seconds in 1 hour
                seconds = seconds % 3600; // seconds remaining after extracting hours
                // 3- Extract minutes:
                const minutes = parseInt( seconds / 60 ); // 60 seconds in 1 minute
                // 4- Keep only seconds not extracted to minutes:
                seconds = seconds % 60;
                //alert( hours+":"+minutes+":"+seconds);
                let time = hours>0 ?hours+":"+minutes+":"+seconds:minutes+":"+seconds

                    return  time;
                }
            },

            gridLineWidth: 1,
            gridLineDashStyle: 'longdash',
            allowDecimals:false,
            title: {
                text: ''
            },
            stackLabels: {
                enabled: false,
                style: {
                    fontWeight: 'bold',
                    color: ( // theme
                        Highcharts.defaultOptions.title.style &&
                        Highcharts.defaultOptions.title.style.color
                    ) || 'gray'
                }
            }
        },
        tooltip: {
            useHTML: true,
            
            formatter:  function () {

            
                var mins1=parseInt((parseInt(this.y/60000)));
                var sec1=parseInt((this.y%3600000)/1000);

                let seconds = this.y / 1000;
                // 2- Extract hours:
                const hours = parseInt( seconds / 3600 ); // 3,600 seconds in 1 hour
                seconds = seconds % 3600; // seconds remaining after extracting hours
                // 3- Extract minutes:
                const minutes = parseInt( seconds / 60 ); // 60 seconds in 1 minute
                // 4- Keep only seconds not extracted to minutes:
                seconds = parseInt(seconds % 60) < 10?"0"+(seconds % 60):seconds % 60;
                //console.log(seconds)
                //seconds = seconds % 60
                //alert( hours+":"+minutes+":"+seconds);
                let time = hours>0 ?hours+":"+minutes+":"+seconds:minutes+":"+seconds

                return `<span style="font-size: 13px;padding-right:15px;" class="tool-tip-x">${this.x}</span><br/>
                         <span dx="0" class="tool-tip-name">${this.series.name} : </span><span style="font-weight:bold" dx="0" class="tool-tip-result">${time} </tspan>`;                         
                } ,
        },

        plotOptions: {
            column: {
                stacking: 'normal',
                dataLabels: {
                    enabled: false
                },
                states: {
                    select: {
                        color: '#ef5e8c',
                        borderColor:'#ef5e8c',
                    },
                },    
                point: {
                    events: {
                        click: (e) => {
                            e.point.select(true);
                            formatSelectedData(e);
                        
                        }
                    }
                },
    
            },

        },
        series:activeTabShift?chartDataWorking.series:chartData.series
    }

    

            function formatSelectedData(e){
                
                if(e.point.series){
                    SetSelectedSeries({name:e.point.series.name,data:e.point.tooltip,time:e.point.category})
                }
            }

    let userDetails = JSON.parse(localStorage.getItem('userLoginDetails'))

    let userSession = userDetails ? userDetails.session : '123456789'

    let org_id = userDetails ? userDetails.org_id : 6
    
    var timezoneOffset = 60 * -2; // in minutes

    function toMs(timeUnformatted) {
        timeUnformatted = timeUnformatted < 10 ? ("0" + timeUnformatted) : timeUnformatted.toString();
        return Date.parse("1/1/1 " + timeUnformatted.replace(".", ":") + ":00") - Date.parse("1/1/1 00:00:00");
      }

    useEffect(()=>{
        if(permission && permission.View=='True'){
        let requestBody={}
        requestBody.startdate=getDateFormat(selectBarTime)
        requestBody.enddate=getDateFormat(selectBarTime)
        requestBody.slug=slug
        requestBody.tag_serial=idVal
        //setChartLoader(true)
        setDonutLoader(true)
        let maxVal = 0;
        let dataOFselected=[]
        
            let letSeriesData=[]

            getDetailedProductivityChart(requestBody,userSession,org_id).then(res=>{
                
                if(res.status==200){
                    letSeriesData.push({
                        name: 'Productive',
                        y: Math.abs(res.data.productive),
                        color:'#90cf4e',
                    },
                    {
                        name: 'Les Productive',
                        y: Math.abs(res.data.less_productive),
                        color:'#fdbf00',
                    },{
                        name: 'Non Productive',
                        y: Math.abs(res.data.non_productive),
                        color:'#fc0001',
                    },{
                        name: 'Out of Range',
                        y: Math.abs(res.data.undefined),
                        color:'#dedee3',
                    },
                    
                    ) 
                     
                }

                SetDonutData(letSeriesData);
                let  configDonutEG= {
                    chart: {
                        plotBackgroundColor: null,
                        plotBorderWidth: null,
                        plotShadow: false,
                        type: 'pie',
                        height:180
                    },
                    title: {
                        text: ''
                    },
                    tooltip: {
                        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                    },
                    accessibility: {
                        point: {
                            valueSuffix: '%'
                        }
                    },
                    exporting: {
                        enabled: false
                    },
                    credits: {
                        enabled: false
                      },
                    plotOptions: {
                        pie: {
                            allowPointSelect: true,
                            cursor: 'pointer',
                            size:"100%",
                            dataLabels: {
                                enabled: false
                            },
                            showInLegend: false
                        }
                    },
                    series: [{
                        name: 'Productivity',
                        innerSize: '60%',
                        colorByPoint: true,
                        data:letSeriesData,
                        cursor: 'pointer',

                    }]
                };
                SetConfigDonut(configDonutEG)
                setDonutLoader(false)

           
           
            })

            getIndividualProductivityList(requestBody,userSession,org_id).then(res=>{

                if(res.status==200){
                    SetProductivity(res.data.productive);
                    SetLessProductivity(res.data.less_productive)
                    SetNonProductivity(res.data.non_productive)
                }
            }) 
        }

    },[selectBarTime,selectedDate,permission])

    function converttoTime(val){

        let seconds = val / 1000;
                // 2- Extract hours:
                const hours = parseInt( seconds / 3600 ); // 3,600 seconds in 1 hour
                seconds = seconds % 3600; // seconds remaining after extracting hours
                // 3- Extract minutes:
                const minutes = parseInt( seconds / 60 ); // 60 seconds in 1 minute
                // 4- Keep only seconds not extracted to minutes:
                seconds = seconds % 60;
                //alert( hours+":"+minutes+":"+seconds);
                return hours>0 ?<><span>{hours}</span>H <span>{minutes}</span>M <span>{seconds}</span>S</>:<><span>{minutes}</span>M <span>{seconds}</span>S</>


    }
    
    function converttoTimeAG(val){
        let seconds = val / 1000;
        const hours = parseInt( seconds / 3600 );
        seconds = seconds % 3600;
        const minutes = parseInt( seconds / 60 ); 
        seconds = seconds % 60;
        return hours>0 ? hours +":"+ minutes+":"+seconds:"00:"+ minutes+":"+seconds


    }
    
    useEffect(()=>{
        if(permission && permission.View=='True'){
        let requestBody={}
        requestBody.startdate=getDateFormat(selectBarTime)
        requestBody.enddate=getDateFormat(selectBarTime)
        requestBody.slug=slug
        requestBody.tag_serial=idVal
        setChartLoader(true)

        let maxVal = 0;
        let dataOFselected=[]
        let shiftHour=[]
        let prodloc=[]    
        getPproductivityDetailOverview(requestBody,userSession,org_id).then(res=>{
 
            let shift = res.shift?JSON.parse(res.shift):[]
            let startSft= res.shift?shift.start_time.split(':'):null
            let endSft= res.shift?shift.end_time.split(':'):null
            let cmlHour = res.shift?shift.productive_work_time:0
            let noncmlHour = res.shift?shift.wasted_work_time:0
            
            SetShiftHourworking(res.shift?shift.shift_hours:0);

            SetShiftStartTime(startSft?startSft[0]+":"+startSft[1]:'-');
            SetShiftEndTime(endSft?endSft[0]+":"+endSft[1]:'-')
            // let st = parseInt(startSft?startSft[1]&& startSft[1] > 40?startSft[0]==23?0:startSft[0]+1:startSft[0]:0);
            // let et = parseInt(endSft?endSft[1]&& endSft[1] > 40?endSft[0]== 23 ? 0:endSft[0]+1:endSft[0]:0);
            let st = parseInt(startSft && startSft[0]?parseInt(startSft[1]) > 40 && parseInt(startSft[0])!=23 ?parseInt(startSft[0]) + 1:parseInt(startSft[0]):0);
            let et = parseInt(endSft && endSft[0]?parseInt(endSft[1]) > 40 && parseInt(endSft[0])!=23 ?parseInt(endSft[0]) + 1:parseInt(endSft[0]):0);
            //console.log("condition Value",startSft,startSft && startSft[0]?parseInt(startSft[1]) > 40 && parseInt(startSft[0])!=23 ?parseInt(startSft[0]) + 1:parseInt(startSft[0]):0)
            //console.log("St et",st,et);
            let oft=[]
            let smaller=st>et?et:st;
            let bigger = st>et?st:et;
            cmlHour = moment.duration(cmlHour).asMilliseconds()
            noncmlHour= moment.duration(noncmlHour).asMilliseconds()
            SetCMLWorkingHour(converttoTime(moment.duration(cmlHour).asMilliseconds()));
            SetCMLNonWorkingHour(converttoTime(moment.duration(noncmlHour).asMilliseconds()))


          
          
           SetWorkingPercent(res.shift?shift.productive_work_percent:0);
           SetNonWorkingPercent(res.shift?shift.wasted_work_percent:0)
        

            if(res.shift){
                //console.log("Shift Status",res.shift);
 
                for(let i=0;i<cat3.length;i++){



                    if(st > et){
                        if( (smaller <= cat3[i]) && (bigger > cat3[i]) ){

                            

                            if(smaller == (i-1)){
                                //console.log('What is ',cat3[i])
                                oft.push({
                                    color: '#b2a5a536',
                                    from: (i- 2) + .8,
                                    to: (i) - .8
                                })
                                oft.push({
                                    color: '#b2a5a536',
                                    from: (i -1)+.75,
                                    to: (i)+.75
                                })
    
                                oft.push({
                                    color: '#b2a5a536',
                                    from: i-1,
                                    to: i
                                })
                                
                            }else{
                                oft.push({
                                    color: '#b2a5a536',
                                    from: (i-1)+.75,
                                    to: i+.75
                                })
        
                            }
    
    
                        }else{
                           
                            shiftHour.push(cat3[i]);
                        }
                    }else{
                        //console.log("condition end bigger ",st,et);
                        if( (smaller <= cat3[i]) && (bigger > cat3[i]) ){

                            shiftHour.push(cat3[i]);    
                        }else{

                            if(smaller == (i-1)){
                                //console.log('What is ',cat3[i])
                                // // oft.push({
                                // //     color: '#b2a5a536',
                                // //     from: (i- 1) + .8,
                                // //     to: (i) - .8
                                // // })
                                oft.push({
                                    color: '#b2a5a536',
                                    from: (i-1)+.75,
                                    to: (i)+.75
                                })
    
                                oft.push({
                                    color: '#b2a5a536',
                                    from: (i+ .3)-1,
                                    to: i+.3
                                })
                                
                            }else{
                                oft.push({
                                    color: '#b2a5a536',
                                    from: (i-1)+.75,
                                    to: i+.75
                                })
        
                            }                           
                            
                        }
                    }


                }
                SetOffShiftTime(oft);

            }
            let allData= [] ;
            let allTIme=0;
            let allProdTime = 0;


            Object.keys(res.data).forEach((key)=>{

                //console.log("Shift Hour",shiftHour,key)
                    let inShift= shiftHour.find(item=>parseInt(item) == parseInt(key))
                    
                    //console.log("Shift test",shiftHour,key,inShift)
                    if(inShift!==undefined ){
                            allData.push({id:key,value:res.data[key],shift:true})       
                    }else{
                        allData.push({id:key,value:res.data[key],shift:false})   
                    }   

                
            })
            
            let productive=[];
            let nonproductive=[];
            let lessproductive=[];
            let undefinedproductive=[];

            let productiveWork=[];
            let nonproductiveWork=[];
            let lessproductiveWork=[];
            let undefinedproductiveWork=[];
 
            let arTime=[]
            //console.log("All Data",allData)
            for(let i=0;i<cat3.length;i++){


                let item = allData.find(item=>item.id==cat3[i] )
                let timeProd=0

                let lessProd=0
                let nonProd=0

                if(item && item.shift===true){


                    //console.log('check',item);



                    Object.keys(item.value.productive).forEach((key,i)=>{
                        let elm = item.value.productive[key];
                        timeProd= timeProd + Math.round(moment.duration(elm.time).asMilliseconds());
                   

                         let index = prodloc.findIndex(item=>item.name==key);
                        //console.log(" item loc",key, index);
                        if(index > -1){
                            
                            let ti = prodloc[index].time + Math.round(moment.duration(elm.time).asMilliseconds())                            
                            //console.log("All Loop ",prodloc,item.id,key)
                            prodloc[index]={name:key,time:ti};

                        }else{
                            //console.log("All Loop ",prodloc,item.id,key)
                            prodloc.push({name:key,time:Math.round(moment.duration(elm.time).asMilliseconds()) })
                            


                        }                       
                     })
                     //console.log("productive hour",item,prodloc)

                     productive.push({y:timeProd,tooltip:item.value.productive})
                     productiveWork.push({y:timeProd,tooltip:item.value.productive})
                    Object.keys(item.value.less_productive).forEach((key,i)=>{
                        let elm = item.value.less_productive[key];
                        lessProd= lessProd + Math.round(moment.duration(elm.time).asMilliseconds());

                    
                    
                         })
                    lessproductive.push({y:lessProd,tooltip:item.value.less_productive})
                    lessproductiveWork.push({y:lessProd,tooltip:item.value.less_productive})
                    allProdTime = allProdTime+ lessProd;
                     Object.keys(item.value.non_productive).forEach((key,i)=>{
                        let elm = item.value.non_productive[key];
                        nonProd= nonProd + Math.round(moment.duration(elm.time).asMilliseconds());
                      
                      
                         })

                   
                    nonproductive.push({y:nonProd,tooltip:item.value.non_productive})
                    nonproductiveWork.push({y:nonProd,tooltip:item.value.non_productive})
                    
                    if(maxVal< timeProd ){
                        maxVal= timeProd;
                        dataOFselected={name:'Productive',data:item.value.productive,time:categories2[i]}
                    }

                    if(maxVal<nonProd){
                        maxVal=nonProd;
                        dataOFselected={name:'Non Productive',data:item.value.non_productive,time:categories2[i]}
                    }

                    if(maxVal<lessProd){
                        maxVal=lessProd;
                        dataOFselected={name:'Less Productive',data:item.value.less_productive,time:categories2[i]}
                    }


                }else if(item){

                    Object.keys(item.value.productive).forEach((key,i)=>{
                        let elm = item.value.productive[key];
                        timeProd= timeProd + Math.round(moment.duration(elm.time).asMilliseconds());
                        

                     })

                     

                    productive.push({y:timeProd,tooltip:item.value.productive})
                    allProdTime = allProdTime+ timeProd;
                    Object.keys(item.value.less_productive).forEach((key,i)=>{
                        let elm = item.value.less_productive[key];
                        lessProd= lessProd + Math.round(moment.duration(elm.time).asMilliseconds());

        
                         })
                    lessproductive.push({y:lessProd,tooltip:item.value.less_productive})
                    allProdTime = allProdTime+ lessProd;
                    Object.keys(item.value.non_productive).forEach((key,i)=>{
                        let elm = item.value.non_productive[key];
                        nonProd= nonProd + Math.round(moment.duration(elm.time).asMilliseconds());
                 
                         })
                    nonproductive.push({y:nonProd,tooltip:item.value.non_productive})
                    allProdTime = allProdTime+ nonProd;
                    if(maxVal< timeProd ){
                        maxVal= timeProd;
                        dataOFselected={name:'Productive',data:item.value.productive,time:categories2[i]}
                    }

                    if(maxVal<nonProd){
                        maxVal=nonProd;
                        dataOFselected={name:'Non Productive',data:item.value.non_productive,time:categories2[i]}
                    }

                    if(maxVal<lessProd){
                        maxVal=lessProd;
                        dataOFselected={name:'Less Productive',data:item.value.less_productive,time:categories2[i]}
                    }

                    productiveWork.push({y:0,tooltip:[]})
                    nonproductiveWork.push({y:0,tooltip:[]})
                    lessproductiveWork.push({y:0,tooltip:[]})
                    undefinedproductiveWork.push({y:0,tooltip:[]})
                }else{
                    productive.push({y:0,tooltip:[]})
                    nonproductive.push({y:0,tooltip:[]})
                    lessproductive.push({y:0,tooltip:[]})
                    undefinedproductive.push({y:0,tooltip:[]})

                    productiveWork.push({y:0,tooltip:[]})
                    nonproductiveWork.push({y:0,tooltip:[]})
                    lessproductiveWork.push({y:0,tooltip:[]})
                    undefinedproductiveWork.push({y:0,tooltip:[]})

                }
 
            }

            //console.log("ProdLocation",prodloc);
            SetProductiveLocation(prodloc)
            SetSelectedSeries({name:dataOFselected.name,data:dataOFselected.data,time:dataOFselected.time})

            SetChartData({
                series:[
                  {
                        name:"Out of Range",
                        data:undefinedproductive,
                        color:"#dedee3",
                        cursor: 'pointer',
                        states: {
                            select: {
                                
                                color: '#ef5e8c',
                                borderColor:'#ef5e8c',
                            },
                        },
                        allowPointSelect: true,
                        point: {
                            events: {
                                click: (e) => {
                                    e.point.select(true);
                                    formatSelectedData(e);                 
                                
                                }
                            }
                        }   
                
                    },
                  {
                    name:"Non Productive",
                    data:nonproductive,
                    color:"#fc0001",  
                    cursor: 'pointer',
                    states: {
                        select: {
                            
                            color: '#ef5e8c',
                            borderColor:'#ef5e8c',
                        },
                    },
                    allowPointSelect: true,
                    point: {
                        events: {
                            click: (e) => {
                                e.point.select(true);
                                formatSelectedData(e);                 
                            
                            }
                        }
                    }
                },
                {
                    name:"Less Productive",
                    data:lessproductive,
                    color:"#fdbf00",
                    cursor: 'pointer',
                    states: {
                        select: {
                            
                            color: '#ef5e8c',
                            borderColor:'#ef5e8c',
                        },
                    },
                    allowPointSelect: true,
                    point: {
                        events: {
                            click: (e) => {
                                e.point.select(true);
                                formatSelectedData(e);                 
                            
                            }
                        }
                    },
                },
                  {
                    name:"Productive",
                    data:productive,
                    color:"#90cf4e",
                    cursor: 'pointer',
                    states: {
                        select: {
                            
                            color: '#ef5e8c',
                            borderColor:'#ef5e8c',
                        },
                    },
                    allowPointSelect: true,
                    point: {
                        events: {
                            click: (e) => {
                                e.point.select(true);
                                formatSelectedData(e);                 
                            
                            }
                        }
                    },
                },
                    ]  
                })


                SetChartDataWorking({
                    series:[
                      {
                            name:"Out of Range",
                            data:undefinedproductiveWork,
                            color:"#dedee3",
                            cursor: 'pointer',
                            states: {
                                select: {
                                    
                                    color: '#ef5e8c',
                                    borderColor:'#ef5e8c',
                                },
                            },
                            allowPointSelect: true,
                            point: {
                                events: {
                                    click: (e) => {
                                        e.point.select(true);
                                        formatSelectedData(e);                 
                                    
                                    }
                                }
                            }   
                    
                        },
                      {
                        name:"Non Productive",
                        data:nonproductiveWork,
                        color:"#fc0001",  
                        cursor: 'pointer',
                        states: {
                            select: {
                                
                                color: '#ef5e8c',
                                borderColor:'#ef5e8c',
                            },
                        },
                        allowPointSelect: true,
                        point: {
                            events: {
                                click: (e) => {
                                    e.point.select(true);
                                    formatSelectedData(e);                 
                                }
                            }
                        }
                    },
                    {
                        name:"Less Productive",
                        data:lessproductiveWork,
                        color:"#fdbf00",
                        cursor: 'pointer',
                        states: {
                            select: {
                                
                                color: '#ef5e8c',
                                borderColor:'#ef5e8c',
                            },
                        },
                        allowPointSelect: true,
                        point: {
                            events: {
                                click: (e) => {
                                    e.point.select(true);
                                    formatSelectedData(e);                 
                                
                                }
                            }
                        },
                    },
                      {
                        name:"Productive",
                        data:productiveWork,
                        color:"#90cf4e",
                        cursor: 'pointer',
                        states: {
                            select: {
                                
                                color: '#ef5e8c',
                                borderColor:'#ef5e8c',
                            },
                        },
                        allowPointSelect: true,
                        point: {
                            events: {
                                click: (e) => {
                                    e.point.select(true);
                                    formatSelectedData(e);                 
                                
                                }
                            }
                        },
                    },
                        ]  
                    })

                SetmaxValue(maxVal)
                // config.yAxis.max=10;
                // config.yAxis.tickAmount= 1;

                setChartLoader(false)
            });     

 

        }

    },[permission,selectBarTime])


   useEffect(()=>{
    if(permission && permission.View=='True'){   
    let requestBody={}
       requestBody.slug=slug
       requestBody.tag_serial=idVal;
       requestBody.startdate= getDateFormat(selectBarTime);
       requestBody.enddate= getDateFormat(selectBarTime);
       getProductivityIndividualInfo(requestBody,userSession,org_id).then(res=>{
           
           if(res.status===200){
               SetIndividualInfo(res.data);
           }
       })
    }
   },[selectBarTime,selectedDate,productivity]) 
    const handleDate = (date, type) => {
        //setDates((prev) => ({ ...prev, [`${type}`]: date }));
        if(type=='start'){
            localStorage.setItem('ProductiveStart',date);
        }else{
            localStorage.setItem('ProductiveEnd',date);
        }
        
               
       };   

    function handleSiteListClick() {
        props.history.push('/productivity')
    }


    const handleMouseEnter = (id) => {
        let doc = document.getElementById(id);
    
        if (doc) {
          doc.style.display = "block";
        }
      };

      const handleMouseLeave = (id) => {
        let doc = document.getElementById(id);
    
        if (doc) {
          doc.style.display = "none";
        }
      };

    useEffect(()=>{
        if(permission && permission.View=='True'){
        let requestBody={}
        // requestBody.startdate= getDateFormat(dates.start) ;
        // requestBody.enddate=getDateFormat(dates.end);
        requestBody.startdate= getDateFormat(selectBarTime) ;
        requestBody.enddate=getDateFormat(selectBarTime);
        requestBody.tag_serial = idVal;
        requestBody.slug=slug
        SetLoaderWidget(true)
        getPproductivityWidget(requestBody,userSession,org_id).then(res=>{
            if(res.status==200){
                updateemployeeData(res.data);

            }
            SetLoaderWidget(false)
        })
    }
    },[selectBarTime,permission])

    useEffect(()=>{
        if(permission && permission.View=='True'){
        ShowCardLocation();
        //ShowCardAcess();
        ShowCardUnProductive();
        setTimeout(function(){
            SetLoaderWidgetBottom(false)
        },500)

        }
    },[employeeData,permission])

    

      const handleSortunproductive = (key) => {

        setSortKeyunproductive(key)
        setSortTypeunproductive(sortTypeunproductive === 'desc' ? 'asc' : 'desc')
        
        setSortKey(key)
        setSortType(sortTypeunproductive)

      }


      const handleSortlocationVisited = (key) => {
    
        setSortKeylocationVisited(key)
        setSortTypelocationVisited(sortTypelocationVisited === 'desc' ? 'asc' : 'desc')
        setSortKey(key)
        setSortType(sortTypelocationVisited)
    
    
      }
    const handleSortAcess = (key )=>{
    
        setSortKeyacess(key)
        setSortTypeacess(sortTypeacess === 'desc' ? 'asc' : 'desc')
        setSortKey(key)
        setSortType(sortTypeacess)
        
    }

    function capitalizeFirstLetter(string) {
        return string.toLowerCase();
    }


    function ShowCardLocation(){

        let arr = [];
        let data = employeeData.locations_visited?employeeData.locations_visited:[];
        let arr2 =[];
        arr2 = data.sort((a,b) => {
          let aMs = moment(a.time, 'HH:mm:ss').format('x')
          let bMs = moment(b.time, 'HH:mm:ss').format('x')
          return  sortTypelocationVisited=='desc'? bMs - aMs  : aMs - bMs
       })  
        for (let index = 0; index < arr2.length; index++) {
          let element = data[index];
          Object.keys(element).forEach((key) => {
            arr.push({name:element[key].name,time:element[key].time,tag_serial:key});
         
          });
        }
        SetLocationVisitedData(arr);
    }
    
    function handleClickCard(id) {
         history.push(`/site-list/view/:${id}`)
           
     }
     const timeComparator = (valueA, valueB, nodeA, nodeB, isInverted) => {



        let aMs = moment(valueA, 'HH:mm:ss').format('x')
        let bMs = moment(valueB, 'HH:mm:ss').format('x')
            if(valueA=='na' || valueB=='na')return 0;
            if (valueA == valueB) return 0;
            return (aMs > bMs) ? 1 : -1;       
     };

    function AGTableLocationVisited(arr2){

        let widthCal=widthContainer
       let arr=<div className='nochildAGTab'><div className="ag-theme-alpine if cell-size-35" style={{height: 275, maxHeight:275  , width: "100%"}}>
        <AgGridReact
                 rowHeight={35}
                // defaultColDef={{sortable: true,resizable: true,minWidth: 45,width:135}}
                defaultColDef={{sortable: true,resizable: true,minWidth:45,
                suppressColumnVirtualisation:true,skipHeaderOnAutoSize:true}}
                 rowData={arr2}
                 key={"AgLocationVistedData"}
                 onCellClicked={ (event) => handleClickCard(event.data.tag_serial) }
                 
                 overlayNoRowsTemplate={
         
                     '<span class="ag-overlay-no-rows-center">No data Found !</span>'
                   }
    
         >
        <AgGridColumn
            field="name"
            headerName={"Location Visited for selected period"}
           valueFormatter={(params)=>capitalizeFirstLetter(params.value) } 
           width={widthContainer - 150}
           
        />
            <AgGridColumn sort={"desc"} field="time" headerName="Time" comparator={timeComparator} width={"100"}
            valueFormatter={(params)=>capitalizeFirstLetter(params.value) } 
            >


            </AgGridColumn>
        </AgGridReact>
    </div></div>
    return arr;
    }
    
    function RedirectSiteManagement(tag_serial){

        props.history.push(`/site-list/view/:${tag_serial}`)
    }

    

    function ShowCardUnProductive(){
        let arr = [];
        let data = employeeData.Less_non_productive?employeeData.Less_non_productive:[];
        let arr2 =[];
        arr2 = data.sort((a,b) => {
          let aMs = moment(a.time, 'HH:mm:ss').format('x')
          let bMs = moment(b.time, 'HH:mm:ss').format('x')
          return  sortTypeunproductive=='desc'? bMs - aMs  : aMs - bMs
       })
    
        for (let index = 0; index < arr2.length; index++) {
          let element = data[index];
          let inc=0;
          Object.keys(element).forEach((key) => {
              if(key=='time'){
              }else{
                arr.push({name:Object.keys(element[key])[0],type:Object.values(element[key])[0],time:Object.values(element[key])[1],tag_serial:key});
                    // arr.push(
                    // <tr className="employee-detail-table-tr" key={'ShowCardUnProductive'+index} onClick={()=>RedirectSiteManagement(key)} style={{cursor:"pointer"}}>
                    //     <td>{capitalizeFirstLetter(Object.keys(element[key])[0])}</td>
                    //     <td className="employee-detail-table-tr-td">{Object.values(element[key])[0]=='non_productive'?<span className='danger productiveTable'>{Object.values(element[key])[1]!=null?Object.values(element[key])[1]:""}</span>:Object.values(element[key])[0]=='less_productive'?<span className='warning productiveTable'>{Object.values(element[key])[1]!=null?Object.values(element[key])[1]:""}</span>:<span className='green productiveTable'>{Object.values(element[key])[1]!=null?Object.values(element[key])[1]:""}</span>}</td>
                    // </tr>
                    // );
                }
                inc++;
          });
        }
        SetUnproductiveVisitedData(arr) ;

    
    }

    function StatusTypeRender(params){
        var eGui = document.createElement('div');

        if(params.data.type=='non_productive'){
            eGui.innerHTML = `<span class='danger productiveTable'>${params.value}</span>`
        }else if(params.data.type=='less_productive'){
            eGui.innerHTML = `<span class='warning productiveTable'>${params.value}</span>`
        }else{
            eGui.innerHTML = `<span class='green productiveTable'>${params.value}</span>`
        }

         return eGui;
    }


    function AGTableUnprotected(arr2){
       let arr=<div className='nochildAGTab'><div className="ag-theme-alpine if cell-size-35" style={{height: 275, maxHeight:275  , width: "100%"}}>
        <AgGridReact
                 rowHeight={35}
                // defaultColDef={{sortable: true,resizable: true,minWidth: 45,width:135}}
                defaultColDef={{sortable: true,resizable: true,minWidth:45,
                suppressColumnVirtualisation:true,skipHeaderOnAutoSize:true}}
                 rowData={arr2}
                 key={"AGTableUnprotected"}
                 onCellClicked={ (event) => handleClickCard(event.data.tag_serial) }
                 
                 overlayNoRowsTemplate={
         
                     '<span class="ag-overlay-no-rows-center">No data Found !</span>'
                   }
    
         >
        <AgGridColumn
            field="name"
            headerName={"Less and unproductive location"}
           valueFormatter={(params)=>capitalizeFirstLetter(params.value) } 
           width={widthContainer - 150}
           
        />
            <AgGridColumn sort={"desc"} field="time" headerName="Time" comparator={timeComparator} width={"100"} 
                cellRenderer={StatusTypeRender}
            ></AgGridColumn>
        </AgGridReact>
    </div></div>
    return arr;
    }
    
    function ShowDataTabActive(){
        let arr2=[];
        if(activeTab=='productivity'){
            arr2= productivity
        }else if(activeTab=='lessproductivity'){
            arr2= lessproductivity
        }else{
            arr2= nonproductivity
        }
    
        let arr=
            <div className="siteWidgetTable siteWidgetTable2 col-lg-12"><div className='nochildAGTab'><div className="ag-theme-alpine if cell-size-35" style={{height: 35 + ((arr2.length)*35), maxHeight:250  , width: "100%"}}>
        <AgGridReact
                 rowHeight={35}
                 headerHeight={35}
                 groupHeaderHeight={35}

                // defaultColDef={{sortable: true,resizable: true,minWidth: 45,width:135}}
                defaultColDef={{sortable: true,resizable: true,minWidth:45,flex:1,
                suppressColumnVirtualisation:true,skipHeaderOnAutoSize:true}}
                 rowData={arr2}
                 key={"AGTableUnprotected"}
                 onCellClicked={ (event) => handleClickCard(event.data.tag_serial) }
                 
                 overlayNoRowsTemplate={         
                     '<span class="ag-overlay-no-rows-center">No data Found !</span>'
                   }

         >
        <AgGridColumn
            field="name"
            headerName={"Location"}
           valueFormatter={(params)=>capitalizeFirstLetter(params.value) } 
        />
         
        </AgGridReact>
    </div></div></div>
    return arr;
    }

    function ShowCardAcess(){


        let arr = [];
        let arr2 =[];
        let data = employeeData.access_policy_breach?employeeData.access_policy_breach:[];
        arr2 = data.sort((a,b) => {
          let aMs = a.time
          let bMs = b.time
          return  sortTypeacess=='desc'? bMs - aMs  : aMs - bMs
       })






        for(let i=0;i<data.length;i++){
            let element= data[i]

            arr.push({name:element[Object.keys(element)[0]].name,time:element[Object.keys(element)[0]].count,tag_serial:Object.keys(element)[0]});
 
        }
        
        SetAcessData(arr)
    }


    function AGTableAcessData(arr2){
        let arr=<div className='nochildAGTab'><div className="ag-theme-alpine if cell-size-35" style={{height: 275, maxHeight:275  , width: "100%"}}>
         <AgGridReact
                  rowHeight={35}
                 // defaultColDef={{sortable: true,resizable: true,minWidth: 45,width:135}}
                 defaultColDef={{sortable: true,resizable: true,minWidth:45,
                 suppressColumnVirtualisation:true,skipHeaderOnAutoSize:true}}
                  rowData={arr2}
                  key={"AGTableAcessData"}
                  onCellClicked={ (event) => handleClickCard(event.data.tag_serial) }
                  
                  overlayNoRowsTemplate={
          
                      '<span class="ag-overlay-no-rows-center">No data Found !</span>'
                    }
     
          >
         <AgGridColumn
             field="name"
             headerName={"Productive Location"}
            valueFormatter={(params)=>capitalizeFirstLetter(params.value) } 
            width={widthContainer - 150}
            
         />
             <AgGridColumn sort={"desc"} field="time" headerName="Time" width={"100"}
             valueFormatter={(params)=>  converttoTimeAG(params.value) } 

              ></AgGridColumn>
         </AgGridReact>
     </div></div>
     return arr;
     }
 

    useEffect(() => {

        let idVal = props.match.params.id.replace(":", "")

        if (idVal) {
            let date = getDateFormat(selectedDate)

            
        }

    }, [selectedDate]);


function AddZeroFormatter(value){
  let sp =value.toString().split('.');
  let inital=parseInt(sp[0])?parseInt(sp[0]):0;
  let end= parseInt(sp[1])?parseInt(sp[1]):0;
  // if(parseInt(sp[0]) < 10){
  //   inital ='0'+ parseInt(sp[0]);
  // }
  if(parseInt(sp[1]) < 10){
    end ='0'+ parseInt(sp[1]);
  }
  ////console.log("AddZeroFormatter",value,parseFloat(inital+"."+end))
  return parseFloat(inital+"."+end);
  //return value;
}

    

    function getDateFormat(date) {
        return moment(date).format('YYYY-MM-DD')
    }

  
  
    function handleDateSelect(date) {
        updateSelectedDate(date)
        SetSelectBarTime(date);
        
        // setDates({
        //     start: date,
        //     end: date,
        // })
        localStorage.setItem('ProductiveStart',date);
        localStorage.setItem('ProductiveEnd',date);
    
  }

    function changeLanguage(lang) {
        getLanguageTranslation(lang).then(res => {
            if (res && res.status >= 200 && res.status <= 200) {
                localStorage.setItem('languageData', JSON.stringify(res.data))
                localStorage.setItem('selectedLanguage', lang)
                props.setSelectedLanguage(lang)

            }
        })
    }



    useEffect(() => {
        if (props.language) {
            updateSelectedLangValue(props.language)
        }
    }, [props.language])

    function RedirectUser(data){

        props.history.push(`/site-list/view/:${data.location_serial}`)

    }

    function DisplayToolTipData(){

        let data = selectedSeries;
        let arr=[];
        if(data.data!=null){

        
        Object.keys(data.data).forEach((key,i)=>{
       arr.push(
        <Row style={{ alignItems: 'center' }} key={"data"+i} onClick={()=>RedirectUser(data.data[key])}>
        <Col className="b-r" style={{ maxWidth: "70%",minWidth:"70%" }} >
            <b className="font-bold">{capitalizeFirstLetter(key)}</b>
        </Col>
        <Col className="" style={{ maxWidth: "30%",minWidth:"30%" }} >
            {/* <div className="priSriMriText">Spread Index</div> */}
            <h6 className="font-bold">{data.data[key].time ? data.data[key].time + '' : '-'}</h6>
        </Col>
    </Row>

    )
        })
    }

        return arr;
    }


        return (
            <>{permissionAPICall?permission.View=="True"?<div className="siteViewMainDiv manpowerManagementMainDiv productivityManagementDiv">
                <div className='employeeDetailsMainDiv'>
                <Container className='header-breadcrumb'>
                <Row>
                    <Col lg={3} className={'m-t-sm'}>
                        <div className="siteViewHeaderDiv">
                            <span className="smallHeader" onClick={handleSiteListClick}>{getTranslatedText('Productivity')}</span>
                            <span className="breadCrumbArrow"> &gt; </span>
                            <span className="mediumHeader">{getTranslatedText('Employee detail')}</span>
                        </div>
                    </Col>
                    <Col lg={9} className="text-right">
         
                <div className="dashboardLanguageMainDiv m-r-md">
                            <DashboardLanguage
                                selectedLangValue={selectedLangValue}
                                changeLanguage={changeLanguage}
                            />
                        </div>
                        <div className="commonHeadingDateMainDiv">
                            <CommonDatePicker
                                selectedDate={selectedDate}
                                handleSelectDate={handleDateSelect}
                            />
                        </div>

                        <DeviceStatus permission={permission} role={props.role}/>
                    </Col>
                    </Row>
                <div className='manpowerManagementEmployeeListMainDiv manpowerManagementEmployeeListProductivity m-t-lg'>
                  
                    <Row className="">

                        <Col lg={3}>
                            <div className="siteViewDetailsLeftSideDiv" style={{ height: '100%' }}>
                                <div className="headerNameDiv">{individualInfo!=null?individualInfo.name:"-"}</div>
                                <div className="subHeaderDiv">{individualInfo!=null?individualInfo.department:"-"}</div>
         
                                <div className="separaterLine"></div>

                                <div className="batteryMainDiv batteryMainDiv2 m-t-lg" style={{overflow:"auto"}}>
                                <div style={{float:"left"}}><img src={batteryIcon} /></div> 
                                    <div style={{float:"left",lineHeight:"20px"}}>&nbsp;{individualInfo.battery_status}{individualInfo.battery_status=='na'? '' : '%'} ({individualInfo.tag_serial})<br/>
                                    </div>
                                </div>
                                <div className="recommendMainDiv">
                                    {/* <h5 className="font-bold text-white">{getTranslatedText('Recommend')}</h5> */}

                                    <div className="tab-view tab-view-2">
                                            <div className={activeTab=='productivity'?"tab tab-activity cursor-pt active":"tab tab-activity cursor-pt"}  onClick={()=>SetActiveTab('productivity') }>Productivity</div>
                                            <div className={activeTab=='nonproductivity'?"tab tab-activity cursor-pt active":"tab tab-activity cursor-pt"} onClick={()=>SetActiveTab('nonproductivity') }>Non Productivity</div>
                                            <div className={activeTab=='lessproductivity'?"tab tab-activity cursor-pt active":"tab tab-activity cursor-pt"} onClick={()=>SetActiveTab('lessproductivity') }>Less Productivity</div>
                                        </div>
                                    {ShowDataTabActive()}
                                    {/* <div className="recommendListMainDiv m-t-md text-white">
                                       

                                        <div className="eachRecommendCardDiv">
                                            <Row>
                                                <Col lg={8}>
                                                    <span>Close contact with people count</span>
                                                </Col>

                                                <Col lg={4} className="text-right ">
                                                    <strong>
                                                        {individualInfo!=null?individualInfo.close_contact_people:"-"} 
                                                    </strong>
                                                </Col>
                                            </Row>
                                        </div>

                                        <div className="eachRecommendCardDiv">
                                            <Row>
                                                <Col lg={8}>
                                                    <span>Close contact with location count</span>
                                                </Col>

                                                <Col lg={4} className="text-right ">
                                                    <strong>
                                                    {individualInfo!=null?individualInfo.close_contact_locations:"-"} 
                                                    </strong>
                                                </Col>
                                            </Row>
                                        </div>

                                        <div className="eachRecommendCardDiv">
                                            <Row>
                                                <Col lg={8}>
                                                    <span>Interacted Employee Count</span>
                                                </Col>

                                                <Col lg={4} className="text-right ">
                                                    <strong>
                                                    {individualInfo!=null?individualInfo.interacted_employee_count:"-"} 
                                                    </strong>
                                                </Col>
                                            </Row>
                                        </div>


                                    </div> */}
                                </div>

                            </div>
                        </Col>
                        <Col lg={9} className='left-no-space-resp'>

                            <div className="siteViewRightSideDiv">
                                <Row>
                                    {/* <Col lg={4}>
                                    <div
                                        className="startDateEndDateMainDiv date-start date-label-full"
                                        style={{ paddingLeft: "0px" }}
                                        >
                                        <label>Start Date</label>
                                        <DatePicker
                                            selected={dates.start}
                                            onChange={(date) => handleDate(date, "start")}
                                            dateFormat={'MMM dd'}
                                            isClearable={false}
                                            placeholderText={getTranslatedText(
                                            "Start Date"
                                            )}
                                            maxDate={moment(dates.end).toDate()}
                                            minDate={moment().subtract(120, "days").toDate()}
                                        />
                                        
                                        </div>

                                        <div
                                            className="startDateEndDateMainDiv date-end date-label-full"
                                            style={{ paddingLeft: "0px" }}
                                            >
                                            <label>End Date</label>
                                            <DatePicker
                                                selected={moment(dates.end).toDate()}
                                                onChange={(date) => handleDate(date, "end")}
                                                dateFormat={'MMM dd'}
                                                isClearable={false}
                                                placeholderText={getTranslatedText("End Date")}
                                                maxDate={selectedDate}
                                                minDate={dates.start}
                                            />
                                            
                                            </div>

                                    </Col> */}
                                    <Col lg={4} className=''>
                                        
                                        
                                     <div>
                                    <div className="startDateEndDateMainDiv date-start date-label-full" style={{width:"150px",float:"left"}} >            
                                        <DatePicker
                                                selected={selectBarTime}
                                                onChange={(date) => SetSelectBarTime(date)}
                                                dateFormat={'MMM dd'}
                                                isClearable={false}
                                                placeholderText={getTranslatedText(
                                                "Start Date"
                                                )}
                                                maxDate={moment(selectedDate).toDate()}
                                                minDate={moment().subtract(120, "days").toDate()}
                                                style={{maxWidth:100}}
                                            />
                                        </div>    
                                        </div>   
                                        <div className='align-right ct-data ct-data-site ct-data-site-2 pos-relative-data' style={{width:"100%"}}>
                                            <div class="ct-graph-data ct-graph-data-false" style={{marginTop:"10px",float:"left" }}>
                                                    <Row  style={{ alignItems: 'center' }}>
                                                        <Col className="b-r" style={{ maxWidth: "40%",minWidth:"40%" }} >
                                                            <b className="font-bold" style={{fontWeight:"bold"}}>Shift</b>
                                                        </Col>
                                                        <Col className="b-r col" style={{ maxWidth: "40%",minWidth:"40%" }} >
                                                            {/* <div className="priSriMriText">Spread Index</div> */}
                                                            <h6 className="font-bold" style={{fontWeight:"bold"}}>{shiftStartTime} - {shiftEndTime}</h6>
                                                        </Col>
                                                        <Col className="" style={{ maxWidth: "20%",minWidth:"20%" }} >
                                                           <h6 className="font-bold" style={{fontWeight:"bold"}}>{shiftHourWorking} Hr</h6>
                                                        </Col>

                                                    </Row>
                                                    <Row className='text-whiteSpace' style={{ alignItems: 'center' }}>
                                                        <Col className="b-r" style={{ maxWidth: "40%",minWidth:"40%",lineHeight:1.1 }} >
                                                            <b className="font-bold" style={{lineHeight:1.3}}>Total Working Hour</b>
                                                        </Col>
                                                        <Col className="b-r col" style={{ maxWidth: "40%",minWidth:"40%" }} >
                                                           <h6 className="font-bold">{cmlWorkingHour}</h6>
                                                        </Col>
                                                        <Col className="" style={{ maxWidth: "20%",minWidth:"20%" }} >
                                                           <h6 className="font-bold">{workingPercent==''?'':workingPercent+' %'}</h6>
                                                        </Col>
                                                        
                                                    </Row>
                                                    <Row className='text-whiteSpace' style={{ alignItems: 'center' }}>
                                                        <Col className="b-r" style={{ maxWidth: "40%",minWidth:"40%",lineHeight:1.1 }} >
                                                            <b className="font-bold" style={{lineHeight:1.3}}>Total Wasted Hour</b>
                                                        </Col>
                                                        <Col className="b-r col" style={{ maxWidth: "40%",minWidth:"40%" }} >
                                                           
                                                            <h6 className="font-bold">{cmlNonWorkingHour}</h6>
                                                        </Col>
                                                        <Col className="" style={{ maxWidth: "20%",minWidth:"20%" }} >
                                                           <h6 className="font-bold">{nonworkingPercent==''?'0 %':nonworkingPercent+" %"}</h6>
                                                        </Col>
                                                    </Row>
                                            </div>
                                        </div>         

                                    </Col>
                                    <Col lg={4} className='donutChart'>
                
                <div className='donut-inner'>
                {donutLoader?
                            <div className="text-center">
                                    <img src={spinnerLoader} className="m-t-lg" />
                                </div> :<>
                    <div className="wt-100">
                      {/* <h6>Productivity</h6> */}
                    </div>
                    <div className='donutInnerTable'>
                    <div className="wt-70"><ReactHighcharts config={configDonut} key={'donutchart'} /></div>
                    <div className="wt-30">
                        <div className='legendarea'>
                            <div className='legendList'><span style={{background:"#90cf4e"}}></span > <span className='textTitle'>Productive</span></div>
                            <div className='legendList'><span style={{background:"#fdbf00"}}></span> <span className='textTitle'> Less Productive</span></div>
                            <div className='legendList'><span style={{background:"#fc0001"}}></span> <span className='textTitle'>Non Productive</span></div>
                            <div className='legendList'><span style={{background:"#dedee3"}}></span> <span className='textTitle'>Out of Range</span></div>
                        </div>
                    </div>
                    </div>
                    
                    </>}
                </div>
            </Col> 
                                    
                                    <Col lg={4} className='align-right ct-data ct-data-site pos-relative-data'>
                        
                        <div className="ct-graph-data ct-graph-data-false " key={"selectedData"} 
                        style={{height:selectedSeries.data!=null?selectedSeries.data.length> 0?((selectedSeries.data.length+ 2 )*25):"":"" }}
                        >
                            
                        <div>
                         <div className='smaller-header'>       
                         <div className='header-left' style={{width:"60%"}}>           
                         {selectedSeries.name}
                        </div>
                        <div className='header-right' style={{width:"40%"}}><strong style={{fontWeight:"500"}}>{selectedSeries.time}
                          </strong></div>                         
                        </div>
                            <Scrollbars style={{height:"90px"}}>
                                {DisplayToolTipData() }
                             </Scrollbars></div>

                        </div>
                    </Col>

                                </Row>
                                <Row>

                                       
            <Col lg={12}>
            {chartLoader?
                            <div className="text-center m-t-lg">
                                    <img src={spinnerLoader} className="m-t-lg" />
                                </div> :<>
                                <div className='StackCHartSelect'>
                                    
                                    <Switch checkedChildren="Enable" unCheckedChildren="Disable" defaultChecked checked={activeTabShift} onChange={(val)=>SetActiveTabShift(val)} />
                                </div>
                    <div className="stackChart"> {activeTabShift=='working'?<ReactHighcharts config={config} key={'Stackedchart'}  />:<ReactHighcharts config={config} key={'Stackedchart'}  />}</div> </>}
            </Col>





                                </Row>

                            </div>
                        </Col>
                    </Row>
                    

                    <Row style={{ margin: 0,minHeight:"275px" }} className="mostInteractedMainDiv">
                        <Col className="p-l-0" lg={4} ref={elementRef}>

                          {loaderWidgetBottom?                            <div className="text-center m-t-lg">
                                    <img src={spinnerLoader} className="m-t-lg" />
                                </div>:AGTableLocationVisited(locationVisitedData)}
                        </Col>
                        <Col lg={4}>
                          {loaderWidgetBottom?                            <div className="text-center m-t-lg">
                                    <img src={spinnerLoader} className="m-t-lg" />
                                </div>:AGTableUnprotected(unproductiveVisitedData)}
                        </Col>
                        <Col lg={4} className={'p-r-0'}>
                          {loaderWidgetBottom?                            <div className="text-center m-t-lg">
                                    <img src={spinnerLoader} className="m-t-lg" />
                                </div>:AGTableAcessData(productiveLocation)}
                        </Col>
                      </Row>
                  
                   
                    </div>
                </Container>
            </div>
            </div>:<Col><div className='center404'> <Image404 /><h4 style={{marginTop:"-30px",color:"#2e3552"}}>Access Restricted to this page, Please contact administrator</h4></div>
            </Col>:<Col lg={12} md={12} sm={12} xs={12} className="apiLoaderPermission">
                                    <img src={spinnerLoader} />
                                </Col>}</>
        )
    }




const mapStateToProps = (state) => ({
    language: state.dashboard.selectedLangaugeValue
})

export default connect(mapStateToProps, { setSelectedLanguage })(withRouter(ProductivityDetails))


