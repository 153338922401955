import axios from "axios";


let prefixURL = process.env.REACT_APP_URL


export function getEmployeeList(requestBody, userSession, org_id) {
    return axios.get(prefixURL + `/get_employee_list?session=${userSession}&org_id=${org_id}&date=${requestBody.date}&flag=all`)
        .then(res => res.data).catch(err => err)
}

export function getEmployeeDetails(requestBody, userSession, org_id) {
    return axios.get(prefixURL + `/get_employee_overview?session=${userSession}&tag_serial=${requestBody.tag_serial}&date=${requestBody.date}&org_id=${org_id}`)
        .then(res => res.data).catch(err => err)
}

export function getOrgPri(requestBody, userSession, org_id) {
    return axios.get(prefixURL + `/get_org_pri?session=${userSession}&org_id=${org_id}&date=${requestBody.date}`)
        .then(res => res.data).catch(err => err)
}

export function getDepartmentList(requestBody, userSession, org_id) {
    return axios.get(prefixURL + `/get_team_list?session=${userSession}&org_id=${org_id}&date=${requestBody.date}`)
        .then(res => res.data).catch(err => err)
}

export function postMarkedemployee(requestBody, userSession, org_id) {
 
        return axios.post(prefixURL+ '/mark_infected', {
            session: userSession,
            date : requestBody.date,
            tag_serial : requestBody.tag_serial,
            status : requestBody.status,
            org_id : org_id
        }).then(res => res.data).catch(err => err)


}


export function getEmployeeIndex(requestBody, userSession, org_id) {

    // let indexName = 'population'
    // return axios.get(prefixURL + `/get_employee_widget?session=${userSession}&tag_serial=${requestBody.tag_serial}&date=${requestBody.date}&org_id=${org_id}&index_name=${indexName}&start_date=${'2021-09-20'}&end_date=${'2021-10-17'}`)
    //     .then(res => res.data).catch(err => err)
    return axios.get(prefixURL + `/get_employee_widget?session=${userSession}&tag_serial=${requestBody.tag_serial}&org_id=${org_id}&start_date=${requestBody.start}&end_date=${requestBody.end}`)
        .then(res => res.data).catch(err => err)
}

export const attendanceChart = async (date, userSession, org_id) => {
    try {
        const res = await axios.get(`${prefixURL}/get_attendance_trend?session=${userSession}&org_id=${org_id}&date=${date}`);
        return res.data;
    } catch (err) {
        return err;
    }
}

export const employeeChart = async (requestBody, userSession, org_id) => {
    try {
        // const res = await axios.get(`${prefixURL}/get_employee_org_charts?session=${userSession}&start_date=${requestBody.start}&emp_id=${requestBody.emp_id}&index_name=population&org_id=${org_id}&end_date=${requestBody.end}`)
        const res = await axios.get(`${prefixURL}/get_employee_org_charts?session=${userSession}&start_date=${requestBody.start}&org_id=${org_id}&end_date=${requestBody.end}`)
        return res.data
    } catch (err) {
        return err;
    }
}

export const atRiskEmployee = async (requestBody, userSession, org_id) => {
    try {
        const res = await axios.get(`${prefixURL}/get_atrisk_employee?session=${userSession}&start_date=${requestBody.start}&org_id=${org_id}&end_date=${requestBody.end}&rank=${requestBody.rank}&sensitivity=${requestBody.sensitivity}`)
        return res.data
    } catch (err) {
        return err;
    }
}

export const atRiskLocation = async (requestBody, userSession, org_id) => {
    try {
        const res = await axios.get(`${prefixURL}/get_atrisk_location?session=${userSession}&start_date=${requestBody.start}&org_id=${org_id}&end_date=${requestBody.end}&rank=${requestBody.rank}`)
        return res.data
    } catch (err) {
        return err;
    }
}

export const contaminatedEmployee = async (requestBody, userSession, org_id) => {
    try {
        const res = await axios.get(`${prefixURL}/get_contaminated_list?session=${userSession}&start_date=${requestBody.start}&org_id=${org_id}&end_date=${requestBody.end}&rank=${requestBody.rank}`)
        return res.data
    } catch (err) {
        return err;
    }
}