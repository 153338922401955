import React, { useState, useCallback,useReducer,useEffect,useRef,useLayoutEffect } from 'react'
import { Container, Row, Col, Dropdown, DropdownButton } from "react-bootstrap";
import { Button } from "antd";
import { CommonHeading } from "../../common/commonHeading";
import Viewer from '../hooks/Viewer'
//import { cameraPlacements } from  '../hooks/_data'
import spinnerLoader from "../../assets/images/Spinner Loader.gif";
import moment from "moment";
import { getTranslatedText } from  "../../common/utilities";
import {
  getLanguageTranslation,
  setSelectedLanguage,
} from "../../dashboard/actionMethods/actionMethods";

import { Switch } from 'antd';

import {
  append,
  compose,
  isEmpty,
  map,
  reject,
  intersperse,
  evolve,
  max,
  mapObjIndexed,values, filter
} from 'ramda'

import DashboardLanguage from "../../components/dashboardLanguage";
import CommonDatePicker from "../../common/commonDatePicker";

import { Slider} from 'antd';
import { Select } from 'antd';
import {ReactComponent as Play} from '../../assets/images/circle-play.svg'
import {ReactComponent as Pause} from '../../assets/images/circle-pause.svg'
import LocationIcon from  '../../assets/images/location.png'

import ProductiveIcon from  "../../assets/images/locationProductive.png"
import nonProductiveIcon from "../../assets/images/locationNonProductive.png";
import lessProductiveIcon from "../../assets/images/locationLessProductive.png";

import {getLocationCordinate} from '../../realtimeTracker/actionMethods/actionMethods';

import { getPproductivitylist } from "../../productivity/actionMethods/actionMethods";
import {getMovementData} from '../actionMethods/actionMethod';

// import TextField from '@mui/material/TextField';
// import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import { getPproductivityDetailOverview,
  getIndividualProductivityList } from '../../productivity/actionMethods/actionMethods';

  import {ReactComponent as Image404 } from '../../assets/images/11132-ai.svg'
import '../style/style.scss'

import '../../realtimeTracker/style/style.scss'
import Scrollbars from 'react-custom-scrollbars';
import 'antd/dist/antd.css';



let dataUniqueLocation=[];

const Simplr = (props) => {
  const { Option } = Select;
  
  const INITIAL_MODE = '3d'  
  const [mode, setMode] = useState(INITIAL_MODE)
  const [activeTab, SetActiveTab]= useState('');
  const [pointData,SetPointData]=useState([]);
  const [spaceID,SetSpaceID]=useState('');
  const [locationCordinate,SetLocationCordinate]= useState([]);
  const [slug,SetSlug] =useState('');
  const [error,SetError]=useState('');
  const noElevationIn2D = useCallback(value => (mode === '3d' ? value : 0), [
    mode
  ])
  
  let date = localStorage.getItem("selectedDate")
    ? new Date(localStorage.getItem("selectedDate"))
    : new Date();

  const [selectedDate, updateSelectedDate] = useState(date);
  const [selectedLangValue, updateSelectedLangValue] = useState("en");
//const slideTooltip=useRef()
  const slideTooltip = useRef(null);
  

  const [width, setWidth] = useState(0);
 
  const autoElevation = map(
    evolve({ position: { elevation: noElevationIn2D } })
  )
  
  const [space, setSpace] = useState()
  
  const [permission,SetPermission] =useState([])
  const [permissionAPICall,SetPermissionAPICall] = useState(false);
  const [department,SetDeparment] =useState([]);
  const [department_id,SetDepartmentID]=useState('');
  
  const [sub_department,SetSubDeparment] =useState([]);
  const [sub_department_id,SetSubDepartmentID]=useState('');

  const [employeeID,SetEmployeeID]=useState('');
  const [employeeAll,SetEmployeeAll]=useState([]);
  const [employeeDefault,SetEmployeeDefault]=useState([]);
  const [loaderEmp,SetLoaderEmp]=useState(false)
  const [start_time,SetStartTime]=useState('');
  const [end_time,SetEndTime]=useState('');
  const [isloading,Setloading] =useState(true);
  
  const [autoPlay,SetAutoPlay] =useState(false);
  const [productiveLocation,SetProductiveLocation] =useState([])
  const [nonproductiveLocation,SetNonProductiveLocation] =useState([])
  const [lessproductiveLocation,SetLessProductiveLocation] =useState([])

  let userDetails = JSON.parse(localStorage.getItem("userLoginDetails"));
  let userSession = userDetails ? userDetails.session : "123456789";
  let org_id = userDetails ? userDetails.org_id : 6;
  let lastReqId = null
  const [slideMax,SetSlideMax]=useState(0);
  const [shiftStarttime,SetShiftStartTime] =useState('');
  const[ ShiftEndtime,SetShiftEndTime]=useState('');
  const [shiftBasedDropdown,SetShiftBasedDropdown] =useState([]);  
  
  const [filterTimeData,SetFilterTimeData] =useState([]);
  const [sucessMessage,SetSucessMessage] =useState(false);

  const [dailyEmpDefault,SetDailyEmpDefault] =useState([]);
  const [dailyEmpDepartment,SetDailyEmpDepartment] =useState([]);
  const [regularEmpDepartment,SetRegularEmpDepartment] =useState([]);
  const [enable,SetEnable] =useState(true);
  const [startBigger,SetStartBigger]=useState(false);  
  const onReady = useCallback(space => setSpace(space), [])

  const [icons, dispatchIcon] = useReducer((icons, action) => {
    switch (action.type) {
      case 'add':
        return [...icons, action.icon]
      case 'update':
        return icons.map(pt =>
          pt.id === action.id ? { ...pt, ...action.updates } : pt
        )
      case 'remove':
        return reject(r => r.id === action.id)(icons)
      default:

    }
  },[])

  const time = [
    {time:"12:00 AM",value:"00:00:00",intVal:0 },
    {time:"01:00 AM",value:"01:00:00",intVal:1},
    {time:"02:00 AM",value:"02:00:00",intVal:2},
    {time:"03:00 AM",value:"03:00:00",intVal:3},
    {time:"04:00 AM",value:"04:00:00",intVal:4},
    {time:"05:00 AM",value:"05:00:00",intVal:5},
    {time:"06:00 AM",value:"06:00:00",intVal:6},
    {time:"07:00 AM",value:"07:00:00",intVal:7},
    {time:"08:00 AM",value:"08:00:00",intVal:8},
    {time:"09:00 AM",value:"09:00:00",intVal:9},
    {time:"10:00 AM",value:"10:00:00",intVal:10},
    {time:"11:00 AM",value:"11:00:00",intVal:11},
    {time:"12:00 PM",value:"12:00:00",intVal:12},
    {time:"01:00 PM",value:"13:00:00",intVal:13},
    {time:"02:00 PM",value:"14:00:00",intVal:14},
    {time:"03:00 PM",value:"15:00:00",intVal:15},
    {time:"04:00 PM",value:"16:00:00",intVal:16},
    {time:"05:00 PM",value:"17:00:00",intVal:17},
    {time:"06:00 PM",value:"18:00:00",intVal:18},
    {time:"07:00 PM",value:"19:00:00",intVal:19},
    {time:"08:00 PM",value:"20:00:00",intVal:20},
    {time:"09:00 PM",value:"21:00:00",intVal:21},
    {time:"10:00 PM",value:"22:00:00",intVal:22},
    {time:"11:00 PM",value:"23:00:00",intVal:23},   
  ]

  const [polylines, dispatchPolyline] = useReducer((polylines, action) => {
  
    switch (action.type) {

      case 'addCoordinate':

        return polylines.map(r =>
          r.id === action.id
            ? { ...r, coordinates: [...r.coordinates, action.coordinate] }
            : r
          
        )
      case 'updateCoordinates':
        return polylines.map(r =>
          r.id === action.id ? { ...r, coordinates: action.coordinates } : r
        )
      case 'removePolyline':
        return reject(r => r.id === action.id)(polylines)
      default:

    }
  }, [ {
    "id": "9e2f2088-01f9-511b-9740-207f8a28870f",
    "name": "W3",
    "coordinates": []
  }])

  useEffect(() => {
    let perm= props.role.geo_map_permissions?JSON.parse(props.role.geo_map_permissions):null;
   
    if(perm){
        SetPermission(perm)
        SetPermissionAPICall(true)
    }else{
    }
},[props.role]);

function isInt(value) {
  return !isNaN(value) && (function(x) { return (x | 0) === x; })(parseFloat(value))
}
useEffect(()=>{
  
  if(permission.employee=="True"){
    
    getLocationCordinate(userSession,org_id).then(res=>{
      if(res.status==200){
        SetSpaceID(res.data.space_id)
        //adding random planned 
        let data = res.data.location
        for(let i =0 ;i<res.data.location.length;i++){

        }

        SetLocationCordinate(res.data.location);
        Setloading(false);

      }

    })
  }
},[permission])
useEffect(()=>{
 
  SetDepartmentID('');
  SetDeparment([]);
  SetSubDeparment([]);
  SetSubDepartmentID([]);
  SetEmployeeID('')
  SetDailyEmpDefault([]);
  SetEmployeeAll([])
  SetDailyEmpDepartment([]);
  SetSlug('')
  if(permission.employee=="True"){
    

    let arr=[];

    let requestBody={};
    requestBody.startdate=getDateFormat(selectedDate)
    requestBody.enddate=getDateFormat(selectedDate)
    requestBody.slug='employee'
    let dept=[]
    let catIndex=0;
    let empList= [];
    getPproductivitylist(requestBody,userDetails.session,org_id).then(res=>{
     
      if (res.status==200){
      


        Object.keys(res.data).forEach((key) => {
          
          let element=res.data[key];
          let child= [];
          let childName='';      
          let parentLength=0;
          let subdept=[]
          Object.keys(element).forEach((key2) => {
        
            if(isInt(key2)){
          
              
            }else{
              
              childName=key2  
              subdept.push(key2);
              for (let c = 0;c<element[key2].length;c++){
                parentLength++;
                element[key2][c].department=key;
                element[key2][c].sub_department=key2;
                empList.push(element[key2][c]);
              }
              child.push({name:key2,data:element[key2] })
            }

          });

          if(childName!==''&& Number.isInteger(childName) ===false ){
            arr.push({name:key,child:child})   
            dept.push({name:key,sub_department:subdept}) 
          }else{

            dept.push({name:key,sub_department:subdept}) 
            for (let i=0;i<element.length;i++){
              element[i].department=key;
              element[i].sub_department='';
              empList.push(element[i]);
            }
            arr.push({name:key,data:element})            
          }
         });


      }


      // SetShowData(arr);
      // SetShowDataDefault(arr);
      SetEmployeeAll(empList)
      SetEmployeeDefault(empList)
      //SetDeparment(dept)
      SetRegularEmpDepartment(dept)
  
      
    })

    let requestBody2={};
    requestBody2.startdate=getDateFormat(selectedDate)
    requestBody2.enddate=getDateFormat(selectedDate)
    requestBody2.slug='daily_worker'
    let deptDaily=[]
    let catIndexDaily=0;
    let empListDaily= [];
    getPproductivitylist(requestBody2,userDetails.session,org_id).then(res=>{
     
      if (res.status==200){
      


        Object.keys(res.data).forEach((key) => {
          
          let element=res.data[key];
          let child= [];
          let childName='';      
          let parentLength=0;
          let subdept=[]
          Object.keys(element).forEach((key2) => {
        
            if(isInt(key2)){
          
              
            }else{
              
              childName=key2  
              subdept.push(key2);
              for (let c = 0;c<element[key2].length;c++){
                parentLength++;
                element[key2][c].department=key;
                element[key2][c].sub_department=key2;
                empListDaily.push(element[key2][c]);
              }
              child.push({name:key2,data:element[key2] })
            }

          });

          if(childName!==''&& Number.isInteger(childName) ===false ){
            arr.push({name:key,child:child})   
            deptDaily.push({name:key,sub_department:subdept}) 
          }else{

            deptDaily.push({name:key,sub_department:subdept}) 
            for (let i=0;i<element.length;i++){
              element[i].department=key;
              element[i].sub_department='';
              empListDaily.push(element[i]);
            }
            arr.push({name:key,data:element})            
          }
         });


      }

      SetDailyEmpDefault(empListDaily)
      SetDailyEmpDepartment(deptDaily)
  
      
    })



  }

},[permission,selectedDate])  

useEffect(()=>{
  SetDepartmentID('');
  SetSubDepartmentID('');
  SetEmployeeID('');
  let dept = slug=='employee'?regularEmpDepartment:dailyEmpDepartment
  SetDepartmentID('');
  SetDeparment(dept);
  

},[slug])


useEffect(()=>{
  if(permission.employee=="True"){
    let sub=[];  
    let subDept=[];
    SetSubDeparment([]);
    SetEmployeeID('')
    SetSubDepartmentID('')
   
      let sub2= slug=='employee'?department.filter(item=>item.name.toString().toLowerCase().includes(department_id.toLowerCase()) ):dailyEmpDepartment.filter(item=>item.name.toString().toLowerCase().includes(department_id.toLowerCase()) )
       for (let j=0;j<sub2.length;j++){
        subDept = subDept.concat(sub2[j].sub_department);
      }  
  if(subDept.length > 0){
    SetSubDeparment(subDept);  
  }   

  let empList2 = slug=='employee'?employeeDefault.filter(item=>item.department== department_id):dailyEmpDefault.filter(item=>item.department== department_id)
  SetEmployeeAll(empList2); 
  }
},[permission,department_id])

useEffect(()=>{
  if(permission.employee=="True"){
    let sub=[];  
    let subDept=[];
    SetEmployeeID('')
    let empList2 = slug=='employee'?employeeDefault.filter(item=>item.department== department_id && item.sub_department.toString().toLowerCase().includes(sub_department_id=='all'?'':sub_department_id.toLowerCase()) ):dailyEmpDefault.filter(item=>item.department== department_id && item.sub_department.toString().toLowerCase().includes(sub_department_id=='all'?'':sub_department_id.toLowerCase()) )
   
    SetEmployeeAll(empList2,department_id); 
  }
},[permission,sub_department_id])



useEffect(()=>{
  if(permission.employee=="True"){
    let requestBody={} ;
    requestBody.startdate= getDateFormat(selectedDate)
    requestBody.enddate= getDateFormat(selectedDate)
    requestBody.tag_serial= employeeID
    requestBody.slug= 'employee'
    SetShiftBasedDropdown([])
    setCount(0)
    SetShiftEndTime('')
    SetAutoPlay(false);
    SetShiftStartTime('')
    SetStartTime('')
    SetFilterTimeData([])
    SetDefaultPoly([])
    SetSucessMessage(false)
    SetStartBigger(false);

    if(space){ 
    dispatchPolyline({
      type: 'updateCoordinates',
      id: "9e2f2088-01f9-511b-9740-207f8a28870f",
      coordinates:[]
    })
  }
    let prod =[];
    let nonProd=[];
    let lessProd = [];

    for(let i= 0;i<productiveLocation.length;i++){

      updateDataLayer(productiveLocation[i],LocationIcon)
    }
    for(let i= 0;i<nonproductiveLocation.length;i++){
     
      updateDataLayer(nonproductiveLocation[i],LocationIcon)
    }
    for(let i= 0;i<lessproductiveLocation.length;i++){
      updateDataLayer(lessproductiveLocation[i],LocationIcon)
    }

    getPproductivityDetailOverview(requestBody,userSession,org_id).then(res=>{
      if(res.shift){
     
        let shift = res.shift?JSON.parse(res.shift):[]
        let startSft= res.shift?shift.start_time.split(':'):null
        let endSft= res.shift?shift.end_time.split(':'):null
        let st = parseInt(startSft && startSft[0]?parseInt(startSft[1]) > 40 && parseInt(startSft[0])!=23 ?parseInt(startSft[0]) + 1:parseInt(startSft[0]):0);
        let et = parseInt(endSft && endSft[0]?parseInt(endSft[1]) > 40 && parseInt(endSft[0])!=23 ?parseInt(endSft[0]) + 1:parseInt(endSft[0]):0);
        let oft=[]
        let smaller=st>et?et:st;
        let bigger = st>et?st:et;
        let shiftHour=[]; 
        
        SetShiftStartTime(startSft?startSft[0]+":"+startSft[1]:'-');
        SetShiftEndTime(endSft?endSft[0]+":"+endSft[1]:'-');
        
        for(let i=0;i<24;i++){
          if(st > et){
            SetStartBigger(true);
              if( (smaller <= i) && (bigger > i) ){
  
              }else{
                  shiftHour.push(i);
              }
          }else{
            SetStartBigger(false);
              if( (smaller <= i) && (bigger > i) ){
                  shiftHour.push(i);    
              }
          }
      }        
      
        SetShiftBasedDropdown( shiftHour)

      }

      getIndividualProductivityList(requestBody,userSession,org_id).then(res=>{
     
        if(res.data && res.data.productive){
          let data = res.data.productive;
          for(let i=0;i<data.length;i++){
            let item = locationCordinate.find(item=> item.tag_serial == data[i].tag_serial)
            if(item){prod.push(item);updateDataLayer(item,ProductiveIcon)}  
          }          
        }
        if(res.data && res.data.less_productive){
          let data = res.data.less_productive;
          for(let i=0;i<data.length;i++){
            let item = locationCordinate.find(item=> item.tag_serial == data[i].tag_serial)
            if(item){lessProd.push(item);updateDataLayer(item,lessProductiveIcon)}  
          }          
        }
        if(res.data && res.data.non_productive){
          let data = res.data.non_productive;
          for(let i=0;i<data.length;i++){
            let item = locationCordinate.find(item=> item.tag_serial == data[i].tag_serial)
            if(item){nonProd.push(item);updateDataLayer(item,nonProductiveIcon)}  
          }          
        }        
        SetProductiveLocation(prod);
        SetNonProductiveLocation(nonProd);
        SetLessProductiveLocation(lessProd);  
      })
      
    })





  }

},[permission,employeeID])

useEffect(() => {
  if(slideTooltip.current){
    setWidth(slideTooltip.current.offsetWidth);
  }
  
}, [slideTooltip]);
  function handleDateSelect(date) {
    updateSelectedDate(date);

    let startDate = new Date().setDate(new Date().getDate() - 29);
    let endDate = new Date(moment(date).format('YYYY-MM-DD 23:59:59'));

    //setChartDates({ start: startDate, end: endDate });
  }

  function AddDataLayer(data){

    if(space){
    space.addDataLayer({
      id: data.id,
      type: 'icon',
    
      data: [{
        position: data.position, 
      }],
      icon: {
        url: data.url,
        width: 96,
        height: 96
      },
      width: 4,
      anchor: 'top',
      tooltip: d => data.tooltip,
      onClick: d=> filterResultLocation(data.tooltip),
    })
  }
}

  function updateDataLayer(data,url,width,widthSize,inc){
    inc=inc?inc:0;
   

    if(space){
    if(url){
      space.updateDataLayer({
        id: data.unique_loc_id,
        data: [{
          position: data.position, 
        }],
        icon: {
          url: url,
          width: width?width:96,
          height: width?width:96
        },
        width: data.widthSize?data.widthSize:4,
        anchor: 'top',
        tooltip: d => data.name +" : "+moment.utc(inc*1000*30).format("HH:mm:ss"),
        onClick: d=> filterResultLocation(data.name),
      })
    
    }else{
    
      space.updateDataLayer({
        id: data.unique_loc_id,
        data: [{
          position: data.position, 
        }],
        width: widthSize?widthSize:4,
        anchor: 'top',
        tooltip: d => data.name +" : "+moment.utc(inc*1000*30).format("HH:mm:ss"),
        onClick: d=> filterResultLocation(data.name),
      })
    }
  }

    
  }

  function filterResultLocation(name){
/*    
    SetEnable(false)
    SetFilterTimeData(defaultPoly.filter(item=>item.name==name));
*/

  }
  

  
  function getDateFormat(date) {
    return moment(date).format("YYYY-MM-DD");
  }


  useEffect(()=>{
    if (!space) {
      return
    }
    let data = [...locationCordinate] 
    if(data.length>0){


      for (let i = 0;i<data.length;i++){
       
        
        let arr={};
        arr.type='add'
        arr.icon={}
        arr.id=data[i].unique_loc_id;
        arr.tooltip=data[i].name 
        arr.position= data[i].position
        arr.position.elevation=5
        arr.name = data[i].name
        arr.width = 96

        arr.url = LocationIcon
        if(productiveLocation.indexOf(data[i])!=-1){
         
          arr.url = ProductiveIcon
        }
        else if(nonproductiveLocation.indexOf(data[i])!=-1){
         
          arr.url = nonProductiveIcon
        }
        else if(lessproductiveLocation.indexOf(data[i])!=-1){
         
          arr.url = lessProductiveIcon
        }
        arr.widthSize=3
        //dispatchIcon(arr);
        AddDataLayer(arr)

      }
    }    
  },[locationCordinate,spaceID,space])


  useEffect (() => {
    if(permission.employee=='False'){
      return
    }

    if (!space) {
      return
    }
 
    space.addDataLayer({
      id: 'icons',
      type: 'icon',
      data: autoElevation(icons),
      icon: {
        url: LocationIcon,
        width: 512,
        height: 512
      },
      width: 4,
      anchor: 'top',
      tooltip: d => d.name,
    })
    
  }, [space, icons, autoElevation,permission])

  



  
  const  [marks,SetMark]  = useState([]) 
  const [marks2,SetMark2] =useState([]) 
  






  const [defaultPoly,SetDefaultPoly] = useState([])

  const [currentCount, setCount] = useState(0);


 useEffect(() => {
    if (!space) {
      return
    }
    
    space.addDataLayer({
      id: 'polylines',
      type: 'polyline',
      data: compose(
        map(
          evolve({ coordinates: map(evolve({ elevation: noElevationIn2D })) })
        ),
        reject(p => isEmpty(p.coordinates))
      )(polylines),
      shape: 'triangle',
      cap:true,
      stepSize:15,
      scale: 0.5,
      color:'#413d3d',
      tooltip: d => d.name,
      onDrop: ({ data, coordinates }) =>
        dispatchPolyline({
          type: 'updateCoordinates',
          id: data.id,
          coordinates
        })
    })
    

  }, [space, polylines, noElevationIn2D])


  

  

  const timer = () => {
    setCount(currentCount + 1);    
  }

  useEffect(() => {

        if (!space) {

          return
        }   
        if (currentCount > (defaultPoly.length) || autoPlay===false || loaderEmp) {
        
            return;
        }
        const id = setInterval(timer, 500);

         let line = [...polylines];
         let cord = defaultPoly.slice(0,(currentCount)); 
          let datatimeData = [...filterTimeData] 
          if(defaultPoly[currentCount]){          
            
            
            
            if(enable){
              let previousData = datatimeData[datatimeData.length - 1]?datatimeData[datatimeData.length - 1]:{name:""}

              if(defaultPoly[currentCount].name != previousData.name ){
                defaultPoly[currentCount].time_spent = 30000; 
                
                SetFilterTimeData(current => [...current, defaultPoly[currentCount]]);
              }else{
                let old= [...filterTimeData];
                if(datatimeData[datatimeData.length - 1]){
                  datatimeData[datatimeData.length - 1].time_spent = datatimeData[datatimeData.length - 1].time_spent + 30000;
                  SetFilterTimeData(datatimeData);
                }else{
                
                }
                
              }
            }else{
              SetFilterTimeData(current => [...current, defaultPoly[currentCount]]);
            }  



          let sizeUpdate= cord.filter(item=>item.tag_serial == defaultPoly[currentCount].tag_serial);
          let currentLocation = locationCordinate.find(item=>item.tag_serial==defaultPoly[currentCount].tag_serial);
          if(currentLocation){
            updateDataLayer(currentLocation,'','',(4+sizeUpdate.length/10),sizeUpdate.length==0?1:sizeUpdate.length + 1)
            
           }
        }


            dispatchPolyline({
              type: 'updateCoordinates',
              id: line[0].id,
              coordinates:cord
            })
          

    
 
       return () => clearInterval(id);
      },[currentCount,space,autoPlay]);

      useEffect(() => {

        if (!space) {

          return
        }   
        if(autoPlay){

        }else{
          let line = [...polylines];
          //let cord = defaultPoly.slice(0,currentCount+1);
          let cord = defaultPoly.slice(0,(currentCount));
          
          
          
          let filterData = defaultPoly.slice(0,(currentCount));
          
      
            //SetFilterTimeData(filterData);

            if(enable){
              //here formattion occur filterData
              let unqiueLoc=[];
              let prevName='';
              for(let i= 0;i<filterData.length;i++){
                 
                  if(filterData[i].name!=prevName){
                    prevName = filterData[i].name
                    filterData[i].time_spent = 30000;
                    unqiueLoc.push(filterData[i])
                  }else{
                    filterData[i - 1].time_spent = filterData[i - 1].time_spent + 30000;
                  }
              }
              SetFilterTimeData(unqiueLoc);

            }else{
              SetFilterTimeData(filterData);
            }


            for (let i = 0;i<dataUniqueLocation.length;i++){
              let filterLength= locationCordinate.filter(item => item.name == dataUniqueLocation[i]) 
              let sizeUpdate= cord.filter(item=>item.name == dataUniqueLocation[i]);
              let currentLocation = locationCordinate.find(item=>item.name == dataUniqueLocation[i]);
              if(currentLocation){
                updateDataLayer(currentLocation,'','',(4+sizeUpdate.length/10),sizeUpdate.length==0?1:sizeUpdate.length + 1)
              
              }
            }

            
            dispatchPolyline({  
              type: 'updateCoordinates',
              id: line[0].id,
              coordinates:cord
            })
          }
          
      },[currentCount,space,autoPlay]);



      function getDateFormat(date) {
        return moment(date).format("YYYY-MM-DD");
      }
    
    
    useEffect(() => {
      if (props.language) {
        updateSelectedLangValue(props.language);
      }
    }, [props.language]);

    
  
  
    const handleDate = (date, type) => {
      // setChartDates((prev) => ({ ...prev, [`${type}`]: date }));
    };

    function changeLanguage(lang) {
      getLanguageTranslation(lang).then((res) => {
        if (res && res.status >= 200 && res.status <= 200) {
          localStorage.setItem("languageData", JSON.stringify(res.data));
          localStorage.setItem("selectedLanguage", lang);
          props.setSelectedLanguage(lang);
        }
      });
    }

    function changeTab(name){
      SetActiveTab(name);
    }
    function HandleRange(e){
      setCount(e);
      SetAutoPlay(false);       
    }
   function autoPlayhandler(){
    SetAutoPlay(!autoPlay)
    if(currentCount > icons.length){
      setCount(0);
    }else{
      
    }
   }

   function formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }
  


   function filterResultHandler(){

    
    SetDefaultPoly([]);
    SetAutoPlay(false);
 

    let endTime = start_time + 1;
    let requestBody={}
    requestBody.tag_serial= employeeID
    requestBody.date= getDateFormat(selectedDate)
    if(startBigger && start_time < 11){
      
      requestBody.date= moment(selectedDate, "YYYY-MM-DD").add(1, 'days').format("YYYY-MM-DD");
    }else{
      
    }
    
    requestBody.start_time = start_time+":00:00"
    requestBody.end_time =(start_time==23?"00":start_time + 1)+":00:00";
  
    const diff =  moment(endTime) - moment(start_time) ;
  
      

  
   
      let aMs = moment(start_time, 'HH:mm:ss').format('x')
      let bMs = moment(endTime, 'HH:mm:ss').format('x')
  
      
    let difference =   bMs - aMs
 
  if(difference <= 3600000 && bMs > aMs ){
    SetLoaderEmp(true);
    let arr = [];
    let locationPoint = [...locationCordinate];
    //let data = [...dummydata]
    let mk = []
    let mk2 = []
    SetError('');
    SetFilterTimeData([])
    SetSucessMessage(false);
    dataUniqueLocation =[];
    getMovementData(requestBody,userSession,org_id).then(res=>{
  
      if(res.status==200){
  
  
        SetLoaderEmp(false);
  
        let data = res.data[employeeID]?res.data[employeeID]:res.data;
        let locName='';
        Object.keys(data).forEach((key) => {
  
          let findPOs = locationPoint.find(item=>item.tag_serial == Object.keys(data[key])[0]) 


          
          mk.push(moment(Object.values(data[key])[0]));

          if(locName==Object.keys(data[key])[0]){

          }else{
            
            locName=Object.keys(data[key])[0];
            mk2.push(moment(Object.values(data[key])[0]));
          }
          if(findPOs){
            let indexLoc = dataUniqueLocation.indexOf(findPOs.name);
            if(indexLoc === -1){
              dataUniqueLocation.push(findPOs.name);  
            }
            let polylineFormat = {}
            polylineFormat.levelIndex=0
            polylineFormat.x=findPOs.position.x
            polylineFormat.z=findPOs.position.z
            polylineFormat.elevation =5
            polylineFormat.name=findPOs.name
            polylineFormat.tag_serial=findPOs.tag_serial
            polylineFormat.time=Object.values(data[key])[0]
            arr.push(polylineFormat);
            
          }
    
    
          
    
          
          
        });

        arr = arr.sort(function(a,b){
          return new Date(a.time) - new Date(b.time);
        });
        mk = mk.sort(function(a,b){
          return  new Date(a) - new Date(b);
        });
       mk = mk.map((item)=> {return  moment(item).utc().format('hh:mm:ss A') })



        SetDefaultPoly(arr);

       // let half=Math.ceil(mk.length / 2);

        SetMark(mk);
        //SetMark2(mk.splice(-half));
        setCount(0);
        SetAutoPlay(true);
        SetSucessMessage(true);
        
  
      }
    })  
  
  

  }else if(bMs < aMs ) {
    SetError('End Time should be higher than Start Time');
  }else{
    SetError('Max Time 1hr');
  }
  
    
  }

  function onChangeEnable(val){
    
    SetEnable(val);

    let data= [...filterTimeData];
    let filterData = defaultPoly.slice(0,(currentCount));
   
    if(val){

      let unqiueLoc=[];
      let prevName='';
      for(let i= 0;i<filterData.length;i++){
          if(filterData[i].name!=prevName){
            prevName = filterData[i].name
            filterData[i].time_spent = 30000;
            unqiueLoc.push(filterData[i])
          }else{
            prevName = filterData[i].name
            filterData[i - 1].time_spent = filterData[i - 1].time_spent + 30000;
            
          }
      }
      SetFilterTimeData(unqiueLoc);
    }else{
      SetFilterTimeData(filterData);
    }

  }
      
  return (<>
    {permissionAPICall?permission.employee=="True"? 
    <div className="manpowerManagementMainDiv">
    <Container className="header-breadcrumb">
      <Row>
        <Col lg={6}>
          
          <div className='tabHeader'>
            
              <div className='tabHeaderList active' >
              <CommonHeading title="Employee Tracker"/>
              </div>   
              <div className='tabHeaderList ' onClick={()=>props.history.push('/geo-map-management')}>
              <CommonHeading title="Facility Tracker" />
              </div>
           </div>   
        </Col>
        <Col lg={6} className="text-right">
          <div className="commonLangaugeStyleDiv">
            <DashboardLanguage
              selectedLangValue={selectedLangValue}
              changeLanguage={changeLanguage}
            />
          </div>

          <div className="siteHeadingDatePickerDiv" style={{ width: "20%" }}>
            <CommonDatePicker
              selectedDate={selectedDate}
              handleSelectDate={handleDateSelect}
            />
          </div>
        </Col>
      </Row>

      <Row className="m-t" >
        <Col lg={12} >
          <div className='manStyle' style={{backgroundColor:"#fff",width:"100%",float:"left"}}>
            <div className='inlineBlockFilter'>
              <h5 style={{marginBottom:0,lineHeight:"35px"}}> Filter</h5>
            </div>
            <div className='inlineBlockFilter'>
            <Select
                placeholder="Selct User Type"
                optionFilterProp="children"
                onChange={(val)=>{SetSlug(val)}}
                //defaultValue={'Select Department'}
                value={slug==''?'Select User Type':slug}
                key="departmentUserType"
                style={{width:140}}
                
            >
              <Option value='employee' key={"UserTypeRegular All"}>Regular Worker</Option>
              <Option value='daily_worker' key={"UserTypeDaily All"}>Daily Worker</Option>
              
            </Select>            
          </div>

            <div className='inlineBlockFilter'>
              <Select
                placeholder="Select Department"
                onChange={(val)=>SetDepartmentID(val)}
                value={department_id==''?"Select Department":department_id}
                key="departmentFilterv1"
                style={{width:200}}
                dropdownClassName={'smallerDrop'}
                disabled={slug==''?true:false}
            >
             
                {
                department && department.length > 0? department.map((item)=>{
                    return <Option value={item.name} key={"Department V1"+item.name}>{item.name}</Option>
                }):""
                }
            </Select>
                </div>
                <div className='inlineBlockFilter'>
                <Select
                    placeholder="Select Sub Department"
                    optionFilterProp="children"
                    style={{width:200}}
                    onChange={(val)=>SetSubDepartmentID(val)}
                    disabled={department_id==''?true:false}                   
                    value={sub_department_id==''?"Select Sub Department":sub_department_id}
                    key="SubdepartmentFilterv1"
                    dropdownClassName={'smallerDrop'}

                >
                  <Option value='all' key={"Sub Department v1 All"}>Select All</Option>
                    {
                    sub_department && sub_department.length > 0? sub_department.map((item)=>{
                        return <Option value={item} key={"Sub Department V1"+item}>{item}</Option>
                    }):""
                    }
                    
                </Select>
                </div>
                <div className='inlineBlockFilter'>
                <Select
                    placeholder="Select Employee"
                    optionFilterProp="children"
                    style={{width:150}}
                    onChange={(val)=>{SetEmployeeID(val); }}
                    disabled={department_id==''?true:false}                   
                    value={employeeID==''?"Select Employee":employeeID}
                    key="SubdepartmentFilterv1"
                    dropdownClassName={'smallerDrop'}

                >
                    {
                    employeeAll && employeeAll.length > 0? employeeAll.map((item)=>{
                        return <Option value={item.tag_serial} key={"Employee V1"+item.emp_name}>{item.emp_name}</Option>
                    }):""
                    }
                    
                </Select>
                </div>
                <div className='inlineBlockFilter' style={{width:"150px"}}>
                  <label>Shift</label> <span style={{float:"right"}}>{shiftStarttime} - {ShiftEndtime}</span>

                {/* <Select
                    placeholder="Select Start Time"
                    optionFilterProp="children"
                    style={{width:250}}
                    onChange={(val)=>SetStartTime(val)}
                    
                    value={start_time}
                    key="StartTimeV1"
                    dropdownClassName={'smallerDrop'}

                >
                    {
                    time.map((item)=>{
                        return <Option value={item.value} key={"Employee V1"+item.value}>{item.time}</Option>
                    })
                    }
                    
                </Select> */}
                </div>
                <div className='inlineBlockFilter'>
                <Select
                    placeholder="Select Time"
                    optionFilterProp="children"
                    style={{width:150}}
                    onChange={(val)=>{SetStartTime(val);}}
                    value={start_time!=""||start_time==0?start_time:"Start Time"}
                    key="EndTimeV1"
                    dropdownClassName={'smallerDrop'}
                    disabled={shiftStarttime==''||ShiftEndtime==''}

                    

                >
                    {
                    shiftBasedDropdown.map((item)=>{

                        return <Option value={item} key={"Employee V2"+item}>{item+":00"}</Option>
                    })
                    }
                    
                </Select>
                </div>
                <div className='inlineBlockFilter'>
                  <div className="btndiv">
                      <button className="btn btn-submit btn-filter" onClick={()=>filterResultHandler(department_id,sub_department_id)} disabled={ department_id==''  || start_time.toString()==''  || employeeID==''}>Submit</button>
                      
                  </div>                
                </div>


</div>
</Col>
    </Row>






      <Row className="m-t">
        <Col lg={9} ref={slideTooltip}>
 
 
                    {loaderEmp? <div className='loaderStyle' style={{background:"#fff"}}><div className="loadingInfo">
                    <h4>
                        <span>Fetching Employee detail please wait</span>
                         <span className="loading-dots">
                                <div className="loading-dots--dot"></div>
                                <div className="loading-dots--dot"></div>
                                <div className="loading-dots--dot"></div>
                          </span>
                      </h4> 
                    
                    

                  </div></div>:''}
                  
                  {sucessMessage && defaultPoly.length >0?<div className='loaderStyle loadStyleSucess' style={{background:"#fff"}}><div className="loadingInfo">
                    <h4>Ready</h4> 
                    
                    

                  </div></div>:sucessMessage && defaultPoly.length ==0?<div className='loaderStyle loadStyleError' style={{background:"#fff"}}><div className="loadingInfo">
                    <h4>Oops not data found for current employee</h4> 
                  </div></div>:""}
                  {isloading? <div className="text-center m-t-lg">
                                    <img src={spinnerLoader} className="m-t-lg" />
                                </div>:spaceID!=''?<>
                                          <Viewer onReady={onReady} spaceID={spaceID} changeTab={changeTab} />
                                          <div position='center' my='md'>
        
       
        <Row className='nicerFormatRange'>
          <Col lg={12}>
            <Slider
              //marks={marks}
            
              min={0} 
               max={defaultPoly.length==0?100:defaultPoly.length}
              value={currentCount}
              onChange={HandleRange}
               disabled={!space}
              tooltipVisible={false}
               tooltip={{
                formatter: value => value 
              }}
            />
            {space && defaultPoly.length > 0 && marks[currentCount]?<div className='sliderLeftTooltip'>
                <div className='tooltipCustom' style={{left:( ((currentCount * width)/ 120) *100)/(width)+"%"}}>
                  <span className='tooltipArea' style={{float:"right"}}>
                   {marks[currentCount]}
                  </span>
                </div>
              {/* left {width} {(currentCount * width)/ 120} {  } */}
            </div>:""}


            {/* <Button disabled={!space} className='autoplayStop btn-smaller' onClick={autoPlayhandler}>{autoPlay&&currentCount<=(icons.length-1)?<Play/>:<Pause/>}</Button>   */}
          </Col>
          <Col lg={2}>
          
          </Col>
          {/* <Col lg={5}>
            <Slider
              marks={marks2}
              defaultValue={currentCount} 
              value={currentCount}
              tooltipVisible={false} 
              onChange={HandleRange}
              disabled={!space}
              key={'range2'}
            />
          </Col> */}
        </Row>
          
      </div>                                
                                    </>:<h2>Please Setup Geo Map or contact to administrator</h2>}








                  </Col>
          <Col lg={3} className="p-l-0 ">
          <div className="legendArea manStyle legendArea2" style={{height:'650px',background:"#fff"}}>
            <div className='legendStart'>
            {/* <div className='legendSwitch'>
                
                <Switch defaultChecked={enable} onChange={onChangeEnable} />
                <span className='title'>{enable?"Location Log":"All Log"}</span>
              </div> */}
              <div className={!space?enable?'legendList legendHeader disable three-colsHeader':'legendList legendHeader disable':enable?'legendList legendHeader  three-colsHeader':'legendList legendHeader'}>
                  <div className='legendLeftAreaProd'><strong>Location</strong></div>
                  {enable?<div className='legendLeftAreaTime'><strong>Spend</strong></div>:""}
                  <div className='legendRightAreaProd'><strong>Time</strong></div>
              </div>
                    <Scrollbars style={{height:"550px"}}>{filterTimeData.map((item,index)=>{
                   
                      return(
                                <div className={!space?enable?'legendList  disable three-colsHeader':'legendList  disable':enable?'legendList three-colsHeader':'legendList'}>
                                        <div className='legendLeftAreaProd'>{item.name}</div>
                                        {enable?<div className='legendLeftAreaTime'>{moment.utc(item.time_spent).format("HH:mm:ss")}</div>:""}
                                        <div className='legendRightAreaProd'>{moment(item.time).utc().format('hh:mm:ss A')}</div>
                                </div>
                                )
                    })}</Scrollbars>

      {/* <div className={!space?'legendList legendHeader legendFooter disable':'legendList legendHeader'}>
                  <div className='legendLeftAreaProd'><strong>Total</strong></div>
                  <div className='legendRightAreaProd'><strong>{0}</strong></div>
              </div> */}

                </div>

          </div>
        </Col>        

      

      </Row>
    </Container>
  </div>:
<Col lg={12}>
            {/* <div className='errorPermission'></div> */}
        <div className='center404'> <Image404 /><h4 style={{marginTop:"-30px",color:"#2e3552"}}>Access Restricted to this page, Please contact administrator</h4></div>
        </Col>:<Col lg={12} md={12} sm={12} xs={12} className="apiLoaderPermission">
                                <img src={spinnerLoader} />
                            </Col>}
                            </>

  )
}

export default Simplr