import mqtt from "mqtt";
import KEY from  '../certificate/tracelive.key' 
import CERT  from '../certificate/tracelive.crt'
import TRUSTED_CA_LIST  from '../certificate/ca.crt'


//const websocketUrl = '35.222.249.181';
const websocketUrl = 'tracemqtt.traceplus.ai';
const username = 'tracelive';
const password = 'TraceLive@321$%';
const port = '9001';
const Secureport = '8883';
const apiEndpoint = "/mqtt";

// wss 9883
// ws 9001
// https 8883
// http 1883



function getClient(errorHandler) {
  
  const url =  `ws://${websocketUrl}:9001/mqtt`;
  const Secureurl = `mqtts://${websocketUrl}:${Secureport}/mqtt`;
  const options= {
    username:username,
    password:password,
    clientId:'demoTraceActive',
    keepalive: 30,
    protocolVersion: 4,
    clean: true,
    reconnectPeriod: 1000,
    connectTimeout: 30 * 1000,
    protocol: 'mqtt',

    //port:9883,
  }
  const Secureoptions= {
    username:username,
    password:password,
    clientId:'demoTraceActive',
    //host:'tracemqtt.traceplus.ai',
    keepalive: 30,
    //protocolId: 'MQTT',
    protocolVersion: 3.1,
    clean: true,
    reconnectPeriod: 1000,
    connectTimeout: 30 * 1000,
    protocol: 'wss',
    rejectUnauthorized:false,
    ca: TRUSTED_CA_LIST,   
    cert:CERT,
    key:KEY,
    //port:9883,
  }

  const client = mqtt.connect(url,options);
 // const client = tls.connect(url,options);

  client.stream.on("error", (err) => {
    errorHandler(`Connection to ${websocketUrl} failed`);
    client.end();
  });
  return client;
}

function subscribe(client, topic, errorHandler) {
  const callBack = (err, granted) => {
    if (err) {
      errorHandler("Subscription request failed");
    }
  };
  return client.subscribe(apiEndpoint + topic, callBack);
}

function onMessage(client, callBack) {
  client.on("message", (topic, message, packet) => {
    callBack(JSON.parse(new TextDecoder("utf-8").decode(message)));
  });
}

function unsubscribe(client, topic) {
  client.unsubscribe(apiEndpoint + topic);
}

function closeConnection(client) {
  client.end();
}
const mqttService = {
  getClient,
  subscribe,
  onMessage,
  unsubscribe,
  closeConnection,
};

export default mqttService;