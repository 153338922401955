import logo from './logo.svg';
import { BrowserRouter } from 'react-router-dom';
import {withRouter} from 'react-router-dom'
import './App.css';
import './assets/styles/aside.scss'
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/styles/common.scss'
import './assets/styles/responsive.css'
import LeftSideBar from './components/leftsidebar';
import RightSideComponents from './components/rightsideComponents';
import { useEffect,useState } from 'react';
import Modal from 'react-modal'
import ReactModal from 'react-modal'
import {getRoleDetail} from './common/actionMethods'
import {logoutUser} from './login/actionMethods/actionMethods'
import { useHistory } from 'react-router-dom'; 
import mqttService from './components/mqttService';

const customStyle = {
  overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(255, 255, 255, 0.75)'
  },
  content: {
      position: 'absolute',
      width: '25rem',
      height: '14rem',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      border: '1px solid #ccc',
      background: '#fff',
      overflow: 'auto',
      WebkitOverflowScrolling: 'touch',
      borderRadius: '4px',
      outline: 'none',
      padding: '0'
  }
}


function App() {

  let isLoggedIn = JSON.parse(localStorage.getItem('isLoggedIn'))?JSON.parse(localStorage.getItem('isLoggedIn')):false;
  let userDetails = localStorage.getItem('userLoginDetails')?JSON.parse(localStorage.getItem('userLoginDetails')):[]
  const [loggedInApp,SetLoggedInApp] = useState(isLoggedIn);
  
  const [role,SetRole]=useState([])
  const [expireModal,SetExpireModal] = useState(false);
  const [timer,SetTimer]=useState(10);


  let history = useHistory ();
  ReactModal.setAppElement('*'); 

  useEffect(()=>{
    if(isLoggedIn){
      SetLoggedInApp(true);
    }else{
      SetLoggedInApp(false);
    }
    identifyBrowser()

  },[isLoggedIn])

  
  function identifyBrowser() { 
    if((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1 ) 
   {
       //console.log('Opera');
   }
   else if(navigator.userAgent.indexOf("Edg") != -1 )
   {
    //console.log('Edge');
   }
   else if(navigator.userAgent.indexOf("Chrome") != -1 )
   {
    //console.log('Chrome');
   }
   else if(navigator.userAgent.indexOf("Safari") != -1)
   {
    //console.log('Safari');
   }
   else if(navigator.userAgent.indexOf("Firefox") != -1 ) 
   {
    //console.log('Firefox');
   }
   else if((navigator.userAgent.indexOf("MSIE") != -1 ) || (!!document.documentMode == true )) //IF IE > 10
   {
    //console.log('IE'); 
   }  
   else 
   {
    //console.log('unknown');
   }
   }
  useEffect(() => {
    if(expireModal){

    
    if(timer == 0 || timer < 0){
      handleLogout()
    }
    if(timer<=0){
      SetExpireModal(false);
      
      handleLogout()
      return
    }
    const interval = setInterval(() => {
        SetTimer((prev)=> prev - 1);
      }, 1000);
    
      return () => clearInterval(interval);
    }
    
    }, [timer,expireModal]);


  useEffect(() => {
    if(loggedInApp){

    
    if(userDetails.role){
        getRoleDetail(userDetails.role,userDetails.session,userDetails.org_id).then(res=>{
            if(res.status==200 || res.code == 200){
              
              SetRole(res.data[0]);
            }
            if(res.status == 300){
              SetExpireModal(true);
            }
        })
       
    } 
  }else{
    
  }

}, [loggedInApp]);

function handleLogout() {
  SetExpireModal(false);

  let requestBody = {}
  requestBody.token = localStorage.getItem('tokenAuthTrace')?localStorage.getItem('tokenAuthTrace'):'tituw3958589'
  requestBody.session = userDetails.session?userDetails.session:'123456789'

    logoutUser(requestBody).then(res => {
      if (res && res.status == 200 ) {
              localStorage.removeItem('isLoggedIn')
              localStorage.removeItem('userLoginDetails')
              localStorage.removeItem('selectedDate')
              localStorage.removeItem('tokenAuthTrace')
              SetLoggedInApp(false);  
              history.push(`/login`)
        
      }
  })
}

  return (
    
      <div className="mainAppDiv">
    
      {
        isLoggedIn ? 
        <LeftSideBar role={role} /> : ''
      }

        <RightSideComponents role={role} />
        <Modal
                isOpen={expireModal}
                style={customStyle}
            >
                <div className='logoutModal'>
                    <div className="logoutTitle">Logout</div>
                    <div className='logoutDesc' style={{fontSize:"14px"}}>Session expired<br/>
                    Redirecting to login in <span style={{color:"#f16b95"}}>{timer}</span> second.
                    </div>

                    <div className='actionButtons'>
                        {/* <span className='btnText borderRight' onClick={handleLogout}>No</span> */}
                        <span className='btnText okBtn' style={{width:"100%"}} onClick={handleLogout}>Ok</span>
                    </div>
                </div>
            </Modal>
            
      </div>
    
  );
}



export default withRouter(App);
