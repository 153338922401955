import axios from 'axios'


let prefixURL2 = process.env.REACT_APP_URL_LOGIN

let token = 'tituw3958589'

export function getRoleDetail( role_id , sessionID, org_id) {
    return axios.get(prefixURL2 + `/admin/view_role_info?session=${sessionID}&org_id=${org_id}&role_id=${role_id}` ).then(res=>res.data).catch(err=>err)
}
