import React, { useState, useEffect,useRef } from 'react'

import { useHistory, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';

import { Container, Row, Col } from 'react-bootstrap';
import moment from 'moment'
import DashboardLanguage from '../../components/dashboardLanguage';

import DatePicker from "react-datepicker";

import '../../siteManagement/styles/siteManagement.scss'

import { getEmployeeList, getDepartmentList, atRiskEmployee, contaminatedEmployee } from '../actionMethods/actionMethods';
import {getVisitorList,getTempEmpList} from '../../visitorManagement/actionMethods/actionMethods';
import selectedPinkArrowIcon from '../../assets/traceplusImages/pink_right_arrow_icon.svg'
import helpIcon from '../../assets/traceplusImages/help-icon.png'
import sortIcon from '../../assets/traceplusImages/sort.png'
import upIcon from '../../assets/traceplusImages/up-arrow.png'
import downIcon from '../../assets/traceplusImages/down-arrow.png'
import dayShiftImage from '../../assets/traceplusImages/sun.svg'


import spinnerLoader from '../../assets/images/Spinner Loader.gif'
import CommonDatePicker from '../../common/commonDatePicker';
import { getTranslatedText } from '../../common/utilities';
import { getLanguageTranslation, setSelectedLanguage } from '../../dashboard/actionMethods/actionMethods';
import { Checkbox, Button, Select } from 'antd';
//import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import {AgGridColumn, AgGridReact} from 'ag-grid-react';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';





const { Option } = Select;

const THREAT_API = {
    'risk': atRiskEmployee,
    'contaminated': contaminatedEmployee
}


function AgGridEmployeeList(props) {

     let date = localStorage.getItem('selectedDate') ? new Date(localStorage.getItem('selectedDate')) : new Date()
 
     const [searchValue, updateSearchValue] = useState('')
     const [messageValue, updateMessageValue] = useState('')
     const [selectedLangValue, updateSelectedLangValue] = useState('en')
     const [employeeList, updateEmployeeList] = useState([])
     const [preDefinedEmployeeList, updatePredefinedEmployeeList] = useState([])
     const [employeeCount, updateEmployeeCount] = useState(0)
     const [isLoading, updateIsLoading] = useState(true)
     const [sortKey, setSortKey] = useState('spread_index')
     const [sortType, setSortType] = useState('desc')
     const [teamData, setTeamData] = useState({})
     const [messageError, setMessageError] = useState("")
     const [teamList, updateTeamList] = useState([])
     const [preDefinedTeamList, updatedPredefinedTeamList] = useState([])
     const [isAll, setIsAll] = useState(false)
     const [selected, setSelected] = useState([])
     const [selectedDate, updateSelectedDate] = useState(date)
     const [positiveDateThreat,updatepositiveDateThreat]=useState();
     const [tempDataRisk,setTempDataRisk]=useState([]);
     const [widthContainer, setWidthContainer] = useState(0);
     const elementRef = useRef(null);   
     let userDetails = JSON.parse(localStorage.getItem('userLoginDetails'))
     let userSession = userDetails ? userDetails.session : '123456789'
     let org_id = userDetails ? userDetails.org_id : 6
 
     let history = useHistory();


      useEffect(() => {

        setWidthContainer(elementRef.current.getBoundingClientRect().width);
       
      }, [elementRef]);

     function handleDateSelect(date) {
        updateSelectedDate(date)

        let requestBody = {}
        requestBody.date = getDateFormat(date)
        updateIsLoading(true)
        getEmployeeList(requestBody, userSession, org_id).then(res => {
            updateIsLoading(false)

            if (res) {
                updateEmployeeCount(res.count)
                updateEmployeeList(res.data)
                updatePredefinedEmployeeList(res.data)

                if (props.setGlobalSearchData) {
                    props.setGlobalSearchData(res.data)
                }
            }
        }).catch(() => {
            updateIsLoading(false)
        })
        getDepartmentListData(requestBody)

    }


    function getDepartmentListData(requestBody) {
        getDepartmentList(requestBody, userSession, org_id).then(res => {
            if (res && res.status >= 200 && res.status <= 299) {
                updateTeamList(res.data)
                updatedPredefinedTeamList(res.data)
            }
        })
    }


    useEffect(() => {
        if (props.date) {
            let requestBody = {}

            if(props.sensitive){
                requestBody.sensitivity=props.sensitive
            }
            if (props.type && props.type === 'threat') {
                requestBody.start = getDateFormat(props.startDate)
                requestBody.end = getDateFormat(props.endDate)
                requestBody.rank = props.rank

             
                if(props.threatEmployee === 'contaminated'){
                    THREAT_API[props.threatEmployee](requestBody, userSession, org_id).then(res => {
                        updateIsLoading(false)
                        
                        if (res && res.data) {
                            let data = res.data
                              
                            updatepositiveDateThreat(data.tested_positive_on);
                            updateEmployeeCount(data.contaminated_count)
                            updateEmployeeList(data.employee_contaminated)
                            updatePredefinedEmployeeList(data.employee_contaminated)
    
                        }
                    }).catch(() => {
                        updateIsLoading(false)
                    })
                }

               else if(props.rank==1 && props.threatEmployee === 'risk'){
                THREAT_API[props.threatEmployee](requestBody, userSession, org_id).then(res => {
                    updateIsLoading(false)
                    if (res && res.data) {
                        let data = res.data
                        let tempdataThreat= [];
                        //updatepositiveDateThreat
                          
                        updatepositiveDateThreat(data.tested_positive_on);
                        if (props.threatEmployee === 'risk') {
                            for(let i1= 0 ; i1<data.atrisk_employee.length;i1++){
                                let element=data.atrisk_employee[i1];
                              
                                element.rank=parseInt(requestBody.rank);
                                tempdataThreat.push(element);
                            }

                            updateEmployeeCount(data.atrisk_employee_count)
                            updateEmployeeList(tempdataThreat)
                            updatePredefinedEmployeeList(tempdataThreat)

                        }
                        setTempDataRisk(tempdataThreat)

                    }
                }).catch(() => {
                    updateIsLoading(false)
                })
            }else if(props.rank==2 && props.threatEmployee === 'risk'){
                let tempdataThreat=[];

            updateIsLoading(false)
            updateEmployeeCount(props.rank2Data.length)
                            updateEmployeeList(props.rank2Data)
                            updatePredefinedEmployeeList(props.rank2Data)
                            setTempDataRisk(props.rank2Data);

            }

            }else if(props.type && props.type === 'Visitor'){
                requestBody.date = getDateFormat(props.date)
                updateIsLoading(true)
                getVisitorList(requestBody, userSession, org_id).then(res => {
                    updateIsLoading(false)

                    if (res) {
                        updateEmployeeCount(res.count)
                        updateEmployeeList(res.data)
                        updatePredefinedEmployeeList(res.data)

                        if (props.setGlobalSearchData) {
                            props.setGlobalSearchData(res.data)
                        }

                        
                    }
                }).catch(() => {
                    updateIsLoading(false)
                })
            }
            else if(props.type && props.type === 'Temporary Employee'){
                updateIsLoading(true)
                getTempEmpList( userSession, org_id,getDateFormat(props.date)).then(res => {
                    updateIsLoading(false)

                    if (res) {
                    
                        updateEmployeeCount(res.count)
                        updateEmployeeList(res.data)
                        updatePredefinedEmployeeList(res.data)

                        if (props.setGlobalSearchData) {
                            props.setGlobalSearchData(res.data)
                        }

                        
                    }
                }).catch(() => {
                    updateIsLoading(false)
                })
            }
            
            else {
                requestBody.date = getDateFormat(props.date)
                updateIsLoading(true)
                getEmployeeList(requestBody, userSession, org_id).then(res => {
                    updateIsLoading(false)

                    if (res) {
                        updateEmployeeCount(res.count)
                        updateEmployeeList(res.data)
                        updatePredefinedEmployeeList(res.data)

                        if (props.setGlobalSearchData) {
                            props.setGlobalSearchData(res.data)
                        }

                        let empData = res.data
                        let teamObj = {}
                        for (let i = 0; i < empData.length; i++) {
                            let team = empData[i]['team']
                            if (teamObj.hasOwnProperty(team)) {
                                teamObj[team] = teamObj[team] + 1
                            } else {
                                teamObj[team] = 1
                            }
                        }

                        setTeamData(teamObj)
                    }
                }).catch(() => {
                    updateIsLoading(false)
                })
                getDepartmentListData(requestBody)
            }
        }
    }, [props.date,props.reloadInc]);


    function getDateFormat(date) {
        return moment(date).format('YYYY-MM-DD')
    }

    function handleManpowerManagementList() {
        history.push('/manpower-management')
    }


   function handleClickCard(id) {
       if(props.type=="Visitor"){
        history.push(`/visitor-management/view/:${id}`)
       } else{
        history.push(`/manpower-management/employee-list/view/:${id}`)
       }
       if(props.handleCloseDevice){
        props.handleCloseDevice();
       }
        
    }

    function showShiftType(type) {
        let shiftType = dayShiftImage

        switch (type) {
            case 'day':
                shiftType = dayShiftImage
                break;

            default:
                break;
        }

        return shiftType
    }
    function getBackgroundCOlor(index, activated) {
        let color = ''

        if (!activated) {
            color = '#EBECED'
        } else if (index === 0) {
            color = '#f9e1e8'
        }

        return color
    }

    function handleChangeTab(type) {
        props.handleTabViewChange(type)
        setIsAll(false)
        setSelected([])
        updateSearchValue("")
        updateEmployeeList(preDefinedEmployeeList)
        updateTeamList(preDefinedTeamList)
    }

    function onChangeCheckbox(){

    }
    const checkboxSelection = function (params) {
        return params.columnApi.getRowGroupColumns().length === 0;
      };
      const headerCheckboxSelection = function (params) {
        return params.columnApi.getRowGroupColumns().length === 0;
      };
      let count=0;
    function teamDataRenderer(params){
        var eGui = document.createElement('div');
        eGui.innerHTML = '<h5 class="font-bold">'+params.value+'</h5><div class="bottom-val"><b>People:</b> '+params.data.team_members_count+'</div>';
         return eGui;
    }

    function empDataRenderer(params){
        var eGui = document.createElement('div');
        eGui.innerHTML = '<h5 class="font-bold">'+params.value+'</h5><div class="bottom-val"><b>Team:</b> '+params.data.team+'</div><div class="bottom-val"><b>Tag:</b> '+params.data.tag_serial+'</div>';
         return eGui;
    }
    function covidStatusRender(params){
        var eGui = document.createElement('div');
        eGui.innerHTML = '<div class="riskDivCovid riskDivCovid-'+params.value+'">'+params.value+'</div>';
         return eGui;
    }
    function RedirectIcon(params){
        var eGui = document.createElement('div');
        eGui.innerHTML = '<img src="'+selectedPinkArrowIcon+'" style="max-width:30px;"/>';
         return eGui;
    }



    useEffect(()=>{

var elements = document.getElementsByClassName("tooltipcustom");


for (var i = 0; i < elements.length; i++) {
    elements[i].addEventListener('mouseenter',tooltipCreator, false);
    elements[i].addEventListener('mouseover',tooltipCreator, false);
}
},[])

function tooltipCreator(event){
   
}
function tooltipDestroyer(event){
   
}

    useEffect(() => {
        if (props.type !== 'Team') {

            if (preDefinedEmployeeList.length) {
                updateIsLoading(true)

                let arr = [...preDefinedEmployeeList]

                for (let i=0;i<preDefinedEmployeeList.length;i++){

                }
                if (searchValue) {
                    let invalid = /[°"§%()[\]{}=\\?´`'#<>|,;.:+_-]+/g;
                    let value = searchValue.replace(invalid, "")
                    arr = arr.filter(function (employeeList) {
                        return (employeeList.name.toLowerCase().search(value.toLowerCase()) !== -1 || employeeList.team.toLowerCase().search(value.toLowerCase()) !== -1 || employeeList.tag_serial.toLowerCase().search(value.toLowerCase()) !== -1)
                    })
                }

                updateEmployeeList(arr)
                setTempDataRisk(arr)
                updateIsLoading(false)
            }
        } else {
            if (preDefinedTeamList.length) {
                updateIsLoading(true)

                let arrTeam = [...preDefinedTeamList]


                if (searchValue) {
                    let invalid = /[°"§%()[\]{}=\\?´`'#<>|,;.:+_-]+/g;
                    let value = searchValue.replace(invalid, "")
                    arrTeam = arrTeam.filter(function (teamList) {
                        return (teamList.team.toLowerCase().search(value.toLowerCase()) !== -1)
                    })
                }
                updateTeamList(arrTeam)
                updateIsLoading(false)
            }
        }

    }, [sortKey, sortType, searchValue])

    
    function timeCompare(){

    }

    const timeComparator = (valueA, valueB, nodeA, nodeB, isInverted) => {


        let aMs = moment(valueA, 'HH:mm:ss').format('x')
        let bMs = moment(valueB, 'HH:mm:ss').format('x')
            if(valueA=='na' || valueB=='na')return 0;
            if (valueA == valueB) return 0;
            return (aMs > bMs) ? 1 : -1;       
     };



     function AGGridEMptyMEssage(key){
        let msg;

        if(props.type=='threat'&&props.threatEmployee=='contaminated'&&preDefinedEmployeeList.length>0){
            msg='<span class="ag-overlay-no-rows-center">No Records Found !</span>'
        }else if(props.type=='threat'&&props.threatEmployee=='contaminated'){
            msg='<span class="ag-overlay-no-rows-center">No Records Found! !</span>'
        }
        
        else if(props.type=='threat'&&props.threatEmployee=='atrisk'){
            msg='<span class="ag-overlay-no-rows-center">No Records Found !</span>'
        }else if(key=='showCardListLocation'){
            msg='<span class="ag-overlay-no-rows-center">No Records Found !</span>'
        }
        else if(props.type==="Visitor" && preDefinedEmployeeList.length>0){
            msg='<span class="ag-overlay-no-rows-center">No Visitor Records Found !</span>'
        }
        else if(props.type==="Temporary Employee" && preDefinedEmployeeList.length>0){
            msg='<span class="ag-overlay-no-rows-center">No Temporary Employee Records Found !</span>'
        }
        else if(props.type==="Visitor"){
            msg='<span class="ag-overlay-no-rows-center">No Visitor Records Found !</span>'
        }else{
            msg='<span class="ag-overlay-no-rows-center">No Record Found !</span>'
            
        }
        return msg
      }


     function showCardList(employeeList) {
      
        let arr;
         let arr2 = [];
         arr2 = teamList.sort((a, b) => {
            if (a.spread_index !== 'na' && b.spread_index !== 'na') {
                return sortType === 'desc' ? b.spread_index - a.spread_index : a.spread_index - b.spread_index
            } else if (a.spread_index !== 'na' && b.spread_index === 'na') {
                return -1
            } else {
                return 1
            }
        }) 
     
         if (props.isManpower && props.type == "Team") {
           
            arr=<div><div className="ag-theme-alpine if cell-size-55" style={{height: ((arr2.length +2) *75),maxHeight:props.height?props.height:350 , width: "calc(100% - 1px)"}}>
                <AgGridReact
    rowHeight={55}
      autoGroupColumnDef={{
        headerName: 'Team',
       
        field: 'team',
        headerCheckboxSelection: true,
        cellRenderer: 'agGroupCellRenderer',
        cellRendererParams: { checkbox: true },
      }}
      defaultColDef={{
           sortable: true,
        resizable: true,
        minWidth:window.innerWidth<1280?100:45,

             }}
      suppressRowClickSelection={true}
     
      rowData={arr2}
      key={"teamData"}
      //onCellClicked={ (event) => //console.log('Cell was clicked',event)}
      noRowsTemplate = '<span>no rows to show sfsdfsdfsf</span>'
      overlayNoRowsTemplate={
        '<span class="ag-overlay-no-rows-center">No Team Record Found !</span>'
      }

	  

      >
        <AgGridColumn
          field="team"
          headerName={"Teams"}
          minWidth={100}
          checkboxSelection={checkboxSelection}
          headerCheckboxSelection={headerCheckboxSelection}
          cellRenderer= {(params)=>teamDataRenderer(params)}
          headerComponentParams =  {{
            template:`<div class="ag-cell-label-container" role="presentation"> 
                <div ref="eLabel" class="ag-header-cell-label" role="presentation"> 
              <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
              <span class="tooltipcustom" ><img src='${helpIcon}'/><span class="descHelp tootltipHelp">Name of Team and member count.</span></span>
              <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span> 
              <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span> 
              <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span> 
            </div> 
          </div>`	
        }}

         >             
         </AgGridColumn>
         <AgGridColumn field="spread_index" headerName="Spread" valueFormatter={(params) =>params.value!='na'?params.value+"%":'-'} 
                   headerComponentParams =  {{
                    template:`<div class="ag-cell-label-container" role="presentation"> 
                        <div ref="eLabel" class="ag-header-cell-label" role="presentation"> 
                      <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
                      <span class="tooltipcustom"><img src='${helpIcon}'/><span class="descHelp tootltipHelp">Risk of transmission through human to human interaction.</span></span>
                      <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span> 
                      <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span> 
                      <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span> 
                    </div> 
                  </div>`	
                }}
         ></AgGridColumn>
            <AgGridColumn field="close_contacts" headerName="Close Contacts" valueFormatter={(params) =>params.value!='na'?params.value:'-'}
                               headerComponentParams =  {{
                                template:`<div class="ag-cell-label-container" role="presentation"> 
                                    <div ref="eLabel" class="ag-header-cell-label" role="presentation"> 
                                  <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
                                  <span class="tooltipcustom"><img src='${helpIcon}'/><span class="descHelp tootltipHelp">Number of contacts where transmission through human to human is over 90%</span></span>
                                  <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span> 
                                  <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span> 
                                  <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span> 
                                </div> 
                              </div>`	
                            }}
            ></AgGridColumn>
            <AgGridColumn sort={"desc"} field="interaction_time" headerName="Avg. Interaction Time" valueFormatter={(params) =>params.value!='na'?params.value:'-'}
                                           headerComponentParams =  {{
                                            template:`<div class="ag-cell-label-container" role="presentation"> 
                                                <div ref="eLabel" class="ag-header-cell-label" role="presentation"> 
                                              <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
                                              <span class="tooltipcustom"><img src='${helpIcon}'/><span class="descHelp tootltipHelp">Cumulated interaction time with other people.</span></span>
                                              <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span> 
                                              <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span> 
                                              <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span> 
                                            </div> 
                                          </div>`	
                                        }}
            ></AgGridColumn>
            <AgGridColumn field="mobility_index" headerName="Mobility Index" valueFormatter={(params) =>params.value!='na'?params.value+"%":'-'} 
            headerComponentParams =  {{
                template:`<div class="ag-cell-label-container" role="presentation"> 
                    <div ref="eLabel" class="ag-header-cell-label" role="presentation"> 
                  <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
                  <span class="tooltipcustom"><img src='${helpIcon}'/><span class="descHelp tootltipHelp">Risk of transmission through human to location interaction (Fomite Risk)</span></span>
                  <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span> 
                  <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span> 
                  <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span> 
                </div> 
              </div>`	
            }}
            ></AgGridColumn>
            <AgGridColumn field="popular_loc_visited" headerName="Location Visited" valueFormatter={(params) =>params.value!='na'?params.value:'-'}
                        headerComponentParams =  {{
                            template:`<div class="ag-cell-label-container" role="presentation"> 
                                <div ref="eLabel" class="ag-header-cell-label" role="presentation"> 
                              <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
                              <span class="tooltipcustom"><img src='${helpIcon}'/><span class="descHelp tootltipHelp">Number of popular areas visited</span></span>
                              <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span> 
                              <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span> 
                              <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span> 
                            </div> 
                          </div>`	
                        }}
            
            ></AgGridColumn>
            <AgGridColumn field="" headerName="" maxWidth={80} cellRenderer= {(params)=>RedirectIcon(params)} hide={true}></AgGridColumn>
        </AgGridReact> </div></div>
         } else {


            arr2 = employeeList.sort((a, b) => {
                if (a.spread_index !== 'na' && b.spread_index !== 'na') {
                    return sortType === 'desc' ? b.spread_index - a.spread_index : a.spread_index - b.spread_index
                } else if (a.spread_index !== 'na' && b.spread_index === 'na') {
                    return -1
                } else {
                    return 1
                }
            })
//manpower Employee
            if(props.isManpower){

                let widthCal=((widthContainer - 23) / 8);
                if(props.columnexist && props.isModalData ){
                    widthCal=((widthContainer - 30 )/ props.columnexist);
                
                }
                else if(props.columnexist){
                    widthCal=((widthContainer - 30) / props.columnexist);
                
                }
                if(window.innerWidth<1280){
                    widthCal="120"
                }
                
                arr=<div><div className="ag-theme-alpine if cell-size-75" style={{height: ((arr2.length +2) *77),maxHeight:props.height?props.height: window.innerHeight - (props.type ? 370 : 280)  , width: "calc(100% - 1px)"}}>
                <AgGridReact
                    rowHeight={75}
                    autoGroupColumnDef={{
                        headerName: 'Name',field: 'name',headerCheckboxSelection: true,
                        cellRendererParams: { checkbox: true },       
                    }}
              
                    defaultColDef={{sortable: true,resizable: true,minWidth:45, width:widthCal , suppressColumnVirtualisation:true,skipHeaderOnAutoSize:true}}
              
                rowData={arr2}
                key={props.AgkeyTable?props.AgkeyTable:"manpowerEmployee"}
                onCellClicked={ (event) => handleClickCard(event.data.tag_serial)}
                overlayNoRowsTemplate={
                    AGGridEMptyMEssage()
                  }
                  
                  onGridReady={ (event) => function(event){
                      
                    event.sizeColumnsToFit();
                  } 
                }


                 >
                <AgGridColumn
                    field="name"
                    //width={200}
                    headerName={"Name"}
                    minWidth={100}
                    checkboxSelection={checkboxSelection}
                    headerCheckboxSelection={headerCheckboxSelection}
                    cellRenderer= {(params)=>empDataRenderer(params)}
                    headerComponentParams =  {{
                        template:`<div class="ag-cell-label-container" role="presentation"> 
                            <div ref="eLabel" class="ag-header-cell-label" role="presentation"> 
                          <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
                          <span class="tooltipcustom"><img src='${helpIcon}'/><span class="descHelp tootltipHelp">Name, Team, MAC ID of his tag.</span></span>
                          <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span> 
                          <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span> 
                          <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span> 
                        </div> 
                      </div>`	
                    }}
                />
                <AgGridColumn field="spread_index" headerName="Spread" valueFormatter={(params) =>params.value!='na'?params.value+"%":'-'}    headerComponentParams =  {{
                    template:`<div class="ag-cell-label-container" role="presentation"> 
                        <div ref="eLabel" class="ag-header-cell-label" role="presentation"> 
                    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
                    <span class="tooltipcustom"><img src='${helpIcon}'/><span class="descHelp tootltipHelp">Risk of transmission through human to human interaction.</span></span>
                    <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span> 
                    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span> 
                    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span> 
                    </div> 
                </div>`	
                }} ></AgGridColumn>
            <AgGridColumn field="close_contacts" headerName="Close Contacts" valueFormatter={(params) =>params.value!='na'?params.value:'-'}  headerComponentParams =  {{
                    template:`<div class="ag-cell-label-container" role="presentation"> 
                        <div ref="eLabel" class="ag-header-cell-label" role="presentation"> 
                    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
                    <span class="tooltipcustom"><img src='${helpIcon}'/><span class="descHelp tootltipHelp">Number of contacts where transmission through human to human is over 90%</span></span>
                    <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span> 
                    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span> 
                    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span> 
                    </div> 
                </div>`	
                }} ></AgGridColumn>
            <AgGridColumn field="interaction_time" sort={"desc"} headerName="Avg. Interaction Time" comparator={timeComparator}  valueFormatter={(params) =>params.value!='na'?params.value:'-'} headerComponentParams =  {{
                    template:`<div class="ag-cell-label-container" role="presentation"> 
                        <div ref="eLabel" class="ag-header-cell-label" role="presentation"> 
                    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
                    <span class="tooltipcustom"><img src='${helpIcon}'/><span class="descHelp tootltipHelp">Cumulated interaction time with other people.</span></span>
                    <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span> 
                    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span> 
                    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span> 
                    </div> 
                </div>`	
                }} ></AgGridColumn>
            <AgGridColumn field="mobility_index" headerName="Mobility" valueFormatter={(params) =>params.value!='na'?params.value+"%":'-'} headerComponentParams =  {{
                    template:`<div class="ag-cell-label-container" role="presentation"> 
                        <div ref="eLabel" class="ag-header-cell-label" role="presentation"> 
                    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
                    <span class="tooltipcustom"><img src='${helpIcon}'/><span class="descHelp tootltipHelp">Risk of transmission through human to location interaction (Fomite Risk)</span></span>
                    <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span> 
                    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span> 
                    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span> 
                    </div> 
                </div>`	
                }} ></AgGridColumn>
            <AgGridColumn field="popular_loc_visited" headerName="Location Visited" valueFormatter={(params) =>params.value!='na'?params.value:'-'} headerComponentParams =  {{
                    template:`<div class="ag-cell-label-container" role="presentation"> 
                        <div ref="eLabel" class="ag-header-cell-label" role="presentation"> 
                    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
                    <span class="tooltipcustom"><img src='${helpIcon}'/><span class="descHelp tootltipHelp">Number of popular areas visited</span></span>
                    <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span> 
                    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span> 
                    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span> 
                    </div> 
                </div>`	
                }} >
                    
                </AgGridColumn>
            <AgGridColumn field="status" headerName="Status" 
                      cellRenderer= {(params)=>covidStatusRender(params)}
                      headerComponentParams =  {{
                        template:`<div class="ag-cell-label-container" role="presentation"> 
                            <div ref="eLabel" class="ag-header-cell-label" role="presentation"> 
                        <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
                        <span class="tooltipcustom"><img src='${helpIcon}'/><span class="descHelp tootltipHelp">Status of the person.</span></span>
                        <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span> 
                        <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span> 
                        <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span> 
                        </div> 
                    </div>`	
                    }} ></AgGridColumn>
            <AgGridColumn field="battery" headerName="Battery" valueFormatter={(params) =>params.value!='na'?params.value+"%":'-'}                       headerComponentParams =  {{
                        template:`<div class="ag-cell-label-container" role="presentation"> 
                            <div ref="eLabel" class="ag-header-cell-label" role="presentation"> 
                        <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
                        <span class="tooltipcustom"><img src='${helpIcon}'/><span class="descHelp tootltipHelp">Level of battery charge in the Personal tag</span></span>
                        <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span> 
                        <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span> 
                        <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span> 
                        </div> 
                    </div>`	
                    }} ></AgGridColumn></AgGridReact>
           </div></div>
        
    }else{

//All Conatminated and At Risk
let widthCal=((widthContainer - 25) / 8);
if(props.columnexist && props.isModalData ){
    widthCal=((widthContainer - 20 )/ props.columnexist);

}
else if(props.columnexist){
    widthCal=((widthContainer - 20) / props.columnexist);

}

if(window.innerWidth<1280){
    widthCal="120"
}

        arr=<div><div className="ag-theme-alpine if cell-size-75" style={{height: ((arr2.length +2) *77),maxHeight:props.height?props.height:350  , width: "calc(100% - 1px)"}}>
        <AgGridReact
                    rowHeight={75}
                    autoGroupColumnDef={{
                        headerName: 'Name',field: 'name',headerCheckboxSelection: true,
                        cellRendererParams: { checkbox: true },       
                    }}
                // defaultColDef={{sortable: true,resizable: true,flex: 1}}
                // defaultColDef={{sortable: true,resizable: true,minWidth:45}}
                defaultColDef={{sortable: true,resizable: true,minWidth:45, width:widthCal , suppressColumnVirtualisation:true,skipHeaderOnAutoSize:true}}
                //pagination={true}
                rowData={arr2}
                key={props.AgkeyTable?props.AgkeyTable:"manpowerEmployee"}
                onCellClicked={ (event) => handleClickCard(event.data.tag_serial)}
                overlayNoRowsTemplate={
                    AGGridEMptyMEssage()
                  }
                 >
                    <AgGridColumn
                        field="name"
                        minWidth={100}
                        //width={200}
                        headerName={"Name"}
                        checkboxSelection={checkboxSelection}
                        headerCheckboxSelection={headerCheckboxSelection}
                        cellRenderer= {(params)=>empDataRenderer(params)}
                        headerComponentParams =  {{
                            template:`<div class="ag-cell-label-container" role="presentation"> 
                                <div ref="eLabel" class="ag-header-cell-label" role="presentation"> 
                            <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
                            <span class="tooltipcustom"><img src='${helpIcon}'/><span class="descHelp tootltipHelp">Name, Team, MAC ID of his tag.</span></span>
                            <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span> 
                            <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span> 
                            <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span> 
                            </div> 
                        </div>`	
                        }}
                />

                    {props.threatEmployee=='risk'?   
                      <AgGridColumn
                        field="rank"
                        
                        headerName={"Contact Rank"}
                        headerComponentParams =  {{
                            template:`<div class="ag-cell-label-container" role="presentation"> 
                                <div ref="eLabel" class="ag-header-cell-label" role="presentation"> 
                            <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
                            <span class="tooltipcustom"><img src='${helpIcon}'/><span class="descHelp tootltipHelp">Contact Rank.</span></span>
                            <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span> 
                            <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span> 
                            <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span> 
                            </div> 
                        </div>`	
                        }}
                />:""}

                <AgGridColumn field="spread_index" headerName="Spread" valueFormatter={(params) =>params.value!='na'?params.value+"%":'-'} 
                headerComponentParams =  {{
                    template:`<div class="ag-cell-label-container" role="presentation"> 
                        <div ref="eLabel" class="ag-header-cell-label" role="presentation"> 
                    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
                    <span class="tooltipcustom"><img src='${helpIcon}'/><span class="descHelp tootltipHelp">Risk of transmission through human to human interaction.</span></span>
                    <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span> 
                    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span> 
                    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span> 
                    </div> 
                </div>`	
                }} ></AgGridColumn>
    <AgGridColumn field="close_contacts" headerName="Close Contacts" valueFormatter={(params) =>params.value!='na'?params.value:'-'} headerComponentParams =  {{
                    template:`<div class="ag-cell-label-container" role="presentation"> 
                        <div ref="eLabel" class="ag-header-cell-label" role="presentation"> 
                    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
                    <span class="tooltipcustom"><img src='${helpIcon}'/><span class="descHelp tootltipHelp">Number of contacts where transmission through human to human is over 90%</span></span>
                    <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span> 
                    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span> 
                    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span> 
                    </div> 
                </div>`	
                }} ></AgGridColumn>
                

    <AgGridColumn field="interaction_time" sort={"desc"} headerName="Avg. Interaction Time" valueFormatter={(params) =>params.value!='na'?params.value:'-'} headerComponentParams =  {{
                    template:`<div class="ag-cell-label-container" role="presentation"> 
                        <div ref="eLabel" class="ag-header-cell-label" role="presentation"> 
                    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
                    <span class="tooltipcustom"><img src='${helpIcon}'/><span class="descHelp tootltipHelp">Cumulated interaction time with other people.</span></span>
                    <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span> 
                    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span> 
                    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span> 
                    </div> 
                </div>`	
                }} ></AgGridColumn>
    <AgGridColumn field="mobility_index" headerName="Mobility" valueFormatter={(params) =>params.value!='na'?params.value+"%":'-'} headerComponentParams =  {{
                    template:`<div class="ag-cell-label-container" role="presentation"> 
                        <div ref="eLabel" class="ag-header-cell-label" role="presentation"> 
                    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
                    <span class="tooltipcustom"><img src='${helpIcon}'/><span class="descHelp tootltipHelp">Risk of transmission through human to location interaction (Fomite Risk)</span></span>
                    <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span> 
                    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span> 
                    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span> 
                    </div> 
                </div>`	
                }} ></AgGridColumn>
    <AgGridColumn field="popular_loc_visited" headerName="Location Visited" valueFormatter={(params) =>params.value!='na'?params.value:'-'} headerComponentParams =  {{
                    template:`<div class="ag-cell-label-container" role="presentation"> 
                        <div ref="eLabel" class="ag-header-cell-label" role="presentation"> 
                    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
                    <span class="tooltipcustom"><img src='${helpIcon}'/><span class="descHelp tootltipHelp">Number of popular areas visited</span></span>
                    <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span> 
                    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span> 
                    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span> 
                    </div> 
                </div>`	
                }} ></AgGridColumn>


 {props.type=='threat'&&props.threatEmployee=='contaminated'?
            <AgGridColumn field="status"  
                      cellRenderer= {(params)=>covidStatusRender(params)}
                      headerComponentParams =  {{
                        template:`<div class="ag-cell-label-container" role="presentation"> 
                            <div ref="eLabel" class="ag-header-cell-label" role="presentation"> 
                        <span  class="ag-header-cell-text less-width-status" role="columnheader">Status On <span className="small">${getDateFormat(props.endDate)}</span> </span>
                        <span class="tooltipcustom"><img src='${helpIcon}'/><span class="descHelp tootltipHelp">Status of the person.</span></span>
                        <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span> 
                        <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span> 
                        <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span> 
                        </div> 
                    </div>`	
                    }} ></AgGridColumn>:<AgGridColumn field="status" headerName="Status" 
                    cellRenderer= {(params)=>covidStatusRender(params)}
                    headerComponentParams =  {{
                      template:`<div class="ag-cell-label-container" role="presentation"> 
                          <div ref="eLabel" class="ag-header-cell-label" role="presentation"> 
                      <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
                      <span class="tooltipcustom"><img src='${helpIcon}'/><span class="descHelp tootltipHelp">Status of the person.</span></span>
                      <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span> 
                      <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span> 
                      <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span> 
                      </div> 
                  </div>`	
                  }} ></AgGridColumn>
                    
            }



        {props.type=='threat'&&props.threatEmployee=='contaminated'?
            <AgGridColumn field="tag_serial" headerName="Positive On" 
            //cellRenderer= {(params)=>covidStatusRender(params)}
            headerComponentParams =  {{
              template:`<div class="ag-cell-label-container" role="presentation"> 
                  <div ref="eLabel" class="ag-header-cell-label" role="presentation"> 
              <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
              <span class="tooltipcustom"><img src='${helpIcon}'/><span class="descHelp tootltipHelp">Reported Positive Date.</span></span>
              <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span> 
              <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span> 
              <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span> 
              </div> 
          </div>`	
          }}
          valueFormatter={(params)=>{
           return moment(positiveDateThreat[params.value]).format("DD/MM/YYYY")
          }}
          ></AgGridColumn>:'' 
        }


<AgGridColumn field="battery" headerName="Battery" valueFormatter={(params) =>params.value!='na'?params.value+"%":'-'}
                      headerComponentParams =  {{
                        template:`<div class="ag-cell-label-container" role="presentation"> 
                            <div ref="eLabel" class="ag-header-cell-label" role="presentation"> 
                        <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
                        <span class="tooltipcustom"><img src='${helpIcon}'/><span class="descHelp tootltipHelp">Level of battery charge in the Personal tag</span></span>
                        <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span> 
                        <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span> 
                        <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span> 
                        </div> 
                    </div>`	
                    }} ></AgGridColumn>


{props.type=='Visitor'?
                                    <AgGridColumn field="check_in" headerName="Check In" valueFormatter={(params) =>(params.value!== null) ?moment(params.value).format('LT'):'-'}
                      headerComponentParams =  {{
                        template:`<div class="ag-cell-label-container" role="presentation"> 
                            <div ref="eLabel" class="ag-header-cell-label" role="presentation"> 
                        <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
                        <span class="tooltipcustom"><img src='${helpIcon}'/><span class="descHelp tootltipHelp">Check in time of person</span></span>
                        <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span> 
                        <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span> 
                        <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span> 
                        </div> 
                    </div>`	
                    }} ></AgGridColumn>:""}
                    {props.type=='Visitor'?
                                    <AgGridColumn field="check_out" headerName="Check Out" valueFormatter={(params) =>(  params.value!== null) ?moment(params.value).format('LT'):'-'}
                      headerComponentParams =  {{
                        template:`<div class="ag-cell-label-container" role="presentation"> 
                            <div ref="eLabel" class="ag-header-cell-label" role="presentation"> 
                        <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
                        <span class="tooltipcustom"><img src='${helpIcon}'/><span class="descHelp tootltipHelp">Check Out time of person</span></span>
                        <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span> 
                        <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span> 
                        <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span> 
                        </div> 
                    </div>`	
                    }} ></AgGridColumn>:""}


</AgGridReact> </div></div>


            }

         }
 
         return arr
     }

     function changeLanguage(lang) {
        getLanguageTranslation(lang).then(res => {
            if (res && res.status >= 200 && res.status <= 200) {
                localStorage.setItem('languageData', JSON.stringify(res.data))
                localStorage.setItem('selectedLanguage', lang)
                props.setSelectedLanguage(lang)

            }
        })
    }

    function handleChange(value) {

      }

      function sendMessage() {
        if (messageValue == '' || messageValue == null || messageValue == undefined) {
            setMessageError("Message is required.")
        } else if (messageValue.length > 100) {
            setMessageError("Message size can not be more than 100 character.")
        } else {
            setMessageError("")
        }
    }

return (<div className={props.type?"siteViewMainDiv siteManagementMainDiv siteManagementMainDiv-"+props.type:"siteViewMainDiv siteManagementMainDiv"} ref={elementRef} style={props.hideHeading ? { paddingTop: 0, paddingBottom: 0 } : {}}>
<Container>

    {props.hideHeading ? '' :
        <Row>
            <Col lg={6}>
                <div className="siteViewHeaderDiv">
                    <span className="smallHeader" onClick={handleManpowerManagementList}>{getTranslatedText('Manpower Management')}</span>
                    <span className="breadCrumbArrow"> &gt; </span>
                    <span className="mediumHeader">{getTranslatedText('Employee Listing')}</span>
                </div>
            </Col>

            <Col lg={6} className="text-right">
                <div className="commonLangaugeStyleDiv">
                    <DashboardLanguage
                        selectedLangValue={selectedLangValue}
                        changeLanguage={changeLanguage}
                    />
                </div>

                <div className="siteHeadingDatePickerDiv" style={{ width: '20%' }}>
                    <CommonDatePicker
                        selectedDate={selectedDate}
                        handleSelectDate={handleDateSelect}
                    />
                </div>
            </Col>

        </Row>
    }

    <Row className={props.hideHeading ? '' : 'm-t'}>
        <Col lg={12}>
            <div className={'siteListMainDiv ' + (props.hideHeading ? 'p-l-0 p-r-0' : '')} style={props.hideHeading ? { paddingTop: 0, paddingBottom: 0 } : {}}>
                <Row style={{ alignItems: 'center' }}>
                    <Col lg={props.selectedTab || props.datepicker ? 4 : 8} className={props.hideHeading ? 'p-l-0' : ''}>
                        <h3 className="locationsListing">
                            {props.title ? props.title : getTranslatedText('Employees/Teams')}
                            {!props.selectedTab ?
                                (`(${employeeList && employeeList.length})`)
                                : null
                            }
                        </h3>
                    </Col>

                    {props.selectedTab &&
                        <Col lg={4} className="resp-team-box" >
                            <div className="empTeamTabMainDiv" style={{ float: 'right' }}>
                                <div className={'eachTab ' + (props.selectedTab == 'employees' ? 'activeTabBG' : '')} onClick={() => handleChangeTab('employees')}>{getTranslatedText('Employees')}</div>
                                <div className={'eachTab ' + (props.selectedTab == 'teams' ? 'activeTabBG' : '')} onClick={() => handleChangeTab('teams')}>{getTranslatedText('Teams')}</div>
                            </div>
                        </Col>
                    }

                    {/* datepicker={true}
                    ={startDate}
                    endDate={endDate} */}
                    {
                        props.datepicker?<><Col lg={2} className="resp-team-box">
                            <div className="contactRankText">
                                            {getTranslatedText("Start Date")}
                                        </div>
                                        <div
                                            className="startDateEndDateMainDiv"
                                            style={{ paddingLeft: "0px",zIndex:999 }}
                                        >
                                            <DatePicker
                                                selected={props.startDate}
                                                onChange={(date) => props.handleDate(date, "start")}
                                                dateFormat={"MMM dd"}
                                                isClearable={false}
                                                placeholderText={getTranslatedText("Start Date")}
                                                maxDate={moment(props.date).toDate()}
                                                minDate={moment().subtract(120, "days").toDate()}
                                            />
                                        </div>
                                        </Col>
                                        <Col lg={2} className="resp-team-box">
                                    <div className="contactRankText" style={{zIndex:999}}>
                                            {getTranslatedText("End Date")}
                                        </div>
                                        <div
                                            className="startDateEndDateMainDiv"
                                            style={{ paddingLeft: "0px",zIndex:999 }}
                                        >
                                            <DatePicker
                                                selected={moment(props.endDate).toDate()}
                                                onChange={(date) => props.handleDate(date, "end")}
                                                dateFormat={"MMM dd"}
                                                isClearable={false}
                                                placeholderText={getTranslatedText("End Date")}
                                                maxDate={moment(props.date).toDate()}
                                                minDate={props.startDate}
                                            />
                                           
                                        </div>
                        </Col></>:""
                    }

                    {props.hideSearch ? '' :
                        <Col lg={4} className={props.hideHeading ? 'p-r-0 resp-team-box' : ''}>
                            <div className="listingSearchMainDiv">
                                <input type="text" value={searchValue} name="siteSearch" placeholder="Search" onChange={(event) => updateSearchValue(event.target.value)} />
                            </div>
                        </Col>
                    }
                </Row>
                { 
                /*
                props.isManpower || props.threatEmployee ?
                    <Row style={{ alignItems: 'center', borderRadius: "0.35rem", border: '1px solid #cad1d7' }}>
                        <Col lg={4} style={{ paddingBottom: "15px", paddingTop: "15px" }}>
                            <div>
                                <label className="extra-box-label">Communication</label>
                                <Select defaultValue="Mobile Application" className="extra-box-dropdown" onChange={handleChange}>
                                    <Option value="Mobile Application">Mobile Application</Option>
                                    <Option value="SMS">SMS</Option>
                                    <Option value="Email">
                                        Email
                                    </Option>
                                </Select>
                            </div>
                        </Col>

                        <Col lg={7} className="align-search-btn">
                            <div className="listingSearchMainDiv">
                                <input type="text" value={messageValue} name="message" placeholder="Message (100 Char Max)" onChange={(event) => updateMessageValue(event.target.value)} />
                            </div>
                            {
                                messageError !== "" ?
                                    <span style={{ color: "red" }}>{messageError}</span>
                                    : ""
                            }
                        </Col>

                        <Col lg={1} className="align-search-btn-search">
                            <div className="empTeamTabMainDiv" >
                                
                                <Button type="default" style={{ color: "blue", backgroundColor: "#ea5d8b" }} onClick={sendMessage}>
                                    Send
                                </Button>
                            </div>
                        </Col>
                    </Row> : ""
                    */
                }

                < Row >
                    <Col lg={12} className={props.hideHeading ? 'p-l-0 p-r-0' : ''}>
                        <div className="listingRecordMainDiv">
                            {isLoading ?
                                <div className="text-center m-t-lg">
                                    <img src={spinnerLoader} className="m-t-lg" />
                                </div> :
                                // employeeList && employeeList.length > 0 ?
                                <React.Fragment>
                                            {showCardList(employeeList)}
                                        {/* {searchValue && employeeList.length == 0 ?
                                            <h3 className="text-center m-t-lg">No Records Found !</h3> : ''
                                        } */}
                    
                                </React.Fragment>
                                // : ''
                            }

                            {/* {searchValue && employeeList.length == 0 ?
                                <h3 className="text-center m-t-lg">No Records Found !</h3> : ''
                            } */}
                        </div>
                    </Col>
                </Row>
            </div>
        </Col>
    </Row>
</Container>
</div >)

}

export default AgGridEmployeeList;