import React, { useState, useEffect } from "react";
import { Container, Row, Col, Dropdown, DropdownButton } from "react-bootstrap";
import { Button } from "antd";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import { withRouter } from "react-router-dom";

import { CommonHeading } from "../../common/commonHeading";
import DashboardLanguage from "../../components/dashboardLanguage";
import "../style/manpowerManagement.scss";

import CommonDatePicker from "../../common/commonDatePicker";
import {
  getOrgPri,
  employeeChart,
} from "../actionMethods/actionMethods";

import helpIcon from "../../assets/traceplusImages/help-icon.png";
import dropdownIcon from "../../assets/images/down-arrow.png";
import mediumRiskIcon from "../../assets/traceplusImages/medium_risk_icon.svg";
import spinnerLoader from "../../assets/images/Spinner Loader.gif";

import moment from "moment";
import Chart from "./barChart";
import BarChart from "../../siteManagement/components/barChart";
import { getTranslatedText } from "../../common/utilities";
import EmployeeList from "./employeeList";
import {
  getLanguageTranslation,
  setSelectedLanguage,
} from "../../dashboard/actionMethods/actionMethods";
import ReactModal from "react-modal";
import AgGridEmployeeList from './agGridEmployeeList';
import DeviceStatus from "../../components/DeviceStatus";

import {ReactComponent as Image404 } from '../../assets/images/11132-ai.svg'

const riskLevelColor = {
  low: "#04e06e",
  medium: "#ffd700",
  high: "#ffa500",
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    height: "90%",
  },
};

function ManPowerMangementList(props) {
  let date = localStorage.getItem("selectedDate")
    ? new Date(localStorage.getItem("selectedDate"))
    : new Date();

  const [selectedDate, updateSelectedDate] = useState(date);
  const [chartData, setChartData] = useState({
    attendance: { categories: [], series: [] },
    spreader: { categories: [], series: [] },
    bridger: { categories: [], series: [] },
  });
  const [selectedLangValue, updateSelectedLangValue] = useState("en");
  const [numAttended, updateNumberAttended] = useState(0);
  const [chartLoader, setChartLoader] = useState(true);

  const [searchValue, updateSearchValue] = useState("");
  const [globalSearchData, setGlobalSearchData] = useState([]);
  const [suggestion, setSuggestions] = useState([])
  const [globalCategory, setGlobalCategory] = useState("Employees");
  const [globalSearch, setGlobalSearch] = useState("");
  const [teamList, updateTeamList] = useState([]);
  const [preDefinedTeamList, updatedPredefinedTeamList] = useState([]);
  const [employeePopupFlag, updateEmployeePopupFlag] = useState(false);
  // const [modalType, setModalType] =useState("");

  const [role,SetRole] = useState([]);
  const [permission,SetPermission] =useState([])
  const [permissionAPICall,SetPermissionAPICall] = useState(false);

  const [chartDates, setChartDates] = useState({
    start: moment().subtract(29, "days").toDate(),
    end: new Date(moment(date).format('YYYY-MM-DD 23:59:59')),
  });

  const [PriData, updatePRIData] = useState("");
  const [prevPriData, updatePrevPriData] = useState("");

  const [selectedTab, updatedSelectedTab] = useState("employees");

  let userDetails = JSON.parse(localStorage.getItem("userLoginDetails"));
  let userSession = userDetails ? userDetails.session : "123456789";
  let org_id = userDetails ? userDetails.org_id : 6;
  let lastReqId = null

  useEffect(() => {
    let perm= props.role.manpower_management?JSON.parse(props.role.manpower_management):null;
    if(perm){
        SetPermission(perm)
        SetPermissionAPICall(true)
    }else{

    }
},[props.role]);

  function handleDateSelect(date) {
    if(permission && permission.View=='True'){

    updateSelectedDate(date);

    let startDate = new Date().setDate(new Date().getDate() - 29);
    let endDate = new Date(moment(date).format('YYYY-MM-DD 23:59:59'));

    setChartDates({ start: startDate, end: endDate });
    }
  }

  function getDateFormat(date) {
    return moment(date).format("YYYY-MM-DD");
  }

  const getBarColor = (val) => {
    if (val < 33) {
      return riskLevelColor.low;
    } else if (val < 66) {
      return riskLevelColor.medium;
    } else {
      return riskLevelColor.high;
    }
  };

  const getChartData = () => {
    setChartLoader(true);
    setChartData({
      attendance: { categories: [], series: [] },
      spreader: { categories: [], series: [] },
      bridger: { categories: [], series: [] },
    });

    let end = getDateFormat(chartDates.end);
    let start = getDateFormat(chartDates.start);

    let obj = {
      start,
      end,
    };
    if(permission && permission.View=='True'){

    employeeChart(obj, userSession, org_id).then((res) => {
      if (res.data) {
        let attendanceCategory = [];
        let attendanceSeries = [];

        let spreaderCategory = [];
        let spreaderSeries = [];

        let bridgerCategory = [];
        let bridgerSeries = [];

        let startDate = new Date(chartDates.start);
        let endDate = new Date(chartDates.end);

        for (let i = startDate; i <= endDate; i.setDate(i.getDate() + 1)) {
          let attendance = res.data.attendance || [];
          let spreader = res.data.super_spreader || [];
          let bridger = res.data.super_bridger || [];

          let d = moment(startDate).format("YYYY-MM-DD");
          let displayDate = moment(d).format("DD MMM");

          let days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
          let d2 = new Date(startDate);
          let dayName = days[d2.getDay()];


          let atIndex = attendance.findIndex(
            (i) => moment(i.timestamp).format("YYYY-MM-DD") === d
          );
          let spIndex = spreader.findIndex(
            (i) => moment(i.timestamp).format("YYYY-MM-DD") === d
          );
          let brIndex = bridger.findIndex(
            (i) => moment(i.timestamp).format("YYYY-MM-DD") === d
          );

          if (atIndex > -1) {
            attendanceCategory.push(displayDate);
            attendanceSeries.push({
              y: attendance[atIndex].attandance,
              name: displayDate,
              color: "#ef5e8c",
              tooltipDate :dayName
            });
          } else {
            attendanceCategory.push(displayDate);
            attendanceSeries.push({
              y: 0,
              name: displayDate,
              color: "#ef5e8c",
              tooltipDate :dayName
            });
          }

          if (spIndex > -1) {
            spreaderCategory.push(displayDate);
            spreaderSeries.push({
              y: spreader[spIndex].super_spreader_count,
              name: displayDate,
              color: "#ef5e8c",
              tooltipDate :dayName
            });
          } else {
            spreaderCategory.push(displayDate);
            spreaderSeries.push({ y: 0, name: displayDate, color: "#ef5e8c",tooltipDate :dayName });
          }

          if (brIndex > -1) {
            bridgerCategory.push(displayDate);
            bridgerSeries.push({
              y: bridger[brIndex].super_bridger_count,
              name: displayDate,
              color: "#ef5e8c",
              tooltipDate :dayName
            });
          } else {
            bridgerCategory.push(displayDate);
            bridgerSeries.push({ y: 0, name: displayDate, color: "#ef5e8c",tooltipDate :dayName });
          }
        }

        setChartData({
          attendance: {
            categories: attendanceCategory,
            series: attendanceSeries,
          },
          spreader: { categories: spreaderCategory, series: spreaderSeries },
          bridger: { categories: bridgerCategory, series: bridgerSeries },
        });
      }
    });
   }
  };

  const handleMouseEnter = (id) => {
    let doc = document.getElementById(id);

    if (doc) {
      doc.style.display = "block";
    }
  };

  const handleMouseLeave = (id) => {
    let doc = document.getElementById(id);

    if (doc) {
      doc.style.display = "none";
    }
  };

  const setPrevPriData = (date) => {
    let prevReqBody = {};
    prevReqBody.date = moment(date).subtract(1, "days").format("YYYY-MM-DD");
    if(permission && permission.View=='True'){

      getOrgPri(prevReqBody, userSession, org_id).then((res) => {
        if (res) {
          updatePrevPriData(res);
        }
      });
    }
  };

  useEffect(() => {
    if (globalSearch.length) {
      lastReqId = setTimeout(() => {
        setSuggestions(globalSearchData.filter((e) => e.name.toLowerCase().search(globalSearch.trim().toLowerCase()) !== -1))
      }, 300);
      return () => {
        clearTimeout(lastReqId)
      }
    } else {
      setSuggestions([])
    }
  }, [globalSearch])

  useEffect(() => {
    if(permission && permission.View=='True'){

    getChartData();

    let requestBody = {};
    requestBody.date = getDateFormat(selectedDate);

    // setPrevPriData(selectedDate);
    // getOrgPriData(requestBody);
    // getDepartmentListData(requestBody)
    }
  }, [selectedDate, chartDates,permission]);

  function getOrgPriData(requestBody) {
    if(permission && permission.View=='True'){

    getOrgPri(requestBody, userSession, org_id).then((res) => {
      updatePRIData(res);
    });
  }
  }

  function getDateFormat(date) {
    return moment(date).format("YYYY-MM-DD");
  }

  function changeLanguage(lang) {
    getLanguageTranslation(lang).then((res) => {
      if (res && res.status >= 200 && res.status <= 200) {
        localStorage.setItem("languageData", JSON.stringify(res.data));
        localStorage.setItem("selectedLanguage", lang);
        props.setSelectedLanguage(lang);
      }
    });
  }

  useEffect(() => {
    if (props.language) {
      updateSelectedLangValue(props.language);
    }
  }, [props.language]);

  function handleTabViewChange(key) {
    updatedSelectedTab(key);
    updateSearchValue("");
  }

  function allEmployeePopUp() {
    updateEmployeePopupFlag(true);
    // setModalType('threat')
  }

  function handleCloseModal() {
    updateEmployeePopupFlag(false);
    // setModalType("")
  }

  function handleClickSearch(id) {
    props.history.push(`/manpower-management/employee-list/view/${id}`);
  }

  const handleDate = (date, type) => {
    setChartDates((prev) => ({ ...prev, [`${type}`]: date }));
  };


  return (<>
    {permissionAPICall?permission.View=="True"? <div className="manpowerManagementMainDiv">
      <Container className="header-breadcrumb">
        <Row>
          <Col lg={3} className="m-t-sm">
            <CommonHeading title="Manpower Management" />
          </Col>
          <Col lg={9} className="text-right">
            
            <div className="dashboardLanguageMainDiv m-r-md">
                            <DashboardLanguage
                                selectedLangValue={selectedLangValue}
                                changeLanguage={changeLanguage}
                            />
                        </div>
                        <div className="commonHeadingDateMainDiv">
                            <CommonDatePicker
                                selectedDate={selectedDate}
                                handleSelectDate={handleDateSelect}
                            />
                        </div>

                        <DeviceStatus permission={permission} role={props.role} />
                                  </Col>
        </Row>

        {!props.hideGlobalSearch && (
          <Row>
            <Col className="m-t">
              <div className="globalSearch">
                <input
                  type="text"
                  value={globalSearch}
                  onChange={(e) => setGlobalSearch(e.target.value)}
                  placeholder={"Search "}
                />

                {globalSearch.length ? (
                  <div className="suggestionBox">
                    {suggestion.length ? (
                      suggestion
                        .map((s, index) => (
                          <div
                            className="suggestion"
                            key={index}
                            onClick={() => handleClickSearch(s.tag_serial)}
                          >
                            {s.name}
                          </div>
                        ))
                    ) : (
                      <div className="suggestion">
                        No {globalCategory} Found
                      </div>
                    )}
                  </div>
                ) : null}

                <div className="optionBox">
                  <DropdownButton title={globalCategory}>
                    <Dropdown.Item
                      onClick={(e) => setGlobalCategory(e.target.text)}
                    >
                      Employees
                    </Dropdown.Item>
                    {/* <Dropdown.Item
                      onClick={(e) => setGlobalCategory(e.target.text)}
                    >
                      Teams
                    </Dropdown.Item> */}
                  </DropdownButton>
                </div>
              </div>
            </Col>
            <Col className="m-t">
              <Button
                type="default"
                style={{
                  color: "blue",
                  backgroundColor: "#ea5d8b",
                  float: "right",
                }}
                onClick={allEmployeePopUp}
              >
                View All Employee
              </Button>
            </Col>
          </Row>
        )}

        {!props.hideGlobalSearch && (
          <Row>
            <Col>
              <div
                className="manpowerManagementEmployeeListMainDiv m-t"
                style={{ backgroundColor: "#202236", padding: "2rem 1.5rem" }}
              >
                <Row className="dateBox">
                  <Col lg={2} className="p-r-0">
                    <div className="contactRankText">
                      {getTranslatedText("Start Date")}
                    </div>
                    <div
                      className="startDateEndDateMainDiv"
                      style={{ paddingLeft: "0px" }}
                    >
                      <DatePicker
                        selected={chartDates.start}
                        onChange={(date) => handleDate(date, "start")}
                        dateFormat={"MMM dd"}
                        isClearable={false}
                        placeholderText={getTranslatedText("Start Date")}
                        maxDate={moment(chartDates.end).toDate()}
                        minDate={moment().subtract(120, "days").toDate()}
                      />
                      {/* <div className="dropdownIconDiv">
                        <img src={dropdownIcon} />
                      </div> */}
                    </div>
                  </Col>
                  <Col lg={1} className="p-l-0"></Col>
                  <Col lg={2} className="p-r-0">
                    <div className="contactRankText">
                      {getTranslatedText("End Date")}
                    </div>
                    <div
                      className="startDateEndDateMainDiv"
                      style={{ paddingLeft: "0px" }}
                    >
                      <DatePicker
                        selected={moment(chartDates.end).toDate()}
                        onChange={(date) => handleDate(date, "end")}
                        dateFormat={"MMM dd"}
                        isClearable={false}
                        placeholderText={getTranslatedText("End Date")}
                        maxDate={selectedDate}
                        minDate={chartDates.start}
                      />
                      {/* <div className="dropdownIconDiv">
                        <img src={dropdownIcon} />
                      </div> */}
                    </div>
                  </Col>
                </Row>
                <div className="chartMainDiv">
                  <div className="chart">
                    <div className="titleBox">
                      <div className="title">Attendance</div>
                      <div className="relative">
                        <img
                          alt=""
                          className="helpicon"
                          src={helpIcon}
                          onMouseEnter={() =>
                            handleMouseEnter(`attendanceHelp`)
                          }
                          onMouseLeave={() =>
                            handleMouseLeave(`attendanceHelp`)
                          }
                        />
                        <div className="descHelp" id="attendanceHelp">
                        Number of active employees.
                        </div>
                      </div>
                    </div>
                    <BarChart
                      categories={chartData.attendance.categories}
                      series={chartData.attendance.series}
                      seriesName={"Attendance"}
                    />
                  </div>
                  <div className="chart">
                    <div className="titleBox">
                      <div className="title">Super Spreader</div>
                      <div className="relative">
                        <img
                          alt=""
                          className="helpicon"
                          src={helpIcon}
                          onMouseEnter={() => handleMouseEnter(`spreaderHelp`)}
                          onMouseLeave={() => handleMouseLeave(`spreaderHelp`)}
                        />
                        <div className="descHelp" id="spreaderHelp">
                        Number of people that are visiting many areas.
                        </div>
                      </div>
                    </div>
                    <BarChart
                      categories={chartData.spreader.categories}
                      series={chartData.spreader.series}
                      seriesName={"Super Spreader"}
                    />
                  </div>
                  <div className="chart">
                    <div className="titleBox">
                      <div className="title">Super Bridger</div>
                      <div className="relative">
                        <img
                          alt=""
                          className="helpicon"
                          src={helpIcon}
                          onMouseEnter={() => handleMouseEnter(`bridgerHelp`)}
                          onMouseLeave={() => handleMouseLeave(`bridgerHelp`)}
                        />
                        <div className="descHelp" id="bridgerHelp">
                        Number of people that are having many interactions with the other people.
                        </div>
                      </div>
                    </div>
                    <BarChart
                      categories={chartData.bridger.categories}
                      series={chartData.bridger.series}
                      seriesName={"Super Bridger"}
                    />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        )}

        <Row className="m-t">
          <Col lg={12}>
            {selectedTab == "employees" ? (
              <div className="manpowerManagementEmployeeListMainDiv">
                <AgGridEmployeeList
                  hideHeading={true}
                  date={selectedDate}
                  selectedTab={selectedTab}
                  isManpower={true}
                  handleTabViewChange={handleTabViewChange}
                  type="Employee"
                  setGlobalSearchData={(data) => setGlobalSearchData(data)}
                  // teamList={teamList}
                />
                {/* <EmployeeList
                  hideHeading={true}
                  date={selectedDate}
                  selectedTab={selectedTab}
                  isManpower={true}
                  handleTabViewChange={handleTabViewChange}
                  type="Employee"
                  setGlobalSearchData={(data) => setGlobalSearchData(data)}
                  // teamList={teamList}
                /> */}
              </div>
            ) : (
              <div className="manpowerManagementEmployeeListMainDiv">
                <AgGridEmployeeList 
                    hideHeading={true}
                    date={selectedDate}
                    selectedTab={selectedTab}
                    isManpower={true}
                    handleTabViewChange={handleTabViewChange}
                    type="Team" 
                />
                
                {/* <EmployeeList
                  hideHeading={true}
                  date={selectedDate}
                  selectedTab={selectedTab}
                  isManpower={true}
                  handleTabViewChange={handleTabViewChange}
                  type="Team"
                  // teamList={teamList}
                /> */}


              </div>
            )}
          </Col>
        </Row>
      </Container>

      <ReactModal
        isOpen={employeePopupFlag}
        style={customStyles}
        onRequestClose={handleCloseModal}
        shouldCloseOnOverlayClick={true}
      >

      <AgGridEmployeeList
          hideHeading={true}
          title={"All Employee"}
          hideGlobalSearch={true}
          // type={modalType}
          AgkeyTable={'viewallEmployee'}
          date={selectedDate}
          height={(75*window.innerHeight)/100}
 
        />
        {/* <EmployeeList
          hideHeading={true}
          title={"All Employee"}
          hideGlobalSearch={true}
          // type={modalType}
          date={selectedDate}
          
        /> */}
      </ReactModal>
    </div>:<Col lg={12}>
            {/* <div className='errorPermission'></div> */}
        <div className='center404'> <Image404 /><h4 style={{marginTop:"-30px",color:"#2e3552"}}>Access Restricted to this page, Please contact administrator</h4></div>
        </Col>:<Col lg={12} md={12} sm={12} xs={12} className="apiLoaderPermission">
                                <img src={spinnerLoader} />
                            </Col>}</>
  );
}

const mapStateToProps = (state) => ({
  language: state.dashboard.selectedLangaugeValue,
});

export default connect(mapStateToProps, { setSelectedLanguage })(
  withRouter(ManPowerMangementList)
);
