import React, { useState, useEffect } from 'react'

import { Container, Row, Col } from 'react-bootstrap';
import moment from 'moment'
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import '../../../siteManagement/styles/siteManagement.scss'
import DashboardLanguage from '../../../components/dashboardLanguage';
import { getSiteOverview, getSiteFootFall, getSiteAreaIndex, footfallChart } from '../../../siteManagement/actionMethods/actionMethods'
import BarChart from '../../../siteManagement/components/barChart';
import spinnerLoader from '../../../assets/images/Spinner Loader.gif'
import CommonDatePicker from '../../../common/commonDatePicker';
import { getTranslatedText } from '../../../common/utilities';
import ClockIcon from '../../../dashboard/styles/images/clock.png'

import { getLanguageTranslation, setSelectedLanguage } from '../../../dashboard/actionMethods/actionMethods';
import { tsAnyKeyword } from '@babel/types';
import { Scrollbars } from "react-custom-scrollbars";
import ReactHighcharts from 'react-highcharts'
import Highcharts from "highcharts";
import DatePicker from "react-datepicker";
import helpIcon from "../../../assets/traceplusImages/help-icon.png";
import sortIcon from '../../../assets/traceplusImages/sort.png'
import upIcon from '../../../assets/traceplusImages/up-arrow.png'
import downIcon from '../../../assets/traceplusImages/down-arrow.png'
import calenderIcon from '../../../assets/traceplusImages/calendar_icon_white.svg'
import Barchart from '../../../siteManagement/components/details/barChart'
import DeviceStatus from '../../../components/DeviceStatus'
const riskLevelColor = {
    "low": '#04e06e',
    "medium": "#ffd700",
    "high": "#ff9696"
}

let timeArr = [
    '1:00 AM', '2:00 AM', '3:00 AM', '4:00 AM', '5:00 AM', '6:00 AM', '7:00 AM', '8:00 AM', '9:00 AM', '10:00 AM', '11:00 AM', '12:00 PM',
    '1:00 PM', '2:00 PM', '3:00 PM', '4:00 PM', '5:00 PM', '6:00 PM', '7:00 PM', '8:00 PM', '9:00 PM', '10:00 PM', '11:00 PM', '12:00 AM'
]

let timeArr1 = [
    '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24'
]

let timeArr2 = [
    '12:00 AM', '1:00 AM', '2:00 AM', '3:00 AM', '4:00 AM', '5:00 AM', '6:00 AM', '7:00 AM', '8:00 AM', '9:00 AM', '10:00 AM', '11:00 AM', '12:00 PM',
    '1:00 PM', '2:00 PM', '3:00 PM', '4:00 PM', '5:00 PM', '6:00 PM', '7:00 PM', '8:00 PM', '9:00 PM', '10:00 PM', '11:00 PM'
]


function ProductivityLocationDetails(props) {
    let date = localStorage.getItem('selectedDate') ? new Date(localStorage.getItem('selectedDate')) : new Date()
    const [dates, setDates] = useState({
        start: new Date(moment(props.date?props.date:date).format('YYYY-MM-DD 23:59:59')),
        end: new Date(moment(props.date?props.date:date).format('YYYY-MM-DD 23:59:59')),
      });
 
    const [siteViewData, updateSiteViewData] = useState('')
    const [prevSiteData, updatePrevSiteData] = useState('')

    const [footFallValue, updateFootFallValue] = useState(0)

    const [footFallData, updateFootFallData] = useState('')

    const [selectedFootfallType, updateFootfallType] = useState('day')

    const [selectedLangValue, updateSelectedLangValue] = useState('en')

    const [locationID, updateLocationID] = useState('')

    const [chartData, setChartData] = useState({ categories: [], series: [], top4: [] })
    const [TheoricMax,UpdateTheoricMax]=useState('');
    const [crowdedArea,UpdatecrowdedArea]=useState('');
    const [selectedDate, updateSelectedDate] = useState(date)
    const [chartLoader, setChartLoader] = useState(true)

    const [sortKey, setSortKey] = useState('locationVisited')
    const [sortType, setSortType] = useState('desc')
    
    const [sortKeylocationVisited, setSortKeylocationVisited] = useState('locationVisited')
    const [sortTypelocationVisited, setSortTypelocationVisited] = useState('desc')
    
    const [sortKeyunproductive, setSortKeyunproductive] = useState('unproductive')
    const [sortTypeunproductive, setSortTypeunproductive] = useState('desc')
  
    const [sortKeyacess, setSortKeyacess] = useState('acess')
    const [sortTypeacess, setSortTypeacess] = useState('desc')


    let userDetails = JSON.parse(localStorage.getItem('userLoginDetails'))

    let userSession = userDetails ? userDetails.session : '123456789'

    let org_id = userDetails ? userDetails.org_id : 6
    const [locationVisitedData,SetLocationVisitedData]=useState([
        {location:"Location 1",time:"00:01:12"},
        {location:"Location 2",time:"00:02:12"},
        {location:"Location 3",time:"00:01:52"},
        {location:"Location 4",time:"00:01:19"},
        {location:"Location 5",time:"00:03:19"},
        {location:"Location 6",time:"00:01:05"},
        {location:"Location 7",time:"00:00:05"},
    ])

    const [unproductiveVisitedData,SetUnproductiveVisitedData]=useState([
        {location:"Location 1",time:"00:01:12"},
        {location:"Location 2",time:"00:02:12"},
        {location:"Location 3",time:"00:01:52"},
        {location:"Location 4",time:"00:01:19"},
        {location:"Location 5",time:"00:03:19"},
        {location:"Location 6",time:"00:01:05"},
        {location:"Location 7",time:"00:00:05"},
    ])

    const [acessData,SetAcessData]=useState([
        {location:"Location 1",time:"00:01:12"},
        {location:"Location 2",time:"00:02:12"},
        {location:"Location 3",time:"00:01:52"},
        {location:"Location 4",time:"00:01:19"},
        {location:"Location 5",time:"00:03:19"},
        {location:"Location 6",time:"00:01:05"},
        {location:"Location 7",time:"00:00:05"},
    ])

    function handleSiteListClick() {
        props.history.push('/site-list')
    }

    

    useEffect(() => {

        let idVal = props.match.params.id.replace(":", "")

        if (idVal) {
            let date = getDateFormat(selectedDate)

            updateLocationID(idVal)


            let requestBody = {}
            requestBody.date = date
            requestBody.locationID = idVal

            getSiteOverview(requestBody, userSession, org_id).then(res => {
                //console.log(res);
                if (res && res.data && res.data.location_details && res.data.location_details.length > 0) {
                    updateSiteViewData(res.data.location_details[0])
                    UpdateTheoricMax(res.data.location_details[0].theroic_max_capacity);
                }
             })
        }

    }, [selectedDate]);

    useEffect(() => {
        if(TheoricMax){
        let idVal = props.match.params.id.replace(":", "")
        getChartData(idVal)
        }
        
    }, [selectedDate])

    useEffect(() => {
          let idVal = props.match.params.id.replace(":", "")
        getChartData(idVal)
        
    }, [TheoricMax])

    const getBarColor = (val) => {
        //console.log(TheoricMax);
        
        if(parseInt(val) > parseInt(TheoricMax)){
            return riskLevelColor.high
        
        }else{
            return riskLevelColor.low
        }
    }

    const getChartData = (idVal) => {
        
        setChartLoader(true)
        setChartData({ categories: [], series: [], top4: [] })

        let d = moment(selectedDate)
        let date = getDateFormat(d)
        
        footfallChart({ date, locationID: idVal }, userSession, org_id).then((res) => {

            updateFootFallData(res.data)
            updateFootFallValue(res.day_footfall)

            let data = res.data.location_footfall
            let categories = timeArr2
            let series = []
            let top4 = []
            let  arr= []   
            let currDate = moment(selectedDate).format("MM/DD/YYYY")
            // let currDate = "09/01/2021"
            // let chartDataCopy = Object.keys(data[currDate])
            let cData = data[currDate]
            
            if (data) {
                let arr = [];
                for (let i = 0; i < 24; i++) {
                    let key = i + " Hr"
                    series.push({
                        y: cData[key],
                        color: getBarColor(cData[key]),
                        name: timeArr2[i],
                    })
                    if(cData[key] > parseInt(TheoricMax)){
                      arr.push(
                        <div className="ChartOverCrowded eachPeakHoursDiv" key={timeArr2[i]}>
                        <img src={ClockIcon} /><span className="font-bold">{timeArr2[i]}</span>
                        </div>
                    
                      );
                    }

 
                }
                UpdatecrowdedArea(arr);

                // chartDataCopy.forEach((key, index) => {
                //     let keyName = key.split(" ")
                //     // //console.log("index of data", keyName[0])
                //     series.push({
                //         y:cData[key],
                //         color: getBarColor(cData[key]),
                //         // name: keyName[0] <= 11 ? keyName[0] + ':00 AM' : (keyName[0] - 12) + ':00 PM'
                //         name : timeArr[index]
                //     })
                // })

                top4 = [...series].filter((s) => s.y > 0).sort((a, b) => (b.y - a.y)).slice(0, 4)
                

                setChartData({ categories, series, top4 })
                setChartLoader(false)
            }
        }).catch((err) => {
            //console.log(err)
            setChartLoader(false)
        })
    }
    

    function getDateFormat(date) {
        return moment(date).format('YYYY-MM-DD')
    }

    function getRiskTYpe(riskIndex) {
        let risk = "";
    
        if (riskIndex <= 33) {
          risk = "LOW";
        } else if (riskIndex > 33 && riskIndex <= 66) {
          risk = "MEDIUM";
        } else if (riskIndex > 66 && riskIndex <= 100) {
          risk = "HIGH";
        } else {
          risk = "NA"
        }
    
        return risk;
      }

      function getBackgroundColorBasedOnRisk(riskIndex) {
        let risk = "";
    
        if (riskIndex <= 33) {
          risk = "lowIndexGradientColor";
        } else if (riskIndex > 33 && riskIndex <= 66) {
          risk = "mediumIndexGradientColor";
        } else if (riskIndex > 66 && riskIndex <= 100) {
          risk = "highIndexGradientColor";
        } else {
          risk = 'naIndexGradientColor'
        }
    
        return risk;
      }

    function handleDateSelect(date) {
        updateSelectedDate(date)
        
        setDates({
            start: date,
            end: date,
        })

        let requestBody = {}
        requestBody.date = getDateFormat(date)
        requestBody.locationID = locationID

        getSiteOverview(requestBody, userSession, org_id).then(res => {
            if (res && res.data && res.data.location_details && res.data.location_details.length > 0) {
                updateSiteViewData(res.data.location_details[0])
            }
        })



        getSiteAreaIndex(requestBody, userSession, org_id).then(res => {

        })
    }

    function handleChangeFootFallType(type) {
        updateFootfallType(type)

        let requestBody = {}
        requestBody.date = moment(selectedDate).format('YYYY-MM-DD')
        requestBody.locationID = locationID

        getSiteFootFall(requestBody, userSession, org_id).then(res => {
            if (res) {
                type == 'week' ? updateFootFallValue(res.week_footfall) : updateFootFallValue(res.day_footfall)
            }
        })
    }

    function changeLanguage(lang) {
        getLanguageTranslation(lang).then(res => {
            if (res && res.status >= 200 && res.status <= 200) {
                localStorage.setItem('languageData', JSON.stringify(res.data))
                localStorage.setItem('selectedLanguage', lang)
                props.setSelectedLanguage(lang)

            }
        })
    }

    const getChangePer = () => {
        let returnData = 0
        let x = prevSiteData.loc_area_index != 'na' ? prevSiteData.loc_area_index : 0
        let y = siteViewData.loc_area_index != 'na' ? siteViewData.loc_area_index : 0

        if (x) {
            returnData = parseFloat(y - x).toFixed(1);
        }
       
        return returnData
    }

    const getChangePerFormit = () => {
        let returnData = 0
        let x = prevSiteData.area_fomite_index != 'na' ? prevSiteData.area_fomite_index : 0
        let y = siteViewData.area_fomite_index != 'na' ?  siteViewData.area_fomite_index : 0
        //parseFloat(((y - x) / x) * 100).toFixed(1);
        if (x) {
            returnData = parseFloat(y - x).toFixed(1);
        }

        //console.log('getChangePerFormit '+returnData)

        return returnData
    }

    useEffect(() => {
        if (props.language) {
            updateSelectedLangValue(props.language)
        }
    }, [props.language])


    const handleDate = (date, type) => {
        setDates((prev) => ({ ...prev, [`${type}`]: date }));
       };   

    function handleSiteListClick() {
        props.history.push('/productivity')
    }


    const handleMouseEnter = (id) => {
        let doc = document.getElementById(id);
    
        if (doc) {
          doc.style.display = "block";
        }
      };

      const handleMouseLeave = (id) => {
        let doc = document.getElementById(id);
    
        if (doc) {
          doc.style.display = "none";
        }
      };

      const handleSortunproductive = (key) => {

        setSortKeyunproductive(key)
        setSortTypeunproductive(sortTypeunproductive === 'desc' ? 'asc' : 'desc')
        
        setSortKey(key)
        setSortType(sortTypeunproductive)

      }


      const handleSortlocationVisited = (key) => {
    
        setSortKeylocationVisited(key)
        setSortTypelocationVisited(sortTypelocationVisited === 'desc' ? 'asc' : 'desc')
        setSortKey(key)
        setSortType(sortTypelocationVisited)
    
    
      }
    const handleSortAcess = (key )=>{
    
        setSortKeyacess(key)
        setSortTypeacess(sortTypeacess === 'desc' ? 'asc' : 'desc')
        setSortKey(key)
        setSortType(sortTypeacess)
        
    }

    function capitalizeFirstLetter(string) {
        return string.toLowerCase();
    }

    function ShowCardLocation(){
        let arr = [];
        let arr2 =[];
        let data = locationVisitedData;
        arr2 = data.sort((a,b) => {
          let aMs = moment(a.time, 'HH:mm:ss').format('x')
          let bMs = moment(b.time, 'HH:mm:ss').format('x')
          return  sortTypelocationVisited=='desc'? bMs - aMs  : aMs - bMs
       })
       //console.log(locationVisitedData,arr2)
    
        for (let index = 0; index < arr2.length; index++) {
            arr.push(
                <tr className="employee-detail-table-tr" key={"locationVisitedData"+index}>
                  <td>{capitalizeFirstLetter(arr2[index].location)}</td>
                  <td className="employee-detail-table-tr-td">{arr2[index].time}</td>
                </tr>
              );
        }

        return arr
    }



    function ShowCardAcess(){
        let arr = [];
        let arr2 =[];
        let data = acessData;
        arr2 = data.sort((a,b) => {
          let aMs = moment(a.time, 'HH:mm:ss').format('x')
          let bMs = moment(b.time, 'HH:mm:ss').format('x')
          return  sortTypeacess=='desc'? bMs - aMs  : aMs - bMs
       })
    
        for (let index = 0; index < arr2.length; index++) {
            arr.push(
                <tr className="employee-detail-table-tr" key={"locationVisitedData"+index}>
                  <td>{capitalizeFirstLetter(arr2[index].location)}</td>
                  <td className="employee-detail-table-tr-td">{arr2[index].time}</td>
                </tr>
              );
        }

        return arr
    }




    if (siteViewData) {


        return (
            <div className="siteViewMainDiv manpowerManagementMainDiv">
                <Container className='header-breadcrumb'>
                    <Row>
                        <Col lg={3} className={'m-t-sm'}>
                            <div className="siteViewHeaderDiv">
                                <span className="smallHeader" onClick={handleSiteListClick}>{getTranslatedText('Site Management')}</span>
                                <span className="breadCrumbArrow"> &gt; </span>
                                <span className="mediumHeader">{getTranslatedText('Site View')}</span>
                            </div>
                        </Col>
                        <Col lg={9} className="text-right">
                        <div className="dashboardLanguageMainDiv m-r-md">
                            <DashboardLanguage
                                selectedLangValue={selectedLangValue}
                                changeLanguage={changeLanguage}
                            />
                        </div>
                        <div className="commonHeadingDateMainDiv">
                            <CommonDatePicker
                                selectedDate={selectedDate}
                                handleSelectDate={handleDateSelect}
                            />
                        </div>

                        <DeviceStatus />

                        </Col>
                    </Row>
                    <Row className="m-t-lg">
                        <Col lg={4}>
                            <div className="siteViewDetailsLeftSideDiv" style={{ height: '100%' }}>
                                <div className="headerNameDiv">{getTranslatedText(siteViewData.name)}</div>
                                <div className="subHeaderDiv">{getTranslatedText(siteViewData.description)}</div>
                                {/* <div className="subHeaderDiv">9am - 6pm | 11pm - 6am</div> */}
                                <div className="subHeaderDiv">Location Tag : <b>{siteViewData.loc_tag}</b></div>
                                {/* <div className="subHeaderDiv">Category Name : <b>{siteViewData.name}</b></div> */}

                                <div className="separaterLine"></div>

                                <div className="areaCapacityMainDiv">
                                    <Row >
                                        <Col lg={6} className="text-center b-r">
                                            <h4 className="font-bold text-white">{siteViewData.area_size}</h4>
                                            <h6 className="text-white">Area Size Sq.m</h6>
                                        </Col>
                                        <Col lg={6} className="text-center">
                                            <h4 className="font-bold text-white">{siteViewData.capacity}</h4>
                                            <h6 className="text-white">Capacity</h6>
                                        </Col>
                                    </Row>
                                    <div className="separaterLine"></div>
                                    <Row >
                                        <Col lg={6} className="text-center b-r">
                                            <h4 className="font-bold text-white">{siteViewData.theroic_max_capacity}</h4>
                                            <h6 className="text-white">Theoric Max</h6>
                                        </Col>
                                        <Col lg={6} className="text-center">
                                            {/* <h4 className="font-bold text-white">{siteViewData.capacity}</h4>
                                            <h6 className="text-white">Capacity</h6> */}
                                        </Col>
                                    </Row>

                                </div>

                                <div className="separaterLine"></div>

                                <div className="recommendMainDiv">
                                    {/* <h5 className="font-bold text-white">{getTranslatedText('Recommend')}</h5> */}

                                    <div className="recommendListMainDiv m-t-md text-white">
                                        <div className="eachRecommendCardDiv">
                                            <Row>
                                                <Col lg={8}>
                                                    <span>Visited by</span>
                                                </Col>

                                                <Col lg={4} className="text-right ">
                                                    <strong>
                                                        {siteViewData.visited_by}
                                                    </strong>
                                                </Col>
                                            </Row>
                                        </div>

                                        <div className="eachRecommendCardDiv">
                                            <Row>
                                                <Col lg={8}>
                                                    <span>Spend more than 15 min</span>
                                                </Col>

                                                <Col lg={4} className="text-right ">
                                                    <strong>
                                                        {siteViewData.Spend_morethan_15mins}
                                                    </strong>
                                                </Col>
                                            </Row>
                                        </div>

                                        <div className="eachRecommendCardDiv">
                                            <Row>
                                                <Col lg={8}>
                                                    <span>Cumulated interaction</span>
                                                </Col>

                                                <Col lg={4} className="text-right ">
                                                    <strong>
                                                        {siteViewData.loc_cum_interaction_time}
                                                    </strong>
                                                </Col>
                                            </Row>
                                        </div>
                                        {/* <div className="eachRecommendCardDiv">
                                            Visited by : {siteViewData.visited_by}
                                            </div>

                                        <div className="eachRecommendCardDiv">
                                           Spend more than 15 min : {siteViewData.Spend_morethan_15mins}
                                            </div>

                                        <div className="eachRecommendCardDiv">
                                            Cumulated interaction time : {siteViewData.loc_cum_interaction_time}
                                            </div> */}
                                    </div>
                                </div>

                            </div>
                        </Col>
                        <Col lg={8} className='left-no-space-resp employeeDetailsMainDiv'>
                            <div className="siteViewRightSideDiv">
                                <Row>

                                </Row>
                            </div>


                            <div className="white-bg  wrapper areaIndexChartMainDiv manpowerManagementEmployeeListProductivity">
                            <Row style={{marginBottom:"15px"}}>
                                    <Col lg={3} style={{paddingRight:"0px"}}>
                                    <div
                                        className="startDateEndDateMainDiv date-start date-label-full"
                                        style={{ paddingLeft: "0px" }}
                                        >
                                        <label>Start Date</label>
                                        <DatePicker
                                            selected={dates.start}
                                            onChange={(date) => handleDate(date, "start")}
                                            dateFormat={'MMM dd'}
                                            isClearable={false}
                                            placeholderText={getTranslatedText(
                                            "Start Date"
                                            )}
                                            maxDate={moment(dates.end).toDate()}
                                            minDate={moment().subtract(120, "days").toDate()}
                                        />
                                        
                                        </div>
                                     </Col>     
                                     <Col lg={3}>      
                                        <div
                                            className="startDateEndDateMainDiv date-end date-label-full"
                                            style={{ paddingLeft: "0px" }}
                                            >
                                            <label>End Date</label>
                                            <DatePicker
                                                selected={moment(dates.end).toDate()}
                                                onChange={(date) => handleDate(date, "end")}
                                                dateFormat={'MMM dd'}
                                                isClearable={false}
                                                placeholderText={getTranslatedText("End Date")}
                                                maxDate={selectedDate}
                                                minDate={dates.start}
                                            />
                                            
                                            </div>

                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12}>
                                        <div className="chartTitle">
                                            Footfall
                                        </div>
                                    </Col>
                                </Row>
                                <Row style={{ paddingLeft : "0.5rem"}}>
                                    <Col lg={3}>
                                        <h6 className="font-bold">Peak Hours</h6>
                                        <div>
                                            {chartData.top4.map((d) => {
                                                return (
                                                    <div className="eachPeakHoursDiv">
                                                        <img src={ClockIcon} />
                                                        <span className="font-bold">{d.name}</span>

                                                    </div>
                                                )
                                            })}
                                            {crowdedArea?<h6 className="font-bold top-seperator">Over Crowded Hours</h6>:''}
                                            <Scrollbars autoHide style={{ width: "100%", height: 130 }} >{crowdedArea}</Scrollbars>
                                            
                                        </div>
                                    </Col>
                                    <Col lg={9}>
                                        {chartLoader ?
                                            <div className="text-center">
                                                <img src={spinnerLoader} />
                                            </div>
                                            :
                                            <Barchart chartData={chartData} TheoricMax={siteViewData.theroic_max_capacity} />
                                        }
                                    </Col>
                                </Row>

                                <Row style={{ margin: 0 }} className="mostInteractedMainDiv">
                        <Col className="p-l-0" lg={6}>
                          <table className="tableBox">
                            <tr className="employee-detail-table-tr">
                              <th style={{width:"75%"}}>
                                 Visited by
                                <img
                                  src={helpIcon}
                                  alt=""
                                  onMouseEnter={() =>
                                    handleMouseEnter(`interHelp`)
                                  }
                                  onMouseLeave={() =>
                                    handleMouseLeave(`interHelp`)
                                  }
                                />
                                <span className="descHelp" id="interHelp">
                                Location Visited by employee
                                </span>

                              </th>
                              <th className="employee-detail-table-tr-td" style={{width:"25%"}}>
                                Time
                                <img
                                  alt=''
                                  className='sorticon'
                                  src={sortKey === 'locationVisited' ? sortTypelocationVisited === 'asc' ? upIcon : downIcon : sortIcon}
                                  onClick={() => handleSortlocationVisited('locationVisited')}
                                />
                              </th>
                            </tr>
                            <Scrollbars
                              autoHide
                              style={{ width: "100%", height: 150 }}
                            >
                              {ShowCardLocation()}
                            </Scrollbars>
                            
                          </table>
                        </Col>

                        <Col lg={6} className={'p-r-0'}>
                          <table className="tableBox">
                            <tr className="employee-detail-table-tr">
                              <th style={{width:"75%"}}>
                                Policy Breach by
                                <img
                                  src={helpIcon}
                                  alt=""
                                  onMouseEnter={() =>
                                    handleMouseEnter(`breach`)
                                  }
                                  onMouseLeave={() =>
                                    handleMouseLeave(`breach`)
                                  }
                                />
                                <span className="descHelp" id="breach">
                                Acess Policy Breach. 
                                </span>
                              </th>
                              <th className="employee-detail-table-tr-td" style={{width:"25%"}}>
                                Time
                                <img
                                  alt=''
                                  className='sorticon'
                                  src={sortKey === 'acess' ? sortTypeacess === 'asc' ? upIcon : downIcon : sortIcon}
                                  onClick={() => handleSortAcess('acess')}
                                />
                              </th>
                            </tr>
                            <Scrollbars
                              autoHide
                              autoHeight
                              style={{ width: "100%" }}
                            >
                              {ShowCardAcess()}
                            </Scrollbars>
                          </table>
                        </Col>
                      </Row>

                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }

    else {
        return (
            <div className="text-center m-t-lg">
                <img src={spinnerLoader} className="m-t-lg" />
            </div>
        )
    }

    }
    



const mapStateToProps = (state) => ({
    language: state.dashboard.selectedLangaugeValue
})

export default connect(mapStateToProps, { setSelectedLanguage })(withRouter(ProductivityLocationDetails))


