import React, { useState, useEffect,useRef ,useCallback, useMemo} from "react";
import { Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import { withRouter } from "react-router-dom";
import { CommonHeading } from "../../common/commonHeading";
import DashboardLanguage from "../../components/dashboardLanguage";
import '../../manPowerManagement/style/manpowerManagement.scss';

import CommonDatePicker from "../../common/commonDatePicker";

import helpIcon from "../../assets/traceplusImages/help-icon.png";
import dropdownIcon from "../../assets/images/down-arrow.png";
import mediumRiskIcon from "../../assets/traceplusImages/medium_risk_icon.svg";
import spinnerLoader from "../../assets/images/Spinner Loader.gif";
import {ReactComponent as DoubleArrow} from '../../assets/images/angles-right.svg'
import {ReactComponent as SingleArrow} from '../../assets/images/angle-right.svg'
import {ReactComponent as Plus} from '../../assets/images/caret-right.svg'
import {ReactComponent as Minus} from '../../assets/images/caret-down.svg'
import moment from "moment";
import { getTranslatedText } from "../../common/utilities";
import {
  getLanguageTranslation,
  setSelectedLanguage,
} from "../../dashboard/actionMethods/actionMethods";
import { Slider } from 'antd';
import  { SliderMarks } from 'antd/es/slider';
import { Link } from 'react-router-dom'

import { getDepartment,getSubDepartment } from "../../productivity/actionMethods/actionMethods";
import { getSiteLocations } from "../../siteManagement/actionMethods/actionMethods";
import { getPproductivitylist } from "../../productivity/actionMethods/actionMethods";
import { getPproductivityDetailOverview } from "../../productivity/actionMethods/actionMethods";
import ReactModal from "react-modal";
import { Checkbox, Select   } from 'antd';
import '../style/style.scss';
import {AgGridColumn, AgGridReact} from 'ag-grid-react';
import ExcelJs from "exceljs";
import ChartReport from "./ChartReport";
import { ifElse } from "ramda";
import CycleCount from "./CycleCount";

const riskLevelColor = {
  low: "#04e06e",
  medium: "#ffd700",
  high: "#ffa500",
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    height: "90%",
  },
};

function ReportExport(props) {
  const { Option } = Select;

  let date = localStorage.getItem("selectedDate")
    ? new Date(localStorage.getItem("selectedDate"))
    : new Date();

  const [selectedDate, updateSelectedDate] = useState(date);

  const [dates, setDate] = useState({
    //start: moment(date).subtract(1, "days").toDate(),
    start: new Date(moment(date).format('YYYY-MM-DD 23:59:59')),
    end: new Date(moment(date).format('YYYY-MM-DD 23:59:59')),
  });

  const [selectedLangValue, updateSelectedLangValue] = useState("en");


  const [selectedTab, updatedSelectedTab] = useState("employees");
  const [permission,SetPermission] =useState([])
  const [permissionAPICall,SetPermissionAPICall] = useState(false);

  const [widthContainer,setWidthContainer]=useState(0);
  const [department,SetDeparment] =useState([]);
  const [department_id,SetDepartmentID] =useState([]);
  const [department_idv2,SetDepartmentIDv2] =useState([]);
  //this is the data

  const [sub_department,SetSubDeparment] =useState([]);
  const [sub_departmentV2,SetSubDeparmentV2] =useState([]);
  const [sub_department_id,SetSubDepartmentID] =useState([]);
  const [sub_department_idV2,SetSubDepartmentIDV2] =useState([]);

  const [location,SetLocation]=useState([]);
  const[location_id,SetLocationID] = useState([]);

  const [employeeAll,SetEmployeeAll]=useState([]);
  const [employeeDefault,SetEmployeeDefault]=useState([]);

  const [showData,SetShowData]=useState([])
  const [showDataDefault,SetShowDataDefault]=useState([])

  const [isLoading,SetisLoading] = useState(true)
  const [isLoading2,SetisLoading2] = useState(false)
  const [isLoading3,SetisLoading3] = useState(false)

  const [count, SetCount]= useState(0)
  const [totalcount, SetTotalCount]= useState(0)
  const [dateCount,SetDateCount]=useState(0);

  const [parentAccord ,SetParentAccord]=useState([])
  const elementRef = useRef(null);   
  const [loopRequest,SetLoopRequest] =useState(0);
  const [filterData,SetFilterData] =useState([]);
  const [filterLocation,SetFilterLocation] =useState([]);
  const [exportEnable,SetExportEnable] =useState(false);
  const [filterActive,SetFilterActive] =useState('working');
  const [filterby,SetFilterby] =useState([]);
  const [filter_min,SetFilterMin] =useState(0);
  const [filter_max,SetFilterMax] =useState(100);
  const [report_by,SetReportBy]=useState(['Percentage'])
  const [filterDataReport2,SetFilterDataReport2]=useState([]);
  const [filterbyCoumn,SetFIlterByCoulmn]=useState([]);
  const plainOptions=['Percentage','Time']
  const [filterSlide,SetFilterSLide] =useState([0,100])
  const [chart1,SetChart1] =useState(false)
  const [chart2,SetChart2] =useState(false)
  const [chartAllData,SetChartAllData]= useState({series:[],categories:[]})
  const [chartAllData2,SetChartAllData2]= useState({series:[],categories:[]})
  const [activeChart,SetActiveChart]= useState('');
  const [activeChart2,SetActiveChart2]= useState('');
  const [filterBtn,SetFilterBtn] =useState([]);
  const [errorMessage,SetErrorMessage]=useState('')



  const [chartData,SetChartData] = useState({series:[],categories:[]})
  const [chartData2,SetChartData2] = useState({series:[],categories:[]})
  const [chartLoader,SetChartLoader] =useState(false);
  const [chartLoader2,SetChartLoader2] =useState(false);
  const [filterTime,SetFilterTime]=useState(0);

  const [filterEnableCycle,SetFilterEnableCycle]=useState(false);
  const [exportEnableCycle,SetExportEnableCycle]=useState(false);
  const filterByOption =[
    {name:"Working Hour",value:"working_hour"},
    {name:"Wasted Hour",value:"wasted_hour"},
    {name:"Productive hour",value:"productive"},
    {name:"Less Productive hour",value:"less_productive"},
    {name:"Non Productive hour",value:"non_productive"},
    {name:"Out of Range",value:"undefined"}
  ]

  let userDetails = JSON.parse(localStorage.getItem("userLoginDetails"));
  let userSession = userDetails ? userDetails.session : "123456789";
  let org_id = userDetails ? userDetails.org_id : 6;
  let lastReqId = null
  const gridRef = useRef();
  const marks2= {
    0: '0',
    5: '5',
    10: '10',
    15: '15',
    20: '20',
    25: '25',
    30: '30',
    35: '35',
    40: '40',
    45: '45',
    50: '50',
    55: '55',
    60: '60',
  };

  const marks= {
    0: '0',
    10: '10',
    20: '20',
    30: '30',
    40: '40',
    50: '50',
    60: '60',
    70: '70',
    80: '80',
    90: '90',
    100: '100',
    
    
  };

  const cat3= [
    7,8,9,10,11,12,13,
    14,15,16,17,18,19,20,
    21,22,23,0,1,2,3,4,5,6
]
  
  useEffect(() => {
    if(permission && permission.View=='True'){
      setWidthContainer(elementRef.current.getBoundingClientRect().width);
    }
    }, [elementRef,permission]);


  function handleDateSelect(date) {
    updateSelectedDate(date);

    let startDate = new Date().setDate(new Date().getDate() - 29);
    let endDate = new Date(moment(date).format('YYYY-MM-DD 23:59:59'));

    setDate({ start: startDate, end: endDate });
  }

  function getDateFormat(date) {
    return moment(date).format("YYYY-MM-DD");
  }


  function getDateFormat(date) {
    return moment(date).format("YYYY-MM-DD");
  }

  function isInt(value) {
    return !isNaN(value) && (function(x) { return (x | 0) === x; })(parseFloat(value))
  }

  useEffect(() => {

    let perm= props.role.manpower_management?JSON.parse(props.role.manpower_management):null;
    
    if(perm){
      

        SetPermission(perm)
        SetPermissionAPICall(true)
    }else{
    }
},[props.role]);
useEffect(()=>{
  if(permission.View=="True"){
    

    // getDepartment(userSession,org_id).then(res=>{
    //   if(res.status==200){
    //     SetDeparment(res.data);  
    //   }
    // });

    let requestBody={}
    requestBody.date= getDateFormat(date);
    getSiteLocations(requestBody,userSession,org_id).then(res=>{
      if(res.status==200){
        SetLocation(res.data.location_details);
      }
    })


    let arr=[];

    requestBody.startdate=getDateFormat(dates.start)
    requestBody.enddate=getDateFormat(dates.end)
    requestBody.slug='employee'
    let dept=[]
    let catIndex=0;
    let empList= [];
    getPproductivitylist(requestBody,userDetails.session,org_id).then(res=>{
     
      if (res.status==200){
      


        Object.keys(res.data).forEach((key) => {
          
          let element=res.data[key];
          let child= [];
          let childName='';      
          let parentLength=0;
          let subdept=[]
          Object.keys(element).forEach((key2) => {
        
            if(isInt(key2)){
          
              
            }else{
              
              childName=key2  
              subdept.push(key2);
              for (let c = 0;c<element[key2].length;c++){
                parentLength++;
                element[key2][c].department=key;
                element[key2][c].sub_department=key2;
                empList.push(element[key2][c]);
              }
              child.push({name:key2,data:element[key2] })
            }

          });

          if(childName!==''&& Number.isInteger(childName) ===false ){
            arr.push({name:key,child:child})   
            dept.push({name:key,sub_department:subdept}) 
          }else{

            dept.push({name:key,sub_department:subdept}) 
            for (let i=0;i<element.length;i++){
              element[i].department=key;
              element[i].sub_department='';
              empList.push(element[i]);
            }
            arr.push({name:key,data:element})            
          }
         });


      }


      // SetShowData(arr);
      // SetShowDataDefault(arr);
      SetEmployeeAll(empList)
      SetEmployeeDefault(empList)
      SetDeparment(dept)
      SetisLoading(false);
      let ar2= [];
      ar2.push({date:getDateFormat(dates.start),data:empList})
      SetFilterDataReport2(ar2);
      
    })


  }
},[permission])

useEffect(()=>{
  if(permission.View=="True"){
    let sub=[];  
    let subDept=[];
    SetSubDeparment([]);


    for(let i =0;i<department_id.length;i++){
      let sub2= department.filter(item=>item.name.toString().toLowerCase().includes(department_id[i].toLowerCase()) )

      for (let j=0;j<sub2.length;j++){
        subDept = subDept.concat(sub2[j].sub_department);
      }  
    }

  if(subDept.length > 0){
    SetSubDeparment(subDept);  
  }

    
  }
},[permission,department_id])


useEffect(()=>{
  SetChartLoader(true)
  if(permission.View=="True"){
      SetActiveChart(filterby[0]) 
  }
},[permission,chartAllData])

useEffect(()=>{
  SetChartLoader(true)
  if(permission.View=="True"){
      let fpOutput = chartAllData.series.filter(item=>item.acSeries==activeChart);
    
      SetChartData({categories:chartAllData.categories,series:fpOutput}) 
      
  }
  setTimeout(function(){
    SetChartLoader(false);
  },300) 
},[permission,activeChart])

useEffect(()=>{
  SetChartLoader2(true)
  if(permission.View=="True"){
      let fpOutput = chartAllData2.series.filter(item=>item.department==activeChart2);
 
      SetChartData2({categories:chartAllData2.categories,series:fpOutput})    
  }
  setTimeout(function(){
    SetChartLoader2(false);
  },300) 
},[permission,activeChart2])

  useEffect(() => {
    if (props.language) {
      updateSelectedLangValue(props.language);
    }
  }, [props.language]);


  const handleDate = (date, type) => {
    setDate((prev) => ({ ...prev, [`${type}`]: date }));
  };
  function changeLanguage(lang) {
    getLanguageTranslation(lang).then((res) => {
      if (res && res.status >= 200 && res.status <= 200) {
        localStorage.setItem("languageData", JSON.stringify(res.data));
        localStorage.setItem("selectedLanguage", lang);
        props.setSelectedLanguage(lang);
      }
    });
  }

  const onBtExport = useCallback(() => {
    gridRef.current.api.exportDataAsExcel();
  });

  function GetRandomDigit(min=0,max=10){
    return  Math.floor(Math.random() * (max - min + 1)) + min;
  }

  function handleClickCard(tag_serial,date){
    let dt =date.split('-');
    localStorage.setItem('ProductiveStart',new Date(dt[2], dt[1] - 1, dt[0]));
    localStorage.setItem('ProductiveEnd',new Date(dt[2], dt[1] - 1, dt[0]));
    localStorage.setItem("selectedDate",new Date(dt[2], dt[1] - 1, dt[0]))
    //props.history.push(`/productivity/employee/:${tag_serial}`);
      
    window.open(
      `/productivity/employee/:${tag_serial}`, "_blank");
  }


  function AGTable(data,key,selectedLocation=location_id){

    key = data.length +key +filterLocation.length;
    let loc = selectedLocation;
    let arr;
    let arr2=[];
   let lengthSel =  Object.keys(data[0]).length - 1

    if(data){  
    let widthCal= key=='allEmployeee'?(widthContainer - 60)/ lengthSel:(widthContainer - 90)/ lengthSel;
    
   
      
    arr2.push( <AgGridColumn
      field="date"
      headerName={"Date"}
     /> 
    )
      arr2.push(  
        <AgGridColumn
            field="name"
            headerName={"Name"}
            valueFormatter={(params)=>capitalizeFirstLetter(params.value)}
            cellClass = {"ag-cell--textUppercase"}
        />
      )
    arr2.push( 
      
    <AgGridColumn
      field="tag_serial"
      headerName={"Tag Serial"}
    /> )

    arr2.push( 
      
      <AgGridColumn
        field="shift"
        headerName={"Shift"}
      /> )
  
 
    arr2.push( 
      
      <AgGridColumn
        field="department"
        headerName={"Department"}
      /> )

    arr2.push( 
      
      <AgGridColumn
        field="sub_department"
        headerName={"Sub Department"}
      /> )
  
    for(let i = 0; i<selectedLocation.length;i++){
      let locationName=  location.find(item=>item.loc_tag==selectedLocation[i] )
     
      if(locationName){
        arr2.push( <AgGridColumn
          field={selectedLocation[i]}
          headerName={locationName.name}
          comparator={timeComparator}
        />);
      }else{
        arr2.push(
           <AgGridColumn
              field={selectedLocation[i]}
              comparator={timeComparator}
              //headerName={"Tag Serial " +i}
            />
        );
      }

    }


    arr = <div className={"textAlignLeft "+ key}><div className="ag-theme-alpine if cell-size-40" style={{height: 60 + ((data.length > 0?data.length > 500?500:data.length:1)*34.8) , width: "calc(100% - 1px)"}}>     
    <AgGridReact
            rowHeight={35}
            ref={gridRef}
            copyHeadersToClipboard= {true}
            enableRangeSelection= {true}
            onCellClicked={ (event) => handleClickCard(event.data.tag_serial,event.data.date)}
            autoGroupColumnDef={{
                headerName: 'Name',minWidth: 200,field: 'name',
    
            }}
            headerHeight={35}
         defaultColDef={{sortable: true,resizable: true,minWidth:45, width:widthCal  ,suppressColumnVirtualisation:true,skipHeaderOnAutoSize:true}}
         pagination= {data.length>500}
         paginationPageSize= {500}             
            rowData={data}
            key={key}
            overlayNoRowsTemplate={
                AGGridEMptyMEssage('')
              }        
         >    
    {arr2}
    </AgGridReact>
    </div></div>
    
      
            }
        SetShowDataDefault(arr);    


  }

  function EdiButtonRender(params){
        

    var op =document.createElement('div');

    var eGui = document.createElement('div');
  
    eGui.addEventListener('click', event => {
      if(event.target.getAttribute("data-action")=='edit'){
        handleClickCard(params.data.tag_serial,params.data.date)
      }
    });    
        eGui.innerHTML=`<div class="element-icon ant-table-cell">
                            <button class="btn btn-edit btn-smaller btn-icon" data-action="edit" data-el="${params.value}" ><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!--! Font Awesome Pro 6.0.0-alpha3 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path d="M288 128C217.3 128 160 185.3 160 256s57.33 128 128 128c70.64 0 128-57.32 128-127.9C416 185.4 358.7 128 288 128zM288 352c-52.93 0-96-43.06-96-96s43.07-96 96-96c52.94 0 96 43.02 96 96.01C384 308.9 340.1 352 288 352zM572.5 238.1C518.3 115.5 410.9 32 288 32S57.69 115.6 3.469 238.1C1.563 243.4 0 251 0 256c0 4.977 1.562 12.6 3.469 17.03C57.72 396.5 165.1 480 288 480s230.3-83.58 284.5-206.1C574.4 268.6 576 260.1 576 256C576 251 574.4 243.4 572.5 238.1zM543.2 260.2C492.3 376 394.5 448 288 448c-106.5 0-204.3-71.98-255-187.3C32.58 259.6 32.05 256.9 31.1 256.2c.0547-1.146 .5859-3.783 .7695-4.363C83.68 135.1 181.5 64 288 64c106.5 0 204.3 71.98 255 187.3c.3945 1.08 .9238 3.713 .9785 4.443C543.9 256.9 543.4 259.6 543.2 260.2z"/></svg> View </button>
                        </div>`;
            


  
     return eGui;



}

  
  function AGTable2(data,key,selectedLocation=filterby,loopInner=0){
    
    key = data.length +key + selectedLocation.length;
    let loc = selectedLocation;
    let arr;
    let arr2=[];
  //  let lengthSel =  11
    if(!data){

      return
    }else{

    }
   let lengthSel =  5  + filterby.length;


    if(data){  
    let widthCal= (widthContainer - 200)/ lengthSel;
    //console.log("Ag Cols",lengthSel,Object.keys(data[0]).length,filterby.length,widthCal)    
    key = data.length +key + selectedLocation.length;

    data = data.sort((a,b) => {
      let dt =a.date.split('-'); 
      let dt2 =b.date.split('-');

      let aMs = new Date(dt[2], dt[1] - 1, dt[0])
      let bMs = new Date(dt2[2], dt2[1] - 1, dt2[0])
      return  aMs - bMs
    })


    arr2.push( <AgGridColumn
      field="date"
      headerName={"Date"}
     /> 
    )
      arr2.push(  
        <AgGridColumn
            field="emp_name"
            headerName={"Name"}
            valueFormatter={(params)=>capitalizeFirstLetter(params.value)}
            cellClass = {"ag-cell--textUppercase"}
        />
      )
      
    arr2.push( 
      
    <AgGridColumn
      field="tag_serial"
      headerName={"Tag Serial"}
    /> )

    // arr2.push( 
      
    //   <AgGridColumn
    //     field="shift"
    //     headerName={"Shift"}
    //   /> )
  
 
    arr2.push( 
      
      <AgGridColumn
        field="department"
        headerName={"Department"}
        valueFormatter={(params)=>capitalizeFirstLetter(params.value)}
        cellClass = {"ag-cell--textUppercase"}
      /> )

    arr2.push( 

      <AgGridColumn
        field="sub_department"
        headerName={"Sub Department"}
        valueFormatter={(params)=>capitalizeFirstLetter(params.value)}
        cellClass = {"ag-cell--textUppercase"}
      />      
      )
   
      if(selectedLocation.indexOf('working_hour') > -1){
   
        arr2.push( 
        <AgGridColumn
              field="working_hour"
              headerName={"Working Hour"}
              valueFormatter={(params)=>params.value+"%"}
            />
          ) 
      }
      if(selectedLocation.indexOf('wasted_hour') > -1){
        arr2.push( <AgGridColumn
          field="wasted_hour"
          headerName={"Wasted Hour"}
          valueFormatter={(params)=>params.value+"%"}
        />)
      }
      if(selectedLocation.indexOf('productive') > -1){
        arr2.push(   <AgGridColumn
          field="productive"
          headerName={"Producivite Hour"}
          valueFormatter={(params)=>params.value+"%"}
        />)
      }

      if(selectedLocation.indexOf('less_productive') > -1){
        arr2.push(    <AgGridColumn
          field="less_productive"
          headerName={"Less Producivite Hour"}
          valueFormatter={(params)=>params.value+"%"}
        />)
      }

      if(selectedLocation.indexOf('non_productive') > -1){
        arr2.push( <AgGridColumn
          field="non_productive"
          headerName={"Non Producivite Hour"}
          valueFormatter={(params)=>params.value+"%"}
        />)
      }

      if(selectedLocation.indexOf('undefined') > -1){
        arr2.push(   <AgGridColumn
          field="undefined"
          headerName={"Out of Range Hour"}
          valueFormatter={(params)=>params.value+"%"}
        />)
      }
      arr2.push(   <AgGridColumn
        field="tag_serial"
        headerName={"Action"}
        cellRenderer= {(params)=>EdiButtonRender(params)}
        width="100px"
      />)
    arr = <div className={"textAlignLeft "+ key}><div className="ag-theme-alpine if cell-size-40" style={{height: 60 + ((data.length > 0?data.length > 500?500:data.length:1)*34.8) , width: "calc(100% - 1px)"}}> 
    
    <AgGridReact
            rowHeight={35}
            ref={gridRef}
            copyHeadersToClipboard= {true}
            enableRangeSelection= {true}
            //onCellClicked={ (event) => handleClickCard(event.data.tag_serial,event.data.date)}
            autoGroupColumnDef={{
                headerName: 'Name',minWidth: 200,field: 'name',
    
            }}
            headerHeight={35}

         defaultColDef={{sortable: true,resizable: true,minWidth:45, width:widthCal  ,suppressColumnVirtualisation:true,skipHeaderOnAutoSize:true}}
         pagination= {data.length>500}
         paginationPageSize= {500}             
            rowData={data}
            
            key={"AgFilterv2 Report"+key}
            overlayNoRowsTemplate={
                AGGridEMptyMEssage('')
              }        
         >    
    {arr2}


    </AgGridReact>
    </div></div>
    
      
            }
        SetShowDataDefault(arr);    


  }

  function capitalizeFirstLetter(string) {
    if(string){
      return string.toLowerCase();
    }
  }

  

  const timeComparator = (valueA, valueB, nodeA, nodeB, isInverted) => {



    let aMs = moment(valueA, 'HH:mm:ss').format('x')
    let bMs = moment(valueB, 'HH:mm:ss').format('x')
        if(valueA=='na' || valueB=='na')return 0;
        if (valueA == valueB) return 0;
        return (aMs > bMs) ? 1 : -1;       
 };

 function filterResultHandler(depID,subdepID){

  SetShowDataDefault([]);

  SetisLoading2(true);
  SetisLoading3(true);


  let empfilter=[]


  let filterDep=[];
    let selectedFilter = [...depID];
    if(subdepID.indexOf('all')){
      selectedFilter.push(...sub_department)
    }else{
      for(let i=0;i<subdepID.length;i++){
        selectedFilter.push(subdepID[i]);
      }
    }

    for(let i=0; i<depID.length;i++){
      let filterList =   employeeDefault.filter(item=> item.department.toString().toLowerCase().includes(depID[i]=='all'?'':depID[i].toString().toLowerCase())  )
      filterDep.push(...filterList);
    }

    for(let i=0; i<subdepID.length;i++){
      let filterList =   filterDep.filter(item=> item.sub_department.toString().toLowerCase().includes(subdepID[i] == 'all'?'':subdepID[i].toString().toLowerCase()) )
      empfilter.push(...filterList);
    }

    if(subdepID.length ==0){
      empfilter= filterDep;
    }



    // for(let i = 0; i<selectedFilter.length;i++){
    //   let filterList =   employeeDefault.filter(item=> item.department.toString().toLowerCase().includes(selectedFilter[i].toString().toLowerCase()) && item.sub_department.toString().toLowerCase().includes(selectedFilter[i].toString().toLowerCase()) )
    //   empfilter.push(...filterList);
    // }










  

  let arr= [];
  let emp=[];
  let startDate = moment(dates.start)
  let endDate =   moment(dates.end)
  let loopCount = 0;
  // var a = moment([2007, 0, 29]);
  // var b = moment([2007, 0, 28]);
  let difference = endDate.diff(startDate, 'days'); 
  
  SetFilterLocation(location_id);


  SetTotalCount(empfilter.length);
  
  
  let totalLoop = empfilter.length * difference
  let loopActual =0;
  for(let j= 0;j <= difference;j++ ) {
    SetCount(0);      
    let dateprod =moment(dates.start, "YYYY-MM-DD").add(j, 'days')
    let time=[];
    let shift=[];
    let loopInner=0 ;
    for (let i= 0;i<empfilter.length;i++) {
      

      let requestBody={}
      let empdetail={};
      requestBody.startdate = moment(dateprod).format('YYYY-MM-DD');
      requestBody.enddate = moment(dateprod).format('YYYY-MM-DD');
      
      requestBody.slug = 'employee'
      requestBody.tag_serial = empfilter[i].tag_serial;
      
      loopActual++;
      getPproductivityDetailOverview(requestBody,userSession,org_id).then(res=>{
        
        if(res.status== 200){
         
          loopInner++
          loopCount++;
          SetCount(loopInner)
          let datetest = Math.ceil(loopCount/empfilter.length) - 1;
          SetDateCount(moment(dates.start, "YYYY-MM-DD").add(datetest, 'days').format('MMM DD')) ;
          
          
          empdetail.name=empfilter[i].emp_name
          empdetail.tag_serial=empfilter[i].tag_serial
          empdetail.date=moment(dateprod).format('DD-MM-YYYY');
          empdetail.department=empfilter[i].department
          empdetail.sub_department=empfilter[i].sub_department            
          //empdetail.data = res.data
          let shiftHour =[]
          let prodloc=[];
          let shift = res.shift?JSON.parse(res.shift):[]
          let startSft= res.shift?shift.start_time.split(':'):[0,0,0]
          let endSft= res.shift?shift.end_time.split(':'):[0,0,0]
          empdetail['shift'] = res.shift?startSft[0]+":"+startSft[1]+" - "+ endSft[0]+":"+endSft[1]: "- ";
          empdetail.productive=[];

          let st = parseInt(startSft && startSft[0]?parseInt(startSft[1]) > 40 && parseInt(startSft[0])!=23 ?parseInt(startSft[0]) + 1:parseInt(startSft[0]):0);
          let et = parseInt(endSft && endSft[0]?parseInt(endSft[1]) > 40 && parseInt(endSft[0])!=23 ?parseInt(endSft[0]) + 1:parseInt(endSft[0]):0);
          let smaller=st>et?et:st;
          let bigger = st>et?st:et;
          for(let i=0;i<location_id.length;i++){
              
            
                empdetail[location_id[i]]= "00:00:00"        
          
         }

          

          if(res.shift && res.data){

         //Pushing shift hour 
          for(let i=0;i < cat3.length;i++){
            if(st > et){
              if( (smaller <= cat3[i]) && (bigger > cat3[i]) ){
              }else{

                  shiftHour.push(cat3[i]);

              }

          }else{
              if( (smaller <= cat3[i]) && (bigger > cat3[i]) ){

                  
                  shiftHour.push(cat3[i]);   

              }
              
          }
        }

        for(let i=0 ; i< shiftHour.length;i++){


            if(res.data[shiftHour[i]]){
              let productive = res.data[shiftHour[i]].productive;
              let nonproductive = res.data[shiftHour[i]].non_productive;
              let lessproductive = res.data[shiftHour[i]].less_productive;

              Object.keys(productive).forEach((key,i)=>{
                let elm = productive[key];
                
                let locExist =  location_id.find(item=>item== elm.location_serial)
                if(locExist){ 
            
                let index = prodloc.findIndex(item=>item.name==key);
            
                
                if(index > -1){
                    
                    let ti = prodloc[index].time + Math.round(moment.duration(elm.time).asMilliseconds())                            
                    
                    prodloc[index]={name:key,time:ti,tag_serial:elm.location_serial};

                }else{
                    
                    prodloc.push({name:key,time:Math.round(moment.duration(elm.time).asMilliseconds()),tag_serial:elm.location_serial })
                    
                }    
                
              }
             })
             Object.keys(lessproductive).forEach((key,i)=>{
              let elm = lessproductive[key];
              
              let locExist =  location_id.find(item=>item== elm.location_serial)
              if(locExist){ 
          
              let index = prodloc.findIndex(item=>item.name==key);
          
              
              if(index > -1){
                  
                  let ti = prodloc[index].time + Math.round(moment.duration(elm.time).asMilliseconds())                            
                  
                  prodloc[index]={name:key,time:ti,tag_serial:elm.location_serial};

              }else{
                  
                  prodloc.push({name:key,time:Math.round(moment.duration(elm.time).asMilliseconds()),tag_serial:elm.location_serial })
                  
              }    
              
            }
           })
           Object.keys(nonproductive).forEach((key,i)=>{
            let elm = nonproductive[key];
            
            let locExist =  location_id.find(item=>item== elm.location_serial)
            if(locExist){ 
        
            let index = prodloc.findIndex(item=>item.name==key);
        
            
            if(index > -1){
                
                let ti = prodloc[index].time + Math.round(moment.duration(elm.time).asMilliseconds())                            
                
                prodloc[index]={name:key,time:ti,tag_serial:elm.location_serial};

            }else{
                
                prodloc.push({name:key,time:Math.round(moment.duration(elm.time).asMilliseconds()),tag_serial:elm.location_serial })
                
            }    
            
          }
         })

             for(let i=0;i<location_id.length;i++){
              
                if(prodloc){
                  let loc =  prodloc.filter((item)=> item.tag_serial == location_id[i])
                  if(loc.length > 0){
                   
                    empdetail[location_id[i]]=converttoTime(loc[0].time)
                  }else{
                    empdetail[location_id[i]]= "00:00:00"        
                  }
                  
                }else{
                  empdetail[location_id[i]]= "00:00:00"      
                }
             }

            empdetail.productive=prodloc;

            }
                     
        }

      }else{
        for(let i=0;i<location_id.length;i++){                
              empdetail[location_id[i]]= "00:00:00"        
       }

      empdetail.productive=[];

      
      }
      emp.push(empdetail)
        if(emp.length==loopCount){
          
          emp.sort(function(a,b){
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return new Date(b.date) - new Date(a.date);
          });
          SetFilterData(emp);
          AGTable(emp,'filter Data',location_id)
          SetExportEnable(true);
          //SetisLoading(false);
          SetisLoading2(false);

          if(emp.length == 0){
            SetErrorMessage(<h2>Filter Result Empty Data</h2>);
          }else{
            SetErrorMessage('');
          }
        }

      }
      if(emp.length==loopActual){
        SetisLoading3(false);
      }else{
       // SetisLoading3(true);
      }

      })

    }
    



 }
}

  
  function filterResultHandler2(){
    // SetisLoading2(true);
     SetisLoading3(true);
     SetChart1(false);
     SetShowDataDefault([])
  
 
  //   let empfilter = employeeDefault.filter(item=> item.department.toString().toLowerCase().includes(department_id=='all'?'':department_id.toString().toLowerCase()) &&item.sub_department.toString().toLowerCase().includes(sub_department_id=='all' || sub_department_id==''?'':sub_department_id.toString().toLowerCase()) )
   
     let empfilter = [];
     let filterDep=[];
       let selectedFilter = [...department_id];
       if(sub_department_id=='all'){
         selectedFilter.push(...sub_department)
       }else{
         for(let i=0;i<sub_department_id.length;i++){
           selectedFilter.push(sub_department_id[i]);
         }
       }
 
       for(let i=0; i<department_id.length;i++){
         let filterList =   employeeDefault.filter(item=> item.department.toString().toLowerCase().includes(department_id[i].toString().toLowerCase())  )
         filterDep.push(...filterList);
       }

       for(let i=0; i<sub_department_id.length;i++){
         let filterList =   filterDep.filter(item=> item.sub_department.toString().toLowerCase().includes(sub_department_id=='all'?'':sub_department_id[i].toString().toLowerCase()) )
         empfilter.push(...filterList);
       }
 

     
     SetFIlterByCoulmn(filterby);    
 
     let arr= [];
     let emp=[];
     let startDate = moment(dates.start)
     let endDate =   moment(dates.end)
     let loopCount = 0;
     let difference = endDate.diff(startDate, 'days'); 
     SetTotalCount(empfilter.length);
     
     
     let totalLoop = empfilter.length * difference
     let loopActual =0;
     let empList= [];    
     let loopInner=0 ;
     let dateInc=0;
     for(let j= 0;j <= difference;j++ ) {
       SetCount(0);      
       let dateprod =moment(dates.start, "YYYY-MM-DD").add(j, 'days')
       let time=[];
       let shift=[];
 
       let requestBody={}
       let empdetail={};
       requestBody.startdate = moment(dateprod).format('YYYY-MM-DD');
       requestBody.enddate = moment(dateprod).format('YYYY-MM-DD');
         
         
       //Get Productvity List
       requestBody.slug='employee'
       
       getPproductivitylist(requestBody,userDetails.session,org_id).then(res=>{
         
         if (res.status==200){
 
   
           Object.keys(res.data).forEach((key) => {
             
             let element=res.data[key];
             Object.keys(element).forEach((key2) => {
             
               if(isInt(key2)){
             
                 
               }else{
                 if(( department_id.length=='' || department_id.indexOf(key) > -1) &&  (sub_department_id.indexOf('all')> - 1 || sub_department_id.indexOf(key2) > -1 || sub_department_id=='' ) ){               
                   
                 
                 for (let c = 0;c<element[key2].length;c++){
                   element[key2][c].department=key;
                   element[key2][c].sub_department=key2;
                   
                   // element[key2][c].working_hour_time = element[key2][c].working_hour
                   // element[key2][c].wasted_hour_time = element[key2][c].wasted_hour
 
 
                   delete element[key2][c]['working_hour'];
                   delete element[key2][c]['wasted_time'];
                   
                   let sumHour =element[key2][c].productive + element[key2][c].less_productive
                   let nonSumHour =element[key2][c].undefined + element[key2][c].non_productive;
                   element[key2][c].date=moment(dateprod).format('DD-MM-YYYY');
                   if(report_by.indexOf('Percentage')  > -1){
                     let minVal= filter_min!=''?parseInt(filter_min):0
                     let maxVal= filter_max!=''?parseInt(filter_max):100
                     //if filter is sort by all
                     if(filterby == 'all'){
                       loopInner++;            
                       element[key2][c].working_hour = sumHour>100?100:sumHour.toFixed(2) ;
                       element[key2][c].wasted_hour = sumHour >= 100?0:nonSumHour>100?100:nonSumHour.toFixed(2);
                       empList.push(element[key2][c]);
                     }else{
                       
                      //  if(filterby.indexOf('working_hour') > -1){
                      //    element[key2][c].working_hour = sumHour>100?100:sumHour.toFixed(2) ;
                      //  }
                       element[key2][c].working_hour = sumHour>100?100:sumHour.toFixed(2) ;
                      //  if(filterby.indexOf('wasted_hour') > -1){
                      //    element[key2][c].wasted_hour = sumHour >= 100?0:nonSumHour>100?100:nonSumHour.toFixed(2);
                      //  }
                       element[key2][c].wasted_hour = sumHour >= 100?0:nonSumHour>100?100:nonSumHour.toFixed(2);
                       if(filterby.indexOf('productive')<= -1 ){
                        // delete element[key2][c]['productive'];
                       }
                       if(filterby.indexOf('non_productive')<= -1 ){
                        // delete element[key2][c]['non_productive'];
                       }
                       if(filterby.indexOf('less_productive')<= -1 ){
                        // delete element[key2][c]['less_productive'];
                       }
                       if(filterby.indexOf('undefined')<= -1 ){
                       //  delete element[key2][c]['undefined'];
                       }
                       delete element[key2][c]['access_policy_breach'];
                       
                       let lp=0;
                       for(let i =0;i<filterby.length ;i++){
 
 
 
                         if(element[key2][c][filterby[i]] >= parseInt(filterSlide[0])  && element[key2][c][filterby[i]] <= parseInt(filterSlide[1])  ){
                           lp++;
                         }
                         if(lp==filterby.length){
                           loopInner++;
                           empList.push(element[key2][c]);
 
                         }
                       }
                       //if filter is sort by any selected item
            
 
                     }
 
 
 
                     
                   }
                   //here is only time thing 
                   if(filterby.indexOf('time')  > -1){
 
                   }
 
                   
 
 
                 }
 
               }
               }
   
             });
 
 
   
            });
   
         
       }
       if(empList.length> 0){
        
       }

        if(loopInner==empList.length){
            dateInc++;
            SetDateCount(moment(dates.start, "YYYY-MM-DD").add(dateInc, 'days').format('MMM DD')) ;
        }


         if(difference==0){

           //Here will be empty message condition
           if(empList.length == 0){
             SetErrorMessage(<h2>Filter Result Empty Data</h2>);
           }else{
             SetErrorMessage('');
           }
 
           //End
           SetFilterData(empList);
 
           
           let loopAr=[]
           let loopAr2=[]
           let series=[];
 
           
           let categories=[];
           if (department_id=='all'){
             loopAr=department.map(item=> {return  item.name});
           }else if(department_id.length > 1){
             loopAr=department_id; 
           }
           else if(sub_department_id.indexOf('all')> -1|| sub_department_id.length == 0){
             //loopAr = department.filter(item=>item.name==department_id);
             //loopAr=loopAr[0].sub_department; 
             loopAr=sub_department; 
           }else{
 
             loopAr = sub_department_id;
           }
           //appending all series value with zero
 
 
           for(let i=0; i <= difference ;i++){
             let dateprod2 =moment(dates.start, "YYYY-MM-DD").add(i, 'days')
             let innerSeries = [];
             let filter = empList.filter(
                 item=>item.date == moment(dateprod2).format('DD-MM-YYYY'))
                 categories.push( moment(dateprod2).format('DD MMM'))
             //V1 chart   
             for(let i= 0 ;i< loopAr.length;i++){
                 let filterDep = filter.filter(item=> (item.department==loopAr[i] || item.sub_department==loopAr[i] ))
                 let zeroLength  =[];
                 
                  zeroLength  = filterDep.filter(item=> item.less_productive == 0 && item.productive== 0 && item.non_productive==0 && item.undefined==0) 
                  
                 
                 
                 //here is selected filter will loop
                 for (let z= 0; z<filterby.length;z++ ){
                  
                   let result = filterDep.reduce(function(sum, current) {
                     
                     return sum + parseFloat(current[filterby[z]]);
                   }, 0)/(filterDep.length - zeroLength.length);
                   //console.log()
                   let findItem = series.find(item => item.name == loopAr[i] && item.acSeries == filterby[z])

                   if(findItem){
                     let ar = findItem.data;
                     ar.push(result);
                     findItem.data=ar
                     
                     //series.push({name:loopAr[i],data:result,date:moment(dateprod2).format('DD MMM')})
                   }else{
                     series.push({name:loopAr[i],data:[result],date:moment(dateprod2).format('DD MMM'),acSeries:filterby[z]})
                   }
                   
                 }
 
 
               }
 
               
 
 
               let firstActive = series.filter(item=>item.acSeries == filterby[0])
 

 
               SetChartData({series:firstActive,categories:categories})  
               
               SetChartAllData({series:series,categories:categories})
 
               //v2 chart
               let filterBtn = []
               let categories2=[]
               let series2=[]
 
               SetFilterBtn(loopAr);
               //looping date
               for(let i=0; i <= difference ;i++){
                 let dateprod2 =moment(dates.start, "YYYY-MM-DD").add(i, 'days')
                 let innerSeries = [];
                
                 categories2.push( moment(dateprod2).format('DD MMM'))
                 //looping selected array department sub department
                 for (let j=0;j<loopAr.length;j++){
                   //let filter = empList.filter(item=>item.date == moment(dateprod2).format('DD-MM-YYYY')) && (item.department==loopAr[j] || item.sub_department==loopAr[j])
                   
                   let filter = empList.filter(item=> (item.department==loopAr[j] || item.sub_department==loopAr[j] ) && (item.date ==moment(dateprod2).format('DD-MM-YYYY')) )
                   //console.log(loopAr[j]);
                   let zeroLength = []
                    zeroLength  = filter.filter(item=> item.less_productive == 0 && item.productive== 0 && item.non_productive==0 && item.undefined==0) 

                    
                       
                       for(let z= 0;z<filterby.length;z++ ){
 
                        let Sname= filterByOption.find(item=>item.value==filterby[z]).name;
 
                        let result = filter.reduce(function(sum, current) {
                     
                         return sum + parseFloat(current[filterby[z]]);
                       }, 0)/(filter.length - zeroLength.length);

                       

                       let findItem = series2.find(item => item.name ==filterby[z]  && item.department == loopAr[j] )
                       //console.log("Test",result,zeroLength,findItem)
                       if(findItem){
                         let ar = findItem.data;
                         ar.push(result);
                         findItem.data=ar
                         
                         //series.push({name:loopAr[i],data:result,date:moment(dateprod2).format('DD MMM')})
                       }else{
                         series2.push({name:filterby[z],data:[result],date:moment(dateprod2).format('DD MMM'),department:loopAr[j]})
                       } 
 
                       
 
 
 
                       }  
 
                     
 
                     }
                     
                 
 
 
                 } 
                SetChartAllData2({series:series2,categories:categories2});
                
                SetActiveChart2(loopAr[0]);

                let firstDisp=series2.filter(item=> item.department==loopAr[0]);
                SetChartData2({series:firstDisp,categories:categories2})
 
 
                 
 

 
           
           }
           SetExportEnable(true);
           
           SetisLoading3(false);
           AGTable2(empList,'testtReport2',filterby,loopInner)   
           
          }else if(dateInc==(difference+1) ){
            //Here will be empty message condition
            if(empList.length == 0){
              SetErrorMessage(<h2>Filter Result Empty Data</h2>);
            }else{
              SetErrorMessage('');
            }
  
            //End
            SetFilterData(empList);
  
            
            let loopAr=[]
            let loopAr2=[]
            let series=[];
  
            
            let categories=[];
            if (department_id=='all'){
              loopAr=department.map(item=> {return  item.name});
            }else if(department_id.length > 1){
              loopAr=department_id; 
            }
            else if(sub_department_id.indexOf('all')> -1|| sub_department_id.length == 0){
              //loopAr = department.filter(item=>item.name==department_id);
              //loopAr=loopAr[0].sub_department; 
              loopAr=sub_department; 
            }else{
  
              loopAr = sub_department_id;
            }
            //appending all series value with zero
  
  
            for(let i=0; i <= difference ;i++){
              let dateprod2 =moment(dates.start, "YYYY-MM-DD").add(i, 'days')
              let innerSeries = [];
              let filter = empList.filter(
                  item=>item.date == moment(dateprod2).format('DD-MM-YYYY'))
                  categories.push( moment(dateprod2).format('DD MMM'))
              //V1 chart   
              for(let i= 0 ;i< loopAr.length;i++){
                  let filterDep = filter.filter(item=> (item.department==loopAr[i] || item.sub_department==loopAr[i] ))
  
                 
                  let zeroLength = []
                   zeroLength  = filterDep.filter(item=> item.less_productive == 0 && item.productive== 0 && item.non_productive==0 && item.undefined==0) 
                  
                  //here is selected filter will loop
                  for (let z= 0; z<filterby.length;z++ ){
                   
                    let result = filterDep.reduce(function(sum, current) {
                      
                      return sum + parseFloat(current[filterby[z]]);
                    }, 0)/(filterDep.length - zeroLength.length) ;
                    let findItem = series.find(item => item.name == loopAr[i] && item.acSeries == filterby[z])
                    if(findItem){
                      let ar = findItem.data;
                      ar.push(result);
                      findItem.data=ar
                      
                      //series.push({name:loopAr[i],data:result,date:moment(dateprod2).format('DD MMM')})
                    }else{
                      series.push({name:loopAr[i],data:[result],date:moment(dateprod2).format('DD MMM'),acSeries:filterby[z]})
                    } 
                    
                  }
  
  
                }
  
                
  

  
                let firstActive = series.filter(item=>item.acSeries == filterby[0])
  
                
  
                SetChartData({series:firstActive,categories:categories})  
                
                SetChartAllData({series:series,categories:categories})
  
                //v2 chart
                let filterBtn = []
                let categories2=[]
                let series2=[]
  

                SetFilterBtn(loopAr);
                //looping date
                for(let i=0; i <= difference ;i++){
                  let dateprod2 =moment(dates.start, "YYYY-MM-DD").add(i, 'days')
                  let innerSeries = [];
                 
                  categories2.push( moment(dateprod2).format('DD MMM'))
                  //looping selected array department sub department
                  for (let j=0;j<loopAr.length;j++){
                    //let filter = empList.filter(item=>item.date == moment(dateprod2).format('DD-MM-YYYY')) && (item.department==loopAr[j] || item.sub_department==loopAr[j])
                    let filter = empList.filter(item=> (item.department==loopAr[j] || item.sub_department==loopAr[j] ) && (item.date ==moment(dateprod2).format('DD-MM-YYYY')) )
                    let zeroLength = []
                     zeroLength  = filter.filter(item=> item.less_productive == 0 && item.productive== 0 && item.non_productive==0 && item.undefined==0) 
                        
                        for(let z= 0;z<filterby.length;z++ ){
  
                         let Sname= filterByOption.find(item=>item.value==filterby[z]).name;
  
                         let result = filter.reduce(function(sum, current) {
                      
                          return sum + parseFloat(current[filterby[z]]);
                        }, 0)/(filter.length - zeroLength.length);
                        let findItem = series2.find(item => item.name ==filterby[z]  && item.department == loopAr[j] )
                        if(findItem){
                          let ar = findItem.data;
                          ar.push(result);
                          findItem.data=ar
                          
                          //series.push({name:loopAr[i],data:result,date:moment(dateprod2).format('DD MMM')})
                        }else{
                          series2.push({name:filterby[z],data:[result],date:moment(dateprod2).format('DD MMM'),department:loopAr[j]})
                        } 
  
                        
  
  
  
                        }  
  
                      
  
                      }
                      
                  
  
  
                  } 
                 SetChartAllData2({series:series2,categories:categories2});
                 
                 SetActiveChart2(loopAr[0]);

                 let firstDisp=series2.filter(item=> item.department==loopAr[0]);
                 SetChartData2({series:firstDisp,categories:categories2})
  
  
                  
  
            
            }
            SetExportEnable(true);
            
            SetisLoading3(false);
            AGTable2(empList,'testtReport2',filterby,loopInner)   
          }
 
         
         
       })
         
 
 
       
 
 
     }
     
   }
 
  function exportToExcelReport2(){
    let dp = department_id.length>1?"Multiple":department_id[0];
    //console.log(department_id,dp)
    let sheetName = userDetails.empName+"-"+moment(dates.start).format("MMM DD")+"-"+moment(dates.end).format("MMM DD")+"-"+dp+".xlsx";

   // let sheetName = userDetails.empName+"-"+moment(dates.start).format("MMM DD")+"-"+moment(dates.end).format("MMM DD")+"-"+department_id+".xlsx";
    let headerName= "Report"
    const filename = userDetails.empName+"-"+moment(dates.start).format("MMM DD")+"-"+moment(dates.end).format("MMM DD")+"-"+department_id+".xlsx";
    const workbook = new ExcelJs.Workbook();
  
    let sheet = workbook.addWorksheet(sub_department_id.length>0 && sub_department_id.length==1?sub_department_id[0]:"All", {
      views: [{ showGridLines: true }]
    });

    let colHeader = []
    colHeader.push({name: 'Date', header: 'Date', key: 'date'}) 
    colHeader.push({name: 'Name', header: 'emp_name', key: 'emp_name'}) 
    colHeader.push({name: 'Tag Serial', header: 'tag_serial', key: 'tag_serial'}) 
    // colHeader.push({name: 'Shift', header: 'shift', key: 'shift'}) 
    colHeader.push({name: 'Department', header: 'department', key: 'department'}) 
    colHeader.push({name: 'Sub Department', header: 'sub_department', key: 'sub_department'}) 

    
  
    for(let i=0;i< filterbyCoumn.length;i++){

   
   
      if(filterbyCoumn[i]=='working_hour'){
        colHeader.push({name: 'Working Hour', header: 'Working Hour', key: filterbyCoumn[i]}) 
      
      }
      if(filterbyCoumn[i]=='wasted_hour'){
        colHeader.push({name: 'Wasted Hour', header: 'Wasted Hour', key: filterbyCoumn[i]}) 
      
      }
      if(filterbyCoumn[i]=='productive'){
        colHeader.push({name: 'Productive Hour', header: 'Productive Hour', key: filterbyCoumn[i]}) 
      
      }

      if(filterbyCoumn[i]=='non_productive'){
        colHeader.push({name: 'Non Productive Hour', header: 'Non Productive Hour', key: filterbyCoumn[i]}) 
      
      }

      if(filterbyCoumn[i]=='less_productive'){
        colHeader.push({name: 'Less Productive Hour', header: 'Less Productive Hour', key: filterbyCoumn[i]}) 
      }

      if(filterbyCoumn[i]=='undefined'){
        colHeader.push({name: 'Out of Range Hour', header: 'Out of Range Hour', key: filterbyCoumn[i]}) 
      }
    }

  let  data = [...filterData]
  
  data = data.sort((a,b) => {
      let dt =a.date.split('-'); 
      let dt2 =b.date.split('-');

      let aMs = new Date(dt[2], dt[1] - 1, dt[0])
      let bMs = new Date(dt2[2], dt2[1] - 1, dt2[0])
      return  aMs - bMs
    })


  
    sheet.addTable({
      name: 'Report',
      ref: "A1", 
      headerRow: true,
      totalsRow: false,
      style: {
        theme: "TableStyleMedium2",
        showRowStripes: false,
        width: 200
      },
      columns : colHeader,
      //rows: [filterData]
      rows: data.map((e) => {
        let loc=[]
        let arr = [e.date ,e.emp_name,e.tag_serial,e.department,e.sub_department];      
        for (let i =0;i<filterbyCoumn.length;i++){
          arr.push(e[filterbyCoumn[i]]+"%")
        } 
        

        return arr;
      })
    })
  

  
  
  
    const table = sheet.getTable(headerName);
    for (let i = 0; i < table.table.columns.length; i++) {
      sheet.getCell(`${String.fromCharCode(65 + i)}1`).font = { size: 12 };
      sheet.getCell(`${String.fromCharCode(65 + i)}1`).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "c5d9f1" }
      };
  
      for (let j = 0; j < table.table.rows.length; j++) {
        let rowCell = sheet.getCell(`${String.fromCharCode(65 + i)}${j + 6}`);
        rowCell.alignment = { wrapText: true };
        rowCell.border = {
          bottom: {
            style: "thin",
            color: { argb: "a6a6a6" }
          }
        };
      }
    }
    sheet.columns.forEach(column => {
      const lengths = column.values.map(v => v.toString().length);
      const maxLength = Math.max(...lengths.filter(v => typeof v === 'number'));
      column.width = maxLength+2;
    });
    table.commit();
  
    const writeFile = (fileName, content) => {
      const link = document.createElement("a");
      const blob = new Blob([content], {
        type: "application/vnd.ms-excel;charset=utf-8;"
      });
      link.download = fileName;
      link.href = URL.createObjectURL(blob);
      link.click();
    };
  
    workbook.xlsx.writeBuffer().then((buffer) => {
      writeFile(sheetName, buffer);
    });
    
  }

  function SetMin(val){
    if(val==''){
      SetFilterMin(val)    
    }
    val=parseInt(val);
    if(val >= 0 && val<= filter_max){
      SetFilterMin(val)    
  
    }
  }

  function SetMax(val){
    if(val==''){
      SetFilterMax(val)    
    }
    val= parseInt(val);
    if(val > 0 && val >=  filter_min){
      SetFilterMax(val)    
  }else{

  }

  }
  const exportToExcel = () => {
    let dp = department_id.length>1?"Multiple":department_id[0];
    //console.log(department_id,dp)
    let sheetName = userDetails.empName+"-"+moment(dates.start).format("MMM DD")+"-"+moment(dates.end).format("MMM DD")+"-"+dp+".xlsx";
    let headerName= "Report"
    const filename = userDetails.empName+"-"+moment(dates.start).format("MMM DD")+"-"+moment(dates.end).format("MMM DD")+"-"+department_id.length>0?"Multiple":department_id[0]+".xlsx";
    const workbook = new ExcelJs.Workbook();
  
    let sheet = workbook.addWorksheet("SubAll", {
      views: [{ showGridLines: true }]
    });

    let colHeader = []
    colHeader.push({name: 'Date', header: 'Date', key: 'date'}) 
    colHeader.push({name: 'Name', header: 'name', key: 'name'}) 
    colHeader.push({name: 'Tag Serial', header: 'tag_serial', key: 'tag_serial'}) 
    colHeader.push({name: 'Shift', header: 'shift', key: 'shift'}) 
    colHeader.push({name: 'Department', header: 'department', key: 'department'}) 
    colHeader.push({name: 'Sub Department', header: 'sub_department', key: 'sub_department'}) 

    
  
    for(let i=0;i< filterLocation.length;i++){

    let locationName=  location.find(item=>item.loc_tag==filterLocation[i] )
 
      if(locationName){
        colHeader.push({name: locationName.name, header: locationName.name, key: filterLocation[i]}) 
      
      }else{
        colHeader.push({name: filterLocation[i], header: filterLocation[i], key: filterLocation[i]}) 
       }
    }

    sheet.addTable({
      name: 'Report',
      ref: "A1", 
      headerRow: true,
      totalsRow: false,
      style: {
        theme: "TableStyleMedium2",
        showRowStripes: false,
        width: 200
      },
      columns : colHeader,
      //rows: [filterData]
      rows: filterData.map((e) => {
        let loc=[]
        let arr = [e.date ,e.name,e.tag_serial,e.shift,e.department,e.sub_department];      
        for (let i =0;i<filterLocation.length;i++){
          arr.push(e[filterLocation[i]])
        } 
        
        return arr;
      })
    })
  

  
  
  
    const table = sheet.getTable(headerName);
    for (let i = 0; i < table.table.columns.length; i++) {
      sheet.getCell(`${String.fromCharCode(65 + i)}1`).font = { size: 12 };
      sheet.getCell(`${String.fromCharCode(65 + i)}1`).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "c5d9f1" }
      };
  
      for (let j = 0; j < table.table.rows.length; j++) {
        let rowCell = sheet.getCell(`${String.fromCharCode(65 + i)}${j + 6}`);
        rowCell.alignment = { wrapText: true };
        rowCell.border = {
          bottom: {
            style: "thin",
            color: { argb: "a6a6a6" }
          }
        };
      }
    }
    sheet.columns.forEach(column => {
      const lengths = column.values.map(v => v.toString().length);
      const maxLength = Math.max(...lengths.filter(v => typeof v === 'number'));
      column.width = maxLength+2;
    });
    table.commit();
  
    const writeFile = (fileName, content) => {
      const link = document.createElement("a");
      const blob = new Blob([content], {
        type: "application/vnd.ms-excel;charset=utf-8;"
      });
      link.download = fileName;
      link.href = URL.createObjectURL(blob);
      link.click();
    };
  
    workbook.xlsx.writeBuffer().then((buffer) => {
      writeFile(sheetName, buffer);
    });
  };

  
  function AGGridEMptyMEssage(key){
    let msg;
        msg='<span class="ag-overlay-no-rows-center">No Records Found !</span>'
    return msg
  }


  function converttoTime(val){


      let seconds = val / 1000;
  const hours = parseInt( seconds / 3600 );
  seconds = seconds % 3600;
  const minutes = parseInt( seconds / 60 ); 
  seconds = seconds % 60;
  
  let dispHour = hours > 0 && hours >9?hours:hours <10?"0"+hours:"00"
  let dispMinute = minutes > 0 && minutes >9?minutes:minutes <10?"0"+minutes:"00"
  let dispSecond = seconds > 0 && seconds >9?seconds:seconds <10?"0"+seconds:"00"

//  return hours>0 ? hours +":"+ minutes+":"+seconds:"00:"+ minutes+":"+seconds
  return dispHour+":"+dispMinute+":"+dispSecond;

}

function onChangeDepartment(val){
  if(val.indexOf('all')> -1){
      let value =[];
      for(let i=0;i<department.length;i++){
        value.push(department[i].name)
      }
      if((val.length -1) == value.length ){
        SetDepartmentID([]);  
      }else{
        SetDepartmentID(value);
      }

      
  }else{
    SetDepartmentID(val);
  }
  SetSubDepartmentID([]);
  SetExportEnable(false)
}
function onChangeSubDepartment(val){
  //val=[val] 
  if(val.indexOf('all')> -1){
    let value =[];
    for(let i=0;i<sub_department.length;i++){
      value.push(sub_department[i])
    }
    if((val.length -1) == value.length ){
      SetSubDepartmentID([]);  
    }else{
      SetSubDepartmentID(value);
    }

    
}else{
  SetSubDepartmentID(val);
}
}

function exportEnableDisable(){
  SetExportEnableCycle(false);
}
function filterEnableDisable(){
  SetFilterEnableCycle(false);
}




  return (
    <div className="manpowerManagementMainDiv">
      <Container className="header-breadcrumb">
        <Row>
          <Col lg={6}>
            <CommonHeading title="Report" />
          </Col>
          <Col lg={6} className="text-right">
            <div className="commonLangaugeStyleDiv">
              <DashboardLanguage
                selectedLangValue={selectedLangValue}
                changeLanguage={changeLanguage}
              />
            </div>

            <div className="siteHeadingDatePickerDiv" style={{ width: "20%" }}>
              <CommonDatePicker
                selectedDate={selectedDate}
                handleSelectDate={handleDateSelect}
              />
            </div>
          </Col>
        </Row>





        <Row className="m-t">
          <Col lg={12}>   
          <div className="manpowerManagementEmployeeListMainDiv">
                <div className="subLineTab">
                <div className={filterActive=='working'?"sublinetablist active ":'sublinetablist '} onClick={()=>{SetFilterActive('working');SetShowData([]);SetShowDataDefault([]);SetExportEnable(false);SetFilterSLide([0,100])} } >Report by Working</div>
                <div className={filterActive=='location'?"sublinetablist active":'sublinetablist'} onClick={()=>{SetFilterActive('location');SetShowData([]);SetShowDataDefault([]);SetExportEnable(false)}}>Report by Location</div>
                <div className={filterActive=='cyclecount'?"sublinetablist active":'sublinetablist'} onClick={()=>{SetFilterActive('cyclecount');SetShowData([]);SetShowDataDefault([]);SetExportEnable(false);SetFilterSLide([0,60])}}>Report by Cycle Count</div>        
        </div>

        <div className="filterField">
                {filterActive=='location'?<Row className="">
                  <Col lg={1} className="p-r-0 report-filter">
                    <label>
                      {getTranslatedText("Start Date")}
                      </label>
                    <div
                      className="startDateEndDateMainDiv"
                      style={{ paddingLeft: "0px" }}
                    >
                      <DatePicker
                        selected={dates.start}
                        onChange={(date) => {handleDate(date, "start");SetExportEnable(false) }}
                        dateFormat={"MMM dd"}
                        isClearable={false}
                        placeholderText={getTranslatedText("Start Date")}
                        maxDate={moment(dates.end).toDate()}
                        minDate={moment().subtract(120, "days").toDate()}
                      />
                      {/* <div className="dropdownIconDiv">
                        <img src={dropdownIcon} />
                      </div> */}
                    </div>
                  </Col>
                  <Col lg={1} className="p-r-0 report-filter">
                      <label>
                        {getTranslatedText("End Date")}
                      </label>
                    <div
                      className="startDateEndDateMainDiv"
                      style={{ paddingLeft: "0px" }}
                    >
                      <DatePicker
                        selected={moment(dates.end).toDate()}
                        onChange={(date) =>{ handleDate(date, "end");SetExportEnable(false) }}
                        dateFormat={"MMM dd"}
                        isClearable={false}
                        placeholderText={getTranslatedText("End Date")}
                        maxDate={selectedDate}
                        minDate={dates.start}
                        
                      />
                      {/* <div className="dropdownIconDiv">
                        <img src={dropdownIcon} />
                      </div> */}
                    </div>
                  </Col>

                  <Col lg={3} className="p-r-0 report-filter">
                  <label>
                        {getTranslatedText("Department")}
                      </label>
                  <Select
                placeholder="Select Department"
                //optionFilterProp="children"
                onChange={(val)=>onChangeDepartment(val)}
                
                value={department_id}
                key="departmentFilterv1"
                style={{width:"100%"}}
                dropdownClassName={'smallerDrop'}
                //mode="multiple"
                mode="tags"
            >
              <Option value='all' key={"Department V1 All Report"}>Select All</Option>
                {
                department && department.length > 0? department.map((item)=>{
                    return <Option value={item.name} key={"Department V1"+item.name}>{item.name}</Option>
                }):""
                }
            </Select>        

        
                  </Col>

                  <Col lg={3} className="p-r-0 report-filter">
                  <label>
                        {getTranslatedText("Sub Department")}
                      </label>
                      <Select
                    placeholder="Select Sub Department"
                    optionFilterProp="children"
                    style={{width:"100%"}}
                    onChange={(val)=>onChangeSubDepartment(val)}
                    disabled={department_id.length>1 || department_id.length==0 ?true:false}                   
                    value={sub_department_id}
                    key="SubdepartmentFilterv1"
                    dropdownClassName={'smallerDrop'}
                    mode="multiple"
                >
                  <Option value='all' key={"Sub Department v1 All"}>Select All</Option>
                    {
                    sub_department && sub_department.length > 0? sub_department.map((item)=>{
                        return <Option value={item} key={"Sub Department V1"+item}>{item}</Option>
                    }):""
                    }
                    
                </Select>
 </Col>
 <Col lg={2} className="report-filter">
                  <label>
                        {getTranslatedText("Location")}
                      </label>
                  <Select
                placeholder="Select Location"
                optionFilterProp="children"
                onChange={(val)=>{SetLocationID(val); SetExportEnable(false) }  }
                //defaultValue={'Select Department'}
                value={location_id}
                key="departmentLocationFilterv1"
                style={{width:"100%"}}
                mode="multiple"
                dropdownClassName={'smallerDrop'}
                
            >
             {SetLocationID.length>0?"":<Option value='all' key={"Location v1 All"}>All</Option>} 
                {
                location && location.length > 0? location.map((item)=>{
                    return <Option value={item.loc_tag} key={"locationV1"+item.loc_tag}>{item.name}</Option>
                }):""
                }
            </Select>
      
</Col>

<Col lg={2} className="">
  <label>
    Action
  </label>
  <div className="btndiv">
                <button className="btn btn-submit btn-filter" onClick={()=>filterResultHandler(department_id,sub_department_id)} disabled={dates.start=='' || dates.end=='' || department_id=='' || location_id==''}>Submit</button>
                <button className="btn btn-submit btn-filter btn-export" onClick={exportToExcel}   disabled={!exportEnable} >Export</button>
                </div>
</Col>


                </Row>:
                filterActive=='working'?<Row className="">
                  <Col lg={1} className="p-r-0 report-filter">
                    <label>
                      {getTranslatedText("Start Date")}
                      </label>
                    <div
                      className="startDateEndDateMainDiv"
                      style={{ paddingLeft: "0px" }}
                    >
                      <DatePicker
                        selected={dates.start}
                        onChange={(date) => {handleDate(date, "start");SetExportEnable(false) }}
                        dateFormat={"MMM dd"}
                        isClearable={false}
                        placeholderText={getTranslatedText("Start Date")}
                        maxDate={moment(dates.end).toDate()}
                        minDate={moment().subtract(120, "days").toDate()}
                      />
                      {/* <div className="dropdownIconDiv">
                        <img src={dropdownIcon} />
                      </div> */}
                    </div>
                  </Col>
                  <Col lg={1} className="p-r-0 report-filter">
                      <label>
                        {getTranslatedText("End Date")}
                      </label>
                    <div
                      className="startDateEndDateMainDiv"
                      style={{ paddingLeft: "0px" }}
                    >
                      <DatePicker
                        selected={moment(dates.end).toDate()}
                        onChange={(date) =>{ handleDate(date, "end");SetExportEnable(false) }}
                        dateFormat={"MMM dd"}
                        isClearable={false}
                        placeholderText={getTranslatedText("End Date")}
                        maxDate={selectedDate}
                        minDate={dates.start}
                        
                      />
                      {/* <div className="dropdownIconDiv">
                        <img src={dropdownIcon} />
                      </div> */}
                    </div>
                  </Col>

                  <Col lg={report_by.indexOf('Percentage') > -1?3:2 } className="p-r-0 report-filter">
                  <label>
                        {getTranslatedText("Department")} 
                      </label>
                  <Select
                placeholder="Select Department"
                optionFilterProp="children"
                onChange={(val)=>onChangeDepartment(val)}
                //defaultValue={department_id}
                value={department_id}
                key="departmentFilterv2"
                style={{width:"100%"}}
                dropdownClassName={'smallerDrop'}
                mode={"multiple"}
            >
              <Option value='all' key={"Department All v2"}>Select All</Option>
                {
                department && department.length > 0? department.map((item)=>{
                    return <Option value={item.name} key={"Department v2"+item.name}>{item.name}</Option>
                }):""
                }
            </Select>        
                  </Col>

                  <Col lg={report_by.indexOf('Percentage') > -1?3:2 } className="p-r-0 report-filter">
                  <label>
                        {getTranslatedText("Sub Department")}
                      </label>
                      <Select
                    placeholder="Select Sub Department"
                   
                    style={{width:"100%"}}
                    onChange={(val)=>onChangeSubDepartment(val)}
                    //defaultValue={'Select Sub Department'}
                    disabled={department_id.length>1 || department_id.length==0 ?true:false}
                    value={sub_department_id}
                    key="SubdepartmentFilterv2"
                    dropdownClassName={'smallerDrop'}
                    mode="multiple"
                >
                  <Option value='all' key={"Sub Department All"}>SelectAll</Option>
                    {
                    sub_department && sub_department.length > 0? sub_department.map((item)=>{
                        return <Option value={item} key={"Sub Department"+item}>{item}</Option>
                    }):""
                    }
                </Select>
 </Col>

 <Col lg={report_by.indexOf('Percentage') > -1?2:2} className="report-filter report-filter-less">
                  <label>
                        {getTranslatedText("Filter By")}
                      </label>
                  <Select
                placeholder="Filter By"
                optionFilterProp="children"
                onChange={(val)=>{SetFilterby(val); SetExportEnable(false) }  }
                //defaultValue={'Select Department'}
                value={filterby}
                key="filterbyFilter"
                style={{width:"100%"}}
                mode={'multiple'}
                dropdownClassName={'smallerDrop'}
                
            >
              <Option value={'working_hour'} key={"Working Hour"}>{"Working Hour"}</Option>
              <Option value={'wasted_hour'} key={"wasted_hour Hour"}>{"Wasted Hour"}</Option>
              <Option value={'productive'} key={"Producivite Hour"}>{"Producivite Hour"}</Option>
              <Option value={'less_productive'} key={"Less Producivite Hour"}>{"Less Productive Hour"}</Option>
              <Option value={'non_productive'} key={"Non Producivite Hour"}>{"Non Productive Hour"}</Option>
              <Option value={'undefined'} key={"Out of Range Hour"}>{"Out of Range Hour"}</Option>
            </Select>
      
</Col>
<Col lg={2} className="report-filter report-filter-less">
<label>
                        {getTranslatedText("Report By")}
                      </label>
<div className="ant-checkbox-group">
                      <Checkbox defaultChecked={true}  checked={true}>Percentage</Checkbox>
                      <Checkbox defaultChecked={false}  disabled>Time</Checkbox>
                      </div>
                      {/* <Checkbox defaultChecked={false} disabled /> */}
 {/* <Checkbox.Group options={plainOptions} value={report_by} onChange={(val)=> {if(val.length>0){SetReportBy(val)} }} />    */}
 </Col>
 
 {filterby!=='all' && report_by.indexOf('Percentage') > -1 ?<Col lg={10} className='m-t'>
  <div className="mgSpace">
 <label>
    Filter by Min (<strong>{filterSlide[0]}</strong>) and Max (<strong>{filterSlide[1]}</strong>) 
  </label>
  <Slider range  marks={marks} value={filterSlide}   onChange={(val)=>{SetFilterSLide(val)}}  />
  </div>
 </Col>:""}


{/* <Col lg={2} className="report-filter">
  <div className="filterMinMax"><label>Min <strong>0</strong></label><input type="number" value={filter_min} className="form-control"onChange={(e)=>SetMin(e.target.value)}/> </div>
  <div className="filterMinMax"><label>Max <strong>100</strong></label><input type="number" value={filter_max} className="form-control"onChange={(e)=>SetMax(e.target.value)}/> </div>
</Col> */}
<Col lg={2} className={filterby!=='all' && report_by.indexOf('Percentage') > -1?'m-t':''}>
  <label>
    Action 
   </label>
            <div className="btndiv">
                  <button className="btn btn-submit btn-filter" onClick={filterResultHandler2} disabled={dates.start=='' || dates.end=='' || department_id=='' || filterby==''}>Submit {dates.start=='' || dates.end=='' || department_id=='' || filterby==''}</button>
                  <button className="btn btn-submit btn-filter btn-export" onClick={exportToExcelReport2}   disabled={!exportEnable} >Export</button>
              </div>
</Col>


                </Row>:
                <Row className="">
                <Col lg={2} className="p-r-0 report-filter">
                  <label>
                    {getTranslatedText("Start Date")}
                    </label>
                  <div
                    className="startDateEndDateMainDiv"
                    style={{ paddingLeft: "0px" }}
                  >
                    <DatePicker
                      selected={dates.start}
                      onChange={(date) => {handleDate(date, "start");SetExportEnable(false) }}
                      dateFormat={"MMM dd"}
                      isClearable={false}
                      placeholderText={getTranslatedText("Start Date")}
                      maxDate={moment(dates.end).toDate()}
                      minDate={moment().subtract(120, "days").toDate()}
                    />
                  </div>
                </Col>
                <Col lg={2} className="p-r-0 report-filter">
                    <label>
                      {getTranslatedText("End Date")}
                    </label>
                  <div
                    className="startDateEndDateMainDiv"
                    style={{ paddingLeft: "0px" }}
                  >
                    <DatePicker
                      selected={moment(dates.end).toDate()}
                      onChange={(date) =>{ handleDate(date, "end");SetExportEnable(false) }}
                      dateFormat={"MMM dd"}
                      isClearable={false}
                      placeholderText={getTranslatedText("End Date")}
                      maxDate={selectedDate}
                      minDate={dates.start}
                      
                    />
                  </div>
                </Col>

                <Col lg={3} className="p-r-0 report-filter">
                <label>
                      {getTranslatedText("Department")} 
                    </label>
                <Select
              placeholder="Select Department"
              optionFilterProp="children"
              onChange={(val)=>onChangeDepartment(val)}
              //defaultValue={department_id}
              value={department_id}
              key="departmentFilterv2"
              style={{width:"100%"}}
              dropdownClassName={'smallerDrop'}
              mode={"multiple"}
          >
            <Option value='all' key={"Department All v2"}>Select All</Option>
              {
              department && department.length > 0? department.map((item)=>{
                  return <Option value={item.name} key={"Department v2"+item.name}>{item.name}</Option>
              }):""
              }
          </Select>        
                </Col>

                <Col lg={3} className="p-r-0 report-filter">
                <label>
                      {getTranslatedText("Sub Department")}
                    </label>
                    <Select
                  placeholder="Select Sub Department"
                 
                  style={{width:"100%"}}
                  onChange={(val)=>onChangeSubDepartment(val)}
                  //defaultValue={'Select Sub Department'}
                  disabled={department_id.length>1 || department_id.length==0 ?true:false}
                  value={sub_department_id}
                  key="SubdepartmentFilterv2"
                  dropdownClassName={'smallerDrop'}
                  mode="multiple"
              >
                <Option value='all' key={"Sub Department All"}>SelectAll</Option>
                  {
                  sub_department && sub_department.length > 0? sub_department.map((item)=>{
                      return <Option value={item} key={"Sub Department"+item}>{item}</Option>
                  }):""
                  }
              </Select>
</Col>
<Col lg={2} className="report-filter">
                  <label>
                        {getTranslatedText("Location")}
                      </label>
                  <Select
                placeholder="Select Location"
                optionFilterProp="children"
                onChange={(val)=>{SetLocationID(val); SetExportEnable(false) }  }
                //defaultValue={'Select Department'}
                value={location_id}
                key="departmentLocationFilterv1"
                style={{width:"100%"}}
                mode="multiple"
                dropdownClassName={'smallerDrop'}
                
            >
            <Option value='all' key={"Location v1 All"}>All</Option>
                {
                location && location.length > 0? location.map((item)=>{
                    return <Option value={item.loc_tag} key={"locationV1"+item.loc_tag}>{item.name}</Option>
                }):""
                }
            </Select>
      
</Col>




<Col lg={10} className='m-t'>
<div className="mgSpace">
<label>
  Filter by Min (<strong>{filterSlide[0]}</strong>) and Max (<strong>{filterSlide[1]}</strong>) 
</label>
<Slider range min={0} max={60}  marks={marks2} value={filterSlide}   onChange={(val)=>{SetFilterSLide(val)}}  />
</div>
</Col>


<Col lg={2} className={filterby!=='all' && report_by.indexOf('Percentage') > -1?'m-t':''}>
<label>
  Action 
 </label>
          <div className="btndiv">
                <button className="btn btn-submit btn-filter" onClick={()=>SetFilterEnableCycle(true)} disabled={dates.start=='' || dates.end=='' || department_id=='' || filterby==''}>Submit {dates.start=='' || dates.end=='' || department_id=='' || filterby==''}</button>
                <button className="btn btn-submit btn-filter btn-export" onClick={()=>SetExportEnableCycle(true)}   disabled={!exportEnable} >Export</button>
            </div>
</Col>


              </Row>
                
                }
                </div> 

                </div>
          </Col>
        </Row>
        < Row className="m-t" >
                    <Col lg={12} className={props.hideHeading ? 'p-l-0 p-r-0' : ''}>
                        <div className="listingRecordMainDiv manpowerManagementEmployeeListMainDiv" ref={elementRef}>
                        
                            {isLoading?
                                <div className="text-center m-t-lg">
                                    <img src={spinnerLoader} className="m-t-lg" />
                                </div> :
                                isLoading2?
                                  <div className="text-center loader-filter m-t-lg">
                                      <img src={spinnerLoader} className="m-t-lg" />
                                      <div className="loadingInfo">
                                        <h4>
                                            <span>Please wait</span>
                                             <span className="loading-dots">
                                                    <div className="loading-dots--dot"></div>
                                                    <div className="loading-dots--dot"></div>
                                                    <div className="loading-dots--dot"></div>
                                              </span>
                                          </h4> 
                                        <div className="loadingInner">Anlysis of date <strong className="pink">{dateCount}</strong> <br/>
                                        <strong className="pink">{count}</strong> out of <strong className="pink">{totalcount}</strong> is in progress</div>
  
                                      </div>
                                        
                                  </div> :
                                <React.Fragment>
                                    {isLoading3?
                                    <div className="loadingInfo loadingInfo2">
                                        <h4>
                                            <span>Please wait</span>
                                             <span className="loading-dots">
                                                    <div className="loading-dots--dot"></div>
                                                    <div className="loading-dots--dot"></div>
                                                    <div className="loading-dots--dot"></div>
                                              </span>
                                          </h4> 
                                        <div className="loadingInner">Anlysis of date <strong className="pink">{dateCount}</strong> <br/>
                                        {filterActive=='location'?<><strong className="pink">{count}</strong> out of <strong className="pink">{totalcount}</strong> is in progress</>:<><strong className="pink">Data Analaysis</strong> is in progress</>}</div>
  
                                      </div>:""}
                            
                                   
                              <div className="rportArea">
                              {errorMessage}
                                { !isLoading3 && exportEnable  && filterActive=='working'?
                                <div className="reportChart">
                                  <div className="accordionHeader" onClick={()=>{SetChart1(!chart1);SetChartLoader(false); }}>                                      
                                      {chart1?<Minus/>:<Plus/>}Chart Compare by <span style={{color:"#ef5e8c"}}>Function</span>
                                 </div>
                                 {chart1?<div className="accordionContentReport">
                                  <div className="buttonFilterArea">
                              {filterbyCoumn.map(item =>{
                                let text= 'Working Hour';

                                if(item =='wasted_hour'){
                                  text= 'Wasted Hour';
                                }else if(item =='productive'){
                                  text= 'Productive Hour';
                                }
                                else if(item =='less_productive'){
                                  text= 'Less Productive';
                                }
                                else if(item =='non_productive'){
                                  text= 'Non Productive';
                                }
                                else if(item =='undefined'){
                                  text= 'Out of Range';
                                }
                                return <button className={activeChart==item?'buttonChartFilter buttonChartFilterActive button btn':'buttonChartFilter button btn'} onClick={()=>SetActiveChart(item)}>{text} </button>
                              })}</div>
{chartLoader?
<div className="text-center m-t-lg">
                                    <img src={spinnerLoader} className="m-t-lg" />
                                </div>:
                                   <ChartReport chartData={chartData} close={()=>SetChart1(false)} key={"reportV1"} />}
                                 </div>:""}


                                 </div>:""}

{/* Chart 2 UI */}
{!isLoading3 && exportEnable  && filterActive=='working'?
<div className="reportChart" style={{marginTop:"20px"}}>
                                  <div className="accordionHeader" onClick={()=>{SetChart2(!chart2);SetChartLoader2(false); }}>                                      
                                      {chart2?<Minus/>:<Plus/>}Chart Compare by <span style={{color:"#ef5e8c"}}>Work Status</span>
                                 </div>
                                 {chart2?<div className="accordionContentReport">
                                  <div className="buttonFilterArea">
                              {filterBtn.map(item =>{
                              
                                return <button className={activeChart2==item?'buttonChartFilter buttonChartFilterActive button btn':'buttonChartFilter button btn'} onClick={()=>SetActiveChart2(item)}>{item} </button>
                              })}</div>
{chartLoader2?
<div className="text-center m-t-lg">
                                    <img src={spinnerLoader} className="m-t-lg" />
                                </div>:
                                   <ChartReport chartData={chartData2} close={()=>SetChart1(false)} key={"reportV2"} />}
                                 </div>:""}
                                 

                                 </div>:""}
</div>
          {filterActive!=='cyclecount'?
            <div style={{marginTop:"20px"}}>
                {showDataDefault}
             </div>:
             <CycleCount 
                role={props.role}
                exportEnableDisable={exportEnableDisable}
                filterEnableDisable={filterEnableDisable}
                min={filterSlide[0]}
                max={filterSlide[1]}
                locationList={location}
                location_id={location_id}
                date={dates}
                department_id= {department_id}
                sub_department_id= {sub_department_id}
                filterEnableCycle ={filterEnableCycle}
                exportEnableCycle = {exportEnableCycle}
              />}

                                </React.Fragment> 
                            }

                        </div>
                    </Col>
                </Row>
      </Container>


      
    </div>
  );
}

const mapStateToProps = (state) => ({
  language: state.dashboard.selectedLangaugeValue,
});

export default connect(mapStateToProps, { setSelectedLanguage })(
  withRouter(ReportExport)
);
