import axios from "axios";


let prefixURL = process.env.REACT_APP_URL


export function getMovementData(requestBody , sessionID, id) {
    
    return axios.get(prefixURL + `/get_employee_movement_report?session=${sessionID}&date=${requestBody.date}&org_id=${id}&start_time=${requestBody.start_time}&end_time=${requestBody.end_time}&tag_serial=${requestBody.tag_serial}`)
        .then(res => res.data).catch(err => err)
}
